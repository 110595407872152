import styled from 'styled-components';
import {
  colorState,
  device,
  radius,
  spacing,
  typography
} from '../../../Universal/Foundation';

interface TableSliderProps {
  isSideNav?: boolean;
  registerUsersTabs?: boolean;
}

export const ComposeMessageStyles = styled.section`
  .messages-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.medium};
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  .messages-card {
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
    height: fit-content;
    box-shadow: none;
    padding: 12px;
    margin-bottom: ${spacing.spacing_24}px;

    section {
      border-bottom: 1px solid ${colorState.border.default.secondary};

      p {
        font-size: ${typography.size.ts};
        color: ${colorState.text.secondary};
        font-weight: ${typography.weight.medium};
        padding: 8px;
        margin-bottom: 8px;
        margin-top: 8px;
        cursor: pointer;

        &:hover {
          background-color: ${colorState.surface.brand.tertiary};
          border-radius: 8px;
        }

        span {
          color: ${colorState.text.brand['text-brand']};
          cursor: pointer;
        }
      }
    }

    section:nth-child(even) {
      p {
        background-color: ${colorState.surface.default.tertiary};
        border-radius: 8px;
      }
    }

    section:last-child {
      border: none;
    }

    .view-details-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .option-icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border: 1px solid transparent;

        &:hover,
        &:focus,
        &:active {
          border-radius: 8px;
          border: 1px solid ${colorState.border.brand.primary};
        }
      }

      &:focus-visible {
        outline: none;
        border: solid 1px ${colorState.border.brand.primary};
        border-radius: 8px;
      }
    }
  }
`;

export const ContactHistoryStyles = styled.section`
  min-height: 200px;
  max-height: 300px;

  .contact-details {
    h6 {
      font-size: ${typography.size.txs};
      font-weight: ${typography.weight.regular};
      color: ${colorState.text.tertiary};
    }

    p {
      font-size: ${typography.size.txs};
      font-weight: ${typography.weight.semibold};
      line-height: 16px;
      color: ${colorState.text.primary};
      white-space: pre-wrap;
    }
  }

  .my-messages,
  .incoming-messages {
    p {
      padding: ${spacing.spacing_8}px;
      border-radius: ${spacing.spacing_12}px;
      font-size: ${typography.size.txs};
      font-weight: ${typography.weight.medium};
      line-height: 18px;
      white-space: pre-wrap;
    }
  }

  .my-messages {
    p {
      background-color: ${colorState.surface.default['selected-brand-bg']};
    }
  }

  .incoming-messages {
    p {
      background-color: ${colorState.surface.default.tertiary};
    }
  }

  .message-date-time,
  .user-name,
  .mark-as-read-text {
    font-size: ${typography.size.txs};
    font-weight: ${typography.weight.regular};
  }

  .message-date-time,
  .user-name {
    color: ${colorState.text.tertiary};
  }

  .user-name,
  .mark-as-read-text {
    word-break: break-all;
    white-space: wrap;
    padding-left: 20px;
  }

  .mark-as-read-text {
    color: ${colorState.text.link};
    text-decoration: underline;
    cursor: pointer;
  }

  .unread-msg {
    color: ${colorState.text.secondary};
  }

  .read-msg {
    color: ${colorState.text.disabled};
  }

  .read-msg,
  .unread-msg {
    overflow: unset;
    word-break: break-word;
  }
`;

export const TableSliderStyles = styled.div<TableSliderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.3em 0.4em;
  background-color: ${colorState.surface.default.tertiary};
  border-bottom: 1px solid ${colorState.border.default.secondary};

  &:focus-visible {
    outline: none;
    border: solid 1px ${colorState.border.brand.primary};
    border-radius: ${spacing.spacing_8}px;
  }

  @media ${device.mobileM} {
    justify-content: center;
  }

  .left-wrapper {
    display: inline-block;
    align-items: center;
    width: ${(props) => (props.isSideNav ? '55%' : '60%')};

    @media ${device.laptopXL} {
      width: 65%;
    }

    &:focus-visible {
      outline: none;
      border: solid 1px ${colorState.border.brand.primary};
      border-radius: ${radius.radius_28}px;
    }

    .slider-container {
      padding: 0 24px;
      width: 100%;

      .slick-arrow:focus-visible {
        border: solid 2px black;
      }

      .slick-prev:before,
      .slick-next:before {
        display: none;
      }
      .slick-list {
        margin: 0 -5px;
      }
      .slick-slide > div {
        margin: 0 5px;
      }
      .disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .slick-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .right-wrapper {
    padding-left: 20px;
    display: inline-block;
    align-items: center;

    .custom-select-width {
      width: 200px;
    }

    h3 {
      margin-bottom: 0;
      text-wrap: nowrap;
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.medium};
      color: ${colorState.text['text-cursor-black']};
    }

    &:focus-visible {
      outline: none;
      border: solid 1px ${colorState.border.brand.primary};
      border-radius: ${radius.radius_28}px;
    }
    .participant-variant-tab {
      color: ${colorState.text.brand.primary};
      background-color: ${colorState.actions.secondary['secondary-bg-pressed']};
    }

    @media ${device.laptopL} {
      display: flex;
      flex-wrap: wrap;
    }

    .more-options-tabs,
    .compose-more-options {
      align-items: center;
      cursor: pointer;
      position: relative;

      .text-more {
        color: ${colorState.text.secondary};
        font-size: ${typography.size.ts};
        font-weight: ${typography.weight.medium};
      }

      .option-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
      }

      &:hover {
        .option-icon svg path,
        .text-more {
          color: ${colorState.text.brand.primary};
          stroke: ${colorState.text.brand.primary};
        }
      }
    }
    @media ${device.laptopXL} {
      .more-options-tabs {
        display: flex !important;
      }
    }
  }

  @media (min-width: 1601px) {
    .right-wrapper-tabs {
      display: flex !important;
      gap: 8px;
    }
  }
`;
