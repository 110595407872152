const colorpalette = {
  brand_traveliq: {
    hex_50: '#EDF3FD',
    hex_100: '#D1DFFA',
    hex_200: '#B6CCF6',
    hex_300: '#89ABF1',
    hex_400: '#5C8BEB',
    hex_500: '#2464E4',
    hex_600: '#225BCE',
    hex_700: '#1746A6',
    hex_800: '#14377D',
    hex_900: '#0F2A60',
    hex_950: '#0B1832'
  },
  base_gray: {
    hex_50: '#F9FAFB',
    hex_100: '#F3F4F6',
    hex_200: '#E8EAED',
    hex_300: '#D1D5DB',
    hex_400: '#9CA3AF',
    hex_500: '#687080',
    hex_600: '#4B5563',
    hex_700: '#374151',
    hex_800: '#1F2937',
    hex_900: '#111827',
    hex_950: '#090D15'
  },
  success: {
    hex_50: '#EBF8F1',
    hex_100: '#D6F0E1',
    hex_200: '#ADE1C3',
    hex_300: '#85D2A5',
    hex_400: '#5CC387',
    hex_500: '#33B469',
    hex_600: '#247F4B',
    hex_700: '#1A5A35',
    hex_800: '#166534',
    hex_900: '#244834'
  },
  info: {
    hex_50: '#EFF6FF',
    hex_100: '#DBEAFE',
    hex_200: '#BFDBFE',
    hex_300: '#93C5FD',
    hex_400: '#60A5FA',
    hex_500: '#3B82F6',
    hex_600: '#2563EB',
    hex_700: '#1D4ED8',
    hex_800: '#1E40AF',
    hex_900: '#1E3A8A'
  },
  danger: {
    hex_50: '#FEF2F2',
    hex_100: '#FEE2E2',
    hex_200: '#FECACA',
    hex_300: '#FCA5A5',
    hex_400: '#F87171',
    hex_500: '#EF4444',
    hex_600: '#DC2626',
    hex_700: '#B91C1C',
    hex_800: '#991B1B',
    hex_900: '#7F1D1D'
  },
  warning: {
    hex_50: '#FFFBEB',
    hex_100: '#FEF3C7',
    hex_200: '#FDE68A',
    hex_300: '#FCD34D',
    hex_400: '#FBBF24',
    hex_500: '#F59E0B',
    hex_600: '#D97706',
    hex_700: '#B45309',
    hex_800: '#92400E',
    hex_900: '#78350F'
  },
  base: {
    white: '#FFFFFF',
    black: '#000000'
  }
};

export const colorState = {
  surface: {
    default: {
      primary: `${colorpalette.base.white}`,
      secondary: `${colorpalette.base_gray.hex_50}`,
      tertiary: `${colorpalette.base_gray.hex_100}`,
      moderate: `${colorpalette.base_gray.hex_300}`,
      'topnav-bg-light': `${colorpalette.base.white}`,
      'sidenav-bg-light': `${colorpalette.base.white}`,
      'primary-inverse': `${colorpalette.base.black}`,
      'selected-brand-bg': `${colorpalette.brand_traveliq.hex_50}`,
      'topnav-bg-dark': `${colorpalette.brand_traveliq.hex_950}`,
      'sidenav-bg-dark': `${colorpalette.brand_traveliq.hex_950}`,
      'focus-fill': `${colorpalette.base.white}1`
    },
    brand: {
      primary: `${colorpalette.brand_traveliq.hex_500}`,
      secondary: `${colorpalette.brand_traveliq.hex_300}`,
      tertiary: `${colorpalette.brand_traveliq.hex_50}`,
      'primary-dark': `${colorpalette.brand_traveliq.hex_600}`
    },
    info: {
      primary: `${colorpalette.info.hex_600}`,
      secondary: `${colorpalette.info.hex_300}`,
      tertiary: `${colorpalette.info.hex_50}`
    },
    success: {
      primary: `${colorpalette.success.hex_600}`,
      secondary: `${colorpalette.success.hex_300}`,
      tertiary: `${colorpalette.success.hex_50}`
    },
    warning: {
      primary: `${colorpalette.warning.hex_500}`,
      secondary: `${colorpalette.warning.hex_300}`,
      tertiary: `${colorpalette.warning.hex_50}`
    },
    danger: {
      primary: `${colorpalette.danger.hex_500}`,
      secondary: `${colorpalette.danger.hex_300}`,
      tertiary: `${colorpalette.danger.hex_50}`
    },
    user: {
      orange: `${colorpalette.brand_traveliq.hex_500}`,
      blue: `${colorpalette.info.hex_500}`,
      green: `${colorpalette.success.hex_500}`,
      yellow: `${colorpalette.warning.hex_500}`,
      red: `${colorpalette.danger.hex_500}`
    }
  },
  icon: {
    default: {
      primary: `${colorpalette.base_gray.hex_900}`,
      'opacity-disabled': `${colorpalette.base.white}60`,
      secondary: `${colorpalette.base_gray.hex_700}`,
      tertiary: `${colorpalette.base_gray.hex_500}`,
      'primary-inverse': `${colorpalette.base.white}`,
      disabled: `${colorpalette.base_gray.hex_400}`
    },
    brand: {
      'primary-disabled': `${colorpalette.brand_traveliq.hex_500}60`,
      'on-secondary-action': `${colorpalette.brand_traveliq.hex_500}`,
      primary: `${colorpalette.brand_traveliq.hex_500}`,
      tertiary: `${colorpalette.brand_traveliq.hex_300}`
    },
    danger: {
      secondary: `${colorpalette.danger.hex_500}`,
      'danger-disabled': `${colorpalette.danger.hex_500}60`,
      primary: `${colorpalette.danger.hex_600}`,
      tertiary: `${colorpalette.danger.hex_300}`
    },
    warning: {
      primary: `${colorpalette.warning.hex_500}`,
      'warning-disabled': `${colorpalette.warning.hex_500}60`,
      'primary-strong': `${colorpalette.warning.hex_600}`,
      tertiary: `${colorpalette.warning.hex_300}`
    },
    success: {
      primary: `${colorpalette.success.hex_500}`,
      'success-disabled': `${colorpalette.success.hex_500}60`,
      'primary-strong': `${colorpalette.success.hex_600}`,
      tertiary: `${colorpalette.success.hex_300}`
    },
    info: {
      primary: `${colorpalette.info.hex_500}`,
      'info-disabled': `${colorpalette.info.hex_500}60`,
      'primary-strong': `${colorpalette.info.hex_600}`,
      tertiary: `${colorpalette.info.hex_300}`
    }
  },
  text: {
    success: {
      primary: `${colorpalette.success.hex_600}`,
      'success-disabled': `${colorpalette.success.hex_600}60`,
      secondary: `${colorpalette.success.hex_500}`,
      tertiary: `${colorpalette.success.hex_300}`
    },
    danger: {
      primary: `${colorpalette.danger.hex_700}`,
      secondary: `${colorpalette.danger.hex_400}`,
      'danger-disabled': `${colorpalette.danger.hex_500}60`,
      tertiary: `${colorpalette.danger.hex_300}`
    },
    warning: {
      primary: `${colorpalette.warning.hex_700}`,
      'warning-disabled': `${colorpalette.warning.hex_500}60`,
      secondary: `${colorpalette.warning.hex_500}`,
      tertiary: `${colorpalette.warning.hex_300}`
    },
    secondary: `${colorpalette.base_gray.hex_700}`,
    tertiary: `${colorpalette.base_gray.hex_500}`,
    disabled: `${colorpalette.base_gray.hex_400}`,
    primary: `${colorpalette.base_gray.hex_900}`,
    'opacity-disabled': `${colorpalette.base.white}60`,
    'text-cursor-brand': `${colorpalette.brand_traveliq.hex_500}`,
    link: `${colorpalette.info.hex_700}`,
    brand: {
      'on-secondary-action': `${colorpalette.brand_traveliq.hex_500}`,
      'text-brand': `${colorpalette.brand_traveliq.hex_500}`,
      primary: `${colorpalette.brand_traveliq.hex_600}`,
      'on-secondary-action-disabled': `${colorpalette.brand_traveliq.hex_500}60`,
      tertiary: `${colorpalette.brand_traveliq.hex_300}`,
      secondary: `${colorpalette.brand_traveliq.hex_500}`
    },
    'input-placeholder': `${colorpalette.base.white}`,
    'text-cursor-black': `${colorpalette.base.black}`,
    info: {
      'info-disabled': `${colorpalette.info.hex_500}60`,
      primary: `${colorpalette.info.hex_700}`,
      tertiary: `${colorpalette.info.hex_300}`
    },
    'primary-inverse': `${colorpalette.base.white}`
  },
  border: {
    default: {
      primary: `${colorpalette.base_gray.hex_300}`,
      secondary: `${colorpalette.base_gray.hex_200}`,
      tertiary: `${colorpalette.base_gray.hex_100}`,
      strong: `${colorpalette.base_gray.hex_500}`,
      white: `${colorpalette.base.white}`,
      hairline: `${colorpalette.base_gray.hex_500}80`,
      'focusring-on-action': `${colorpalette.brand_traveliq.hex_500}`,
      'focusring-bg': `${colorpalette.base.white}`,
      'focusring-bg-on-dark': `${colorpalette.base.white}`,
      'focusring-on-inputs': `${colorpalette.brand_traveliq.hex_500}40`
    },
    brand: {
      primary: `${colorpalette.brand_traveliq.hex_500}`,
      secondary: `${colorpalette.brand_traveliq.hex_400}`,
      'primary-strong': `${colorpalette.brand_traveliq.hex_600}`,
      tertiary: `${colorpalette.brand_traveliq.hex_300}`
    },
    danger: {
      'primary-strong': `${colorpalette.danger.hex_600}`,
      primary: `${colorpalette.danger.hex_500}`,
      secondary: `${colorpalette.danger.hex_400}`,
      tertiary: `${colorpalette.danger.hex_300}`
    },
    warning: {
      'primary-strong': `${colorpalette.warning.hex_600}`,
      primary: `${colorpalette.warning.hex_500}`,
      secondary: `${colorpalette.warning.hex_400}`,
      tertiary: `${colorpalette.warning.hex_300}`
    },
    info: {
      'primary-strong': `${colorpalette.info.hex_600}`,
      primary: `${colorpalette.info.hex_500}`,
      secondary: `${colorpalette.info.hex_400}`,
      tertiary: `${colorpalette.info.hex_300}`
    },
    success: {
      'primary-strong': `${colorpalette.success.hex_600}`,
      primary: `${colorpalette.success.hex_500}`,
      secondary: `${colorpalette.success.hex_400}`,
      tertiary: `${colorpalette.success.hex_300}`
    }
  },
  background: {
    'default-page-bg': `${colorpalette.base_gray.hex_100}`,
    'secondary-bg': `${colorpalette.base_gray.hex_200}`,
    muted: `${colorpalette.base_gray.hex_50}`
  },
  actions: {
    default: {
      'default-bg': `${colorpalette.base_gray.hex_100}`,
      'default-bg-hover': `${colorpalette.base_gray.hex_200}`,
      'default-bg-pressed': `${colorpalette.base_gray.hex_300}`,
      'default-bg-disabled': `${colorpalette.base.white}`
    },
    primary: {
      'primary-bg': `${colorpalette.brand_traveliq.hex_500}`,
      'primary-bg-hover': `${colorpalette.brand_traveliq.hex_600}`,
      'primary-bg-pressed': `${colorpalette.brand_traveliq.hex_700}`,
      'primary-bg-disabled': `${colorpalette.brand_traveliq.hex_300}`,
      'primary-bg-selected': `${colorpalette.brand_traveliq.hex_700}`
    },
    secondary: {
      'secondary-bg': `${colorpalette.base.white}`,
      'secondary-bg-hover': `${colorpalette.brand_traveliq.hex_50}`,
      'secondary-bg-pressed': `${colorpalette.brand_traveliq.hex_100}`,
      'secondary-bg-disabled': `${colorpalette.base.white}`
    },
    tertiary: {
      'tertiary-bg': `${colorpalette.base.white}`,
      'tertiary-bg-hover': `${colorpalette.base_gray.hex_200}`,
      'tertiary-bg-pressed': `${colorpalette.base_gray.hex_300}`,
      'tertiary-bg-disabled': `${colorpalette.base.white}`
    },
    input: {
      primary: `${colorpalette.base.white}`,
      secondary: `${colorpalette.base_gray.hex_50}`,
      disabled: `${colorpalette.base_gray.hex_100}`,
      moderate: `${colorpalette.base_gray.hex_200}`
    },

    danger: {
      'danger-bg': `${colorpalette.danger.hex_600}`,
      'danger-bg-hover': `${colorpalette.danger.hex_700}`,
      'danger-bg-pressed': `${colorpalette.danger.hex_800}`,
      'danger-bg-disabled': `${colorpalette.danger.hex_300}`
    },
    warning: {
      'warning-bg': `${colorpalette.warning.hex_600}`,
      'warning-bg-hover': `${colorpalette.warning.hex_700}`,
      'warning-bg-pressed': `${colorpalette.warning.hex_800}`,
      'warning-bg-disabled': `${colorpalette.warning.hex_300}`
    },
    success: {
      'success-bg': `${colorpalette.success.hex_500}`,
      'success-bg-hover': `${colorpalette.success.hex_600}`,
      'success-bg-pressed': `${colorpalette.success.hex_700}`,
      'success-bg-disabled': `${colorpalette.success.hex_300}`
    },
    info: {
      'info-bg': `${colorpalette.info.hex_500}`,
      'info-bg-hover': `${colorpalette.info.hex_600}`,
      'info-bg-pressed': `${colorpalette.info.hex_700}`,
      'info-bg-disabled': `${colorpalette.info.hex_300}`
    },
    'danger-secondary': {
      'danger-bg': `${colorpalette.base.white}`,
      'danger-bg-hover': `${colorpalette.danger.hex_50}`,
      'danger-bg-pressed': `${colorpalette.danger.hex_100}`,
      'danger-bg-disabled': `${colorpalette.base.white}`
    },
    'warning-secondary': {
      'danger-bg': `${colorpalette.base.white}`,
      'danger-bg-hover': `${colorpalette.warning.hex_50}`,
      'danger-bg-pressed': `${colorpalette.warning.hex_100}`,
      'danger-bg-disabled': `${colorpalette.base.white}`
    },
    'default-bg-secondary': `${colorpalette.base.white}`
  },
  shadow: {
    brand: {
      primary: `${colorpalette.brand_traveliq.hex_100}`
    },
    default: {
      primary: `${colorpalette.base_gray.hex_100}`
    },
    danger: {
      primary: `${colorpalette.danger.hex_100}`
    }
  }
};
