const WebApp = {
  // General
  ERROR_MESSAGE: 'An Error Occurred!',

  // Login
  ENTER_6_DIGIT_VERIFICATION_CODE:
    'Enter the 6 digit verification code sent to your registered ',
  CODE_VALIDATION_FOR_5_MINUTES:
    'The verification code is valid for 5 minutes from the time it is sent',
  DIDNT_RECEIVE_CODE: `Didn't receive the code?`,
  RESEND_VERIFICATION_CODE: 'Resend Verification Code',
  GO_BACK_TO_LOGIN_PAGE: 'Go back to login page',
  GO_BACK: 'Go Back',
  TWO_FACTOR_AUTHENTICATION: 'Two-Factor Authentication',
  CLICK_SUBMIT_BUTTON: `Click 'Submit' button to receive verification on registered `,
  LOGIN: 'Login',
  PLEASE_LOGIN_BACK: 'Please login back',
  YOU_ARE_LOGGED_OUT_LOGIN_BACK: 'You are logged out. Please log back in!',
  LOGIN_TO_YOUR_ACCOUNT: 'Login to your account',
  LOG_IN_TO_YOUR_TRAINING_ACCOUNT: 'Log in to your Training Account',
  LOG_IN_TO_YOUR_LIVE_ACCOUNT: 'Log in to your Live Account',
  ENTER_EMAIL: 'Enter Email',
  RECAPTCHA_REQUIRED: 'Recaptcha required',
  LIVE: 'Live',
  TRAINING: 'Training',

  // Closures
  MAX_VERTICES_VALIDATION:
    'Oops! Shapes with more than 100 vertex points are not allowed.',
  MIN_VERTICES_VALIDATION:
    'Oops! Shapes with less than 3 vertex points are not allowed.',
  WITHIN_STATE_LINES_VERTICES: 'Closures must be drawn within the boundary',
  POLYGON_SHAPE_VALIDATION: 'Lines should not be intersecting.',
  CLOSURE_DETAILS: 'Closure Details',

  // ForgotPassword
  FORGOT_PASSWORD: 'Forgot Password?',
  MUST_SAME: 'New Password and Confirm Password Must be same',
  CHANGE_PASSWORD: 'Change Password',
  PASSWORD_MUST: 'Password Must',
  PASSWORD_LENGTH_VALIDAITON: 'Be at least 16 characters long',
  PASSWORD_UPPERCASE_VALIDATION: 'Have at least one upper case',
  PASSWORD_LOWERCASE_VALIDATION: 'Have at least one lower case',
  PASSWORD_NUMBER_VALIDATION: 'Have at least one number',
  PASSWORD_SPECIAL_CHARACTER_VALIDATION:
    'Have at least one special character (#$%@.,!^)',
  CHECK_YOUR_EMAIL: 'Check your Email',
  SUCCESSFUL_RESET_PASSWORD_TEXT:
    'We have successfully sent a password recover instruction in your registered mail ID.',
  GO_TO_LOGIN: 'Go to Login',
  PASSWORD_RESET_TEXT: `Please enter your email address below and click 'Submit' to receive a reset password link in your email.`,
  INVALID_EMAIL_FORMAT: 'Invalid email format',
  NEW_PASSWORD: 'New Password',
  ENTER_NEW_PASSWORD: 'Enter New Password',
  CREATE_NEW_PASSWORD: 'Create New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  ENTER_CONFIRM_PASSWORD: 'Enter Confirm Password',
  PLEASE_USE_LEGAL_SYMBOLS:
    'Special characters must only include the following characters: #$%@.,!^',

  // common
  PLEASE_SELECT_OPTION: 'Select option',
  SUBMIT: 'Submit',
  CANCEL: 'Cancel',
  PENNSYLVANIA_CONNECT_IS_A_PRODUCT: 'Pennsylvania Connect is a product of',
  ALERT_NOW_IS_A_PRODUCT_OF: 'Alert Now is a product of',
  FOR_MORE_DETAILS_VISIT: 'For more details visit',
  UPDATE: 'Update',
  DELETE: 'Delete',
  EDIT: 'Edit',
  ENABLE: 'Enable',
  BUTTON: 'button',
  ADD: 'Add',
  SELECT_CATEGORY: 'Select Category',
  COPY: 'Copy',
  NOTE: 'Note',
  YES: 'Yes',
  FAILED_TO_CONNECT:
    'Failed to connect to the server. Please try again in few minutes.',
  SAVE: 'Save',
  CLEAR: 'Clear',
  VIEW: 'View',
  SAVE_CHANGES: 'Save Changes',
  LOADING: 'Loading...',
  SAVING: 'Saving...',
  ADDING: 'Adding...',
  DELETING: 'Deleting...',
  UPDATING: 'Updating...',
  SUBMITTING: 'Submitting...',
  SENDING: 'Sending...',
  EDITING: 'Editing...',
  ENABLING: 'Enabling...',
  DISABLING: 'Disabling...',
  CLOSING: 'Closing...',
  APPROVING: 'Approving...',
  IN_PROGRESS: 'In Progress...',
  COLLAPSE: 'Collapse',
  EXPAND: 'Expand',

  // Map
  INCIDENT_DETAILS: 'Incident Details',
  CLOSE: 'Close',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  CURRENT: 'Current',
  UPDATED: 'Updated',
  TRAFFIC: 'Traffic',
  LEGEND: 'Legend',
  FREE_FLOWING: 'Free Flowing',
  MODERATE_TRAFFIC: 'Moderate Traffic',
  HEAVY_TRAFFIC: 'Heavy Traffic',
  STOP_AND_GO: 'Stop and Go',
  CLOSED: 'Closed',
  CURRENT_CONDITION: 'Current Condition',
  UPDATED_ON: 'Updated On',

  // myprofile
  NO_NAME: 'No Name',
  NOTIFICATIONS: 'Notifications',
  MY_PROFILE: 'My Profile',
  TRAINING_VIDEO: 'Training Video',
  HELP: 'Help',
  LOGOUT: 'Logout',

  // Closures
  CLOSURES: 'Closures',
  PLEASE_DRAW_A_POLYGON: 'Please draw a polygon',
  EVENT_ID: 'Event ID',
  ENTER_EVENT_ID: 'Enter Event ID',
  EVENT_MANAGED_BY: 'Event Managed By',
  THIS_FIELD_IS_REQUIRED: 'This field is required',
  CHARACTERS_REMAINING: 'Characters remaining',
  ENTER_TEXT: 'Enter Text',
  DESCRIPTION: 'Description',
  AFFECTED_AREA: 'Affected Area',
  SELECT_AFFECTED_AREA: 'Select Affected Area',
  DRAW_POLYGON_ON_MAP: 'Draw Polygon on Map',
  CHOOSE_FROM_SHAPES_LIBRARY: 'Choose from Shapes Library',
  SELECT_OPTION: 'Select option',
  CENTER_POINT_LATITUDE: 'Center Point Latitude',
  ENTER_CENTER_POINT_LATITUDE: 'Enter Center Point Latitude',
  CENTER_POINT_LONGITUDE: 'Center Point Longitude',
  ENTER_CENTER_POINT_LONGITUDE: 'Enter Center Point Longitude',
  SAVE_ALERT_POLYGON_FOR_FUTURE_USE: 'Save Alert Polygon For Future Use',
  POLYGON_NAME: 'Polygon Name',
  ENTER_POLYGON_NAME: 'Enter Polygon Name',
  SEND_IPAWS_ALERT: 'Send IPAWS Alert',
  REMEMBER_TO_CALL_PEMA: 'Remember to call PEMA',
  EVENT_CATEGORY: 'Event Category',
  ALERT_DURATION_HOUR_S: 'Alert Duration Hour(s)',
  ADD_NEW_CLOSURE: 'Add New Closure',
  CLOSE_THIS_EVENT: 'Close this Event',
  ARE_YOU_SURE_YOU_WANT_TO_CLOSE_THIS_EVENT:
    'Are you sure you want to close this event?',
  LAST_UPDATED: 'Last Updated',
  ACTION: 'Action',
  TEXT: 'Text',
  MESSAGE_WILL_BE_SENT_TO_ALL_THE_USERS:
    "This Message will be sent to all the registered users to inform them the event is now closed. Note: This closure will not be displayed once you click 'Close Event'",
  CHARACTERS_360: '360 characters',
  CLOSE_EVENT: 'Close Event',
  YES_CLOSE: 'Yes, Close',
  EVENT_CLOSED_SUCCESSFULLY: 'Event was successfully closed',
  DEFAULT_MESSAGE: 'Default Message',
  CLOSE_EVENT_DEFAULT_MESSAGE:
    '511PA: Operations are back to normal. You are opted out of services for this closure. Call 911 for Emergencies.',

  // Questions
  S_NO: 'S.NO',
  CATEGORY_NAME: 'Category Name',
  QUESTIONS: 'Questions',
  TYPE: 'Type',
  ABBREVIATED: 'Abbreviated',
  ACTIONS: 'Actions',
  NO_DATA_FOUND: 'No Data Found',
  ADD_NEW_QUESTION: 'Add New Question',
  ADD_NEW_CATEGORY: 'Add New Category',
  ADD_CATEGORY: 'Add Category',
  EDIT_CATEGORY: 'Edit Category',
  LIBRARY: 'library',
  EVENT: 'Event',
  ADD_QUESTION_TO_LIBRARY: 'Add Question to library',
  ADD_QUESTION_TO_CLOSURE: 'Add Ad-Hoc Question for this Closure',
  COPY_TO_THIS_EVENT: 'Copy to this Event',
  QUESTION_LIBRARY: 'Questions Library',
  THIS_EVENT_QUESTIONS: 'This Event Questions',
  SHOW_QUESTION_IN: 'Show Question in',
  MULTIPLE_CHOICE: 'Multiple Choice',
  MULTIPLE_CHOICES: 'Multiple choices',
  NUMERIC: 'Numeric',
  CHOICE_FOR_WEB: 'Choice (for Web)',
  ENTER_CHOICE_FOR_WEB: 'Enter Choice (for Web)',
  ABBREVIATED_QUESTION: 'Abbreviated Question',
  ADD_CHOICE: 'Add Choice',
  REMOVE_CHOICE: 'Remove Choice',
  YOUR_LIST_OF_QUESTIONS_ARE_EMPTY: 'Your list of questions are empty',
  YOUR_LIST_OF_QUESTIONS_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_QUESTIONS_YET:
    'Your list of questions are empty because you haven’t added any questions yet.',
  ARE_YOU_SURE_WANT_TO_DELETE_QUESTION: 'Are you sure want to delete question?',
  SUCCESSFULLY_ADDED_NEW_CATEGORY: 'Category list has been added Successfully',
  SUCCESSFULLY_UPDATED_CATEGORY: 'Category list has been updated Successfully',
  ARE_YOU_SURE_WANT_TO_DELETE_CATEGORY: 'Are you sure want to delete category?',
  YOUR_CATEGORY_LIST_ARE_EMPTY: 'Your Category lists are empty',
  YOUR_CATEGORY_LIST_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_CATEGORY_YET:
    'Your Category list are empty because you haven’t added any Category List yet.',
  ADD_THIS_QUESTION_CHOICES_TO_FILTER:
    'Also add this multiple choice filter to the registered users page in the closure.',

  // Register Users
  YES_SEND: 'Yes, Send',
  QUESTIONS_ADDED_SUCCESSFULLY: 'Questions have been added succesfully',
  QUESTIONS_ADDED_SUCCESSFULLY_TO_EVENT:
    'Questions have been added succesfully to the event',
  QUESTIONS_UPDATED_SUCCESSFULLY: 'Questions Updated Successfully',
  DELETED_SUCCESSFULLY: 'Deleted Successfully',
  UNABLE_TO_DELETE: 'Unable to Delete',
  ENTER_MIN_VALUE: 'Enter Min Value',
  MIN_VALUE: 'Min Value',
  MIN_VALUE_REQUIRED: 'Min value required',
  ENTER_MAX_VALUE: 'Enter Max Value',
  MAX_VALUE: 'Max Value',
  MAX_VALUE_REQUIRED: 'Max value required',
  MAX_SHUOULD_BE_GREATER_THAN: 'Max value should be greater than Min value',
  SUCCESSFULLY_SENT_TO_ALL_REGISTERED_USERS:
    'Successfully sent to all registered users.',
  LOCATION_NOT_SHARED: 'Location Not Shared',

  // Register Users
  COMPOSE_MESSAGE: 'Compose Message',
  SEND: 'Send',
  CONSERVE_YOUR_CELLPHONE: 'Conserve your cellphone battery.',
  DISPLAY_A_TROUBLE_SIGN:
    'Display a trouble sign (bright colored t-shirt, scarf) to indicate you need help.',
  CONSERVE_VEHICLE_FUEL:
    'Conserve vehicle fuel, limit your engine running time to 10 minutes each hour.',
  THE_WORDS_CALL:
    'The agency managing the event, example: “511 PennDOT: “ will be added to the beginning of the message and “Call 911 for emergencies.” will be appended to the end of the message.',
  LIFE_SAFETY_MESSAGES: 'Life Safety Messages',
  WRITE_TEXT_MESSAGE: 'Write a Text Message',
  CHARACTERS: 'Characters',
  ADD_TO_WEBSITE: 'Add to Website',
  MARK_MESSAGE: 'Mark this Message as a Safety Tip',
  ARE_YOU_SURE_WANT_TO_SEND_MESSAGE: 'Are you sure want to send message ?',
  DO_YOU_WANT_SEND_NEWLY_ADDED_QUESTION:
    'New question(s) added successfully. Do you want to send it to all the registered users?',
  ALL_MESSAGES: 'All  Messages',
  PUBLIC_SAFETY_ANNOUNCEMENT: 'Public Safety Announcements',
  SITUATIONAL_AWARNESS_UPDATES: 'Situational Awareness Updates',
  TEXT_COPIED: 'Text copied to clipboard',
  UNABLE_TO_COPY_TEXT: 'Unable to copy text',
  PLEASE_SELECT_ATLEAST_ONE_USER: 'Please select at least one user',
  SUCCESSFULLY_SENT: 'Successfully sent',
  FAILED_TO_SENT: 'Failed to sent',
  NEWLY_ADDED_ONLY: 'Newly Added (Not Yet Contacted) Only',
  NO_VALUE: 'N/A',
  NO: 'No',
  REGISTERED_USERS: 'Registered Users',
  NEW_PARTICIPANT: 'New Participant',
  NEW_PARTICIPANTS: 'New Participants',
  NO_REGISTERED_USERS: 'No Registered Users',
  CONTACT_HISTORY: 'Contact History',
  PHONE: 'Phone #',
  REQUEST_ALL_VEHICLE_LOCATIONS: 'Request All Vehicle Locations',
  ONLY: 'Only',
  MARK_AS_READ: 'Mark as read',
  REGISTRATION_COMPLETED: 'Registration Completed, ',
  IF_YOU_HAVE_AN_IMMEDIATE:
    'if you have an immediate emergency or medical need, Please call 911.',
  THANKYOU_YOUR_INFORMATION_HAS_BEEN_SUBMITTED:
    'Thank you. Your information has been submitted. ',
  GO_BACK_TO_CLOSURES_STATUS_PAGE: 'Go back to the Closures Status Page',
  GO_BACK_TO_PREVIOUS_PAGE: 'Go back to the Previous Page',
  YOUR_CURRENT_LOCATION_UPDATED:
    'Thank you! Your Current location has been updated in the system.',
  YOUR_CURRENT_LOCATION_REMOVED:
    'Thank you! Your location has been removed from the system.',
  USER_DETAILS: 'User Details',
  LAST_CONTACTED_ON: 'Last Contacted On',
  ARE_YOU_SURE: 'Are you sure?',
  REQUESTED_VEHICLES_LOCATION: 'Requested location of all vehicles',
  UNREGISTERED: 'Unregistered',
  SELECTED_USER_GOT_UNREGISTERED: 'Selected User got unregistered',
  USER_GOT_ACTIVATED: 'One of the users got activated.',
  USER_GOT_DEACTIVATED: 'One of the users got deactivated.',
  USER_UPDATED_LOCATION: 'One of the users updated their location.',
  LIFE_SAFETY_MESSAGE_DELETED_SUCCESSFULLY:
    'Life Safety Message has been deleted successfully',
  LIFE_SAFETY_MESSAGE_ADDED_SUCCESSFULLY:
    'Life Safety Message has been created successfully',
  LIFE_SAFETY_MESSAGE_EDITED_SUCCESSFULLY:
    'Life Safety Message has been edited successfully',
  ADD_MESSAGE: 'Add Message',
  MESSAGE: 'Message',

  //Messages
  MESSAGES: 'Messages',
  UNREAD_MESSAGES: 'Unread Messages',
  SELECT_ALL: 'Select All',
  MESSAGE_MARKED_READ: 'One of the messages marked as read.',
  MESSAGES_MARKED_AS_READ: 'Few messages are marked as read.',

  //Map Filters
  CLEAR_ALL: 'Clear All',
  PTC: 'PTC',
  PA_TURNPIKE: 'PA Turnpike',
  PENNDOT: 'PennDOT',
  LEGENDS: 'Legends',
  LEGENDS_UNAVAILABLE:
    'Legends are temporarily unavailable. Please start the traveliq to access legends.',

  //After Action Reports
  REQUESTED_ALERT_HOUR: 'Requested Alert Duration Hour(s)',

  // IPAWS Admin
  EVENT_DETAILS: 'Event Details',
  CREATED_BY: 'Created By',
  CMAM_WEA_TEXT: 'CMAM/WEA Text',
  CMAM_WEA_LONG_TEXT: 'CMAM/WEA Long Text',
  CMAM_SPANISH_WEA_TEXT: 'CMAM/WEA Text (Spanish)',
  CMAM_SPANISH_WEA_LONG_TEXT: 'CMAM/WEA Long Text (Spanish)',
  JURISDICTIONS_AFFECTED: 'Jurisdictions Affected',
  ALERT_DURATION_HOUR: 'Alert Duration Hour(s)',
  URGENCY: 'Urgency',
  SEVERITY: 'Severity',
  CERTAINITY: 'Certainty',
  WEA_HANDLING: 'WEA Handling',
  INCIDENT_STATUS: 'Incident Status',
  REQUESTED_ALERT_TYPE: 'Requested Alert Type',
  REQUESTED_BY: 'Requested By',
  ALERT_STATUS: 'Alert Status',
  IPAWS_REQUESTS_HISTORY: 'IPAWS Requests History',
  DISAPPROVE: 'Disapprove',
  APPROVE: 'Approve',
  SITREP: 'SITREP',
  REQUESTED_DATE_TIME: 'Requested Date/Time',
  POSTED_DATE_TIME: 'Posted Date/Time',
  ALERT_IDENTIFIER: 'Alert Identifier',
  POSTED_BY: 'Posted By',
  SUCCEEDED: 'Succeeded',
  REQUEST_CONTENT: 'Request Content',
  RESPONSE_CONTENT: 'Response Content',
  IPAWS_REQUESTS_HISTORY_FOR: 'IPAWS Requests History for',
  PASSWORD: 'Password',
  DISAPPROVAL_MESSAGE: 'Disapproval Message',
  APPROVAL_FORM_DESCRIPTION_TEXT:
    "Please fill the form below and click 'APPROVE' for the IPAWS alert confirmation",
  COG_ID: 'COG ID',
  EVENT_CERTIFICATE_EXPIRATION_DATE: 'Event Certificate Expiration Date',
  IPAWS_CONNECT_STATUS: 'IPAWS Connect Status',
  EMAIL_ADDRESS: 'Email Address',
  FORM_APPROVAL_DETAILS: 'Form Approval Details',
  DISAPPROVE_CONFIRMATION: 'Disapprove Confirmation',
  WRITE_A_TEXT_MESSAGE: 'Write a text message',
  IPAWS_HAS_BEEB_APPROVED: 'IPAWS has been approved.',
  IPAWS_HAS_BEEN_DISAPPROVED: 'IPAWS has been disapproved.',
  ADMIN_REQUEST_HISTORY: 'Admin Request History',
  IPAWS_ALERT_HAS_BEEN_CANCELLED: 'IPAWS Alert has been cancelled.',
  CONFIRM: 'Confirm',
  MARKING: 'Marking...',
  READ_BY: 'Read by',
  CREATE: 'Create',

  // Admin Users
  VIEW_PROFILE: 'View Profile',
  PERMISSION_LIST_WILL_BE_CHANGE_WHEN_SELECT_THE_USER_GROUP:
    'Permission list will be updated when the user group is changed',
  USER_GROUPS: 'User Groups',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  FIRST_NAME_IS_REQUIRED: 'First name is required.',
  LAST_NAME_IS_REQUIRED: 'Last name is required.',
  EMAIL: 'Email',
  CONTACT_NUMBER: 'Contact Number',
  ENTER_CONTACT_NUMBER: 'Enter Contact Number',
  SELECT_USER_GROUPS: 'Select User Groups',
  AGENCY: 'Agency',
  SEND_IPAWS_NOTIFICATIONS: 'Send IPAWS Notifications',
  SEND_IPAWS_EMAIL_NOTIFICATIONS: 'Send IPAWS Email Notifications',
  SEND_IPAWS_SMS_NOTIFICATIONS: 'Send IPAWS SMS Notifications',
  PHONE_NUMBER_IS_ONLY_REQUIRED_FOR_SEND_IPAWS_SMS_NOTIFICATIONS:
    'Phone number is only required for "Send IPAWS SMS Notifications."',
  ENABLE_USER: 'Enable User',
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER:
    'Are you sure you want to remove the user?',
  YES_REMOVE: 'Yes, Remove',
  YES_ENABLE: 'Yes, Enable',
  YES_DISABLE: 'Yes, Disable',
  USER_DISABLED_AND_WILL_NOT_BE_ABLE_TO_LOGIN_TO_THE_APPLICATION_ANYMORE:
    'The account will be disabled and the user will not be able to login to the application anymore',
  AFTER_ENABLEING_THE_USER_WILL_BE_ABLE_TO_ACCESS_HIS_ACCOUNT:
    'The account will be enabled and the user will be able to access his account',
  NAME: 'Name',
  STATUS: 'Status',
  IPAWS_NOTIFICATIONS: 'IPAWS Notifications',
  CHANGE_USER_GROUP: 'Change User Group',
  REMOVE_USER: 'Remove User',
  ADD_USER: 'Add User',
  NO_DATA_TO_SHOW: 'No data to show',
  DISABLE: 'Disable',
  DISABLE_USER: 'Disable User',
  SEARCH: 'Search',
  EMAIL_IS_REQUIRED: 'Email is required',
  SELECT_AT_LEAST_ONE_GROUP: 'Select at least one group',
  AGENCY_IS_REQUIRED: 'Agency is required',
  PLEASE_PROVIDE_YOUR_CONTACT_NUMBER_ABOVE:
    'Please provide your contact number above.',
  USER_MANAGEMENT: 'User Management',
  SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN:
    'Something went wrong. Please try again',
  ADMIN_CREATED_SUCCESSFULLY: 'Admin created successfully',
  ADMIN_UPDATED_SUCCESSFULLY: 'Admin updated successfully',
  ADMIN_REMOVED_SUCCESSFULLY: 'Admin removed successfully',
  YOUR_LIST_OF_USERS_ARE_EMPTY: 'Your list of users are empty',
  ADD_NEW_USERS: 'Add New Users',
  YOUR_LIST_OF_USERS_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_USERS_YET:
    'Your list of users are empty because you haven’t added any users yet.',

  NO_USER_FOUND_WITH_GIVEN_VALUE: 'No user found with the given search value',
  USER_NOT_FOUND: 'User not found',
  USER_GROUP: 'User Group',
  USER_PROFILE: 'User Profile',
  VIEW_USER_PROFILE: 'View User Profile',
  EDIT_USER: 'Edit User',
  EDIT_USER_PROFILE: 'Edit User Profile',
  EDIT_EVENT_DETAILS: 'Edit your event details using the edit button',

  // Public View site
  LOOKS_LIKE_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN:
    'Looks like something went wrong, Please try again!',
  TERMS_AND_CONDITIONS_POLICY: 'Location Use, Policy Terms & Conditions',
  MOBILE_NUMBER_SHOULD_CONTAIN_10_DIGITS:
    'Mobile Number should contain 10 digits',
  ENTER_MOBILE_NUMBER: 'Enter Mobile Number',
  UPDATE_MY_LOCATION: 'Update My Location',
  COUNTRY_CODE: 'Country Code',
  MOBILE_NUMBER: 'Mobile Number',
  UPDATE_CURRENT_LOCATION: 'Update current location',
  OPT_OUT_LOCATION: 'Opt out location',
  IS_REQUIRED: 'is required',
  USA_COUNTRY_CODE_PLACEHOLDER: '+1 (USA)',
  ENTER: 'Enter',
  SHOULD_BE_10_DIGITS: 'should be 10 digits',
  PHONE_NUMBER: 'Phone Number',
  CLOSURES_REGISTER_MESSAGE:
    'Register for Updates This registration process is to be used by travelers who are directly impacted by this closure – others can visit',
  LOCATION_PERMISSION_ERROR:
    'Location permission is required. Please check your settings. ',
  ENGLISH: 'English',
  LAST_UPDATED_ON: 'Last Updated on',
  MESSAGE_HISTORY: 'Message History',
  MANAGE_LOCATION: 'Manage Location',
  REGISTER_FOR_THIS_CLOSURE: 'Register for this Closure',
  CLOSURE: 'Closure',
  ENTER_QUESTION_FOR_WEB: 'Enter Question (for Web)',
  QUESTION_FOR_IVR: 'Question (for IVR)',
  ENTER_QUESTION_FOR_IVR: 'Enter Question (for IVR)',
  CATEGORY: 'Category',
  CHOICE_FIELD_IS_REQUIRED: 'Choice Field is required',
  ABBREVIATED_QUESTION_FOR_ADMIN_REPORTS:
    'Abbreviated Question (for Admin Reports)',
  CHOICE_FOR_IVR: 'Choice (for IVR)',
  ENTER_CHOICE_FOR_IVR: 'Enter Choice (for IVR)',
  ALSO_ADD_THIS_QUESTION_TEXT:
    'Also add this question to the Question Library for later use in an another closure',
  CURRENTLY_NO_ITEMS_TO_DISPLAY:
    'Currently, there are no items to display. Please check back later.',
  REFRESH_PAGE: 'Refresh this page',
  PERIODICALLY_VIEW_INFORMATION:
    ' periodically to view the most up-to-date information',
  GO_TO_CLOSURES_PAGE: 'Go back to the Closures Status page',
  TERMS_AND_CONDIITONS: 'Terms and conditions',
  MESSAGE_HISOTRY: 'Message History',
  UNABLE_TO_RETRIEVE: 'Unable to retrieve data',
  NO_ACTIVE_CLOSURES: 'There are no active Closures',
  NO_MESSAGES: 'There are no Messages',
  DO_YOU_WANT_OPT_IN_LOCATION_SERVICE: 'Do you want opt in location service?',
  NEW_PASSWORD_REQUIRED: 'New password required.',

  // Validations
  YUP: {
    INVALID_EMAIL_ADDRESS: 'Invalid email address',
    EMAIL_IS_REQUIRED: 'Email is required',
    PASSWORD_IS_REQUIRED: 'Password is required',
    RE_CAPTCHA_IS_REQUIRED: 're-Captcha is required',
    USER_NAME_IS_REQUIRED: 'User Name is required',
    IVR_MESSAGE_IS_REQUIRED: 'IVR message is required',
    ABBREVIATED_QUESTION_IS_REQUIRED: 'Abbreviated question is required',
    QUESTION_FOR_WEB_IS_REQUIRED: 'Question for web is required',
    QUESTION_FOR_IVR_IS_REQUIRED: 'Question for IVR is required',
    TYPE_IS_REQUIRED: 'Type is required',
    CATEGORY_IS_REQUIRED: 'Category is required',
    EVENT_ID: 'Event Id',
    SHORT_MESSAGE: 'Short Message',
    LONG_MESSAGE: 'Long Message',
    SPANISH_SHORT_MESSAGE: 'Spanish Short message',
    SPANISH_LONG_MESSAGE: 'Spanish Long message',
    CENTER_LATITUDE: 'Center Latitude',
    CENTER_LONGITUDE: 'Center Longitude',
    POLYGON_NAME: 'Polygon Name',
    EVENT_CATEGORY: 'Event Category',
    ALERT_DURATION: 'Alert Duration',
    STATUS: 'Status',
    AFFECTED_AREA: 'Affected area',
    EVENT: 'Event',
    SEVERITY: 'Severity',
    CERTAINITY: 'Certainity',
    URGENCY: 'Urgency',
    WEA_HANDLING: 'WEA Handling',
    LIFE_SAFETY_MESSAGE: 'Life Safety Message',
    COG_NAME: 'COG Name',
    URL: 'Url',
    COG_CERTIFICATE_EXPIRY: 'COG Certificate Expiry',
    IPAWS_KEY: 'IPAWS Key',
    IPAWS_CERTIFICATE: 'IPAWS Certificate',
    IPAWS_SUB_CERTIFICATE: 'IPAWS Sub Certificate'
  },

  // Shapes Module
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_POLYGON:
    'Are you sure you want to delete the polygon',
  YES_DELETE: 'Yes, Delete',
  VIEW_POLYGON: 'View Polygon',
  DELETE_POLYGON: 'Delete Polygon',
  ADD_SHAPE: 'Add Shape',
  EDIT_SHAPE: 'Edit Shape',
  VIEW_SHAPE: 'View Shape',
  NEW_SHAPE_ADDED_SUCCESSFULLY: 'New Shape Added Successfully',
  SHAPE_EDITED_SUCCESSFULLY: 'Shape Edited Successfully',
  SHAPE_DELETED_SUCCESSFULLY: 'Shape Deleted Successfully',
  SHAPES_LIBRARY: 'Shapes Library',
  YOUR_LIST_OF_SHAPES_LIBRARY_ARE_EMPTY:
    'Your list of shapes Library are empty',
  YOUR_LIST_OF_SHAPES_LIBRARY_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_SHAPES_YET:
    'Your list of shapes library are empty because you haven’t added any shapes yet.',
  ADD_NEW_SHAPE: 'Add New Shape',
  NO_SHAPE_FOUND_WITH_GIVEN_VALUE: 'No shape found with the given search value',
  SHAPE_NOT_FOUND: 'Shape not found',

  // Resources Module
  CHOOSE_ANY_ONE: 'Choose Any One',
  CHOOSE_ANY_TIMESPAN: ' Choose Any Timespan',
  SELECT_ONE_OF_THE_GIVEN_CHOICES: 'Select one of the given choices',

  //Certificate Module

  MANAGE_CERTIFICATE: 'Manage Certificate',
  CERTIFICATE_DETAILS: 'Certificate Details',
  COG_NAME: 'COG Name',
  URL: 'Url',
  COG_CERTIFICATE_EXPIRY: 'COG Certificate Expiry',
  IPAWS_KEY: 'IPAWS Key',
  IPAWS_CERTIFICATE: 'IPAWS Certificate',
  IPAWS_SUB_CERTIFICATE: 'IPAWS Sub Certificate',
  CERTIFICATE: 'Certificate',
  CERTIFICATE_UPDATED_SUCCESSFULLY: 'Certificate has been updated successfully',
  ENTER_COG_NAME: 'Enter COG Name',
  ENTER_URL: 'Enter Url',
  ENTER_COG_CERTIFICATE_EXPIRY: 'Enter COG Certificate Expiry',
  ENTER_IPAWS_KEY: 'Enter IPAWS Key',
  ENTER_IPAWS_CERTIFICATE: 'Enter IPAWS Certificate',
  ENTER_IPAWS_SUB_CERTIFICATE: 'Enter IPAWS Sub Certificate',
  CERTIFICATE_HAS_BEEN_UPDATED_SUCCESSFULLY:
    'Certificate Details has been updated successfully',

  //Form Validation
  NAME_IS_REQUIRED: 'Name is required.',
  LATITUDE_IS_REQUIRED: 'Latitude is required.',
  LONGITUDE_IS_REQUIRED: 'Longitude is required.',
  TEXT_MESSAGE: 'Text Message',
  MESSAGE_FOR_PHONE_SYSTEM: 'Message (for Phone System)',
  AUTOMATED_PHONE_CALL: 'Automated Phone Call',
  CONTACT_BY: 'Contact By',
  REGISTER_FOR_UPDATE:
    'Register for Updates: This registration process is to be used by travelers who are directly impacted by this closure – others can visit',
  PA_CONNECT_URL: 'www.511PA.com',
  PATURNPIKE_URL: 'www.paturnpike.com',
  FOR_INFORMATION: 'for information.',
  TERMS_AND_CONDITION_DESCRIPTION:
    'If you choose to opt-in, your location will be shared for the duration of this event. You will receive periodic messages as part of this service. Standard message and data rates may apply. Your location permission will be temporary and will expire when the event concludes. For more information, please review our Terms and Conditions and Privacy Policy at',
  TERMS_AND_CONDITIONS_PATH: 'pa.511connect.com/TC',
  GO_BACK_TO_THE_PREV_PAGE: 'Go back to the Previous page',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  THIS_PAGE_IS_FOR_TECHINICAL:
    'This page is for technical assistance. If you have an emergency, dial 911.'
};

export const STRINGS = Object.freeze({ ...WebApp });
