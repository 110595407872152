import { useEffect, useMemo, useState } from 'react';
import { colorState } from '../../Universal/Foundation';
import {
  Button,
  CheckBox,
  Icon,
  Input,
  Modal,
  Select
} from '../../Universal/NovusDSImports';
import {
  btnStyles,
  checkBoxStyles,
  inputStyles,
  selectStyles
} from '../../Universal/NovusDSImports/variants';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { STRINGS } from '../../Constants/ConstantStrings';
import { addClosureQuestion } from '../../Store/reducers/ClosureQuestions';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { FormSegment } from '../globalStyles';
import { RootState } from '../../store';
import { getPermission } from '../../CommonUtilities/CommonUtilities';

const AddEditClosureQuestionModal = (props: any) => {
  const {
    onClose,
    categories,
    selectedCategory,
    activeTab,
    editData,
    isEdit,
    addQuestionInCurrentCategory
  } = props;
  const dispatch = useReduxDispatch(),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    [isCheck, setIsCheck] = useState<boolean>(false),
    [addToDefault, setAddToDefault] = useState<boolean>(false),
    [isFilterable, setIsFilterable] = useState<boolean>(false),
    [type, setType] = useState<string>(''),
    [isSubmit, setIsSubmit] = useState<boolean>(false),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    [formFields, setFormFields] = useState<any>([
      {
        multiple_choices: '',
        multiple_choices_ivr: ''
      }
    ]),
    isSubmitting = useReduxSelector(
      (state: RootState) => state.Questions.isSubmitting
    );
  const fields = {
    display_name: 'display_name',
    ivr_message: 'ivr_message',
    abbr_name: 'abbr_name',
    field_type: 'field_type',
    multiple_choices: 'multiple_choices',
    multiple_choices_ivr: 'multiple_choices_ivr',
    category: 'category',
    // numeric: 'numeric',
    dynamicFields: 'dynamicFields',
    min_value: 'min_value',
    max_value: 'max_value'
  };

  const choiceOptions = useMemo(
    () => [
      {
        label: STRINGS.MULTIPLE_CHOICE,
        value: 'choice'
      },
      {
        label: STRINGS.NUMERIC,
        value: 'numeric'
      }
    ],
    []
  );

  const AddQuestionSchema = yup.object().shape({
    display_name: yup
      .string()
      .trim()
      .required(STRINGS.YUP.QUESTION_FOR_WEB_IS_REQUIRED),
    ivr_message: yup
      .string()
      .trim()
      .required(STRINGS.YUP.QUESTION_FOR_IVR_IS_REQUIRED),
    abbr_name: yup
      .string()
      .trim()
      .required(STRINGS.YUP.ABBREVIATED_QUESTION_IS_REQUIRED),
    field_type: yup.object({}).required(STRINGS.YUP.TYPE_IS_REQUIRED),
    category:
      activeTab === 'event'
        ? yup.object().nullable()
        : yup.object().required('Category is required'),
    dynamicFields: yup.array(),
    min_value: yup.number(),
    max_value: yup.number()
  });

  const getDefaultValues = () => {
    const values: any = {
      display_name: '',
      ivr_message: '',
      abbr_name: '',
      field_type: {
        label: 'Multiple Choice',
        value: 'choice'
      },
      multiple_choices: [],
      multiple_choices_ivr: [],
      category: null,
      default: false,
      dynamicFields: [],
      min_value: 0,
      max_value: 10
    };
    return values;
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    trigger
  } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(AddQuestionSchema)
  });

  useEffect(() => {
    if (isEdit && editData) {
      setValue(fields.ivr_message, editData.ivr_message);
      setValue(fields.display_name, editData.display_name);
      setAddToDefault(editData.default);
      setValue(
        fields.field_type,
        choiceOptions.filter(
          (item: any) => item.value === editData.field_type
        )[0]
      );
      setValue(fields.abbr_name, editData.abbr_name);
      setValue(
        fields.category,
        categories.filter((item: any) => item.id === editData.category)[0]
      );
      if (editData.category) {
        setIsCheck(true);
      }
      if (editData.is_filterable) setIsFilterable(true);
      setType(editData.field_type);
      if (editData.field_type === 'choice') {
        const choicesData: any = [];
        if (editData.multiple_choices && editData.multiple_choices.length) {
          editData.multiple_choices.forEach((item: any, index: number) => {
            choicesData.push({
              multiple_choices: editData.multiple_choices[index] || '',
              multiple_choices_ivr: editData.multiple_choices_ivr[index] || ''
            });
          });
        }
        setValue(fields.dynamicFields, choicesData);
        setFormFields(choicesData);
      } else if (editData.field_type === 'numeric') {
        setValue('min_value', editData.min_value);
        setValue('max_value', editData.max_value);
      }
    } else {
      setIsCheck(activeTab === STRINGS.LIBRARY);
      setType('choice');
      setValue('dynamicFields', [
        { multiple_choices: '', multiple_choices_ivr: '' }
      ]);
    }
    if (addQuestionInCurrentCategory && activeTab === STRINGS.LIBRARY) {
      setValue('category', selectedCategory);
    }
  }, [
    activeTab,
    addQuestionInCurrentCategory,
    categories,
    choiceOptions,
    editData,
    fields.abbr_name,
    fields.category,
    fields.display_name,
    fields.dynamicFields,
    fields.field_type,
    fields.ivr_message,
    isEdit,
    selectedCategory,
    setValue
  ]);

  const addField = () => {
    setValue('dynamicFields', [
      ...getValues().dynamicFields,
      { multiple_choices: '', multiple_choices_ivr: '' }
    ]);
    setFormFields(getValues().dynamicFields);
  };

  const removeChoice = (index: number) => {
    const dynamicFields = [...getValues().dynamicFields];
    dynamicFields.splice(index, 1);
    setValue('dynamicFields', dynamicFields);
    setFormFields(dynamicFields);
  };

  const settingCategories = () => {
    if (!addToDefault) {
      const defaultCategoryIndex = categories.findIndex(
        (category) => category.name === 'Default Questions'
      );
      setValue('category', categories[defaultCategoryIndex]);
      trigger('category');
    } else {
      setValue('category', null);
    }
  };

  const splitingChoices = (data: any) => {
    const choicesWeb = data.dynamicFields.map((item) => item.multiple_choices),
      choicesIVR = data.dynamicFields.map((item) => item.multiple_choices_ivr);
    return { choicesWeb, choicesIVR };
  };

  const dynamicFormsValidation = () => {
    let isValid = true;
    if (getValues().field_type.value === 'choice') {
      for (let index = 0; index < getValues().dynamicFields.length; index++) {
        const choice = getValues().dynamicFields[index];
        if (
          !choice.multiple_choices.trim() ||
          !choice.multiple_choices_ivr.trim()
        ) {
          isValid = false;
          break;
        }
      }
      if (isCheck && !getValues().category) {
        isValid = false;
      }
      return isValid;
    } else if (getValues().field_type.value === 'numeric') {
      if (Number(getValues().max_value) <= Number(getValues().min_value)) {
        isValid = false;
      }
    }
    return isValid;
  };

  const onSubmit = (data: any) => {
    setIsSubmit(true);
    if (dynamicFormsValidation()) {
      onClose(false);
      let params = {
        display_name: data.display_name,
        ivr_message: data.ivr_message,
        abbr_name: data.abbr_name,
        field_type: data.field_type.value,
        multiple_choices: [],
        multiple_choices_ivr: [],
        default: addToDefault
      };
      if (isCheck && data.category) {
        params['category'] = data.category.id;
      } else {
        params['category'] = null;
      }
      if (isFilterable) params['is_filterable'] = true;
      if (data.field_type.value === 'choice') {
        const choices: any = data.dynamicFields ? splitingChoices(data) : null;
        if (choices.choicesWeb.length) {
          params['multiple_choices'] = choices.choicesWeb;
        }
        if (choices.choicesIVR.length) {
          params['multiple_choices_ivr'] = choices.choicesIVR;
        }
      } else {
        params['min_value'] = data.min_value;
        params['max_value'] = data.max_value;
      }
      params = { ...params, ...selectedClosure };
      dispatch(
        addClosureQuestion({
          params,
          closureId: selectedClosure?.id,
          status_id: selectedClosure?.status.id,
          alert_status: selectedClosure?.alert_status,
          categoryId:
            activeTab === STRINGS.LIBRARY && data.category
              ? data.category.id
              : '',
          activeTab,
          isFromLibraryToClosure: false,
          isUpdate: isEdit,
          questionId: isEdit ? editData.id : '',
          selectedCategory:
            activeTab === STRINGS.LIBRARY && selectedCategory
              ? selectedCategory.id
              : ''
        })
      );
    }
  };

  const onInvalid = () => {
    setIsSubmit(true);
  };

  return (
    <>
      <FormSegment onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <section className="formset-fields">
          <div className="form-group">
            <Controller
              name={fields.display_name}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="question"
                  label={STRINGS.ENTER_QUESTION_FOR_WEB}
                  value={value}
                  placeholder={STRINGS.ENTER_QUESTION_FOR_WEB}
                  onChange={(e) => {
                    if (
                      e.target.value.length <= 160 ||
                      e.nativeEvent.inputType === 'deleteContentBackward'
                    ) {
                      setValue(`ivr_message`, e.target.value, {
                        shouldValidate: isSubmit
                      });
                      return onChange(e);
                    }
                  }}
                  isMargin={false}
                  asterisk
                  error={errors?.['display_name']}
                  hintText={
                    <p className="hint-text">
                      <span className="text-danger">
                        {`${errors?.['display_name']?.message || ''}`}
                      </span>
                      <span>
                        {160 -
                          getValues('display_name')?.length +
                          ' Characters'}
                      </span>
                    </p>
                  }
                  {...inputStyles}
                />
              )}
            />
          </div>
          <div className="form-group">
            <Controller
              name={fields.ivr_message}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  {...inputStyles}
                  id="question_for_phone_system"
                  label={STRINGS.QUESTION_FOR_IVR}
                  value={value}
                  placeholder={STRINGS.ENTER_QUESTION_FOR_IVR}
                  onChange={onChange}
                  isMargin={false}
                  asterisk
                  error={errors?.ivr_message}
                  hintText={errors?.ivr_message?.message}
                />
              )}
            />
          </div>
          <div className="form-group">
            <Controller
              name={fields.abbr_name}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  {...inputStyles}
                  id="abbreviated_question"
                  label={STRINGS.ABBREVIATED_QUESTION_FOR_ADMIN_REPORTS}
                  value={value}
                  placeholder={STRINGS.ABBREVIATED_QUESTION}
                  onChange={onChange}
                  asterisk
                  isMargin={false}
                  error={errors?.abbr_name}
                  hintText={errors?.abbr_name?.message}
                />
              )}
            />
          </div>
          <div className="form-group">
            <Controller
              name={fields.field_type}
              control={control}
              rules={{ required: true }}
              render={({ field: { value } }) => (
                <Select
                  displayName={'Type'}
                  placeHolder={STRINGS.SELECT_OPTION}
                  options={choiceOptions}
                  value={value}
                  onChange={(e) => {
                    setType(e.value);
                    setIsSubmit(false);
                    if (e.value === 'numeric') {
                      if (!isEdit) {
                        setValue(fields.dynamicFields, []);
                        setValue('min_value', 0);
                        setValue('max_value', 10);
                        setFormFields([]);
                      }
                    } else {
                      if (!isEdit) {
                        setValue('min_value', 0);
                        setValue('max_value', 10);
                        addField();
                      } else if (!getValues().dynamicFields.length) {
                        addField();
                      }
                    }
                    setValue(
                      fields.field_type,
                      {
                        label: e.label,
                        value: e.value
                      },
                      { shouldValidate: true }
                    );
                  }}
                  asterisk
                  {...selectStyles}
                  checkmarkStrokeColor={`${colorState.icon.brand.primary}`}
                  error={errors?.field_type}
                  hintText={errors?.field_type?.message}
                />
              )}
            />
          </div>
          {isCheck && (
            <div className="form-group border-bottom">
              <Controller
                name={fields.category}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="mb-4"
                    {...selectStyles}
                    asterisk
                    displayName={STRINGS.CATEGORY}
                    placeHolder={STRINGS.SELECT_OPTION}
                    options={categories}
                    value={value}
                    disabled={addQuestionInCurrentCategory}
                    onChange={(e) => {
                      setAddToDefault(() =>
                        e.value === 'Default Questions' ? true : false
                      );
                      return onChange(e);
                    }}
                    checkmarkStrokeColor={`${colorState.icon.brand.primary}`}
                    error={
                      errors?.category ||
                      (activeTab === 'event' &&
                        isSubmit &&
                        isCheck &&
                        !getValues().category)
                    }
                    hintText={
                      errors?.category
                        ? errors.category.message
                        : activeTab === 'event' &&
                          isSubmit &&
                          isCheck &&
                          !getValues().category
                        ? STRINGS.THIS_FIELD_IS_REQUIRED
                        : ''
                    }
                  />
                )}
              />
            </div>
          )}
          {type === 'choice' && (
            <>
              {formFields &&
                getValues().dynamicFields?.map((item: any, index: number) => (
                  <div className="d-flex">
                    <div className="pe-1"> {index + 1}.</div>
                    <div className="d-flex flex-column w-100">
                      <div className="form-group">
                        <Controller
                          name={fields.multiple_choices}
                          control={control}
                          rules={{ required: false }}
                          defaultValue={
                            isEdit ? formFields[index].multiple_choices : []
                          } // Set the default value
                          render={({ field: { onChange } }) => (
                            <Input
                              id="choice"
                              label={STRINGS.CHOICE_FOR_WEB}
                              placeholder={STRINGS.ENTER_CHOICE_FOR_WEB}
                              value={
                                getValues().dynamicFields[index]
                                  .multiple_choices
                              }
                              onChange={(e) => {
                                onChange(e);
                                const multiple_choices = e.target.value;
                                formFields[index].multiple_choices =
                                  multiple_choices;
                                formFields[index].multiple_choices_ivr =
                                  multiple_choices;
                                setValue(
                                  `dynamicFields[${index}].multiple_choices`,
                                  e.target.value,
                                  {
                                    shouldValidate: true
                                  }
                                );
                                setValue(
                                  `dynamicFields[${index}].multiple_choices_ivr`,
                                  e.target.value,
                                  {
                                    shouldValidate: true
                                  }
                                );
                              }}
                              asterisk={true}
                              isMargin={false}
                              {...inputStyles}
                              error={
                                isSubmit &&
                                !formFields[index].multiple_choices.trim()
                              }
                              hintText={
                                isSubmit &&
                                !formFields[index].multiple_choices.trim() &&
                                STRINGS.CHOICE_FIELD_IS_REQUIRED
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <Controller
                          name={fields.multiple_choices_ivr}
                          control={control}
                          rules={{ required: false }}
                          defaultValue={
                            isEdit ? formFields[index].multiple_choices_ivr : []
                          } // Set the default value
                          render={({ field: { onChange } }) => (
                            <Input
                              id="choice_for_IVR"
                              label={STRINGS.CHOICE_FOR_IVR}
                              placeholder={STRINGS.ENTER_CHOICE_FOR_IVR}
                              value={
                                getValues().dynamicFields[index]
                                  .multiple_choices_ivr
                              }
                              onChange={(e) => {
                                onChange(e);
                                const multiple_choices_ivr = e.target.value;
                                formFields[index].multiple_choices_ivr =
                                  multiple_choices_ivr;
                                setValue(
                                  `dynamicFields[${index}].multiple_choices_ivr`,
                                  e.target.value,
                                  {
                                    shouldValidate: true
                                  }
                                );
                              }}
                              asterisk={true}
                              isMargin={false}
                              {...inputStyles}
                              error={
                                isSubmit &&
                                !formFields[index].multiple_choices_ivr.trim()
                              }
                              hintText={
                                isSubmit &&
                                !formFields[
                                  index
                                ].multiple_choices_ivr.trim() &&
                                STRINGS.CHOICE_FIELD_IS_REQUIRED
                              }
                            />
                          )}
                        />
                      </div>
                      <div
                        className={`d-flex ${
                          index === getValues().dynamicFields.length - 1
                            ? 'justify-content-between'
                            : 'align-self-end'
                        } mb-4`}
                      >
                        {index === getValues().dynamicFields.length - 1 && (
                          <Button
                            type="button"
                            onClick={addField}
                            disabled={isSubmitting}
                            {...btnStyles.default}
                          >
                            {STRINGS.ADD_CHOICE}
                          </Button>
                        )}
                        {getValues().dynamicFields.length > 1 && (
                          <Button
                            type="button"
                            onClick={() => removeChoice(index)}
                            disabled={isSubmitting}
                            {...btnStyles.tertiary}
                          >
                            <Icon
                              icon={'trash_01'}
                              stroke={colorState.icon.info['primary-strong']}
                              width={16}
                              height={16}
                            />
                            {STRINGS.REMOVE_CHOICE}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
          {type === 'numeric' && (
            <>
              <div className="form-group">
                <Controller
                  name={fields.min_value}
                  control={control}
                  defaultValue={0} // Set the default value
                  render={({ field: { onChange, value } }) => (
                    <Input
                      id="min"
                      type="number"
                      label={STRINGS.MIN_VALUE}
                      min={0}
                      placeholder={STRINGS.ENTER_MIN_VALUE}
                      value={value}
                      onChange={onChange}
                      onKeyDown={(e: any) => {
                        ['e', 'E', '+', '-', '.'].includes(e.key) &&
                          e.preventDefault();
                      }}
                      asterisk={true}
                      isMargin={false}
                      {...inputStyles}
                      error={
                        isSubmit &&
                        !getValues().min_value &&
                        getValues().min_value !== 0
                      }
                      hintText={STRINGS.MIN_VALUE_REQUIRED}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Controller
                  name={fields.max_value}
                  control={control}
                  rules={{ required: false }}
                  defaultValue="" // Set the default value
                  render={({ field: { onChange, value } }) => (
                    <Input
                      id="max"
                      min={1}
                      type="number"
                      label={STRINGS.MAX_VALUE}
                      placeholder={STRINGS.ENTER_MAX_VALUE}
                      value={value}
                      onChange={onChange}
                      onKeyDown={(e: any) => {
                        ['e', 'E', '+', '-', '.'].includes(e.key) &&
                          e.preventDefault();
                      }}
                      asterisk={true}
                      isMargin={false}
                      {...inputStyles}
                      error={
                        (isSubmit && !getValues().max_value) ||
                        (isSubmit &&
                          Number(getValues().max_value) <=
                            Number(getValues().min_value))
                      }
                      hintText={
                        isSubmit && !getValues().max_value
                          ? STRINGS.MAX_VALUE_REQUIRED
                          : STRINGS.MAX_SHUOULD_BE_GREATER_THAN
                      }
                    />
                  )}
                />
              </div>
            </>
          )}
          {activeTab === 'event' && getPermission(currentUser, 'add_field') && (
            <div className="custom-checkbox-group">
              <CheckBox
                disabled={false}
                size={'md'}
                onClick={() => {
                  setIsCheck(!isCheck);
                  if (isSubmit) setIsSubmit(false);
                }}
                onKeyDown={(e: any) => {
                  if (e.key === ' ') {
                    setIsCheck(!isCheck);
                    if (isSubmit) setIsSubmit(false);
                  }
                }}
                selected={isCheck}
                {...checkBoxStyles}
                error={errors && errors.default}
                hintText={errors && errors.default && errors.default.message}
              />
              <label>{STRINGS.ALSO_ADD_THIS_QUESTION_TEXT}</label>
            </div>
          )}
          {type === 'choice' && (
            <div className="custom-checkbox-group">
              <CheckBox
                disabled={false}
                size={'md'}
                onClick={() => {
                  setIsFilterable(!isFilterable);
                }}
                onKeyDown={(e: any) => {
                  if (e.key === ' ') {
                    setIsFilterable(!isFilterable);
                  }
                }}
                selected={isFilterable}
                {...checkBoxStyles}
                error={errors && errors.default}
                hintText={errors && errors.default && errors.default.message}
              />
              <label>{STRINGS.ADD_THIS_QUESTION_CHOICES_TO_FILTER}</label>
            </div>
          )}

          {activeTab === 'library' && !addQuestionInCurrentCategory && (
            <div className="custom-checkbox-group">
              <CheckBox
                size="md"
                onClick={() => {
                  setAddToDefault((prev) => {
                    settingCategories();
                    return !prev;
                  });
                }}
                onKeyDown={(e: any) => {
                  if (e.key === '') {
                    setAddToDefault((prev) => {
                      settingCategories();
                      return !prev;
                    });
                  }
                }}
                selected={addToDefault}
                {...checkBoxStyles}
              />
              <label>Also add this question to the default questions</label>
            </div>
          )}
        </section>
        <section className="footer">
          <div className="d-flex gap-3 justify-content-end w-100">
            <Button
              type="button"
              onClick={() => onClose()}
              disabled={isSubmitting}
              {...btnStyles.secondary}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              {...btnStyles.primary}
            >
              {isEdit
                ? isSubmitting
                  ? STRINGS.UPDATING
                  : STRINGS.UPDATE
                : isSubmitting
                ? STRINGS.SUBMITTING
                : STRINGS.SUBMIT}
            </Button>
          </div>
        </section>
      </FormSegment>
    </>
  );
};

export default Modal(AddEditClosureQuestionModal);
