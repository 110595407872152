import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  Redirect
} from 'react-router-dom';

import { colorState, device } from '../../Universal/Foundation';
import { TopNavigation } from '../../Universal/NovusDSImports';

import PublicView from './PublicView';
import ManageLocationForm from './ManageLocation';
import ClosureRegistrationForm from './ClosureRegistration';
import MessageHistory from './MessageHistory';
import { trackUserView } from '../../CommonUtilities/CommonUtilities';
import { useReduxSelector } from '../../Store/reduxHooks';
import { RootState } from '../../store';

const PublicViewContainer: React.FC = () => {
  const history = useHistory();
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    trackUserView();
  }, []);

  useEffect(() => {
    const value = document.getElementById('translate_google');
    if (!value) {
      const addScript = document.createElement('script');
      addScript.setAttribute('id', 'translate_google');
      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }

    return () => {
      const target = document.getElementById('google_translate_element');
      if (value) {
        value.parentNode?.removeChild(value);
      }

      if (target) {
        target.parentNode?.removeChild(target);
      }
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    if (window.matchMedia) {
      const handleResize = () => {
        const MediaQuery = window.matchMedia(`${device.mobileXL}`);
        setIsMobileView(MediaQuery.matches);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.addEventListener('resize', handleResize);
      };
    }
  }, []);

  const { title_logo } = useReduxSelector(
    (state: RootState) => state.ClientInfo
  );

  const renderSelect = () => {
    return <div id="google_translate_element" />;
  };

  return (
    <Router>
      <TopNavigation
        showAvatar={renderSelect()}
        navigationBgColor={`${colorState.surface.default['topnav-bg-dark']}`}
        showNotification={false}
      >
        <img
          src={title_logo}
          onClick={() => {
            history.go(0);
          }}
          className="cursor-pointer pe-3"
          alt="PA Connect Logo"
          width={180}
          loading="lazy"
        />
      </TopNavigation>
      <Switch>
        <Route exact path="/">
          <PublicView />
        </Route>
        <Route path="/ManageLocation">
          <ManageLocationForm />
        </Route>
        <Route path="/Registration">
          <ClosureRegistrationForm />
        </Route>
        <Route path="/MessageHistory">
          <MessageHistory />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default PublicViewContainer;
