import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { showError, showPopUp } from '../../CommonUtilities/CommonUtilities';
import { api_prefix } from '../../Constants/constants';
import {
  addClosureQuestion,
  addEditCategory,
  deleteCategory,
  deleteQuestion,
  getclosureCategories,
  getclosuresCategoriesReceived,
  notifyNewQuestions,
  receivedQuestionsLibraryData,
  requestQuestionsLibraryData,
  requestQuestionsclosureData,
  requestQuestionsclosureFailed,
  reveivedQuestionsclosureData,
  showNewlyAddedQuestionPopup,
  submitted,
  submittingQuestion
} from '../reducers/ClosureQuestions';
import { getClosuresData } from '../reducers/Closures';
import { STRINGS } from '../../Constants/ConstantStrings';
import { defaultGetResponse } from '../../GetResponse';
import {
  RequestQuestionsCategories,
  RequestQuestionsLibraryType,
  RequestQuestionsclosuresType
} from '../actions/ClosureQuestions';
import { unSelectSelectedRows } from '../reducers/Common';

function* requestQuestionsLibraryDataSaga({
  payload
}: RequestQuestionsLibraryType) {
  try {
    const url = `${api_prefix}questions/?category=${payload.categoryId}&ordering=-modified,id&limit=${payload.limit}`;
    const json = yield axios.get(url).then((response) => response.data);
    yield put(unSelectSelectedRows({ shouldUnselect: true }));
    yield put(receivedQuestionsLibraryData(json));
  } catch (error: any) {
    yield put(
      receivedQuestionsLibraryData({ results: [], libraryQuestionsData: [] })
    );
    showPopUp(error.response.data.category[0], 'error');
  }
}

function* requestQuestionsclosureDataSaga({
  payload
}: RequestQuestionsclosuresType) {
  try {
    const url = `${api_prefix}closures/${payload.closureId}/get_closure_questions/?ordering=-modified,id&limit=${payload.limit}`;
    const json = yield axios.get(url).then((response) => response.data);
    yield put(reveivedQuestionsclosureData(json));
  } catch (error: any) {
    yield put(requestQuestionsclosureFailed());
    showPopUp(error.response.data.detail, 'error');
  }
}

function* addClosureQuestionSaga({ payload }: any) {
  try {
    yield put(submittingQuestion());
    let url = '';
    if (payload.isUpdate) {
      url = `${api_prefix}questions/${payload.questionId}/`;
      yield axios.put(url, payload.params).then((response) => response.data);
      showPopUp(STRINGS.QUESTIONS_UPDATED_SUCCESSFULLY, 'success');
      yield put(getClosuresData({ limit: 'all' }));
      payload.activeTab === 'event'
        ? yield put(
            requestQuestionsclosureData({
              closureId: payload.closureId,
              limit: 'all'
            })
          )
        : yield put(
            requestQuestionsLibraryData({
              categoryId: payload.selectedCategory,
              limit: 'all'
            })
          );
    } else if (payload.activeTab === 'event') {
      if (payload.isFromLibraryToClosure) {
        url = `${api_prefix}closures/${payload.closureId}/add_ques_from_category/`;
        const json = yield axios
          .post(url, { questions_list: payload.params.questions })
          .then((response) => response.data);

        if (payload.params.status.name !== 'Two-Way Communications (Open)') {
          showPopUp(STRINGS.QUESTIONS_ADDED_SUCCESSFULLY_TO_EVENT, 'success');
        }
        yield put(getClosuresData({ limit: 'all' }));
        yield put(
          requestQuestionsclosureData({
            closureId: payload.closureId,
            limit: 'all'
          })
        );
        if (payload.params.status.name === 'Two-Way Communications (Open)') {
          yield put(
            showNewlyAddedQuestionPopup({
              isShowNewlyAddedQuestionsToUsers: true,
              newQuestionId: json?.created_question_list
            })
          );
        }
      } else {
        url = `${api_prefix}closures/${payload.closureId}/add_new_closure_question/`;
        const json = yield axios
          .patch(url, payload.params)
          .then((response) => response.data);
        showPopUp(STRINGS.QUESTIONS_ADDED_SUCCESSFULLY, 'success');
        yield put(getClosuresData({ limit: 'all' }));
        yield put(
          requestQuestionsclosureData({
            closureId: payload.closureId,
            limit: 'all'
          })
        );
        yield put(getClosuresData({ limit: 'all' }));
        if (payload.params.status.name === 'Two-Way Communications (Open)') {
          yield put(
            showNewlyAddedQuestionPopup({
              isShowNewlyAddedQuestionsToUsers: true,
              newQuestionId: json.question_id
            })
          );
        }
      }
    } else {
      url = `${api_prefix}questions/`;
      yield axios.post(url, payload.params).then((response) => response.data);
      showPopUp(STRINGS.QUESTIONS_ADDED_SUCCESSFULLY, 'success');
      if (payload.selectedCategory) {
        yield put(
          requestQuestionsLibraryData({
            categoryId: payload.selectedCategory,
            limit: 'all'
          })
        );
      }
    }

    yield put(submitted());
  } catch (error: any) {
    yield put(submitted());
    showError(error);
    yield put(requestQuestionsclosureFailed());
  }
}

function* getclosureCategoriesSaga({ payload }: RequestQuestionsCategories) {
  try {
    const url = `${api_prefix}categories/?limit=${payload.limit}`;
    const json = yield axios.get(url).then((response) => response.data);
    yield put(getclosuresCategoriesReceived(json));
  } catch (error: any) {
    yield put(getclosuresCategoriesReceived(defaultGetResponse));
    showError(error);
  }
}

function* deleteQuestionSaga({ payload }: any) {
  try {
    yield put(submittingQuestion());
    let url = '';
    if (payload.activeTab === 'event') {
      url = `${api_prefix}closures/${payload.params.closureId}/delete_closure_question/?question_id=${payload.params.questionId}`;
    } else {
      url = `${api_prefix}questions/${payload.params.questionId}/`;
    }
    yield axios.delete(url).then((response) => response.data);
    showPopUp(STRINGS.DELETED_SUCCESSFULLY, 'success');
    if (payload.activeTab === 'event') {
      yield put(
        requestQuestionsclosureData({
          closureId: payload.params.closureId,
          limit: 'all'
        })
      );
      yield put(getClosuresData({ limit: 'all' }));
    } else {
      yield put(
        requestQuestionsLibraryData({
          categoryId: payload.params.categoryId,
          limit: 'all'
        })
      );
    }

    yield put(submitted());
  } catch (error: any) {
    yield put(submitted());
    yield put(
      requestQuestionsclosureData({
        closureId: payload.params.closureId,
        limit: 'all'
      })
    );
    showError(error);
  }
}

function* notifyNewQuestionsSaga({ payload }: any) {
  try {
    const url = `${api_prefix}closures/${payload.closureId}/notify_question/`;
    yield axios
      .post(url, { questions_list: payload.questions_list })
      .then((response) => response.data);
    showPopUp(STRINGS.SUCCESSFULLY_SENT_TO_ALL_REGISTERED_USERS, 'success');
  } catch (error: any) {
    showError(error);
  }
}

function* addEditCategorySaga({ payload }: any) {
  try {
    let url = '';
    if (payload?.isEdit) {
      url = `${api_prefix}categories/${payload.id}/`;
      yield axios
        .patch(url, { name: payload.name })
        .then((response) => response.data);
      showPopUp(STRINGS.SUCCESSFULLY_UPDATED_CATEGORY, 'success');
    } else {
      url = `${api_prefix}categories/`;
      yield axios
        .post(url, { name: payload.name })
        .then((response) => response.data);
      showPopUp(STRINGS.SUCCESSFULLY_ADDED_NEW_CATEGORY, 'success');
    }
    yield put(getclosureCategories({ limit: 'all' }));
  } catch (error: any) {
    showError(error);
  }
}

function* deleteCategorySaga({ payload }: any) {
  try {
    const url = `${api_prefix}categories/${payload.id}/`;
    yield axios.delete(url).then((response) => response.data);
    yield put(getclosureCategories({ limit: 'all' }));
    showPopUp(STRINGS.DELETED_SUCCESSFULLY, 'success');
  } catch (error: any) {
    showError(error);
  }
}

export default function* watchClosureQuestions() {
  yield takeLatest(
    requestQuestionsclosureData,
    requestQuestionsclosureDataSaga
  );

  yield takeLatest(
    requestQuestionsLibraryData,
    requestQuestionsLibraryDataSaga
  );

  yield takeLatest(getclosureCategories, getclosureCategoriesSaga);

  yield takeLatest(notifyNewQuestions, notifyNewQuestionsSaga);

  yield takeLatest(addClosureQuestion, addClosureQuestionSaga);

  yield takeLatest(deleteQuestion, deleteQuestionSaga);

  yield takeLatest(addEditCategory, addEditCategorySaga);

  yield takeLatest(deleteCategory, deleteCategorySaga);
}
