import React from 'react';
import { STRINGS } from '../../Constants/ConstantStrings';
import { Input, Modal } from '../../Universal/NovusDSImports';
import { inputStyles } from '../../Universal/NovusDSImports/variants';

const AddNewCategory = (props: any) => {
  const { categoryData, setCategoryData, addNewCategorySubmit } = props;

  return (
    <Input
      type="text"
      name={STRINGS.CATEGORY_NAME}
      label={STRINGS.CATEGORY_NAME}
      placeholder={`Enter ${STRINGS.CATEGORY_NAME}`}
      value={categoryData}
      onChange={(e) => {
        setCategoryData(e?.target?.value);
      }}
      asterisk={true}
      isMargin={false}
      {...inputStyles}
      error={addNewCategorySubmit && !categoryData}
      hintText={
        addNewCategorySubmit && !categoryData && `${STRINGS.CATEGORY_NAME} should be requried`
      }
    />
  );
};

export default Modal(React.memo(AddNewCategory));
