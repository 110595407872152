import { createSlice } from '@reduxjs/toolkit';
import {
  EmailVerifyAction,
  EmailVerifyActionReceived,
  ForgotPassword,
  ForgotPasswordAction,
  PasswordUpdateAction
} from '../actions/ForgotPassword';

const initialState: ForgotPassword = {
  isPasswordUpdateSuccess: false,
  onPasswordUpdateMessage: '',
  emailVerified: false
};

export const ForgotPasswordSlice = createSlice({
  name: 'ForgotPassword',
  initialState,
  reducers: {
    passwordUpdateReceived: (state, action: PasswordUpdateAction) => {
      state.isPasswordUpdateSuccess = action.payload.isPasswordUpdated;
      state.onPasswordUpdateMessage = action.payload.message;
    },
    passwordUpdate: (_state, _action: ForgotPasswordAction) => {},
    emailVerify: (_state, _action: EmailVerifyAction) => {},
    emailVerifyReceived: (state, action: EmailVerifyActionReceived) => {
      state.emailVerified = action.payload.isEmailVerified;
      state.onPasswordUpdateMessage = action.payload.message;
    },
    resetPasswordStoreValues: (state) => {
      state.emailVerified = false;
      state.isPasswordUpdateSuccess = false;
      state.onPasswordUpdateMessage = '';
    }
  }
});

export const {
  passwordUpdate,
  passwordUpdateReceived,
  emailVerify,
  emailVerifyReceived,
  resetPasswordStoreValues
} = ForgotPasswordSlice.actions;
export default ForgotPasswordSlice.reducer;
