import { useRef } from 'react';
import { Modal } from '../../Universal/NovusDSImports';
import { useClickOutside } from '../../Hooks/useClickOutside';

const SnapshotModal = (props) => {
  const { selectedCamera, markerOpenRef, postModalClose } = props;
  const MarkerExcludedRef = useRef(null);

  useClickOutside(
    markerOpenRef,
    () => {
      postModalClose();
    },
    [MarkerExcludedRef]
  );

  return (
    <div className="h-100" ref={markerOpenRef}>
      <img
        src={selectedCamera.snapshotAddress}
        alt={selectedCamera.locationName}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default Modal(SnapshotModal);
