import styled from 'styled-components';
import { spacing, typography } from '../../Universal/Foundation';
import { colorState } from '../../Universal/Foundation/colors';

export const ManageCertificate = styled.div`
  padding: ${spacing.spacing_20}px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;

  h2 {
    font-size: ${typography.size.tl};
    font-style: normal;
    font-weight: ${typography.weight.semibold};
    line-height: ${spacing.spacing_24}px;
    color: ${colorState.text.primary};
    margin-bottom: 0;
  }

  h3,
  p {
    font-size: ${typography.size.ts};
    line-height: ${spacing.spacing_20}px;
    margin-bottom: 0;
  }

  h3 {
    font-weight: ${typography.weight.regular};
    line-height: ${spacing.spacing_20}px;
    color: ${colorState.text.secondary};
    margin-bottom: ${spacing.spacing_4}px;
  }

  p {
    font-weight: ${typography.weight.medium};
    color: ${colorState.text.primary};
    word-break: break-word;
  }

  .event-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: ${spacing.spacing_12}px;
    padding: 0 ${spacing.spacing_12}px 0 ${spacing.spacing_12}px;
  }

  .formset-fields {
    overflow: unset;
  }
`;
