import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { STRINGS } from '../../Constants/ConstantStrings';
import { loginRequest } from '../../Store/reducers/Login';
import { useReduxDispatch } from '../../Store/reduxHooks';
import { colorState } from '../../Universal/Foundation';
import { Button, Icon, Input } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  inputStyles
} from '../../Universal/NovusDSImports/variants';
import { FormSegment } from '../globalStyles';
import AuthenticationPage from './AuthenticationPage';
import { ErrorMessage, FormContainer } from './styles';
import { RootState } from '../../store';
import { REACPTCHA_SITE_KEY, isProdInstance } from '../../settings';
import ErrorBoundary from '../../GenericErrorBoundary';

interface LoginFormType {
  onClick: () => void;
}

export default function LoginForm(props: LoginFormType) {
  const dispatch = useReduxDispatch(),
    [newPasswordVisibility, setNewPasswordVisibility] =
      useState<boolean>(false),
    loginData = useSelector((state: RootState) => state.Login.loginData),
    message = useSelector((state: RootState) => state.Login.message),
    isFetchingLogin = useSelector(
      (state: RootState) => state.Login.isFetchingLogin
    );

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email(STRINGS.YUP.INVALID_EMAIL_ADDRESS)
      .required(STRINGS.YUP.EMAIL_IS_REQUIRED),
    password: yup.string().required(STRINGS.YUP.PASSWORD_IS_REQUIRED),
    recaptcha: yup.boolean().oneOf([true], STRINGS.YUP.RE_CAPTCHA_IS_REQUIRED)
  });

  const getDefaultValues = () => ({
    email: '',
    password: '',
    recaptcha: false
  });

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(LoginSchema)
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = methods;

  const onSubmit = (data: any) => {
    const params = {
      email: data.email,
      password: data.password
    };
    dispatch(loginRequest(params));
  };

  return (
    <FormProvider {...methods}>
      <FormContainer>
        {loginData ? (
          <AuthenticationPage />
        ) : (
          <>
            <h1>
              {isProdInstance
                ? STRINGS.LOG_IN_TO_YOUR_LIVE_ACCOUNT
                : STRINGS.LOG_IN_TO_YOUR_TRAINING_ACCOUNT}
            </h1>
            <FormSegment onSubmit={handleSubmit(onSubmit)}>
              <section className="formset-fields p-1">
                <div className="form-group">
                  <Controller
                    name={'email'}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        id="email"
                        label="Email"
                        value={value}
                        type="email"
                        placeholder={STRINGS.ENTER_EMAIL}
                        onChange={onChange}
                        autoComplete="email"
                        isMargin={false}
                        {...inputStyles}
                        error={errors?.email}
                        hintText={errors?.email?.message}
                      />
                    )}
                  />
                </div>
                <div className="form-group">
                  <Controller
                    name={'password'}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        id="password"
                        label="Password"
                        type={newPasswordVisibility ? 'text' : 'password'}
                        value={value}
                        autoComplete="current-password"
                        placeholder="Enter Password"
                        onChange={onChange}
                        isMargin={false}
                        onRightIconActionClick={() =>
                          setNewPasswordVisibility(!newPasswordVisibility)
                        }
                        iconRight={
                          newPasswordVisibility ? (
                            <Icon
                              icon={'eye'}
                              fill="none"
                              stroke={`${colorState.icon.default.secondary}`}
                            />
                          ) : (
                            <Icon
                              icon={'eye_off'}
                              fill="none"
                              stroke={`${colorState.icon.default.secondary}`}
                            />
                          )
                        }
                        {...inputStyles}
                        error={errors?.password}
                        hintText={errors?.password?.message}
                      />
                    )}
                  />
                </div>
                {message && <ErrorMessage>{`${message}.`}</ErrorMessage>}
                <div className="d-flex justify-content-end align-items-center flex-wrap pb-3">
                  <p
                    onClick={props.onClick}
                    className="m-0 text-forgotpassword"
                    role="link"
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        props.onClick();
                      }
                    }}
                  >
                    {STRINGS.FORGOT_PASSWORD}
                  </p>
                </div>
                <div className="form-group">
                  <ErrorBoundary
                    fallback={
                      <div style={{ color: 'red' }}>Issue with ReCaptcha</div>
                    }
                  >
                    <Controller
                      name={'recaptcha'}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        // Site key is a reCAPTCHA is a unique identifier provided by Google to associate a specific website with reCAPTCHA services. It is used to integrate reCAPTCHA into your website's HTML code and is necessary for rendering the reCAPTCHA widget.
                        // It should have ownership/Adminstator mail account but As of now we are generated with dummy google account mail account site key for testing.

                        <ReCAPTCHA
                          sitekey={REACPTCHA_SITE_KEY}
                          onChange={(e) => {
                            if (e) {
                              onChange(e);
                              // clearErrors('recaptcha');
                              setValue('recaptcha', true);
                            } else {
                              // setError('recaptcha', {
                              //   type: 'required',
                              //   message: 'Recaptcha is required'
                              // });
                              setValue('recaptcha', false);
                            }
                          }}
                        />
                      )}
                    />
                  </ErrorBoundary>
                </div>
                {errors?.recaptcha && (
                  <ErrorMessage>{STRINGS.RECAPTCHA_REQUIRED}.</ErrorMessage>
                )}
              </section>
              <Button
                className={'w-100 my-4'}
                {...btnStyles.primary}
                type="submit"
                isLoading={isFetchingLogin}
              >
                {STRINGS.LOGIN}
              </Button>
            </FormSegment>
          </>
        )}
      </FormContainer>
    </FormProvider>
  );
}
