import styled from 'styled-components';
import {
  colorState,
  device,
  radius,
  spacing,
  typography
} from '../../Universal/Foundation';
import { primaryNavWidth } from '../../Components/globalStyles';

const profileDropdownWidth = '288';
const notificationPopupDimension = '460';

export const ExamplePopup = styled.div`
  width: 460px;
  height: 300px;
  background: white;
  box-shadow: 0px 4px 22px rgba(45, 77, 108, 0.25);
  border-radius: 16px;
  padding: 16px;
`;

export const MenuWrapper = styled.div`
  padding-left: ${primaryNavWidth - 300}px;

  ul {
    height: 60px;
    align-items: center;

    li {
      padding: 8px 12px;
      &.active,
      &:hover {
        background-color: ${colorState.surface.brand.primary};
        transition: background-color 0.3s linear;
        border-radius: ${radius.radius_rounded}px;
      }

      @media ${device.laptopL} {
        padding: 8px !important;

        a {
          display: flex;
        }
      }
    }
  }

  @media ${device.laptop} {
    display: none;
  }

  @media ${device.laptopL} {
    padding-left: 4px;
  }

  @media ${device.laptop} {
    padding-left: 0;

    li {
      padding: 8px 4px !important;
    }

    li a {
      display: flex;
    }
  }
`;

export const MobileMenuWrapper = styled.div`
  @media ${device.tablet} {
    width: 70% !important;
  }

  @media ${device.laptop} {
    position: absolute;
    top: 0;
    width: 30%;
    height: 100%;
    left: 0;
    z-index: 99;
    box-shadow: 0 0 10px #ccc;
    background-color: ${colorState.surface.default['topnav-bg-dark']};

    .menu-close {
      display: block;
      display: flex;
      justify-content: flex-end;
      padding: 12px;

      svg {
        path {
          stroke: white;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      padding: 10px !important;
      padding-top: 0 !important;

      a {
        padding: 8px 12px;

        &.active,
        &:hover {
          background-color: ${colorState.surface.brand.primary};
          transition: background-color 0.3s linear;
          border-radius: ${radius.radius_rounded}px;
          color: white !important;
        }

        img {
          padding: 6px;
        }
      }
    }
  }
`;

export const TopNavWrapper = styled.div`
  ul {
    a {
      color: ${colorState.text['primary-inverse']};
      text-decoration: none;
      cursor: pointer;

      b {
        font-size: ${typography.size.ts};
        font-weight: ${typography.weight.medium};

        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          font-size: ${typography.size.txs};
          font-weight: ${typography.weight.medium};
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .left {
    .training-text {
      color: ${colorState.text['primary-inverse']};
      font-size: ${typography.size.tm};
      font-weight: ${typography.weight.semibold};
      line-height: 20px;
      position: relative;
      top: 2px;
      text-wrap: nowrap;
      cursor: default;
    }

    ul {
      gap: 12px !important;

      @media ${device.laptopL} {
        gap: 8px !important;
      }
    }

    @media ${device.laptopL} {
      ul img {
        width: auto !important;
      }
    }

    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      img {
        width: 100px !important;
      }
      .training-text {
        font-size: ${typography.size.ts};
      }
    }
    @media ${device.mobileL} {
      img {
        width: 150px !important;
      }
    }
  }

  .right {
    z-index: 9;
  }

  .badge-icon {
    svg,
    path {
      stroke: white;
    }
  }

  .menu-icon {
    svg,
    path {
      stroke: white;
    }

    @media ${device.laptop} {
      display: block !important;
    }
  }

  .user-login {
    display: flex;
    align-items: center;

    .user-name {
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.medium};
      color: ${colorState.text['primary-inverse']};
      padding: 12px;
      white-space: nowrap;

      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        max-width: 100px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media ${device.laptop} {
        display: none;
      }
    }
  }
`;

export const NotificationPopupContainer = styled.div`
  width: ${notificationPopupDimension}px;
  max-height: 50vh;
  overflow-y: auto;
  border-radius: 16px;
  box-shadow: 0px 4px 22px rgba(45, 77, 108, 0.25);
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media ${device.mobileL} {
    position: relative;
    left: 30%;
    top: 30px;
    width: 100%;
  }

  .header,
  .body {
    background-color: white;
  }

  .header {
    padding: 12px 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    h3 {
      color: ${colorState.text.primary};
      font-size: ${typography.size.h6}px;
      font-weight: ${typography.weight.semibold};
      margin: 0;
    }

    span {
      color: ${colorState.text.secondary};
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.medium};
    }

    .notification-badge {
      display: flex;
      padding: 1px 6px 0px 6px;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      background-color: ${colorState.surface.default.moderate};
      color: green;
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.extrabold};
    }
  }

  .body {
    padding: 20px;
    padding-top: 6px;
  }
`;

export const ProfilePopupContainer = styled.div`
  width: ${profileDropdownWidth}px;
  max-width: 300px;
  height: auto;
  background: ${colorState.surface.default.primary};
  box-shadow: 0px 4px 22px rgba(45, 77, 108, 0.25);
  border-radius: ${radius.radius_12}px;
  padding: ${spacing.spacing_8}px;
  font-weight: ${typography.weight.medium};
  font-size: ${typography.size.ts};
  color: ${colorState.text.secondary};
  cursor: default;

  .user-details {
    padding-left: 12px;

    .user-email {
      font-weight: ${typography.weight.regular};
      font-size: ${typography.size.txs};
      color: ${colorState.text.tertiary};
    }
  }

  .user-name {
    font-size: ${typography.size.tm};
    font-weight: ${typography.weight.medium};
    color: ${colorState.text.secondary};
  }

  .user-name,
  .user-email,
  li {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-word;
  }

  .profile-menu-options {
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
    border-radius: ${radius.radius_10}px;
    padding: ${spacing.spacing_8}px;
    margin-top: 12px;

    ul {
      padding: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      margin-block-start: 0;
      margin-block-end: 0;

      /* li:nth-child(3) svg path {
        fill: ${colorState.icon.default.primary};
      } */

      li {
        color: ${colorState.text.secondary};
        list-style: none;
        cursor: pointer;
        padding: 10px 8px;

        p {
          font-size: ${typography.size.ts};
          font-weight: ${typography.weight.medium};
          margin-bottom: 0;
          padding-left: 12px;
          color: ${colorState.text.secondary};
        }

        :hover {
          background-color: ${colorState.surface.default.tertiary};
          border-radius: 8px;
        }

        &:focus-visible {
          border: solid 2px ${colorState.border.brand.primary};
          outline: none;
          border-radius: 8px;
        }
      }

      li:last-child {
        border-top: solid 1px ${colorState.border.default.secondary};
        margin-top: ${spacing.spacing_10}px;
        padding-top: ${spacing.spacing_20}px;

        svg path {
          fill: none;
        }
      }
    }
  }

  .user-login {
    .user-name {
      display: none;
    }
  }
`;
