import { STRINGS } from '../../Constants/ConstantStrings';
import { ArcadisIcon } from '../../Universal/Assets';
import { Footer } from './styles';

export default function FooterComponent() {
  return (
    <Footer>
      <div className="d-flex left">
        <div className="d-flex product-ad">
          <p>{STRINGS.ALERT_NOW_IS_A_PRODUCT_OF}</p>
          <img
            src={ArcadisIcon}
            alt="arcadis-icon"
            className="pe-2 ps-2"
            loading="lazy"
          />
        </div>
        <p>© 2024 All Rights Reserved</p>
      </div>
      <p className="right">
        {STRINGS.FOR_MORE_DETAILS_VISIT} :{' '}
        <a
          href="https://traveliq.co/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="For more details visit www.traveliq.co"
        >
          <span className="link-text">&nbsp;www.traveliq.co</span>
        </a>
      </p>
    </Footer>
  );
}
