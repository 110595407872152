import { put, takeLatest } from 'redux-saga/effects';
import {
  camerasReceived,
  eventsReceived,
  getCameras,
  getEvents,
  getRestFacilities,
  restFacilitiesReceived
} from '../reducers/MapFilters';
import { showError, showPopUp } from '../../CommonUtilities/CommonUtilities';
import { BASE_URL, travelIQKey } from '../../settings';
import { STRINGS } from '../../Constants/ConstantStrings';
import axios from 'axios';
import { api_prefix } from '../../Constants/constants';

// fetch is used instead of axios because axios is giving cors error
// axios automatically sets content-header whereas fetch claims it as text/plain thought the correct content-header is json
// so fetch is working

function* fetchCameras() {
  try {
    const json = yield axios
      .get(`${api_prefix}cameras/get_cameras_info/`)
      .then((response) => response.data);

    yield put(camerasReceived({ cameras: json }));
  } catch (error) {
    yield put(camerasReceived({ cameras: null }));
    showError(error);
  }
}

function* fetchRestFacilities() {
  try {
    const json = yield fetch(
      `${BASE_URL}/api/v2/get/restareas/?key=${travelIQKey}`
    ).then((data) => {
      if (data.status === 503) {
        showPopUp(STRINGS.LEGENDS_UNAVAILABLE, 'warning');
      } else {
        return data.json().then((response) => response);
      }
    });
    yield put(restFacilitiesReceived({ restFacilities: json }));
  } catch (error) {
    yield put(restFacilitiesReceived({ restFacilities: null }));
    showError(error);
  }
}

function* fetchEvents() {
  try {
    const json = yield fetch(
      `${BASE_URL}/api/v2/get/event/?key=${travelIQKey}`
    ).then((data) => {
      if (data.status === 503) {
        showPopUp(STRINGS.LEGENDS_UNAVAILABLE, 'warning');
      } else {
        return data.json().then((response) => response);
      }
    });
    yield put(eventsReceived({ events: json }));
  } catch (error) {
    yield put(eventsReceived({ events: null }));
    showError(error);
  }
}

export default function* watchMapFilters() {
  yield takeLatest(getCameras, fetchCameras);
  yield takeLatest(getRestFacilities, fetchRestFacilities);
  yield takeLatest(getEvents, fetchEvents);
}
