import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormDataType, AddEditAdminUserModalProps } from './adminUsers.types';
import { STRINGS } from '../../Constants/ConstantStrings';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Input,
  Select,
  Button,
  Modal,
  CheckBox,
  Switch,
  RadioBox,
  Icon,
  Avatar
} from '../../Universal/NovusDSImports';
import { useSelector, useDispatch } from 'react-redux';

import {
  addNewAdminUser,
  editAdminUser
} from '../../Store/reducers/AdminUsers';
import {
  FormSegment,
  TextNote,
  UserGroupProfileSection
} from '../globalStyles';
import {
  btnStyles,
  checkBoxStyles,
  inputStyles,
  radioBoxStyles,
  selectStyles,
  switchStyles
} from '../../Universal/NovusDSImports/variants';
import { colorState } from '../../Universal/Foundation';
import {
  debounceFunction,
  getPermission,
  getUserColor,
  getUserInitials
} from '../../CommonUtilities/CommonUtilities';
import { useReduxSelector } from '../../Store/reduxHooks';
import { RootState } from '../../store';

const formSchema = yup.object({
  first_name: yup.string().trim().required(STRINGS.FIRST_NAME_IS_REQUIRED),
  last_name: yup.string().trim().required(STRINGS.LAST_NAME_IS_REQUIRED),
  email: yup.string().email().required(STRINGS.EMAIL_IS_REQUIRED),
  phone: yup.string(),
  group_ids: yup.array().min(1, STRINGS.SELECT_AT_LEAST_ONE_GROUP),
  agency: yup.number().required(STRINGS.AGENCY_IS_REQUIRED),
  send_ipaws_email_notifications: yup.boolean(),
  send_ipaws_sms_notifications: yup.boolean(),
  enabled: yup.boolean()
});

const AddEditAdminUserModal: React.FC<AddEditAdminUserModalProps> = ({
  setIsModalOpen,
  postModalClose,
  editMode,
  setEditMode,
  editFormData,
  setEditFormData,
  userGroup,
  setUserGroup,
  searchFilterValue
}) => {
  const [groups, setGroups] = useState<Array<{ value: number; label: string }>>(
    []
  );
  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [smsChecked, setSmsChecked] = useState<boolean>(false);
  const [userEnabled, setUserEnabled] = useState<boolean>(true);
  const [check, setCheck] = useState<number>();

  const currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    isSubmitting = useReduxSelector(
      (state: RootState) => state.AdminUsers.isSubmittingAdmin
    ),
    dispatch = useDispatch();

  const formData: FormDataType = {
    first_name: '',
    last_name: '',
    fullname: '',
    email: '',
    phone: undefined,
    group_ids: [],
    agency: undefined,
    send_ipaws_email_notifications: false,
    send_ipaws_sms_notifications: false,
    enabled: true,
    profile_image: ''
  };

  const adminGroups = useSelector(
    (state: RootState) => state.AdminUsers.adminGroups
  );

  const agencies = useSelector(
    (state: RootState) => state.AdminUsers.adminAgencies.results
  );
  const [agency, setAgency] = useState<Array<{ value: number; label: string }>>(
    []
  );
  const [isReadOnlyAdminOptionSelect, setIsReadOnlyAdminOptionSelect] =
    useState<boolean>(false);

  useEffect(() => {
    setAgency(
      agencies.map((agency) => {
        return { value: agency.id, label: agency.name };
      })
    );
  }, [agencies]);

  useEffect(() => {
    if (editFormData && (editMode !== 'Add' || userGroup)) {
      const { phone_number, groups } = editFormData;
      const newGroups = groups?.map((group) => ({
        value: group.id,
        label: group.name
      }));
      if (
        newGroups &&
        newGroups.length === 1 &&
        newGroups[0].label === 'Read Only Admin'
      ) {
        setIsReadOnlyAdminOptionSelect(true);
      }
      const newPhone =
        phone_number && phone_number.number ? phone_number.number : undefined;

      setValue('first_name', editFormData && editFormData.first_name);
      setValue('last_name', editFormData.last_name);
      setValue('email', editFormData.email);
      setValue('phone', newPhone);
      setValue('group_ids', newGroups);
      setCheck(editFormData.agency ? agencies[editFormData.agency - 1].id : 0);
      setValue(
        'send_ipaws_email_notifications',
        editFormData.send_ipaws_email_notifications
      );
      setEmailChecked(editFormData.send_ipaws_email_notifications);
      setSmsChecked(editFormData.send_ipaws_sms_notifications);
      setValue(
        'send_ipaws_sms_notifications',
        editFormData.send_ipaws_sms_notifications
      );
      setValue('agency', editFormData.agency);
      setUserEnabled(editFormData.enabled);
      setValue('enabled', editFormData.enabled);
    }
  }, [editMode, editFormData, userGroup]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver<any>(formSchema)
  });

  useEffect(() => {
    setGroups(
      adminGroups.results.map((group) => {
        return {
          value: group.id,
          label: group.name
        };
      })
    );
  }, [adminGroups, adminGroups.results]);

  const timerToSaveDetails = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (
      errors.send_ipaws_sms_notifications &&
      errors.send_ipaws_sms_notifications.message
    ) {
      debounceFunction(timerToSaveDetails, 5000, () => {
        clearErrors('send_ipaws_sms_notifications');
      });
    }
  }, [errors?.send_ipaws_sms_notifications]);

  const onSubmit = (data) => {
    const { first_name, last_name, email, phone, group_ids } = data;
    const newData = {
      first_name,
      last_name,
      email,
      enabled: userEnabled,
      phone: phone ? `+${1}${phone}` : null,
      group_ids: group_ids.map((group) => group.value),
      agency: check,
      send_ipaws_email_notifications: emailChecked,
      send_ipaws_sms_notifications: smsChecked
    };

    if ((editMode === 'Edit' || userGroup) && editFormData && editFormData.id) {
      dispatch(
        editAdminUser({
          userId: editFormData.id,
          data: newData,
          params: {
            ordering: 'first_name, email, id',
            limit: 'all',
            search: searchFilterValue
          }
        })
      );
      postModalClose();
    } else {
      dispatch(
        addNewAdminUser({
          data: newData,
          params: {
            ordering: 'first_name, email, id',
            limit: 'all',
            search: searchFilterValue
          }
        })
      );
      postModalClose();
    }
  };

  const isReadOnlyAdminSelect = (data) => {
    let isReadOnlyExist = false;
    if (data?.length) {
      data.forEach((admin) => {
        if (admin.value === 3 && getValues('group_ids')[0]?.value !== 3) {
          isReadOnlyExist = true;
        }
      });
      if (isReadOnlyExist) {
        setValue(
          'group_ids',
          data.filter((admin: any) => admin.value === 3)
        );
        setIsReadOnlyAdminOptionSelect(true);
      } else {
        setValue(
          'group_ids',
          data.filter((admin: any) => admin.value !== 3)
        );
        setIsReadOnlyAdminOptionSelect(false);
      }
    } else {
      setValue('group_ids', data);
      setIsReadOnlyAdminOptionSelect(false);
    }
  };

  return (
    <>
      {userGroup ? (
        <UserGroupProfileSection className="d-flex flex-column p-4 gap-4 flex-grow-1">
          <div className="change-user-group">
            <div className="d-flex align-items-center">
              {editFormData && (
                <Avatar
                  src={editFormData ? editFormData?.profile_image : ''}
                  username={getUserInitials(
                    editFormData && editFormData?.fullname
                  )}
                  userNameColor={getUserColor(
                    editFormData && editFormData?.fullname
                  )}
                  isDefault={false}
                />
              )}
              <div className="user-details">
                <p className="text-name">
                  {editFormData && editFormData.first_name}{' '}
                  {editFormData && editFormData.last_name}
                </p>
                <p className="text-email">
                  {editFormData && editFormData.email}
                </p>
              </div>
            </div>
            <Button
              onClick={() => {
                setEditMode('View');
                setEditFormData({ ...editFormData });
                setUserGroup(false);
                setIsModalOpen(true);
              }}
              {...btnStyles.tertiary}
              className="col-sm-4"
            >
              {STRINGS.VIEW_PROFILE}
              <Icon
                icon="share_01"
                className="ms-2"
                height="16px"
                width="16px"
                stroke={`${colorState.icon.brand.primary}`}
              />
            </Button>
          </div>
          <TextNote className="p-3">
            {STRINGS.PERMISSION_LIST_WILL_BE_CHANGE_WHEN_SELECT_THE_USER_GROUP}
          </TextNote>
          <FormSegment
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <section className="formset-fields p-0">
              <div className="form-group">
                <Controller
                  name="group_ids"
                  control={control}
                  render={({ field: { value } }) => (
                    <Select
                      options={groups}
                      isMultiSelect="true"
                      value={value}
                      {...selectStyles}
                      displayName={STRINGS.USER_GROUPS}
                      checkmarkStrokeColor="red"
                      error={errors.group_ids && !watch('group_ids').length}
                      onChange={(e) => isReadOnlyAdminSelect(e)}
                      placeHolder={STRINGS.SELECT_USER_GROUPS}
                      hintText={
                        errors &&
                        errors.group_ids &&
                        !watch('group_ids').length &&
                        errors.group_ids.message
                      }
                      asterisk={true}
                      checkboxColor={colorState.icon.brand.primary}
                    />
                  )}
                />

                {isReadOnlyAdminOptionSelect && (
                  <p className="mt-2 text-start">
                    Note: "Read-Only" will result in the loss of admin rights
                  </p>
                )}
              </div>
            </section>
            <section className="footer">
              <div className="d-flex gap-3 justify-content-end w-100">
                <Button
                  onClick={postModalClose}
                  disabled={isSubmitting}
                  {...btnStyles.secondary}
                  type="button"
                >
                  {STRINGS.CANCEL}
                </Button>
                <Button
                  type="submit"
                  disabled={editMode || isSubmitting}
                  {...btnStyles.primary}
                >
                  {isSubmitting ? STRINGS.SAVING : STRINGS.SAVE_CHANGES}
                </Button>
              </div>
            </section>
          </FormSegment>
        </UserGroupProfileSection>
      ) : (
        <FormSegment
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <>
            {editMode === 'View' &&
              getPermission(currentUser, 'add_applicationuser') && (
                <div className="edit-form">
                  <p className={'mb-0'}>
                    Edit your profile using the edit button
                  </p>
                  <Button
                    type="button"
                    className="col-2"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditMode('Edit');
                    }}
                    disabled={isSubmitting}
                    {...btnStyles.secondary}
                  >
                    <Icon
                      icon="edit_02"
                      height="16px"
                      width="16px"
                      stroke={`${colorState.icon.brand.primary}`}
                    />
                    {STRINGS.EDIT}
                  </Button>
                </div>
              )}
          </>
          <section className="formset-fields">
            <div className=" d-flex col-12">
              <div className="form-group col-6 pe-3 mb-0">
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      id="first_name"
                      type="text"
                      label={STRINGS.FIRST_NAME}
                      value={value}
                      onChange={(e) => onChange(e)}
                      placeholder={STRINGS.FIRST_NAME}
                      error={errors && errors.first_name}
                      hintText={
                        errors && errors.first_name && errors.first_name.message
                      }
                      disabled={editMode === 'View'}
                      asterisk={true}
                      {...inputStyles}
                    />
                  )}
                />
              </div>

              <div className="form-group col-6 mb-0">
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      id="last_name"
                      type="text"
                      label={STRINGS.LAST_NAME}
                      value={value}
                      onChange={(e) => onChange(e)}
                      placeholder={STRINGS.LAST_NAME}
                      error={errors && errors.last_name}
                      hintText={
                        errors && errors.last_name && errors.last_name.message
                      }
                      disabled={editMode === 'View'}
                      asterisk={true}
                      {...inputStyles}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    id="email"
                    type="text"
                    label={STRINGS.EMAIL}
                    value={value}
                    onChange={(e) => onChange(e)}
                    placeholder={STRINGS.ENTER_EMAIL}
                    error={errors && errors.email}
                    hintText={errors && errors.email && errors.email.message}
                    disabled={editMode === 'View'}
                    asterisk={true}
                    {...inputStyles}
                  />
                )}
              />
            </div>

            <div className="form-group">
              <div className="d-flex">
                <div className="text-nowrap col-4 pe-1">
                  <Input
                    name="Country Code"
                    label="Country Code"
                    placeholder="+1 (USA)"
                    isMargin={false}
                    {...inputStyles}
                    disabled={true}
                  />
                </div>
                <div className="col-8 ps-1">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Input
                            label={STRINGS.CONTACT_NUMBER}
                            id="phone"
                            type="text"
                            value={value}
                            placeholder={STRINGS.ENTER_CONTACT_NUMBER}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors('send_ipaws_sms_notifications');
                              if (e.target.value.length === 0) {
                                setSmsChecked(false);
                              }
                            }}
                            error={errors.phone}
                            hintText={
                              errors && errors.phone && errors.phone.message
                            }
                            disabled={editMode === 'View'}
                            {...inputStyles}
                            isMargin={false}
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <Controller
                name="group_ids"
                control={control}
                render={({ field: { value } }) => (
                  <Select
                    options={groups}
                    isMultiSelect="true"
                    value={value}
                    displayName={STRINGS.USER_GROUPS}
                    placeHolder={STRINGS.SELECT_USER_GROUPS}
                    error={errors.group_ids && !watch('group_ids').length}
                    onChange={(e) => isReadOnlyAdminSelect(e)}
                    hintText={
                      errors &&
                      errors.group_ids &&
                      !watch('group_ids').length &&
                      errors.group_ids.message
                    }
                    disabled={editMode === 'View'}
                    asterisk={true}
                    {...selectStyles}
                    checkboxColor={colorState.icon.brand.primary}
                  />
                )}
              />

              {isReadOnlyAdminOptionSelect && (
                <p className="mt-2 text-start">
                  Note: "Read-Only" will result in the loss of admin rights
                </p>
              )}
            </div>

            <div className="form-group custom-radio-group">
              <h6>
                {STRINGS.AGENCY}
                <span className="text-danger ps-1">*</span>
              </h6>
              <div className="form-group mb-1">
                {agency.map((option, index: number) => (
                  <RadioBox
                    key={index}
                    id={index}
                    text={option.label}
                    checked={check === option.value}
                    onChange={() => {
                      setCheck(option.value);
                      setValue('agency', option.value);
                      clearErrors('agency');
                    }}
                    disabled={editMode === 'View'}
                    {...radioBoxStyles}
                  />
                ))}
              </div>
              <p className="error-text">
                {errors &&
                  errors.agency &&
                  typeof errors.agency.message === 'string' &&
                  errors.agency.message}
              </p>
            </div>

            <div className="form-group mt-4">
              <div>
                <h6>{STRINGS.SEND_IPAWS_NOTIFICATIONS}</h6>
                <p className="error-text">
                  {errors &&
                    errors.send_ipaws_sms_notifications &&
                    typeof errors.send_ipaws_sms_notifications.message ===
                      'string' &&
                    errors.send_ipaws_sms_notifications.message}
                </p>
              </div>
              <p className="mb-3">
                {
                  STRINGS.PHONE_NUMBER_IS_ONLY_REQUIRED_FOR_SEND_IPAWS_SMS_NOTIFICATIONS
                }
              </p>
              <CheckBox
                text={STRINGS.SEND_IPAWS_EMAIL_NOTIFICATIONS}
                onClick={() => {
                  setValue('send_ipaws_email_notifications', !emailChecked);
                  setEmailChecked(!emailChecked);
                }}
                selected={emailChecked}
                {...register('send_ipaws_email_notifications')}
                className="mb-3"
                disabled={editMode === 'View'}
                {...checkBoxStyles}
                onKeyDown={(e: any) => {
                  if (e.key === ' ') {
                    setValue('send_ipaws_email_notifications', !emailChecked);
                    setEmailChecked(!emailChecked);
                  }
                }}
              />
              <CheckBox
                text={STRINGS.SEND_IPAWS_SMS_NOTIFICATIONS}
                onClick={() => {
                  if (getValues('phone')) {
                    setValue('send_ipaws_sms_notifications', !smsChecked);
                    setSmsChecked(!smsChecked);
                  } else {
                    setError('send_ipaws_sms_notifications', {
                      message: STRINGS.PLEASE_PROVIDE_YOUR_CONTACT_NUMBER_ABOVE
                    });
                  }
                }}
                onKeyDown={(e: any) => {
                  if (e.key === ' ') {
                    if (getValues('phone')) {
                      setValue('send_ipaws_sms_notifications', !smsChecked);
                      setSmsChecked(!smsChecked);
                    } else {
                      setError('send_ipaws_sms_notifications', {
                        message:
                          STRINGS.PLEASE_PROVIDE_YOUR_CONTACT_NUMBER_ABOVE
                      });
                    }
                  }
                }}
                selected={smsChecked}
                {...register('send_ipaws_sms_notifications')}
                disabled={editMode === 'View'}
                {...checkBoxStyles}
              />
            </div>
            <div className="form-group d-flex align-items-center">
              <h6 className="me-3 mb-0 pb-0">{STRINGS.ENABLE_USER}</h6>
              <Controller
                name="enabled"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    id="enabled"
                    selected={userEnabled}
                    onClick={() => {
                      setUserEnabled((prev) => !prev);
                    }}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    disabled={editMode === 'View'}
                    value={value}
                    {...switchStyles}
                  />
                )}
              />
            </div>
          </section>

          <section className="footer">
            <div className="d-flex gap-3 justify-content-end w-100">
              <Button onClick={postModalClose} {...btnStyles.secondary}>
                {STRINGS.CANCEL}
              </Button>
              {editMode !== 'View' ? (
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  {...btnStyles.primary}
                >
                  {isSubmitting
                    ? STRINGS.SAVING
                    : editMode === 'Add'
                    ? STRINGS.SAVE
                    : STRINGS.SAVE_CHANGES}
                </Button>
              ) : null}
            </div>
          </section>
        </FormSegment>
      )}
    </>
  );
};

export default Modal(AddEditAdminUserModal);
