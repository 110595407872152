import axios from 'axios';
import { showError, showPopUp } from '../../CommonUtilities/CommonUtilities';
import { api_prefix } from '../../Constants/constants';
import { FetchMapData } from '../actions/Resources';
import { put, takeLatest } from 'redux-saga/effects';
import { fetchMapData, mapDataReceived } from '../reducers/Resources';

function* fetchMapDataSaga({ payload }: FetchMapData) {
  try {
    const json = yield axios
      .post(`${api_prefix}closures/get_excel_report/`, payload.params)
      .then((response) => response.data);
    yield put(mapDataReceived({ data: json?.data }));
    if(!json?.data.length) showPopUp('No closures in selected time period', 'info');
  } catch (error) {
    yield put(mapDataReceived({ data: [] }));
    showError(error);
  }
}

export default function* watchResources() {
  yield takeLatest(fetchMapData, fetchMapDataSaga);
}
