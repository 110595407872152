import dayjs from 'dayjs';
import { Icon } from '../../../Universal/NovusDSImports';
import { useReduxDispatch, useReduxSelector } from '../../../Store/reduxHooks';
import { markSelectedMessagesAsRead } from '../../../Store/reducers/RegisteredUsers';
import { STRINGS } from '../../../Constants/ConstantStrings';
import { RootState } from '../../../store';
import { PopupWindow } from '../../globalStyles';
import { ContactHistoryStyles } from './styles';
import { getPermission } from '../../../CommonUtilities/CommonUtilities';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import PALoader from '../../../SharedComponets/PALoader';

export const ContactHistory = (props: any) => {
  const isFetchingChatHistory = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.isFetchingChatHistory
    ),
    currentUser = useReduxSelector(
      (State: RootState) => State.Common.currentUserDetails
    ),
    closuresUnreadMessagesCount = useReduxSelector(
      (state: RootState) => state.Closures.closuresUnreadMessagesCount
    ),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    dispatch = useReduxDispatch();

  const popUpRef = useRef<HTMLDivElement>(null);

  const [customStyles, setCustomStyles] = useState<CSSProperties>({
    zIndex: 2,
    position: 'fixed',
    right: props.viewWidth + 30,
    top: props.topVal,
    minWidth: 300,
    maxWidth: 400
  });

  const Popup = document.getElementById('contact-history-popup');
  Popup?.addEventListener('wheel', (e) => {
    e.stopPropagation();
  });

  useEffect(() => {
    if (popUpRef && popUpRef.current) {
      const rect = popUpRef.current.getBoundingClientRect();

      if (window.innerHeight < rect.bottom) {
        setCustomStyles((prevStyles) => ({
          ...prevStyles,
          /* /2 - As innerHeight is less that the bottomHeight we are making to rendering the popup in the middle of View height. */
          top: props.topVal - rect.height / 2 + props.viewHeight
        }));
      } else {
        setCustomStyles((prevStyles) => ({
          ...prevStyles,
          /* /4 - As innerHeight is greater than the bottomHeight we are rendering the popup in the 1/4 height of  view height. */
          top: props.topVal - (rect.height / 4 - props.viewHeight)
        }));
      }
    }
  }, [popUpRef, props.topVal]);

  return (
    <>
      <PopupWindow
        style={customStyles}
        id="contact-history-popup"
        ref={popUpRef}
      >
        <div className="header">
          <h5>{STRINGS.CONTACT_HISTORY}</h5>
          <div
            tabIndex={0}
            onKeyDown={() => {
              props.onPopupClose();
              props.ContactHistoryPopup();
            }}
          >
            <Icon
              icon="close"
              width={'16px'}
              height={'16px'}
              onClick={() => {
                props.onPopupClose();
                props.ContactHistoryPopup();
              }}
              className="close-icon"
            >
              {STRINGS.CLOSE}
            </Icon>
          </div>
        </div>
        {isFetchingChatHistory ? (
          <PALoader />
        ) : (
          <ContactHistoryStyles className="body d-flex flex-column gap-3 overflow-auto">
            <div className="contact-details">
              <h6>{STRINGS.PHONE}</h6>
              <p className="white-space-wrap">
                {props.selectedUser.phone_number}
              </p>
            </div>
            {props.chatHistory &&
              props.chatHistory.map((message: any) => {
                return (
                  <div
                    className={`${
                      message.sent_by ? 'my-messages' : 'incoming-messages'
                    } d-flex flex-column gap-1`}
                  >
                    <div
                      className={`${
                        message.sent_by
                          ? 'justify-content-end'
                          : 'justify-content-between'
                      } d-flex`}
                    >
                      <span className="message-date-time">
                        {dayjs(message.created).format('MM/DD/YYYY hh:mm A')}
                      </span>
                      {!message.sent_by ? (
                        !message.is_read ? (
                          getPermission(
                            currentUser,
                            'update_messages_states'
                          ) && (
                            <span
                              onClick={() =>
                                dispatch(
                                  markSelectedMessagesAsRead({
                                    messageIds: [message.id],
                                    userId: props.selectedUser.id,
                                    closureUnreadMessagesCount:
                                      closuresUnreadMessagesCount,
                                    closureId: selectedClosure.id
                                  })
                                )
                              }
                              className="mark-as-read-text"
                            >
                              {STRINGS.MARK_AS_READ}
                            </span>
                          )
                        ) : (
                          <span className="user-name">
                            {message.read_by?.email}
                          </span>
                        )
                      ) : null}
                    </div>
                    <p
                      className={
                        !message.sent_by && message.is_read
                          ? 'read-msg'
                          : 'unread-msg'
                      }
                    >
                      {message.message_body}
                      {'  '}
                    </p>
                  </div>
                );
              })}
          </ContactHistoryStyles>
        )}
      </PopupWindow>
    </>
  );
};

export default ContactHistory;
