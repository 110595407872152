import {
  Fragment,
  FunctionComponent,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { MapComponentWrapper, PopupWindow } from '../globalStyles';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polygon,
  useLoadScript
} from '@react-google-maps/api';
// import { PA_BOUNDARY } from '../../Constants/constants';
import { convertArrayToLatLng } from '../../CommonUtilities/CommonUtilities';
import { useReduxSelector } from '../../Store/reduxHooks';
import { RootState } from '../../store';
import { TransportationIcon } from '../../Universal/Assets';
import { STRINGS } from '../../Constants/ConstantStrings';
import { Icon } from '../../Universal/NovusDSImports';
import dayjs from 'dayjs';
import { ClosuresData } from '../../Store/actions/Resources';
import PALoader from '../../SharedComponets/PALoader';
import GoogleMapsErrorBoundary from '../../GoogleMapsErrorBoundary';
import { GOOGLE_MAPS_API_KEY } from '../../settings';

const libraries: any = ['drawing'];

const PopupWindowModal: FunctionComponent<any> = memo((props) => {
  const { closure, setSelectedClosure } = props;
  return (
    <PopupWindow>
      <div className="header">
        <h5>{STRINGS.INCIDENT_DETAILS}</h5>
        <Icon
          icon="close"
          width={'16px'}
          height={'16px'}
          onClick={() => setSelectedClosure(null)}
          className="close-icon"
        >
          {STRINGS.CLOSE}
        </Icon>
      </div>
      <div className="body">
        <h6>{closure?.wea_short_msg_english}</h6>
        <div className="row latitude-longitude-section">
          <div className="col-sm-6">
            <label>{STRINGS.LATITUDE}</label>
            <p>{closure?.centre_latitude}</p>
          </div>
          <div className="col-sm-6">
            <label>{STRINGS.LONGITUDE}</label>
            <p>{closure?.centre_longitude}</p>
          </div>
        </div>
        <div className="group mt-3">
          <label>{STRINGS.CURRENT_CONDITION}</label>
          <p>{closure?.description}</p>
        </div>

        <div className="group">
          <label>{STRINGS.UPDATED_ON}</label>
          <p> {dayjs(closure?.created_on).format('MM/DD/YYYY  hh:mm A')}</p>
        </div>
      </div>
    </PopupWindow>
  );
});

interface ResourcesMapPropType {
  selectedClosure: any;
  setSelectedClosure: any;
}

const ResourcesMap: FunctionComponent<ResourcesMapPropType> = (props) => {
  const { selectedClosure, setSelectedClosure } = props;
  const closuresData = useReduxSelector(
      (state: RootState) => state.Resources.mapData
    ),
    isFetchingMapData = useReduxSelector(
      (state: RootState) => state.Resources.isFetchingMapData
    );

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries
  });
  const [center] = useState<{ lat: any; lng: any }>({
      lat: 40.881086,
      lng: -77.692104
    }),
    mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (closuresData?.length) {
      const tempBounds = new google.maps.LatLngBounds();
      closuresData.forEach((closure: ClosuresData) => {
        closure.geometry?.coordinates?.[0]?.forEach((coord: any) => {
          tempBounds.extend({
            lat: coord[1],
            lng: coord[0]
          });
        });
      });
      mapRef.current?.fitBounds(tempBounds);
    }
  }, [closuresData]);

  const onMapLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;
    },
    [mapRef]
  );

  return !isFetchingMapData && isLoaded ? (
    <MapComponentWrapper id="map-wrapper">
      <Suspense fallback={<PALoader />}>
        <GoogleMapsErrorBoundary>
          <GoogleMap
            onLoad={onMapLoad}
            center={center}
            mapContainerStyle={{
              width: '100%',
              height: '100%',
              borderRadius: 8
            }}
            zoom={8}
            options={{
              mapTypeControl: true,
              mapTypeControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_LEFT, // Set position of the control
                mapTypeIds: ['roadmap', 'satellite', 'terrain', 'hybrid']
              },
              fullscreenControl: true,
              fullscreenControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_RIGHT
              }
              // restriction: {
              //   strictBounds: false,
              //   latLngBounds: PA_BOUNDARY
              // }
            }}
          >
            {closuresData?.map((closure, index) => (
              <>
                {closure.geometry?.coordinates?.[0] ? (
                  <Polygon
                    key={index}
                    paths={convertArrayToLatLng(
                      closure.geometry?.coordinates?.[0]
                    )}
                    options={{
                      fillColor: 'red',
                      fillOpacity: 0.2,
                      strokeWeight: 3,
                      strokeColor: 'red',
                      clickable: false
                    }}
                  />
                ) : (
                  <Fragment key={index}></Fragment>
                )}
              </>
            ))}
            {closuresData?.map((closure, index) => (
              <Marker
                key={index}
                position={{
                  lat: closure.centre_latitude,
                  lng: closure.centre_longitude
                }}
                options={{
                  draggable: false
                  // optimized: true
                }}
                onClick={() => setSelectedClosure(index)}
                icon={TransportationIcon}
              >
                {selectedClosure === index && (
                  <InfoWindow
                    onCloseClick={() => setSelectedClosure(null)}
                    position={{
                      lat: closure.centre_latitude,
                      lng: closure.centre_longitude
                    }}
                  >
                    <PopupWindowModal
                      closure={closure}
                      setSelectedClosure={setSelectedClosure}
                    />
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </GoogleMap>
        </GoogleMapsErrorBoundary>
      </Suspense>
    </MapComponentWrapper>
  ) : (
    <PALoader />
  );
};

export default ResourcesMap;
