import axios from 'axios';
import { api_prefix } from '../../Constants/constants';
import {
  ApproveRequestAction,
  CancelAlertRequestAction,
  DisapproveRequestAction,
  FetchIPAWSRequestsAction,
  MarkAlertAsReadRequestAction,
  UpdateCAPValuesAction,
  UpdateCOGDetailsAction
} from '../actions/Ipaws';
import { showError, showPopUp } from '../../CommonUtilities/CommonUtilities';
import { put, takeLatest } from 'redux-saga/effects';
import {
  IPAWSRequestsReceived,
  alertApproveRequestSuccess,
  alertCancelRequestSuccess,
  alertDisapproveRequestSuccess,
  cogDetailsReceived,
  submittingIPAWS,
  markAlertAsReadCompleted,
  submittedIPAWS,
  getIPAWSRequests,
  getCOGDetails,
  requestAlertApprove,
  requestCancelAlert,
  requestAlertDisapprove,
  markAlertAsRead,
  getIPAWSOptionsData,
  IPAWSOptionsDataReceived,
  updateCAPValues,
  updateCOGDetails
} from '../reducers/IPAWS';
import { STRINGS } from '../../Constants/ConstantStrings';
import { getClosuresData } from '../reducers/Closures';

function* fetchCAPMessages({ payload }: FetchIPAWSRequestsAction) {
  try {
    const json = yield axios
      .get(
        `${api_prefix}capmessage/?closure=${payload.closureId}&limit=${payload.limit}`
      )
      .then((response) => response);
    yield put(
      IPAWSRequestsReceived({ IPAWSRequestsHistoryData: json?.data?.results })
    );
  } catch (error) {
    yield put(IPAWSRequestsReceived({ IPAWSRequestsHistoryData: null }));
    showError(error);
  }
}

function* fetchCOGDetails() {
  try {
    const json = yield axios
      .get(`${api_prefix}capmessage/get_cog_details/`)
      .then((response) => response);
    yield put(cogDetailsReceived({ cogDetails: json?.data?.message }));
  } catch (error) {
    yield put(cogDetailsReceived({ cogDetails: null }));
    showError(error);
  }
}

function* requestAlertApproveSaga({ payload }: ApproveRequestAction) {
  try {
    yield put(submittingIPAWS());
    yield axios
      .post(
        `${api_prefix}closures/${payload.closureId}/approve_alert/`,
        payload.approvePayload
      )
      .then((response) => response);
    yield put(alertApproveRequestSuccess({ isApproveRequestSuccess: true }));
    yield put(getClosuresData({ limit: 'all' }));
    yield put(submittedIPAWS());
    showPopUp(STRINGS.IPAWS_HAS_BEEB_APPROVED, 'success');
    payload.onComplete();
  } catch (error: any) {
    yield put(submittedIPAWS());
    yield put(alertApproveRequestSuccess({ isApproveRequestSuccess: false }));
    // showError(error);
    if (error.response?.status === 503) {
      showPopUp('Gateway timeout. Please try again.', 'error');
    } else if (error?.response?.status === 500) {
      showPopUp('Internal server error. Please try again.', 'error');
    } else if (error?.response?.status === 401) {
      showPopUp('Invalid email or password. Please try again.', 'error');
    } else if (error?.response?.status === 400) {
      showPopUp(error?.response?.data?.message, 'error');
    } else {
      showPopUp(STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN, 'error');
    }
  }
}

function* requestAlertDisapproveSaga({ payload }: DisapproveRequestAction) {
  try {
    yield put(submittingIPAWS());
    yield axios
      .post(
        `${api_prefix}closures/${payload.closureId}/decline_alert/`,
        payload.disapprovePayload
      )
      .then((response) => response);
    yield put(
      alertDisapproveRequestSuccess({ isDisapproveRequestSuccess: true })
    );
    yield put(getClosuresData({ limit: 'all' }));
    yield put(submittedIPAWS());
    showPopUp(STRINGS.IPAWS_HAS_BEEN_DISAPPROVED, 'success');
    payload.onComplete();
  } catch (error) {
    yield put(submittedIPAWS());
    yield put(
      alertDisapproveRequestSuccess({ isDisapproveRequestSuccess: false })
    );
    showError(error);
  }
}

function* requestAlertCancel({ payload }: CancelAlertRequestAction) {
  try {
    yield put(submittingIPAWS());
    yield axios
      .post(`${api_prefix}closures/${payload.closureId}/cancel_alert/`)
      .then((response) => response);
    yield put(alertCancelRequestSuccess({ isCancelRequestSuccess: true }));
    yield put(getClosuresData({ limit: 'all' }));
    yield put(submittedIPAWS());
    if (payload?.onComplete) payload?.onComplete();
    showPopUp(STRINGS.IPAWS_ALERT_HAS_BEEN_CANCELLED, 'success');
  } catch (error) {
    yield put(submittedIPAWS());
    yield put(alertCancelRequestSuccess({ isCancelRequestSuccess: false }));
    showError(error);
  }
}

function* requestMarkAlertAsRead({ payload }: MarkAlertAsReadRequestAction) {
  try {
    yield axios
      .post(`${api_prefix}closures/${payload.closureId}/set_read_by/`)
      .then((response) => response);
    yield put(markAlertAsReadCompleted({}));
    yield put(getClosuresData({ limit: 'all' }));
  } catch (error) {
    yield put(markAlertAsReadCompleted({}));
    showError(error);
  }
}

function* fetchingIPAWSOptionsData() {
  try {
    const json = yield axios
      .get(`${api_prefix}closures/get_cap_values/`)
      .then((response) => response);
    yield put(IPAWSOptionsDataReceived({ IPAWSOptionsData: json?.data }));
  } catch (error) {
    showError(error);
  }
}

function* updateCAPValuesSaga({ payload }: UpdateCAPValuesAction) {
  try {
    yield axios
      .patch(
        `${api_prefix}closures/${payload.params.id}/update_closure_cap_values/`,
        payload.params
      )
      .then((response) => response);
    showPopUp(`CAP values have been updated successfully`, 'success');
    yield put(getClosuresData({ limit: 'all' }));
  } catch (error) {
    showError(error);
  }
}

function* updateCOGDetailsSaga({ payload }: UpdateCOGDetailsAction) {
  try {
    yield put(submittingIPAWS());
    yield axios
      .post(`${api_prefix}capmessage/add_ipaws_creds/`, payload.params)
      .then((response) => response);
    payload.onCompleteAction();
    yield put(submittedIPAWS());
    showPopUp(STRINGS.CERTIFICATE_HAS_BEEN_UPDATED_SUCCESSFULLY, 'success');
    yield put(getCOGDetails({}));
  } catch (error) {
    yield put(submittedIPAWS());
    showError(error);
  }
}

export default function* watchIPAWS() {
  yield takeLatest(getIPAWSRequests, fetchCAPMessages);
  yield takeLatest(getCOGDetails, fetchCOGDetails);
  yield takeLatest(requestAlertApprove, requestAlertApproveSaga);
  yield takeLatest(requestAlertDisapprove, requestAlertDisapproveSaga);
  yield takeLatest(requestCancelAlert, requestAlertCancel);
  yield takeLatest(markAlertAsRead, requestMarkAlertAsRead);
  yield takeLatest(getIPAWSOptionsData, fetchingIPAWSOptionsData);
  yield takeLatest(updateCAPValues, updateCAPValuesSaga);
  yield takeLatest(updateCOGDetails, updateCOGDetailsSaga);
}
