import createSagaMiddleware from '@redux-saga/core';
import saga from './Store/sagas';
import rootReducer from './Store/reducers';
import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import { isDevInstance, isTesting, isUatInstance } from './settings';
import { RecursivePartial } from './CommonTypes';
const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({
  collapsed: true,
  duration: true
  // predicate: () => ENVIRONMENT !== 'production',
});

const middlewares: any = [sagaMiddleware];
if ((isDevInstance || isUatInstance) && !isTesting) {
  middlewares.push(logger);
}

export const setupStore = (preloadedState?: RecursivePartial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    devTools:
    (isDevInstance || isUatInstance) && !isTesting ? { trace: true } : false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
    preloadedState
  })
}

export const store = setupStore();

sagaMiddleware.run(saga);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
