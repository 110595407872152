import { styled } from '@storybook/theming';
import { TooltipProps } from './helpers';

export const TooltipWrapper = styled.div<TooltipProps>`
  position: absolute;
  z-index: 9999 !important;
  top: 0;
  left: 0;

  .tooltip {
    background-color: ${(props: any) => props.tooltipBgColor};
    color: ${(props: any) => props.tooltipTextColor};
    min-width: auto;
    max-width: ${(props: any) => props.tooltipMaxWidth};
    width: max-content;
    opacity: 1;
    border: 1px solid ${(props: any) => props.borderColor};
    // box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    //   0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    padding: ${(props: any) => (props.noPadding ? '0' : '8px 12px')};
    border-radius: 8px;
    position: absolute;
  }
`;

export const TooltipView = styled.div<TooltipProps>`
  opacity: 1;
  max-height: ${(props: any) => props.customHeight};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  overflow: auto;
`;
