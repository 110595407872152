import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { showError, showPopUp } from '../../CommonUtilities/CommonUtilities';
import { api_prefix } from '../../Constants/constants';
import {
  AddOrEditSafetyMessageAction,
  ComposeMessageAction,
  DeleteSafetyMessageAction,
  FetchChatHistoryAction,
  FetchRegisteredUsersAction,
  RequestAllVehicleLocationsAction,
  markSelectedMessagesAsReadAction
} from '../actions/RegisteredUsers';
import {
  isComposeMessageSentSuccessfullyReceived,
  chatHistoryReceived,
  getChatHistory,
  registeredUsersReceived,
  lifeSafetyMessagesReceived,
  requestingLocationCompleted,
  getRegisteredUsers,
  composeMessageRequest,
  getLifeSafetyMessages,
  markSelectedMessagesAsRead,
  requestAllVehicleLocation,
  deleteLifeSafetyMessage,
  addOrEditLifeSafetyMessage
} from '../reducers/RegisteredUsers';
import { STRINGS } from '../../Constants/ConstantStrings';
import { getMessagesData } from '../reducers/Messages';
import {
  closuresUnreadMessagesCountReceived,
  getVehicleStats
} from '../reducers/Closures';

function* fetchRegisteredUsers({ payload }: FetchRegisteredUsersAction) {
  try {
    let url = `${api_prefix}response/?closure=${payload.closureId}&limit=${payload.limit}`;
    if (payload.custom_ordering) {
      if (payload.custom_ordering === 'not_yet_contacted') {
        url += `&custom_ordering=-last_contacted_time`;
      } else {
        url += `&ordering=id&custom_ordering=${payload.custom_ordering}&abbrevated_name=${payload.abbr_name}`;
      }
    }
    const json = yield axios.get(url).then((response) => response);
    yield put(
      registeredUsersReceived({
        registeredUsers: json && json.data && json.data.results
      })
    );
    if (payload.onCompleteAction) {
      payload.onCompleteAction(
        payload.custom_ordering,
        payload.abbr_name,
        json && json.data && json.data.results
      );
    }
    yield put(getVehicleStats({ closureId: payload.closureId }));
  } catch (error) {
    yield put(registeredUsersReceived({ registeredUsers: null }));
    showError(error);
  }
}

function* composeMessageRequestSaga({ payload }: ComposeMessageAction) {
  try {
    yield axios
      .post(
        `${api_prefix}chatmessages/outbound_message/`,
        payload.composeMessagePayload
      )
      .then((response) => response);
    yield put(isComposeMessageSentSuccessfullyReceived({ isSent: true }));
    if (payload.composeMessagePayload.fetchMessages) {
      yield put(
        getMessagesData({ closureId: payload.composeMessagePayload.closure })
      );
    } else {
      yield put(
        getRegisteredUsers({
          closureId: payload.composeMessagePayload.closure,
          limit: 'all'
        })
      );
      yield put(
        getVehicleStats({ closureId: payload.composeMessagePayload.closure })
      );
    }
    showPopUp(STRINGS.SUCCESSFULLY_SENT, 'success');
  } catch (error) {
    yield put(isComposeMessageSentSuccessfullyReceived({ isSent: false }));
    showPopUp(STRINGS.FAILED_TO_SENT, 'error');
  }
}

function* fetchChatHistory({ payload }: FetchChatHistoryAction) {
  try {
    const json = yield axios
      .get(
        `${api_prefix}chatmessages/?limit=all&fields=sent_by,message_body,is_read,read_by,id,created,&received_user=${payload.userId}&ordering=created`
      )
      .then((response) => response);
    yield put(
      chatHistoryReceived({
        chatHistory: json && json.data && json.data.results
      })
    );
  } catch (error) {
    yield put(chatHistoryReceived({ chatHistory: null }));
    showError(error);
  }
}

function* markSelectedMessagesAsReadSaga({
  payload
}: markSelectedMessagesAsReadAction) {
  try {
    yield axios
      .post(`${api_prefix}chatmessages/update_messages/`, {
        is_read: true,
        message_ids: payload.messageIds
      })
      .then((response) => response);
    const data = { ...payload.closureUnreadMessagesCount };
    data[payload.closureId] -= payload.messageIds.length;
    if (data[payload.closureId] < 0) {
      data[payload.closureId] = 0;
    }
    yield put(
      closuresUnreadMessagesCountReceived({ closuresUnreadMessagesCount: data })
    );
    yield put(getChatHistory({ userId: payload.userId }));
    if (payload.fetchMessages) {
      yield put(getMessagesData({ closureId: payload.closureId || null }));
    }
  } catch (error) {
    showError(error);
  }
}

function* requestAllVehicleLocationSaga({
  payload
}: RequestAllVehicleLocationsAction) {
  try {
    yield axios
      .post(`${api_prefix}response/request_location/`, {
        closure: payload.closureId
      })
      .then((response) => response);
    payload.postModalClose();
    yield put(requestingLocationCompleted({}));
    showPopUp(STRINGS.REQUESTED_VEHICLES_LOCATION);
  } catch (error) {
    yield put(requestingLocationCompleted({}));
    payload.postModalClose();
    showError(error);
  }
}

function* getLifeSafetyMessagesSaga() {
  try {
    const json = yield axios
      .get(`${api_prefix}safetymessages/`)
      .then((response) => response);
    yield put(lifeSafetyMessagesReceived({ data: json?.data?.results }));
  } catch (error) {
    showError(error);
  }
}

function* deleteLifeSafetyMessageSaga({ payload }: DeleteSafetyMessageAction) {
  try {
    yield axios
      .delete(`${api_prefix}safetymessages/${payload.id}/`)
      .then((response) => response);
    showPopUp(STRINGS.LIFE_SAFETY_MESSAGE_DELETED_SUCCESSFULLY, 'success');
    yield put(getLifeSafetyMessages({}));
  } catch (error) {
    showError(error);
  }
}

function* addOrEditLifeSafetyMessageSaga({
  payload
}: AddOrEditSafetyMessageAction) {
  try {
    if (payload.params.isEdit) {
      yield axios
        .patch(`${api_prefix}safetymessages/${payload.params.id}/`, {
          message: payload.params.message
        })
        .then((response) => response);
      showPopUp(STRINGS.LIFE_SAFETY_MESSAGE_EDITED_SUCCESSFULLY, 'success');
    } else {
      yield axios
        .post(`${api_prefix}safetymessages/`, {
          message: payload.params.message
        })
        .then((response) => response);
      showPopUp(STRINGS.LIFE_SAFETY_MESSAGE_ADDED_SUCCESSFULLY, 'success');
    }
    yield put(getLifeSafetyMessages({}));
  } catch (error: any) {
    showError(error);
  }
}

export default function* watchRegisteredUsers() {
  yield takeLatest(getRegisteredUsers, fetchRegisteredUsers);
  yield takeLatest(composeMessageRequest, composeMessageRequestSaga);
  yield takeLatest(getChatHistory, fetchChatHistory);
  yield takeLatest(markSelectedMessagesAsRead, markSelectedMessagesAsReadSaga);
  yield takeLatest(requestAllVehicleLocation, requestAllVehicleLocationSaga);
  yield takeLatest(getLifeSafetyMessages, getLifeSafetyMessagesSaga);
  yield takeLatest(deleteLifeSafetyMessage, deleteLifeSafetyMessageSaga);
  yield takeLatest(addOrEditLifeSafetyMessage, addOrEditLifeSafetyMessageSaga);
}
