import { put, takeLatest } from 'redux-saga/effects';

import {
  getShapesFetch,
  getShapesSuccess,
  addNewShapeData,
  addNewShapeDataSuccess,
  editShapeData,
  editShapeDataSuccess,
  removeShapeData,
  removeShapeDataSuccess
} from '../reducers/Shapes';

import {
  AddNewShapeType,
  EditShapeType,
  FetchShapesType,
  RemoveShapeType
} from '../actions/Shapes';

import { defaultGetResponse } from '../../GetResponse';

import { api_prefix } from '../../Constants/constants';

import { STRINGS } from '../../Constants/ConstantStrings';

import { showError, showPopUp } from '../../CommonUtilities/CommonUtilities';

import axios from 'axios';

function* fetchShapes({ payload }: FetchShapesType) {
  try {
    const { params } = payload;
    const fetchShapesURL = `${api_prefix}polygons/`;
    if (!params.ordering) {
      params.ordering = '-modified, id';
    }
    const shapes = yield axios
      .get(fetchShapesURL, { params })
      .then((response) => response.data);

    yield put(getShapesSuccess({ shapes, shouldAppend: payload.shouldAppend }));
  } catch (err) {
    yield put(getShapesSuccess({ shapes: defaultGetResponse }));
    showError(err);
  }
}

function* addNewShape({ payload }: AddNewShapeType) {
  try {
    const addNewShapeURL = `${api_prefix}polygons/`;
    const { data, params } = payload;

    yield axios.post(addNewShapeURL, data).then((response) => response.data);

    showPopUp(STRINGS.NEW_SHAPE_ADDED_SUCCESSFULLY, 'success');

    const fetchShapesURL = `${api_prefix}polygons/`;
    const shapes = yield axios
      .get(fetchShapesURL, { params })
      .then((response) => response.data);

    yield put(getShapesSuccess({ shapes }));
  } catch (error) {
    showError(error);
    yield put(addNewShapeDataSuccess({}));
  }
}

function* editShape({ payload }: EditShapeType) {
  try {
    const { data, polygonId, params } = payload;
    const editShapeURL = `${api_prefix}polygons/${polygonId}/`;

    yield axios.patch(editShapeURL, data).then((response) => response.data);

    showPopUp(STRINGS.SHAPE_EDITED_SUCCESSFULLY, 'success');

    const fetchShapesURL = `${api_prefix}polygons/`;
    const shapes = yield axios
      .get(fetchShapesURL, { params })
      .then((response) => response.data);

    yield put(getShapesSuccess({ shapes }));
  } catch (error) {
    showError(error);
    yield put(editShapeDataSuccess({}));
  }
}

function* removeShape({ payload }: RemoveShapeType) {
  try {
    const { polygonId, params } = payload;
    const deleteShapeURL = `${api_prefix}polygons/${polygonId}/`;

    yield axios.delete(deleteShapeURL).then((response) => response.data);

    showPopUp(STRINGS.SHAPE_DELETED_SUCCESSFULLY, 'success');

    const fetchShapesURL = `${api_prefix}polygons/`;
    const shapes = yield axios
      .get(fetchShapesURL, { params })
      .then((response) => response.data);

    yield put(getShapesSuccess({ shapes }));
  } catch (error) {
    showError(error);
    yield put(removeShapeDataSuccess({}));
  }
}

export default function* watchShapes() {
  yield takeLatest(getShapesFetch, fetchShapes);
  yield takeLatest(addNewShapeData, addNewShape);
  yield takeLatest(editShapeData, editShape);
  yield takeLatest(removeShapeData, removeShape);
}
