import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { api_prefix } from '../../Constants/constants';
import {
  emailVerify,
  emailVerifyReceived,
  passwordUpdate,
  passwordUpdateReceived
} from '../reducers/ForgotPassword';
import { showError } from '../../CommonUtilities/CommonUtilities';

function* emailVerifySaga({ payload }: any) {
  try {
    let url = `${api_prefix}change_password/forgot_password/`;
    yield axios.post(url, payload);
    yield put(
      emailVerifyReceived({
        isEmailVerified: true,
        message: 'Successfully Verified'
      })
    );
  } catch (error: any) {
    yield put(
      emailVerifyReceived({
        isEmailVerified: false,
        message: error.response.data.message
      })
    );
  }
}

function* passwordUpdateSaga({ payload }: any) {
  try {
    let url = `${api_prefix}change_password/update_password/`;
    const json = yield axios
      .post(url, payload)
      .then((response) => response.data);
    yield put(
      passwordUpdateReceived({
        isPasswordUpdated: true,
        message: json.message
      })
    );
  } catch (error: any) {
    showError(error);
    if (error.response?.data?.message[0] === 'Reset password link expired') {
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    }
    yield put(
      passwordUpdateReceived({
        isPasswordUpdated: false,
        message: error.response.data.password[0]
      })
    );
  }
}

export default function* watchForgotPassword() {
  yield takeLatest(emailVerify, emailVerifySaga);
  yield takeLatest(passwordUpdate, passwordUpdateSaga);
}
