import { colorState } from '../Foundation';

export const btnStyles = {
  default: {
    color: colorState.text.secondary,
    backgroundColor: colorState.actions.default['default-bg'],
    border: 'none',
    hoverColor: colorState.actions.default['default-bg-hover'],
    activeColor: colorState.actions.default['default-bg-pressed'],
    focusColor: colorState.actions.default['default-bg']
  },
  primary: {
    color: 'white',
    backgroundColor: colorState.actions.primary['primary-bg'],
    border: 'none',
    hoverColor: colorState.actions.primary['primary-bg-hover'],
    activeColor: colorState.actions.primary['primary-bg-pressed'],
    focusColor: colorState.actions.primary['primary-bg']
  },
  secondary: {
    color: colorState.text.brand.secondary,
    backgroundColor: colorState.actions.secondary['secondary-bg'],
    border: `1px solid ${colorState.border.brand.secondary}`,
    hoverColor: colorState.actions.secondary['secondary-bg-hover'],
    activeColor: colorState.actions.secondary['secondary-bg-pressed'],
    focusColor: colorState.border.brand.primary
  },
  tertiary: {
    color: colorState.text.brand.secondary,
    backgroundColor: 'transparent',
    border: 'none',
    hoverColor: colorState.actions.secondary['secondary-bg-hover'],
    activeColor: colorState.actions.secondary['secondary-bg-pressed'],
    focusColor: colorState.border.brand.primary
  },
  danger: {
    color: 'white',
    backgroundColor: colorState.actions.danger['danger-bg'],
    border: 'none',
    hoverColor: colorState.actions.danger['danger-bg-hover'],
    activeColor: colorState.actions.danger['danger-bg-pressed'],
    focusColor: colorState.border.danger.primary
  },
  dangerTertiary: {
    color: colorState.text.danger.primary,
    backgroundColor: 'transparent',
    border: 'none',
    hoverColor: colorState.actions['danger-secondary']['danger-bg-hover'],
    activeColor: colorState.actions['danger-secondary']['danger-bg-pressed'],
    focusColor: colorState.border.danger.primary
  },

  warning: {
    color: colorState.text['primary-inverse'],
    backgroundColor: colorState.actions.warning['warning-bg'],
    border: 'none',
    hoverColor: colorState.actions.warning['warning-bg-hover'],
    focusColor: colorState.actions.warning['warning-bg']
  },

  warningSecondary: {
    color: colorState.text.warning.primary,
    backgroundColor: colorState.actions['warning-secondary']['danger-bg'],
    border: `1px solid ${colorState.border.warning.secondary}`,
    hoverColor: colorState.actions['warning-secondary']['danger-bg-hover'],
    focusColor: colorState.actions.warning['warning-bg-pressed'],
    activeColor: colorState.actions['warning-secondary']['danger-bg-pressed']
  }
};

export const inputStyles = {
  labelTextColor: colorState.text.secondary,
  hintTextColor: colorState.text.tertiary,
  boxShadow: `0 0 0 3px ${colorState.shadow.brand.primary}`,
  bgColor: 'transparent',
  borderColor: colorState.border.default.secondary,
  hoverBorderColor: colorState.border.brand.primary
};

export const avatarStyles = {
  userNameColor: colorState.text.brand.primary
};

export const selectStyles = {
  labelTextColor: colorState.text.secondary,
  hintTextColor: colorState.text.tertiary,
  placeholderColor: colorState.text.tertiary,
  borderColor: colorState.border.default.secondary,
  hoverBorderColor: colorState.border.brand.primary,
  boxShadow: colorState.shadow.brand.primary,
  checkmarkStrokeColor: colorState.icon.brand.primary,
  optionSelectedTextColor: colorState.text.brand.primary,
  optionSelectedBgColor: colorState.surface.brand.tertiary
};

export const checkBoxStyles = {
  borderColor: colorState.border.brand.primary,
  focusColor: colorState.border.brand.primary,
  boxShadowColor: colorState.shadow.brand.primary,
  hoverBorderColor: colorState.border.brand.primary,
  backgroundColor: colorState.border.brand.primary
};

export const radioBoxStyles = {
  borderColor: colorState.border.brand.primary,
  focusBorderColor: colorState.border.brand.primary,
  boxShadow: colorState.shadow.brand.primary,
  hoverBorderColor: colorState.border.brand.primary,
  focusDotColor: colorState.surface.brand.primary
};

export const statusVariants: Record<string, Record<string, string>> = {
  success: {
    color: colorState.text.success.primary,
    backgroundColor: colorState.surface.success.tertiary,
    stroke: colorState.icon.success['primary-strong']
  },
  warning: {
    color: colorState.text.warning.primary,
    backgroundColor: colorState.surface.warning.tertiary,
    stroke: colorState.icon.warning['primary-strong']
  },
  danger: {
    color: colorState.text.danger.primary,
    backgroundColor: colorState.surface.danger.tertiary,
    stroke: colorState.icon.danger['primary']
  }
};

export const modalStyles = {
  modalHeaderBgColor: colorState.surface.brand.primary,
  modalHeaderTextColor: 'white',
  closeIconStrokeColor: 'white'
};

export const confirmPopupModalStyles = {
  modalHeaderBgColor: colorState.surface.default.primary,
  modalHeaderTextColor: colorState.surface.default['primary-inverse'],
  closeIconStrokeColor: 'black'
};

export const switchStyles = {
  backgroundColor: colorState.actions.primary['primary-bg']
};

export const singleDatePickerStyles = {
  daySelectedBgColor: colorState.surface.brand.primary,
  dayOnHoverColor: colorState.border.brand.primary,
  dayOnHoverBgColor: colorState.surface.brand.tertiary,
  todayBorderColor: colorState.border.brand.secondary,
  todayTextColor: colorState.text.brand.primary,
  hoverBorderColor: colorState.border.brand.secondary,
  boxShadow: `0 0 0 3px ${colorState.shadow.brand.primary}`
};

export const loaderStyles = {
  borderColor: colorState.icon.default.disabled,
  borderBottomColor: colorState.icon.brand.primary
};
