import styled from 'styled-components';
import {
  colorState,
  radius,
  spacing,
  typography
} from '../../Universal/Foundation';

export const GenericTableStyles = styled.table<any>`
  table-layout: ${(props) => (props.isResizeable ? 'fixed' : '')};
  display: table;
  width: 100%;
  overflow: auto;

  @-moz-document url-prefix() {
    border-collapse: separate;
    border-spacing: 0px;
  }

  &:focus-visible {
    outline: none;
    border: solid 2px ${colorState.border.brand.primary};
    border-radius: 8px;
  }

  .event-questions-wrapper {
    font-size: ${typography.size.ts};
    font-weight: ${typography.weight.medium};
    color: ${colorState.text.primary};
    word-break: break-word;

    ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        padding-top: 12px;
        margin-left: 20px;
        font-weight: ${typography.weight.regular};
        color: ${colorState.text.tertiary};
      }
    }
  }

  .serial-no {
    padding-left: 12px;
    text-wrap: nowrap;
  }
`;

export const TableHeader = styled.thead<any>`
  position: sticky;
  top: -1px;
  z-index: 1;

  tr {
    display: table-row !important;
    width: 100% !important;
    background-color: ${colorState.surface.default.secondary};

    th {
      display: table-cell !important;
      border: 1px solid ${colorState.border.default.secondary};
      padding: 12px 20px;
      width: ${(props) => (props.isResizeable ? '' : 'fit-content !important')};
      cursor: pointer;

      @-moz-document url-prefix() {
        border: 0.5px solid ${colorState.border.default.secondary};
      }

      b {
        font-size: ${typography.size.ts};
        font-weight: ${typography.weight.medium};
        color: ${colorState.text.primary};
        width: fit-content !important;
        max-width: 600px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;

      
      .resizer {
        display: inline-block;
        background: 1px solid ${colorState.border.default.secondary};
        width: 0.5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing, &:hover {
          background: black;
        }
      }
      }
&:hover {
        background-color: ${colorState.background['secondary-bg']};
        }
    }      
    }
  }
`;

export const TableBody = styled.tbody<any>`
  tr {
    display: table-row !important;
    :nth-child(odd) {
      background-color: ${colorState.surface.default.secondary};
    }
    min-height: 58px;
    max-height: max-content;

    &:focus-visible {
      outline: none;
      border: solid 2px ${colorState.border.brand.primary};
    }

    &:hover {
      background-color: ${colorState.surface.brand.tertiary};
    }

    td {
      display: table-cell !important;
      border: 1px solid ${colorState.border.default.secondary};
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.regular};
      color: ${colorState.text.tertiary};
      cursor: default;
      width: ${(props) => (props.isResizeable ? '' : 'fit-content !important')};

      @-moz-document url-prefix() {
        border: 0.5px solid ${colorState.border.default.secondary};
      }

      .table-data-cell {
        padding: 14px 20px;
        max-width: 600px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        line-height: 20px;
      }

      .number {
        margin-right: 0px;
        margin-left: 15px;
        padding: 14px 0px 0px 0px;
        font-size: ${typography.size.ts};
        font-weight: ${typography.weight.medium};
        color: ${colorState.text.primary};
      }

      .disabled {
        background-color: transparent;
        cursor: not-allowed;
        margin: 6px;
      }

      .action-icons {
        cursor: pointer;
        padding: 6px;
        width: fit-content;
        border-radius: ${radius.radius_8}px;

        .disabled {
          &:hover {
            .trash {
              path {
                stroke: none !important;
              }
            }
          }
        }

        &:hover {
          .trash {
            path {
              stroke: ${colorState.icon.danger.primary} !important;
            }
          }
        }

        &:hover {
          background-color: ${colorState.surface.default.moderate};
        }
      }

      .generate-pdf-icon {
        border-radius: ${radius.radius_20}px;
        background-color: ${colorState.surface.default.tertiary};
        padding: ${spacing.spacing_10}px;
      }

      .disabled-generated-pdf-icon {
        path {
          stroke: ${colorState.icon.brand['primary-disabled']} !important;
        }
      }

      .link {
        color: ${colorState.text.link};
        cursor: pointer;

        .disabled-link {
          opacity: 70%;
          cursor: not-allowed;
        }
      }
    }

    .disabled-cell {
      opacity: 70% !important;
      cursor: not-allowed !important;
    }

    td.selected-td {
      border-bottom: 1px solid ${colorState.surface.brand.primary};
    }
  }

  .view-details-section {
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;

    .option-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border: 1px solid transparent;

      &:hover,
      &:focus,
      &:active {
        border-radius: 8px;
        border: 1px solid ${colorState.border.brand.primary};
      }
    }

    &:focus-visible {
      outline: none;
      border: solid 1px ${colorState.border.brand.primary};
      border-radius: 8px;
    }
  }
`;

export const TablePopupStyles = styled.div`
  min-width: 180px;
  padding: 4px;
  background-color: ${colorState.background['secondary-bg']};
  border-radius: 8px;

  ul {
    background-color: ${colorState.surface.default.primary};
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 0;
    list-style: none;

    &:focus-visible {
      outline: none;
      border: solid 1px ${colorState.border.brand.primary};
      border-radius: 8px;
    }

    li {
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.medium};
      color: ${colorState.text.secondary};
      font-family: 'Poppins';
      font-display: fallback;
      padding: 8px;
      cursor: pointer;

      &:hover,
      &:active svg {
        path {
          stroke: ${colorState.icon.brand.primary};
        }
      }

      &:hover,
      &:active {
        width: 100%;
        background-color: ${colorState.surface.brand.tertiary};
        border-radius: 8px;
        color: ${colorState.text.brand.primary};
      }

      &:focus-visible {
        outline: none;
        border: solid 1px ${colorState.border.brand.primary};
        border-radius: 8px;
      }
    }
    .disabled {
      opacity: 70%;
      cursor: not-allowed;
    }
  }
`;
