import { createSlice } from '@reduxjs/toolkit';
import {
  FetchIPAWSRequestsAction,
  IPAWSInterface,
  IPAWSRequestsReceivedAction,
  FetchCOGDetailsAction,
  COGDetailsReceivedAction,
  ApproveRequestAction,
  ApproveRequestSuccess,
  DisapproveRequestAction,
  DisapproveRequestSuccess,
  CancelAlertRequestAction,
  CancelAlertRequestSuccess,
  MarkAlertAsReadRequestAction,
  MarkAlertAsReadRequestCompletedAction,
  FetchingIPAWSOptionsDatAction,
  IPAWSOptionsDataReceivedAction,
  UpdateCAPValuesAction,
  UpdateCOGDetailsAction
} from '../actions/Ipaws';

const initialState: IPAWSInterface = {
  IPAWSRequestsHistoryData: null,
  isFetchingIPAWSRequestsHistoryData: false,
  isApproveRequestSuccess: null,
  cogDetails: null,
  isDisapproveRequestSuccess: false,
  isCancelRequestSuccess: false,
  isFetchingCOGDetails: false,
  isRequestInProgress: false,
  isSubmittingIPAWS: false,
  isMarkAsReadInProgress: false,
  isFetchingIPAWSOptionsData: false,
  IPAWSOptionsData: null
};

const IPAWSSlice = createSlice({
  name: 'IPAWS',
  initialState,
  reducers: {
    getIPAWSRequests: (state, _action: FetchIPAWSRequestsAction) => {
      state.isFetchingIPAWSRequestsHistoryData = true;
    },
    IPAWSRequestsReceived: (state, action: IPAWSRequestsReceivedAction) => {
      state.IPAWSRequestsHistoryData = action.payload.IPAWSRequestsHistoryData;
      state.isFetchingIPAWSRequestsHistoryData = false;
    },
    getCOGDetails: (state, _action: FetchCOGDetailsAction) => {
      state.isFetchingCOGDetails = true;
    },
    cogDetailsReceived: (state, action: COGDetailsReceivedAction) => {
      state.cogDetails = action.payload.cogDetails;
      state.isFetchingCOGDetails = false;
    },
    updateCOGDetails: (_state, _action: UpdateCOGDetailsAction) => {},
    requestAlertApprove: (state, _action: ApproveRequestAction) => {
      state.isRequestInProgress = true;
    },
    alertApproveRequestSuccess: (state, action: ApproveRequestSuccess) => {
      state.isRequestInProgress = false;
      state.isApproveRequestSuccess = action.payload.isApproveRequestSuccess;
    },
    requestAlertDisapprove: (state, _action: DisapproveRequestAction) => {
      state.isRequestInProgress = true;
    },
    alertDisapproveRequestSuccess: (
      state,
      action: DisapproveRequestSuccess
    ) => {
      state.isRequestInProgress = false;
      state.isDisapproveRequestSuccess =
        action.payload.isDisapproveRequestSuccess;
    },
    requestCancelAlert: (state, _action: CancelAlertRequestAction) => {
      state.isRequestInProgress = true;
    },
    alertCancelRequestSuccess: (state, action: CancelAlertRequestSuccess) => {
      state.isRequestInProgress = false;
      state.isCancelRequestSuccess = action.payload.isCancelRequestSuccess;
    },
    submittingIPAWS: (state) => {
      state.isSubmittingIPAWS = true;
    },
    submittedIPAWS: (state) => {
      state.isSubmittingIPAWS = false;
    },
    markAlertAsRead: (state, _action: MarkAlertAsReadRequestAction) => {
      state.isMarkAsReadInProgress = true;
    },
    markAlertAsReadCompleted: (
      state,
      _action: MarkAlertAsReadRequestCompletedAction
    ) => {
      state.isMarkAsReadInProgress = false;
    },
    getIPAWSOptionsData: (state, _action: FetchingIPAWSOptionsDatAction) => {
      state.isFetchingIPAWSOptionsData = true;
    },
    IPAWSOptionsDataReceived: (
      state,
      action: IPAWSOptionsDataReceivedAction
    ) => {
      state.IPAWSOptionsData = action.payload.IPAWSOptionsData;
      state.isFetchingIPAWSOptionsData = false;
    },
    updateCAPValues: (_state, _action: UpdateCAPValuesAction) => {}
  }
});

export const {
  getIPAWSRequests,
  IPAWSRequestsReceived,
  getCOGDetails,
  cogDetailsReceived,
  updateCOGDetails,
  requestAlertApprove,
  requestAlertDisapprove,
  requestCancelAlert,
  alertCancelRequestSuccess,
  alertDisapproveRequestSuccess,
  alertApproveRequestSuccess,
  submittingIPAWS,
  submittedIPAWS,
  markAlertAsRead,
  markAlertAsReadCompleted,
  getIPAWSOptionsData,
  IPAWSOptionsDataReceived,
  updateCAPValues
} = IPAWSSlice.actions;
export default IPAWSSlice.reducer;
