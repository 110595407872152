import { InfoWindow } from '@react-google-maps/api';
import { PopupWindow } from '../../globalStyles';
import { Icon } from '../../../Universal/NovusDSImports';
import { STRINGS } from '../../../Constants/ConstantStrings';
import { useClickOutside } from '../../../Hooks/useClickOutside';
import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface CommonEventsTooltipProps {
  commonEvent: any;
  setSelectedCommonEvent: Function;
  setMapCenter: Function;
  setZoomLevel: Function;
  markerOpenRef: React.RefObject<any>;
}

const CommonEventsTooltip: React.FC<CommonEventsTooltipProps> = (props) => {
  const {
    commonEvent,
    markerOpenRef,
    setSelectedCommonEvent,
    setZoomLevel,
    setMapCenter
  } = props;

  const MarkerExcludedRef = useRef(null);

  const eventType =
    commonEvent.EventType === 'accidentsAndIncidents'
      ? 'Incidents'
      : commonEvent.EventType === 'roadwork'
      ? 'Active Roadwork'
      : commonEvent.EventType === 'closures'
      ? 'Special Events'
      : '';

  useClickOutside(
    markerOpenRef,
    () => {
      setSelectedCommonEvent(null);
    },
    [MarkerExcludedRef]
  );

  useEffect(() => {
    setZoomLevel(16);
    setMapCenter({
      lat: commonEvent.Latitude,
      lng: commonEvent.Longitude
    });
  }, [commonEvent, setSelectedCommonEvent]);

  return (
    <InfoWindow
      onCloseClick={() => setSelectedCommonEvent(null)}
      position={{
        lat: commonEvent.Latitude,
        lng: commonEvent.Longitude
      }}
    >
      <PopupWindow ref={markerOpenRef}>
        <div className="header">
          <h5>{eventType}</h5>
          <Icon
            icon="close"
            width={'16px'}
            height={'16px'}
            onClick={() => {
              if (commonEvent) setSelectedCommonEvent(null);
            }}
            className="close-icon"
          >
            {STRINGS.CLOSE}
          </Icon>
        </div>
        <div className="body">
          <h6>{commonEvent.Description}</h6>

          <div className="row latitude-longitude-section mx-0">
            <div className="col-sm-6 pe-1 ps-0">
              <label>Route</label>
              <p>{commonEvent.RoadwayName}</p>
            </div>

            <div className="col-sm-6 pe-0 ps-0">
              <label>Location</label>
              <p>{commonEvent.Location}</p>
            </div>

            <div className="col-sm-6 pe-0 ps-0">
              <label>Direction</label>
              <p>{commonEvent.DirectionOfTravel}</p>
            </div>

            {commonEvent?.MPStart && commonEvent?.MPEnd && (
              <div className="col-sm-6 pe-0 ps-0">
                <label>Milepost</label>
                <p>
                  {commonEvent.MPStart} - {commonEvent.MPEnd}
                </p>
              </div>
            )}

            {commonEvent?.LanesAffected && (
              <div className="col-sm-6 pe-0 ps-0">
                <label>Lanes Affected</label>
                <p>{commonEvent.LanesAffected}</p>
              </div>
            )}
          </div>

          <div className="group mt-3">
            <label>Start Time</label>
            <p>
              {dayjs(commonEvent.StartDate * 1000)
                .tz('America/New_York')
                .format('MMM D YYYY, h:mm A')}
            </p>
          </div>

          {commonEvent?.PlannedEndDate && (
            <div className="group mt-3">
              <label>Anticipated End Time</label>
              <p>
                {dayjs(commonEvent.PlannedEndDate * 1000)
                  .tz('America/New_York')
                  .format('MMM D YYYY, h:mm A')}
              </p>
            </div>
          )}

          {commonEvent?.Recurrence && (
            <div className="group mt-3">
              <label>Recurrence</label>
              <p>{commonEvent.Recurrence}</p>
            </div>
          )}

          <div className="group mt-3">
            <label>Last updated</label>
            <p>
              {dayjs(commonEvent.LastUpdated * 1000)
                .tz('America/New_York')
                .format('MMM D YYYY, h:mm A')}
            </p>
          </div>
        </div>
      </PopupWindow>
    </InfoWindow>
  );
};

export default CommonEventsTooltip;
