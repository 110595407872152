import React from 'react';
import Router from './Router';

const ApplicationContainer = () => {
  const MainComponent = React.memo(function MainComponent() {
    return <Router />;
  });

  return <MainComponent />;
};

export default ApplicationContainer;
