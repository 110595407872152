import { createSlice } from '@reduxjs/toolkit';
import {
  ClosuresInterface,
  FetchTicketStatusAction,
  TicketStatusReceivedAction,
  FetchEventNamesAction,
  EventNamesReceivedAction,
  FetchSavedPolygonsAction,
  SavedPolygonsReceivedAction,
  AddClosureAction,
  AddClosureSuccessAction,
  FetchClosuresDataAction,
  ClosuresDataReceivedAction,
  CloseEventRequestAction,
  CloseEventSuccessAction,
  SetNotificationsUpdatedClosuresDataAction,
  UpdateClosureNotificationCountAction,
  GetVehicleStatsAction,
  GetVehicleStatsRecievedAction,
  GetClosuresUnreadMessagesCountAction,
  ClosuresUnreadMessagesCountReceivedAction
} from '../actions/Closures';
import { getFlippedGeoJSONForGoogleMaps } from '../../CommonUtilities/CommonUtilities';

const initialState: ClosuresInterface = {
  eventNames: null,
  ticketStatuses: null,
  WEAStatuses: null,
  savedPolygons: null,
  isAddClosureSuccess: false,
  closuresData: null,
  closingEventSuccessful: false,
  isFetchingClosureData: false,
  isSubmitting: false,
  notificationsUpdatedClosureData: null,
  vehicleStats: null,
  isFetchingVehicleStats: false,
  closuresUnreadMessagesCount: null
};

export const ClosuresSlice = createSlice({
  name: 'Closures',
  initialState,
  reducers: {
    getTicketStatus: (_state, _action: FetchTicketStatusAction) => {},
    ticketStatusesReceived: (state, action: TicketStatusReceivedAction) => {
      state.ticketStatuses = action.payload.ticketStatuses;
    },
    getEventNames: (_state, _action: FetchEventNamesAction) => {},
    eventNamesReceived: (state, action: EventNamesReceivedAction) => {
      state.eventNames = action.payload.eventNames;
    },
    getSavedPolygons: (_state, _action: FetchSavedPolygonsAction) => {},
    savedPolygonsReceived: (state, action: SavedPolygonsReceivedAction) => {
      state.savedPolygons = action.payload.savedPolygons?.map((polygon) => ({
        ...polygon,
        geometry: {
          type: 'Polygon',
          coordinates: [
            polygon.geometry.coordinates[0].map((coords) => [
              coords[1],
              coords[0]
            ])
          ]
        }
      }));
    },
    getClosuresData: (state, _action: FetchClosuresDataAction) => {
      state.isFetchingClosureData = true;
    },
    closuresDataReceived: (state, action: ClosuresDataReceivedAction) => {
      state.isFetchingClosureData = false;
      // Flip the coords sytem for google maps
      if (action.payload?.closuresData?.results) {
        action.payload.closuresData.results =
          action.payload.closuresData?.results?.map((closure) => {
            return closure.geometry
              ? {
                  ...closure,
                  geometry: getFlippedGeoJSONForGoogleMaps(
                    closure.geometry?.coordinates?.[0],
                    'polygon'
                  )
                }
              : closure;
          });
      }
      if (
        action.payload.shouldAppend &&
        state.closuresData &&
        action.payload.closuresData
      ) {
        action.payload.closuresData.results = [
          ...state.closuresData.results,
          ...action.payload.closuresData.results
        ];
      }
      state.closuresData = action.payload.closuresData;
      state.isAddClosureSuccess = false;
    },
    addClosure: (_state, _action: AddClosureAction) => {},
    addClosureSuccess: (state, action: AddClosureSuccessAction) => {
      state.isAddClosureSuccess = action.payload.isAddClosureSuccess;
    },
    closeEventRequest: (_state, _action: CloseEventRequestAction) => {},
    closeEventSuccess: (state, action: CloseEventSuccessAction) => {
      state.closingEventSuccessful = action.payload.closingEventSuccessful;
    },
    submittingClosure: (state) => {
      state.isSubmitting = true;
    },
    submitted: (state) => {
      state.isSubmitting = false;
    },
    setNotificationsUpdatedClosureData: (
      state,
      action: SetNotificationsUpdatedClosuresDataAction
    ) => {
      state.notificationsUpdatedClosureData =
        action.payload.notificationsUpdatedClosureData;
    },
    getVehicleStats: (state, _action: GetVehicleStatsAction) => {
      state.isFetchingVehicleStats = true;
    },
    vehicleStatsReceived: (state, action: GetVehicleStatsRecievedAction) => {
      state.isFetchingVehicleStats = false;
      state.vehicleStats = action.payload.vehicleStats;
    },
    updateClosureNotificationCount: (
      state,
      action: UpdateClosureNotificationCountAction
    ) => {
      const data = state.notificationsUpdatedClosureData;
      data?.forEach((closure: any, index: number) => {
        if (closure.id === action.payload.closureId) {
          switch (action.payload.action) {
            case 'add_registered_user':
              data[index].registeredUsers += 1;
              break;
            case 'add_message':
              state.closuresUnreadMessagesCount[action.payload.closureId] += 1;
              break;
            case 'clear_registered_users':
              data[index].registeredUsers = 0;
              break;
            case 'clear_messages':
              data[index].messages = 0;
              break;
            default:
              break;
          }
        }
      });
      state.notificationsUpdatedClosureData = data;
    },
    getClosuresUnreadMessagesCount: (
      _state,
      _action: GetClosuresUnreadMessagesCountAction
    ) => {},
    closuresUnreadMessagesCountReceived: (
      state,
      action: ClosuresUnreadMessagesCountReceivedAction
    ) => {
      state.closuresUnreadMessagesCount =
        action.payload.closuresUnreadMessagesCount;
    }
  }
});

export const {
  getEventNames,
  getTicketStatus,
  eventNamesReceived,
  ticketStatusesReceived,
  savedPolygonsReceived,
  getSavedPolygons,
  addClosure,
  addClosureSuccess,
  getClosuresData,
  closuresDataReceived,
  closeEventRequest,
  closeEventSuccess,
  submittingClosure,
  setNotificationsUpdatedClosureData,
  updateClosureNotificationCount,
  submitted,
  getVehicleStats,
  vehicleStatsReceived,
  closuresUnreadMessagesCountReceived,
  getClosuresUnreadMessagesCount
} = ClosuresSlice.actions;
export default ClosuresSlice.reducer;
