import styled from 'styled-components';
import {
  colorState,
  device,
  spacing,
  typography
} from '../../Universal/Foundation';

export const MessageStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .messages-grid {
    border: 1px solid ${colorState.border.default.primary};
    border-radius: 0 0 12px 12px;
    box-shadow: 0px 1px 2px 0px rgba(23, 23, 28, 0.06),
      0px 1px 3px 0px rgba(23, 23, 28, 0.1);
    margin-bottom: 12px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    overflow: auto;

    .left {
      width: 360px;
      border-right: 1px solid ${colorState.border.default.primary};
      border-top: none;
      overflow: auto;

      @media ${device.laptopL} {
        width: 300px !important;
      }
    }

    .middle {
      flex: 1;
    }

    .right {
      width: 360px;
      border-left: 1px solid ${colorState.border.default.primary};

      @media ${device.laptop} {
        position: unset;
        z-index: unset;
      }

      @media ${device.laptopL} {
        width: 300px;
      }
    }
    @media ${device.mobileL} {
      flex-direction: column;

      .left,
      .right,
      .middle {
        width: 100% !important;
      }

      .middle {
        border-top: 1px solid ${colorState.border.default.primary};
      }

      .right {
        z-index: 10 !important;
        height: 100%;
        right: 0;
        top: 0;
        padding: 10px;
      }
    }
  }

  .select-all-wrapper {
    padding: 8px 12px;
    border-bottom: 1px solid ${colorState.border.default.primary};

    svg {
      margin-right: 0;
    }
  }

  .table-messages {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 12px;
    padding: 16px;
  }

  .selected-message {
    background-color: ${colorState.surface.brand.tertiary};
    border-radius: ${spacing.spacing_8}px;
  }

  .disabled {
    opacity: 70%;
    cursor: not-allowed !important;
  }

  .messages-wrapper {
    cursor: pointer;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .left-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      .unread-msg,
      .read-msg,
      .disabled-msg {
        color: ${colorState.text.primary};
        font-size: ${typography.size.ts};
        line-height: 20px;
        margin-bottom: 0;
      }

      .unread-msg {
        font-weight: ${typography.weight.semibold};
      }

      .read-msg {
        font-weight: ${typography.weight.medium};
      }

      p {
        color: ${colorState.text.secondary};
        font-size: ${typography.size.txs};
        font-weight: ${typography.weight.regular};
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1; /* Number of lines to show */
        text-overflow: ellipsis;
        word-break: break-word;
      }

      .disabled-msg {
        color: ${colorState.text.link};
        cursor: pointer;
        text-decoration: underline;
        padding-left: ${spacing.spacing_12}px;
      }

      &:focus-visible {
        outline: none;
        border: 1px solid ${colorState.border.brand.primary};
        border-radius: 8px;
      }
    }

    .right-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .date-time-wrapper {
        white-space: nowrap;
        padding-bottom: 4px;
        margin-bottom: 0;
        color: ${colorState.text.secondary};
        font-size: ${typography.size.txs};
        font-weight: ${typography.weight.regular};
      }
    }

    &:focus-visible {
      outline: none;
      border: 1px solid ${colorState.border.brand.primary};
      border-radius: 8px;
    }
  }
`;

export const ChatBoxStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: ${spacing.spacing_16}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${spacing.spacing_32}px;
  overflow: auto;

  .messages-wrapper {
    display: flex;

    .messages {
      display: flex;
      padding: ${spacing.spacing_16}px ${spacing.spacing_12}px;
      gap: ${spacing.spacing_16}px;
      padding: ${spacing.spacing_12}px;

      p {
        margin-bottom: 0;
        font-size: ${typography.size.ts};
        line-height: 20px;
        color: ${colorState.text.primary};
      }

      .read-by-user,
      .sent-user {
        font-size: ${typography.size.txs};
        line-height: 16px;
        color: ${colorState.text.secondary};
        font-weight: ${typography.weight.regular};
      }

      .unread-msg {
        font-weight: ${typography.weight.semibold};
      }

      .read-msg {
        font-weight: ${typography.weight.medium};
      }
      .read-msg,
      .read-by-user {
        opacity: 70%;
      }
    }

    .date-time-wrapper {
      margin-bottom: 0;
      color: ${colorState.text.secondary};
      font-size: ${typography.size.txs};
      font-weight: ${typography.weight.regular};
    }
  }

  .chat-incoming-messages-wrapper {
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;

    .incoming-messages {
      border-radius: 0px 8px 8px 8px;
      background-color: ${colorState.surface.default.tertiary};
      border: 1px solid ${colorState.border.default.secondary};
    }
  }

  .chat-outgoing-messages-wrapper {
    flex-direction: column;
    align-items: flex-end;
    word-break: break-word;

    .outgoing-messages {
      background-color: ${colorState.surface.brand.tertiary};
      border-radius: 8px 0px 8px 8px;

      .read-msg {
        font-weight: ${typography.weight.medium};
        color: ${colorState.text.secondary};
        opacity: unset;
      }

      .sent-user {
        color: ${colorState.text.secondary};
      }
    }
  }
`;

export const MessageInput = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.spacing_16}px;
  position: relative;

  textarea {
    padding-right: ${spacing.spacing_48}px;
  }

  .send-btn {
    position: absolute;
    right: ${spacing.spacing_28}px;
    top: ${spacing.spacing_24}px;
    width: 32px;
    height: 32px;
    border-radius: ${spacing.spacing_24}px;
    background-color: ${colorState.icon.brand.primary};
    padding: ${spacing.spacing_4}px ${spacing.spacing_2}px
      ${spacing.spacing_4}px ${spacing.spacing_4}px;
  }
`;
