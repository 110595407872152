import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import {
  getClientInfoFetch,
  getClientInfoSuccess
} from '../reducers/ClientInfo';

import { BASE_URL } from '../../settings';
import { api_prefix } from '../../Constants/constants';

function* fetchClientInfo() {
  try {
    const updatedBASE_URL = BASE_URL.replace('admin-', '');
    const getClientInfoUrl = `${updatedBASE_URL}${api_prefix}publicview/get_client_info/`;

    const clientInfo = yield axios
      .get(getClientInfoUrl)
      .then((response) => response.data);

    yield put(getClientInfoSuccess({ clientInfo: clientInfo?.data }));
  } catch (err) {
    console.error(err);
  }
}

export default function* watchClientInfo() {
  yield takeLatest(getClientInfoFetch, fetchClientInfo);
}
