import { MutableRefObject, useCallback, useEffect } from 'react';

export function useClickOutside(
  ref: any,
  callbackfunction: any,
  excludedElements: Array<MutableRefObject<any>>
) {
  const handleClickOutside = useCallback(
    (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        let isExcluded = false;
        for (const excludedElement of excludedElements) {
          if (excludedElement.current?.contains(event.target)) {
            isExcluded = true;
            break;
          }
        }
        if (!isExcluded) {
          callbackfunction();
        }
      }
    },
    [callbackfunction, excludedElements, ref]
  );

  useEffect(() => {
    const handleDocumentClick = (event: any) => handleClickOutside(event);
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [ref, callbackfunction, excludedElements, handleClickOutside]);
}
