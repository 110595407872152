import { BrowserRouter, Redirect, Switch, useHistory } from 'react-router-dom';
import AuthorisedRoute from './AuthorisedRoute';
import TopNav from '../SharedComponets/TopNavigation';
import { useEffect, useState } from 'react';
import { AppContainer, MainContainer } from '../Components/globalStyles';
import { NavbarMenuItem } from '../CommonTypes';
import { navbarMenuItems } from '../Constants/constants';
import FooterComponent from '../SharedComponets/Footer';
import { isPublicView } from '../settings';
import { useReduxDispatch } from '../Store/reduxHooks';
import { setSelectedClosure, setSelectedTab } from '../Store/reducers/Common';

export function Router() {
  const [activeItem, setActiveItem] = useState<NavbarMenuItem | null>(null);
  const dispatch = useReduxDispatch();

  useEffect(() => {
    if (!activeItem) {
      setActiveItem(
        navbarMenuItems.find((item) =>
          window.location.pathname.includes(`${item.link}`)
        ) || null
      );
    }
  }, [activeItem]);

  const [showOnMobile, setShowOnMobile] = useState(false);
  const history = useHistory();

  const handleItemClick = (item: NavbarMenuItem) => {
    dispatch(setSelectedTab({ selectedTab: '' }));
    dispatch(setSelectedClosure({ selectedClosure: null }));
    localStorage.setItem('isWebSocketConnected', 'false');
    setActiveItem(item);
    history.push(item.link);
    setShowOnMobile(false);
  };

  const handleMenuToggle = () => {
    setShowOnMobile(!showOnMobile);
  };

  const handleMenuClose = () => {
    setShowOnMobile(false);
  };

  return !isPublicView ? (
    <AppContainer>
      <BrowserRouter>
        {!window.location.pathname.includes('/AdminGuide') &&
          localStorage.getItem('isLoggedIn') && (
            <TopNav
              items={navbarMenuItems.filter((item) => !item.isHidden)}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              showOnMobile={showOnMobile}
              handleMenuToggle={handleMenuToggle}
              handleMenuClose={handleMenuClose}
              handleItemClick={handleItemClick}
            />
          )}
        <div
          className="d-flex overflow-auto flex-column flex-grow-1"
          id="layout-container"
        >
          <MainContainer id="main-container">
            <Switch>
              {navbarMenuItems.map((item, index) => (
                <AuthorisedRoute
                  path={item.link}
                  page={item.page}
                  key={index}
                />
              ))}
              <AuthorisedRoute path={'/AdminGuide'} page={'AdminGuide/index'} />
              <Redirect to="/ClosureEvent" />
            </Switch>
          </MainContainer>
          <FooterComponent />
        </div>
      </BrowserRouter>
    </AppContainer>
  ) : (
    <BrowserRouter>
      <Switch>
        <AuthorisedRoute
          path={'/Registration'}
          page={'PublicView/ClosureRegistration'}
        />
        <AuthorisedRoute
          path={'/MessageHistory'}
          page={'PublicView/MessageHistory'}
        />
        <AuthorisedRoute
          path={'/ManageLocation'}
          page={'PublicView/ManageLocation'}
        />
        <AuthorisedRoute path={'/'} page={'PublicView'} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
