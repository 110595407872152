import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { requestQuestionsclosureData } from '../../../Store/reducers/ClosureQuestions';
import {
  getRegisteredUsers,
  chatHistoryReceived,
  getChatHistory,
  requestAllVehicleLocation,
  isComposeMessageSentSuccessfullyReceived
} from '../../../Store/reducers/RegisteredUsers';
import { useReduxDispatch, useReduxSelector } from '../../../Store/reduxHooks';
import { Badge, Button, Icon } from '../../../Universal/NovusDSImports';
import TableTitle from '../../../SharedComponets/TableTitle';
import { TabItems, TableWrapper } from '../../globalStyles';
import { btnStyles } from '../../../Universal/NovusDSImports/variants';
import { colorState } from '../../../Universal/Foundation';
import { STRINGS } from '../../../Constants/ConstantStrings';
import { unSelectSelectedRows } from '../../../Store/reducers/Common';
// import ContactHistory from './ContactHistory';
import {
  genarateColorFromString,
  getContrast,
  getPermission,
  removeEventManagedByLabel,
  showError,
  showPopUp
} from '../../../CommonUtilities/CommonUtilities';
import { RootState } from '../../../store';
// import NoDataFound from '../../../SharedComponets/NoDataFound';
import { NoManageUsersIcon } from '../../../Universal/Assets';
import dayjs from 'dayjs';
import usePrevious from '../../../Hooks/UsePrevious';
// import PALoader from '../../../SharedComponets/PALoader';
import axios from 'axios';
import { api_prefix } from '../../../Constants/constants';
import FileDownload from 'js-file-download';
// import ConfirmationPopupModal from '../../../SharedComponets/ConfirmationPopupModal';
import Slider from 'react-slick';
import { TableSliderStyles } from './styles';
import { TablePopupStyles } from '../../GenericTableComponent/styles';
import { useClickOutside } from '../../../Hooks/useClickOutside';
import { getVehicleStats } from '../../../Store/reducers/Closures';
import PALoader from '../../../SharedComponets/PALoader';

const GenericTableRT7 = lazy(
  () => import('../../GenericTableComponent/GenericTable')
);
const ContactHistory = lazy(() => import('./ContactHistory'));
const ConfirmationPopupModal = lazy(
  () => import('../../../SharedComponets/ConfirmationPopupModal')
);
const NoDataFound = lazy(() => import('../../../SharedComponets/NoDataFound'));

const RegisteredUsers = (props: any) => {
  const {
    setMapCenter,
    setIsMessageBoxOpen,
    setSelectedRows,
    selectedRows,
    setselectedRegisteredUser,
    isSideNav,
    selectedRegisteredUser,
    setIsCollapsableMap,
    isMessageBoxOpen,
    activeTabName,
    setActiveTabName
  } = props;

  const selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    registeredUsersData = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.registeredUsers
    ),
    isComposeMessageSent = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.isComposeMessageSent
    ),
    questionsclosuresData = useReduxSelector(
      (state: RootState) => state.Questions.closuresQuestionsData
    ),
    isShowNewlyAddedQuestionsToUsers = useReduxSelector(
      (state: RootState) => state.Questions.isShowNewlyAddedQuestionsToUsers
    ),
    chatHistoryData = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.chatHistory
    ),
    updatedRegisteredUsersCount = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.updatedRegisteredUsersCount
    ),
    isFetchingRegisteredUsers = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.isFetchingRegisteredUsers
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    isRequestingLocationInProgress = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.isRequestingLocation
    ),
    isFetchingVehicleStats = useReduxSelector(
      (state: RootState) => state.Closures.isFetchingVehicleStats
    ),
    vehicleStats = useReduxSelector(
      (state: RootState) => state.Closures.vehicleStats
    ),
    [selectedUser, setSelectedUser] = useState<any>(null),
    [tableData, setTableData] = useState<null | Array<any>>(null),
    [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false),
    [registerUsersTabs, setRegisterUsersTabs] = useState<any>(false),
    [topVal, setTopVal] = useState<number>(),
    [viewWidth, setViewWidth] = useState<number>(),
    [viewHeight, setViewHeight] = useState<number>(),
    [isLoadingInitialData, setIsLoadingInitialData] = useState<boolean>(true),
    [openStateId, setOpenStateId] = useState<string>(''),
    [isConfirmationModalOpen, setIsConfirmationModalOpen] =
      useState<boolean>(false),
    previousIsFetchingRegisteredUsers = usePrevious(isFetchingRegisteredUsers),
    dispatch = useReduxDispatch();

  const [isFetchingSitrep, setIsFetchingSitrep] = useState<boolean>(false);

  useEffect(() => {
    if (
      previousIsFetchingRegisteredUsers &&
      !isFetchingRegisteredUsers &&
      isLoadingInitialData
    ) {
      setIsLoadingInitialData(false);
    }
  }, [
    isFetchingRegisteredUsers,
    isLoadingInitialData,
    previousIsFetchingRegisteredUsers
  ]);

  useEffect(() => {
    if (selectedClosure) {
      dispatch(
        getRegisteredUsers({ closureId: selectedClosure?.id, limit: 'all' })
      );
      if (getPermission(currentUser, 'view_closurequestions')) {
        dispatch(
          requestQuestionsclosureData({
            closureId: selectedClosure?.id,
            limit: 'all'
          })
        );
      }
      dispatch(getVehicleStats({ closureId: selectedClosure?.id }));
    }
  }, [dispatch, selectedClosure, currentUser]);

  useEffect(() => {
    if (isComposeMessageSent) {
      setIsMessageBoxOpen(false);
      dispatch(unSelectSelectedRows({ shouldUnselect: true }));
      dispatch(isComposeMessageSentSuccessfullyReceived({ isSent: false }));
    }
  }, [dispatch, isComposeMessageSent, setIsMessageBoxOpen]);

  useEffect(() => {
    setSelectedRows(null);
    setActiveTabName(null);
  }, [selectedClosure]);

  useEffect(() => {
    if (registeredUsersData) {
      const data: any = [];
      registeredUsersData.forEach((user: any) => {
        const userTempData = { ...user };
        questionsclosuresData?.forEach((question: any) => {
          userTempData[question.id] = user.answers && user.answers[question.id];
        });
        data.push(userTempData);
      });
      setTableData(data);
    }
  }, [registeredUsersData, questionsclosuresData]);

  const ContactHistoryPopup = () => {
    setIsTooltipOpen(false);
    const contactHistory = document.getElementById(
      `contact-history-${openStateId}`
    );
    const tdElement = contactHistory?.closest('td');
    if (tdElement) {
      tdElement.classList.remove('selected-td');
    }
  };

  useEffect(() => {
    const selectedPhoneNumberList = document.querySelectorAll(
      '#user-mobile-number'
    );
    for (let i = 0; i < selectedPhoneNumberList.length; i++) {
      const tdElement = selectedPhoneNumberList[i]?.closest('td');
      //only the selected phone number will get that classname added
      if (
        selectedPhoneNumberList[i].innerHTML ===
        selectedRegisteredUser?.phone_number
      ) {
        if (tdElement) {
          tdElement.classList.add('selected-td');
        }
      }
      //except selected number, for all remaining numbers will remove classname
      else {
        tdElement?.classList.remove('selected-td');
      }
    }
  }, [selectedRegisteredUser]);

  const handleClick = (user: any) => {
    if (user.is_active) {
      if (user?.location && user.location.coordinates?.length) {
        setMapCenter({
          lat: user.location.coordinates[0],
          lng: user.location.coordinates[1]
        });
        setselectedRegisteredUser(user);
        setIsCollapsableMap(true);
      } else {
        showPopUp('Selected User has not provided location', 'info');
        setMapCenter({
          lat: selectedClosure?.centre_latitude,
          lng: selectedClosure?.centre_longitude
        });
        setselectedRegisteredUser(null);
      }
    } else {
      setselectedRegisteredUser(null);
      showPopUp(STRINGS.SELECTED_USER_GOT_UNREGISTERED, 'info');
    }
  };

  const selectedRegisteredUserRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedRegisteredUserRef.current) {
      selectedRegisteredUserRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [selectedRegisteredUser]);

  const setColumns = () => {
    const columns: any = [
      {
        Header: 'Phone#',
        accessor: 'phone_number',
        Cell: ({ row }) => (
          <div
            className={'link'}
            id="user-mobile-number"
            onClick={() => {
              handleClick(row.original);
            }}
            ref={
              selectedRegisteredUser?.phone_number === row.original.phone_number
                ? selectedRegisteredUserRef
                : null
            }
          >
            {row.original.phone_number}
          </div>
        )
      },
      {
        Header: 'Inside Zone',
        accessor: 'inside_zone',
        sortType: (rowA: any, rowB: any, columnId: any, desc: any) => {
          const insideZoneA = rowA.values[columnId];
          const insideZoneB = rowB.values[columnId];

          const valueA = insideZoneA === null ? 2 : insideZoneA ? 1 : 0;
          const valueB = insideZoneB === null ? 2 : insideZoneB ? 1 : 0;

          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        },
        Cell: (data: any) => {
          return (
            <div>
              {data.row.original.inside_zone !== null
                ? data.row.original.inside_zone
                  ? STRINGS.YES
                  : STRINGS.NO
                : STRINGS.LOCATION_NOT_SHARED}
            </div>
          );
        }
      },
      {
        Header: 'Contacted By',
        accessor: 'contact_by'
      }
    ];
    if (questionsclosuresData?.length)
      questionsclosuresData.forEach((question: any, index: number) => {
        columns.push({
          Header: question.abbr_name,
          id: index,
          accessor: (data: any) => data[question.id]
        });
      });
    columns.push({
      Header: 'Last Contacted',
      accessor: 'last_contacted_time',
      Cell: (data: any) => {
        return (
          <div>
            {data.row.original.last_contacted_time
              ? dayjs(data.row.original.last_contacted_time).format(
                  'MM/DD/YYYY  hh:mm A'
                )
              : 'N/A'}
          </div>
        );
      }
    });

    window.addEventListener('resize', () => {
      ContactHistoryPopup();
      setRegisterUsersTabs(false);
    });

    const Table = document.getElementById('register-user-table');
    if (Table) {
      Table.addEventListener('wheel', () => {
        // 'wheel' this can be used not only for scroll event also it works for zooming.
        ContactHistoryPopup();
        setRegisterUsersTabs(false);
      });
    }

    const ContactHistoryCoordinates = (id: any) => {
      const contactHistory = document
        .getElementById(`contact-history-${id}`)
        ?.closest('td');
      setOpenStateId(id);
      const top: any =
        contactHistory && contactHistory.getBoundingClientRect().top;
      setTopVal(top);
      const width: any =
        contactHistory && contactHistory.getBoundingClientRect().width;
      setViewWidth(width);
      const height: any =
        contactHistory && contactHistory.getBoundingClientRect().height;
      setViewHeight(height);

      const tdElement = contactHistory?.closest('td');
      if (tdElement) {
        tdElement.classList.add('selected-td');
      }
      setIsTooltipOpen(true);
    };

    columns.push({
      Header: 'Contact History',
      Cell: (data: any) => {
        return (
          <div id={`contact-history-${data.row.original.id}`}>
            {data.row.original.last_contacted_time ? (
              <>
                <div
                  aria-label="View-contact-history"
                  tabIndex={0}
                  className={'view-details-section link'}
                  onClick={() => {
                    if (data.row.original.id !== selectedUser?.id) {
                      ContactHistoryPopup();
                    }
                    if (
                      data.row.original.last_contacted_time &&
                      getPermission(currentUser, 'view_chatmessage')
                    ) {
                      ContactHistoryCoordinates(data.row.original.id);
                      setSelectedUser(data.row.original);
                      dispatch(
                        getChatHistory({ userId: data.row.original.id })
                      );
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      if (
                        data.row.original.last_contacted_time &&
                        getPermission(currentUser, 'view_chatmessage')
                      ) {
                        ContactHistoryCoordinates(data.row.original.id);
                        setSelectedUser(data.row.original);
                        dispatch(
                          getChatHistory({ userId: data.row.original.id })
                        );
                      }
                    }
                  }}
                >
                  {STRINGS.VIEW}
                </div>
              </>
            ) : (
              STRINGS.NO_VALUE
            )}

            {isTooltipOpen && data.row.original.id === selectedUser.id && (
              <ContactHistory
                selectedUser={selectedUser}
                chatHistory={chatHistoryData}
                setSelectedUser={setSelectedUser}
                setIsTooltipOpen={setIsTooltipOpen}
                topVal={topVal}
                onPopupClose={onPopupClose}
                viewWidth={viewWidth}
                viewHeight={viewHeight}
                ContactHistoryPopup={ContactHistoryPopup}
              />
            )}
          </div>
        );
      }
    });
    return columns;
  };

  const onPopupClose = () => {
    setIsTooltipOpen(false);
    dispatch(chatHistoryReceived({ chatHistory: null }));
    setSelectedUser(null);
  };

  const handleFilter = (filter: string, abbr_name: string, usersData: any) => {
    setActiveTabName(filter);
    setselectedRegisteredUser(null);
    setSelectedRows(null);
    const data: any = {};
    let questionId: number;
    questionsclosuresData?.forEach((question) => {
      if (question.abbr_name === abbr_name) questionId = question.id;
    });
    usersData?.forEach((user: any, index: number) => {
      if (user.is_active) {
        if (filter !== 'not_yet_contacted') {
          if (
            questionId &&
            user.answers &&
            user.answers[questionId] === filter
          ) {
            data[index] = true;
          }
        } else {
          if (!user.last_contacted_time) {
            data[index] = true;
          }
        }
      }
    });
    setSelectedRows(data);
  };

  const getFilteredRegisteredUsersData = (
    filter: string,
    abbr_name?: string
  ) => {
    dispatch(
      getRegisteredUsers({
        closureId: selectedClosure?.id,
        limit: 'all',
        custom_ordering: filter,
        abbr_name: abbr_name || '',
        onCompleteAction: handleFilter
      })
    );
  };

  const getUpdatedUsersData = () => {
    setSelectedRows({});
    setActiveTabName(null);
    dispatch(
      getRegisteredUsers({ closureId: selectedClosure?.id, limit: 'all' })
    );
  };

  const customConfirmationStyles = {
    content: {
      minWidth: '15%',
      minHeight: '10vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const handleAllVehicleLocationRequestClick = () => {
    setIsConfirmationModalOpen(true);
  };

  const onSendClick = () => {
    dispatch(
      requestAllVehicleLocation({
        closureId: selectedClosure?.id,
        postModalClose: postModalClose
      })
    );
  };

  const postModalClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const getActiveUsersCount = useCallback(() => {
    let count = 0;
    if (registeredUsersData) {
      registeredUsersData.forEach((user) => {
        if (user.is_active) count += 1;
      });
    }
    return count;
  }, [registeredUsersData]);

  const handleSelectAllClick = () => {
    if (
      selectedRows &&
      Object.keys(selectedRows).length &&
      Object.keys(selectedRows).length === getActiveUsersCount()
    ) {
      setSelectedRows({});
    } else {
      const data = {};
      registeredUsersData?.forEach((user: any, index: number) => {
        if (user.is_active) data[index] = true;
      });
      setSelectedRows(data);
    }
    if (activeTabName) {
      setActiveTabName(null);
    }
  };

  const handleSelectCheckBoxClick = (index: number) => {
    const data = { ...selectedRows };
    if (selectedRows && selectedRows[index]) {
      delete data[index];
    } else {
      data[index] = true;
    }
    setActiveTabName(null);
    setSelectedRows(data);
  };

  const handleClear = () => {
    if ((selectedRows && Object.keys(selectedRows)?.length) || activeTabName) {
      setIsMessageBoxOpen(false);
      setSelectedRows(null);
      setActiveTabName(null);
      setselectedRegisteredUser(null);
      getUpdatedUsersData();
    }
  };

  const renderNewlyAddedFilter = () => {
    return (
      <TabItems
        tabIndex={0}
        id={`tab-${STRINGS.NEWLY_ADDED_ONLY}`}
        onClick={() => getFilteredRegisteredUsersData('not_yet_contacted')}
        selectedtab={activeTabName === STRINGS.NEWLY_ADDED_ONLY}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            getFilteredRegisteredUsersData('not_yet_contacted');
          }
        }}
        aria-label={STRINGS.NEWLY_ADDED_ONLY}
        title={STRINGS.NEWLY_ADDED_ONLY}
      >
        {STRINGS.NEWLY_ADDED_ONLY}
        <Badge
          style={{
            color: getContrast(genarateColorFromString('not_yet_contacted')),
            background: genarateColorFromString('not_yet_contacted')
          }}
        >
          {vehicleStats?.['not_yet_contacted']}
        </Badge>
      </TabItems>
    );
  };

  const renderConfirmationFooter = () => {
    return (
      <div className="gap-3 d-flex">
        <Button onClick={postModalClose} {...btnStyles.default}>
          {STRINGS.CANCEL}
        </Button>
        <Button
          onClick={onSendClick}
          {...btnStyles.danger}
          disabled={isRequestingLocationInProgress}
        >
          {STRINGS.SEND}
        </Button>
      </div>
    );
  };

  const NoRegisteredUsersData = () => {
    return (
      <NoDataFound
        src={NoManageUsersIcon}
        description={STRINGS.NO_REGISTERED_USERS}
        alt={'no-registered-users-img'}
        isAbsolute
        subMsgDescription={
          STRINGS.YOUR_LIST_OF_USERS_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_USERS_YET
        }
        imgWidth={60}
        imgHeight={60}
      />
    );
  };

  const PrevArrow = (props: any) => {
    const { className, style, onClick, currentSlide } = props;
    return (
      <div
        role="button"
        className={`${className} ${currentSlide === 0 ? 'disabled' : ''}`}
        style={{ ...style }}
        onClick={currentSlide !== 0 ? onClick : undefined}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onClick();
          }
        }}
        aria-label={'previous arrow'}
      >
        <Icon icon="chevron_left" />
      </div>
    );
  };

  const NextArrow = ({ className, style, onClick }: any) => {
    return (
      <div
        role="button"
        className={`${className}`}
        style={{ ...style }}
        onClick={onClick}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onClick();
          }
        }}
        aria-label={'next arrow'}
      >
        <Icon icon="chevron_right" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: isSideNav ? 5 : 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: isSideNav ? 4 : 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: isSideNav ? 4 : 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: isSideNav ? 3 : 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const openRegisterUsersTabs = () => {
    setRegisterUsersTabs(!registerUsersTabs);
  };

  const customStyles: any = {
    zIndex: 5,
    top: 30,
    width: 300,
    right: 0,
    position: 'absolute'
  };

  const openStateElement = useRef(null);
  const excludedElement: any = useRef([]);
  useClickOutside(openStateElement, openRegisterUsersTabs, [excludedElement]);

  useEffect(() => {
    const slick = document.querySelector('.slick-track');
    const slickSlide = document.querySelectorAll('.slick-slide');
    if (slick) {
      slick.setAttribute('role', 'tablist');
    }
    slickSlide.forEach((slide) => {
      slide.setAttribute('role', 'tab');
    });
  }, [registerUsersTabs]);

  return (
    <TableWrapper id="table-group" className="register-users-table">
      <TableTitle
        title={STRINGS.REGISTERED_USERS}
        message={
          selectedClosure &&
          removeEventManagedByLabel(selectedClosure?.wea_short_msg_english)
        }
      >
        {getPermission(currentUser, 'outbound_message_frontend') && (
          <Button
            title="Compose Message"
            onClick={() => {
              ContactHistoryPopup();
              setIsMessageBoxOpen(true);
            }}
            disabled={!(selectedRows && Object.keys(selectedRows).length)}
            {...btnStyles.primary}
          >
            <Icon
              icon={'message'}
              stroke={colorState.icon.default['primary-inverse']}
              width={'16px'}
              height={'16px'}
            />
            {STRINGS.COMPOSE_MESSAGE}
          </Button>
        )}
      </TableTitle>
      {isLoadingInitialData ||
      isFetchingRegisteredUsers ||
      isFetchingVehicleStats ? (
        <PALoader />
      ) : (
        <TableSliderStyles
          isSideNav={isSideNav}
          registerUsersTabs={registerUsersTabs}
        >
          {vehicleStats && Object.keys(vehicleStats)?.length > 1 ? (
            <div className="left-wrapper">
              <div
                className="slider-container"
                tabIndex={0}
                aria-label={'tablist'}
              >
                <Slider {...settings} tabIndex={0}>
                  {vehicleStats &&
                    Object.keys(vehicleStats)?.map((abbr_name: any) => {
                      return Object.keys(vehicleStats[abbr_name])?.map(
                        (filter: any, index: number) => {
                          return (
                            <TabItems
                              id={`tab-${filter}`}
                              tabIndex={0}
                              key={index}
                              onClick={() =>
                                getFilteredRegisteredUsersData(
                                  filter,
                                  abbr_name
                                )
                              }
                              selectedtab={activeTabName === filter}
                              aria-label={filter}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  getFilteredRegisteredUsersData(
                                    filter,
                                    abbr_name
                                  );
                                }
                              }}
                              title={`${
                                filter === 'not_yet_contacted'
                                  ? 'Newly Added (Not Yet Contacted)'
                                  : filter
                              } ${STRINGS.ONLY}`}
                              className="justify-content-between"
                            >
                              <span className="text-truncate">
                                {filter === 'not_yet_contacted'
                                  ? 'Newly Added (Not Yet Contacted)'
                                  : filter}{' '}
                                {STRINGS.ONLY}
                              </span>
                              <Badge
                                style={{
                                  color: getContrast(
                                    genarateColorFromString(filter)
                                  ),
                                  background: genarateColorFromString(filter)
                                }}
                              >
                                {vehicleStats[abbr_name][filter]}
                              </Badge>
                            </TabItems>
                          );
                        }
                      );
                    })}
                </Slider>
              </div>
            </div>
          ) : (
            renderNewlyAddedFilter()
          )}

          <div className="right-wrapper d-flex gap-2">
            <div className="position-relative">
              <div
                className={`${
                  isMessageBoxOpen
                    ? 'compose-more-options'
                    : 'more-options-tabs d-none'
                } `}
              >
                <div
                  className="d-flex align-items-center gap-3"
                  tabIndex={0}
                  id="register-users-tabs"
                >
                  {updatedRegisteredUsersCount >
                    registeredUsersData?.length && (
                    <TabItems
                      tabIndex={0}
                      onClick={getUpdatedUsersData}
                      className="participant-variant-tab text-nowrap"
                      selectedtab={activeTabName === getUpdatedUsersData}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          getUpdatedUsersData();
                        }
                      }}
                      defaultTextColor
                      defaultRadius
                    >
                      {<Icon icon={'user_01'} />}
                      <Badge variant="info">
                        {updatedRegisteredUsersCount -
                          (registeredUsersData &&
                            registeredUsersData.length)}{' '}
                      </Badge>
                    </TabItems>
                  )}
                  <span
                    className="option-icon"
                    ref={excludedElement}
                    onClick={openRegisterUsersTabs}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        openRegisterUsersTabs();
                      }
                    }}
                  >
                    <Icon icon={'dots_vertical'} width="20px" height="20px" />
                  </span>
                </div>
                {registerUsersTabs && (
                  <TablePopupStyles style={customStyles} ref={openStateElement}>
                    <ul tabIndex={0}>
                      {registeredUsersData?.length &&
                      getPermission(currentUser, 'users_location_request') ? (
                        <li
                          tabIndex={0}
                          id={`tab-${STRINGS.REQUEST_ALL_VEHICLE_LOCATIONS}`}
                          onClick={handleAllVehicleLocationRequestClick}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              handleAllVehicleLocationRequestClick();
                            }
                          }}
                          aria-label={STRINGS.REQUEST_ALL_VEHICLE_LOCATIONS}
                          title={STRINGS.REQUEST_ALL_VEHICLE_LOCATIONS}
                        >
                          <Icon
                            icon="refresh_ccw_02"
                            stroke={colorState.icon.default.tertiary}
                            width={16}
                            height={16}
                            className="me-2"
                          />
                          {STRINGS.REQUEST_ALL_VEHICLE_LOCATIONS}
                        </li>
                      ) : null}
                      <li
                        role="tab"
                        tabIndex={0}
                        className={`${isFetchingSitrep ? 'disabled' : ''}`}
                        onClick={async () => {
                          if (!isFetchingSitrep) {
                            setIsFetchingSitrep(true);
                            await axios
                              .get(
                                `${api_prefix}closures/${selectedClosure?.id}/get_situation_report/`,
                                { responseType: 'blob' }
                              )
                              .then((response) => {
                                FileDownload(
                                  response.data,
                                  `SituationReport_${
                                    selectedClosure?.id
                                  }_${dayjs().format('YYYYMMDDHHmmss')}.pdf`
                                );
                                setIsFetchingSitrep(false);
                              })
                              .catch((err) => {
                                showError(err);
                                setIsFetchingSitrep(false);
                              });
                          }
                        }}
                      >
                        <Icon
                          icon="file_06"
                          stroke={
                            isFetchingSitrep
                              ? colorState.icon.default.disabled
                              : colorState.icon.default.tertiary
                          }
                          width={16}
                          height={16}
                          className="me-2"
                        />
                        {STRINGS.SITREP}
                      </li>
                      <li
                        role="tab"
                        tabIndex={0}
                        onClick={handleClear}
                        className={`
                          ${
                            !(
                              selectedRows && Object.keys(selectedRows).length
                            ) &&
                            !activeTabName &&
                            'disabled'
                          }
                          table-clear-tab
                        `}
                        // className="table-clear-tab"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleClear();
                          }
                        }}
                        aria-label="clear-tab"
                      >
                        <Icon
                          icon={'eraser'}
                          className="eraser-icon me-2"
                          stroke={colorState.icon.default.secondary}
                          width={16}
                          height={16}
                          alt="eraser-icon"
                        />
                        {STRINGS.CLEAR}
                      </li>
                    </ul>
                  </TablePopupStyles>
                )}
              </div>
              <div
                className={`${
                  !isMessageBoxOpen && 'right-wrapper-tabs'
                } d-none`}
              >
                {!!registeredUsersData?.length &&
                  getPermission(currentUser, 'users_location_request') && (
                    <TabItems
                      tabIndex={0}
                      id={`tab-${STRINGS.REQUEST_ALL_VEHICLE_LOCATIONS}`}
                      onClick={handleAllVehicleLocationRequestClick}
                      selectedtab={isConfirmationModalOpen}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleAllVehicleLocationRequestClick();
                        }
                      }}
                      aria-label={STRINGS.REQUEST_ALL_VEHICLE_LOCATIONS}
                      title={STRINGS.REQUEST_ALL_VEHICLE_LOCATIONS}
                      defaultTextColor
                      defaultRadius
                    >
                      <Icon
                        icon="refresh_ccw_02"
                        stroke={colorState.icon.default.tertiary}
                        width={16}
                        height={16}
                      />
                      {STRINGS.REQUEST_ALL_VEHICLE_LOCATIONS}
                    </TabItems>
                  )}
                {getPermission(currentUser, 'download_situation_report') && (
                  <TabItems
                    tabIndex={0}
                    onClick={async () => {
                      if (!isFetchingSitrep) {
                        setIsFetchingSitrep(true);
                        await axios
                          .get(
                            `${api_prefix}closures/${selectedClosure?.id}/get_situation_report/`,
                            { responseType: 'blob' }
                          )
                          .then((response) => {
                            FileDownload(
                              response.data,
                              `SituationReport_${
                                selectedClosure?.id
                              }_${dayjs().format('YYYYMMDDHHmmss')}.pdf`
                            );
                            setIsFetchingSitrep(false);
                          })
                          .catch((err) => {
                            showError(err);
                            setIsFetchingSitrep(false);
                          });
                      }
                    }}
                    title="SITREP"
                    disabled={isFetchingSitrep}
                    defaultTextColor
                    defaultRadius
                  >
                    <Icon
                      icon="file_06"
                      stroke={
                        isFetchingSitrep
                          ? colorState.icon.default.disabled
                          : colorState.icon.default.tertiary
                      }
                      width={16}
                      height={16}
                    />
                    SITREP
                  </TabItems>
                )}
                {updatedRegisteredUsersCount > registeredUsersData?.length && (
                  <TabItems
                    tabIndex={0}
                    onClick={getUpdatedUsersData}
                    className="participant-variant-tab text-nowrap"
                    selectedtab={activeTabName === getUpdatedUsersData}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        getUpdatedUsersData();
                      }
                    }}
                    defaultTextColor
                    defaultRadius
                  >
                    {<Icon icon={'user_01'} />}
                    <Badge variant="info">
                      {updatedRegisteredUsersCount -
                        (registeredUsersData &&
                          registeredUsersData.length)}{' '}
                    </Badge>
                  </TabItems>
                )}
                <TabItems
                  tabIndex={0}
                  onClick={handleClear}
                  className={`
                          ${
                            !(
                              selectedRows && Object.keys(selectedRows).length
                            ) &&
                            !activeTabName &&
                            'disabled'
                          }
                          table-clear-tab
                        `}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleClear();
                    }
                  }}
                  disabled={
                    !(selectedRows && Object.keys(selectedRows).length) &&
                    !activeTabName
                  }
                  aria-label="clear-tab"
                  defaultTextColor
                  defaultRadius
                >
                  <Icon
                    icon={'eraser'}
                    className="eraser-icon"
                    stroke={colorState.icon.default.secondary}
                    width={16}
                    height={16}
                    alt="eraser-icon"
                  />
                  {STRINGS.CLEAR}
                </TabItems>
              </div>
            </div>
          </div>
        </TableSliderStyles>
      )}
      <div
        className="w-100 d-flex overflow-auto flex-grow-1 flex-column"
        id={'register-user-table'}
      >
        {!(
          isLoadingInitialData ||
          isFetchingRegisteredUsers ||
          isFetchingVehicleStats
        ) ? (
          tableData?.length ? (
            <Suspense fallback={<PALoader />}>
              <GenericTableRT7
                columns={setColumns()}
                tableData={tableData}
                setSelectedRows={setSelectedRows}
                showCheckBoxColumn={true}
                checkBoxColumnText={'S. No'}
                defaultAllColumnsSelected={isShowNewlyAddedQuestionsToUsers}
                selectedRows={selectedRows}
                noDataFoundScreen={
                  !tableData?.length ? NoRegisteredUsersData() : <></>
                }
                customSelectAllCheckBoxLogic={handleSelectAllClick}
                customSingleCheckBoxLogic={handleSelectCheckBoxClick}
                isRegisteredUsers={true}
                activeUsersCount={getActiveUsersCount()}
              />
            </Suspense>
          ) : (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              No Registered Users Data
            </div>
          )
        ) : null}
      </div>
      <ConfirmationPopupModal
        isOpen={isConfirmationModalOpen}
        description={STRINGS.ARE_YOU_SURE}
        customFooter={true}
        showFooter={renderConfirmationFooter()}
        showHeader={false}
        postModalClose={postModalClose}
        closeModal={() => postModalClose()}
        showBorderTop={false}
        isBodyPaddingNone={true}
        style={customConfirmationStyles}
      />
    </TableWrapper>
  );
};

export default RegisteredUsers;
