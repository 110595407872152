import React from 'react';
import { Loader } from '../../Universal/NovusDSImports';
import { LoaderWrapper } from './style';
import { loaderStyles } from '../../Universal/NovusDSImports/variants';

const PALoader: React.FC = () => {
  return (
    <LoaderWrapper>
      <Loader {...loaderStyles} />
    </LoaderWrapper>
  );
};

export default PALoader;
