import axios from 'axios';
import { showError } from '../../CommonUtilities/CommonUtilities';
import { api_prefix } from '../../Constants/constants';
import { FetchMessagesData } from '../actions/Messages';
import { getMessagesData, messagesDataReceived } from '../reducers/Messages';
import { put, takeLatest } from 'redux-saga/effects';
import { getClosuresUnreadMessagesCount } from '../reducers/Closures';

function* fetchMessagesData({ payload }: FetchMessagesData) {
  try {
    const json = yield axios
      .get(
        `${api_prefix}closures/${payload.closureId}/messages_screen/?limit=all`
      )
      .then((response) => response);
    yield put(messagesDataReceived({ messagesData: json?.data?.data }));
    yield put(getClosuresUnreadMessagesCount({ limit: 'all' }));
  } catch (error) {
    showError(error);
  }
}

export default function* watchMessages() {
  yield takeLatest(getMessagesData, fetchMessagesData);
}
