import styled from 'styled-components';
import { colorState } from '../../Universal/Foundation/colors';
import { spacing, typography } from '../../Universal/Foundation';

export const EventDetailStyles = styled.div`
  overflow: auto;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .event-details-edit {
    padding: ${spacing.spacing_12}px ${spacing.spacing_16}px;
    border-bottom: 1px solid ${colorState.border.default.primary};
    background-color: ${colorState.surface.default.secondary};

    h1,
    p {
      font-size: ${typography.size.ts};
      line-height: 20px;
      margin-bottom: 0;
    }

    h1 {
      color: ${colorState.text.primary};
      font-weight: ${typography.weight.medium};
    }

    p {
      font-weight: ${typography.weight.regular};
      color: ${colorState.text.tertiary};
      word-break: break-word;
    }
  }

  .event-details-wrapper {
    padding: ${spacing.spacing_16}px;
    overflow: auto;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${spacing.spacing_20}px;

    .event-details-data {
      h1,
      p {
        font-size: ${typography.size.ts};
        line-height: 20px;
      }

      h1 {
        color: ${colorState.text.primary};
        font-weight: ${typography.weight.medium};
        margin-bottom: ${spacing.spacing_8}px;
      }

      p {
        font-weight: ${typography.weight.regular};
        color: ${colorState.text.tertiary};
        margin-bottom: 0;
        word-break: break-word;
      }
    }

    .clickable-links {
      p {
        color: ${colorState.text.link};
        cursor: pointer;
        font-size: ${typography.size.ts};
        font-weight: ${typography.weight.medium};
        line-height: 20px;
        margin-bottom: ${spacing.spacing_16}px;
      }
    }
  }
  .footer {
    border-top: 1px solid ${colorState.border.default.tertiary};
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    position: sticky;
    bottom: 0;
    background: white;
    padding: ${spacing.spacing_16}px ${spacing.spacing_24}px
      ${spacing.spacing_24}px ${spacing.spacing_24}px;
  }
`;

export const ApprovePopupStyles = styled.div`
  padding: ${spacing.spacing_12}px ${spacing.spacing_16}px
    ${spacing.spacing_16}px ${spacing.spacing_16}px;

  .form-details {
    display: flex;
    flex-direction: column;
    gap: ${spacing.spacing_12}px;
    border-bottom: 1px solid ${colorState.border.default.primary};
    padding-bottom: ${spacing.spacing_16}px;
    margin-bottom: ${spacing.spacing_16}px;

    .form-description-text {
      color: ${colorState.text.warning.primary};
      font-size: ${typography.size.txs};
      font-weight: ${typography.weight.regular};
      margin-bottom: 0;
    }

    .details-heading {
      color: ${colorState.text.tertiary};
      font-weight: ${typography.weight.regular};
      margin-bottom: 0;
    }

    .description {
      color: ${colorState.text.primary};
      font-weight: ${typography.weight.semibold};
      margin-bottom: 0;
    }

    .details-heading,
    .description {
      font-size: ${typography.size.txs};
    }
  }
`;

export const DisapprovePopupStyles = styled.div`
  padding: ${spacing.spacing_12}px ${spacing.spacing_16}px
    ${spacing.spacing_16}px ${spacing.spacing_16}px;
`;
