import React, { useEffect, useRef, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { getShapesFetch } from '../../Store/reducers/Shapes';
import Card from '../../SharedComponets/Card';
import GenericTableRT7 from '../GenericTableComponent/GenericTable';
import { Button, Input, Icon } from '../../Universal/NovusDSImports';
import { TooltipComponent } from '../../SharedComponets/Tooltip';
import AddEditShape from './AddEditShape';
import PolygonPreviewModal from './PolygonPreviewModal';
import {
  debounceFunction,
  getCentroid,
  getPermission,
  getOrderingParam
} from '../../CommonUtilities/CommonUtilities';
import GoogleMapsComponent from './GoogleMapsComponent';
import ConfirmationModal from './ConfirmationModal';
import { STRINGS } from '../../Constants/ConstantStrings';
import { RootState } from '../../store';
import { TableWrapper } from '../globalStyles';
import TableTitle from '../../SharedComponets/TableTitle';
import {
  btnStyles,
  confirmPopupModalStyles,
  inputStyles,
  modalStyles
} from '../../Universal/NovusDSImports/variants';
import { colorState } from '../../Universal/Foundation';
import { TablePopupStyles } from '../GenericTableComponent/styles';
import NoDataFound from '../../SharedComponets/NoDataFound';
import { NoShapesDataIcon } from '../../Universal/Assets';
import PALoader from '../../SharedComponets/PALoader';
import GoogleMapsErrorBoundary from '../../GoogleMapsErrorBoundary';
import { setShowTableLoader } from '../../Store/reducers/Common';

const Shapes: React.FC = () => {
  const dispatch = useReduxDispatch();

  const { shapes, isFetchingShapes } = useReduxSelector(
    (state: RootState) => state.Shapes
  );
  const [showAddEditForm, setShowAddEditForm] = useState<boolean>(false),
    [polygonPreviewOpen, setPolygonPreviewOpen] = useState<boolean>(false),
    [editModeType, setEditModeType] = useState<string>(),
    [editModeData, setEditModeData] = useState<any>(),
    [drawnPolygon, setDrawnPolygon] = useState<any>(null),
    [isConfimationModalOpen, setIsConfimationModalOpen] =
      useState<boolean>(false),
    [isDrawing, setIsDrawing] = useState<boolean>(false),
    [searchFilterValue, setSearchFilterValue] = useState<string>(''),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    timerToSaveDetails = useRef<NodeJS.Timeout | null>(null),
    [tableSortOrder, setTableSortOrder] = useState<
      Array<{ id: string; desc: boolean }>
    >([]),
    tableRowLimit = useReduxSelector(
      (state: RootState) => state.Common.tableRowLimit
    );

  const getNextSetOfData = (offset: number) => {
    dispatch(
      getShapesFetch({
        params: {
          offset,
          limit: tableRowLimit,
          search: searchFilterValue,
          ordering: getOrderingParam(tableSortOrder)
        },
        shouldAppend: true
      })
    );
  };

  useEffect(() => {
    debounceFunction(timerToSaveDetails, 100, () => {
      dispatch(
        getShapesFetch({
          params: {
            limit: tableRowLimit,
            search: searchFilterValue,
            ordering: getOrderingParam(tableSortOrder)
          }
        })
      );
    });
  }, [searchFilterValue, tableSortOrder]);

  useEffect(() => {
    if (!isFetchingShapes) {
      dispatch(setShowTableLoader({ showTableLoader: false }));
    }
  }, [isFetchingShapes]);

  const columns = [
    {
      Header: STRINGS.NAME,
      accessor: 'polygon_name'
    },
    {
      Header: STRINGS.CENTER_POINT_LATITUDE,
      Cell: ({ row }) => {
        const value = getCentroid(row.original.geometry.coordinates[0]);
        return <div>{value[1]}</div>;
      }
    },
    {
      Header: STRINGS.CENTER_POINT_LONGITUDE,
      Cell: ({ row }) => {
        const value = getCentroid(row.original.geometry.coordinates[0]);
        return <div>{value[0]}</div>;
      }
    },
    {
      Header: STRINGS.VIEW_POLYGON,
      Cell: ({ row }) => (
        <div className="view-details-section gap-3">
          <Button
            {...btnStyles.tertiary}
            className="d-flex justify-content-center align-items-center"
            onClick={() => {
              setPolygonPreviewOpen(true);
              setEditModeData({ ...row.original });
            }}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setPolygonPreviewOpen(true);
                setEditModeData({ ...row.original });
              }
            }}
            aria-label="view"
          >
            {STRINGS.VIEW_POLYGON}
            <Icon
              icon="view_details"
              className="ms-2"
              height="16px"
              width="16px"
              stroke={colorState.icon.brand.primary}
            />
          </Button>
          <div
            className="option-icon"
            aria-label="options"
            tabIndex={0}
            role="button"
            data-tooltip-id={row.original.id}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.currentTarget.click();
              }
            }}
          >
            <Icon icon="dots_vertical" width={'20px'} height={'20px'} />
          </div>
          <TooltipComponent
            id={row.original.id}
            openOnClick
            tooltipBgColor={colorState.surface.default.secondary}
            noPadding
            noArrow
            closeOnScroll
            place="bottom"
          >
            <TablePopupStyles>
              <ul role="menu" aria-label="view-details-section" tabIndex={0}>
                {getPermission(currentUser, 'change_savedpolygons') && (
                  <li
                    onClick={() => {
                      setEditModeType('Edit');
                      setShowAddEditForm(true);
                      setEditModeData({ ...row.original });
                      setIsDrawing(false);
                    }}
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        setEditModeType('Edit');
                        setShowAddEditForm(true);
                        setEditModeData({ ...row.original });
                        setIsDrawing(false);
                      }
                    }}
                    aria-label="edit"
                  >
                    Edit
                  </li>
                )}
                {getPermission(currentUser, 'delete_savedpolygons') && (
                  <li
                    className="text-danger"
                    onClick={() => {
                      setIsConfimationModalOpen(true);
                      setEditModeData({ ...row.original });
                    }}
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        setIsConfimationModalOpen(true);
                        setEditModeData({ ...row.original });
                      }
                    }}
                    aria-label="delete"
                  >
                    {STRINGS.DELETE}
                  </li>
                )}
              </ul>
            </TablePopupStyles>
          </TooltipComponent>
        </div>
      )
    }
  ];
  const NoShapesLibraryData = () => {
    return (
      <NoDataFound
        alt={'no-shapes-library-img'}
        src={NoShapesDataIcon}
        isAbsolute
        description={STRINGS.YOUR_LIST_OF_SHAPES_LIBRARY_ARE_EMPTY}
        subMsgDescription={
          STRINGS.YOUR_LIST_OF_SHAPES_LIBRARY_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_SHAPES_YET
        }
        btn={
          <Button {...btnStyles.primary} onClick={onAddNewShapeClick}>
            <Icon
              icon={'add'}
              stroke={colorState.icon.default['primary-inverse']}
            />
            {STRINGS.ADD_NEW_SHAPE}
          </Button>
        }
      />
    );
  };

  const NoShapesFilterData = () => {
    return (
      <NoDataFound
        src={NoShapesDataIcon}
        description={STRINGS.SHAPE_NOT_FOUND}
        alt={'no-manage-users-img'}
        isAbsolute
        subMsgDescription={STRINGS.NO_SHAPE_FOUND_WITH_GIVEN_VALUE}
      />
    );
  };

  const onAddNewShapeClick = () => {
    setEditModeType('Add');
    setShowAddEditForm(true);
    setIsDrawing(true);
  };

  return (
    <div className="w-100 d-flex gap-2">
      <Card
        className={`${
          showAddEditForm && editModeType ? 'middle' : 'w-100'
        } d-flex gap-2`}
      >
        <TableWrapper id="table-group" className="px-0">
          <TableTitle title={STRINGS.SHAPES_LIBRARY}>
            {getPermission(currentUser, 'add_savedpolygons') && (
              <Button
                onClick={onAddNewShapeClick}
                disabled={editModeType}
                title="Add New Shape"
                {...btnStyles.primary}
              >
                <Icon icon={'add'} stroke={'white'} />
                {STRINGS.ADD_SHAPE}
              </Button>
            )}
          </TableTitle>

          {editModeType ? (
            <GoogleMapsErrorBoundary>
              <GoogleMapsComponent
                editModeType={editModeType}
                editModeData={editModeData}
                isDrawing={isDrawing}
                setIsDrawing={setIsDrawing}
                drawnPolygon={drawnPolygon}
                setDrawnPolygon={setDrawnPolygon}
              />
            </GoogleMapsErrorBoundary>
          ) : (
            <>
              <div className="search-input col-12 col-sm-6 col-md-4 col-lg-3">
                <Input
                  name="shapesSearch"
                  placeholder={STRINGS.SEARCH}
                  value={searchFilterValue}
                  onChange={(e) => {
                    setSearchFilterValue(e.target.value);
                  }}
                  isMargin={false}
                  hideLabel={true}
                  iconLeft={
                    <Icon
                      icon={'search'}
                      stroke={colorState.icon.default.secondary}
                    />
                  }
                  {...inputStyles}
                />
              </div>
              {!shapes?.results ? (
                <PALoader />
              ) : (
                <div className="d-flex flex-column flex-grow-1 overflow-auto">
                  <GenericTableRT7
                    columns={columns}
                    tableData={shapes.results || []}
                    isSearching={searchFilterValue.length > 0}
                    noDataFoundScreen={NoShapesLibraryData()}
                    noFilterDataFoundScreen={NoShapesFilterData()}
                    infiniteScrollSettings={{
                      getNextSetOfData,
                      pageSize: tableRowLimit,
                      totalCount: shapes?.meta?.count
                    }}
                    setSelectedSortOrder={setTableSortOrder}
                    defaultSorted={tableSortOrder}
                  />
                </div>
              )}
            </>
          )}
        </TableWrapper>

        <PolygonPreviewModal
          isOpen={polygonPreviewOpen}
          setIsModalOpen={setPolygonPreviewOpen}
          editModeData={editModeData}
          setEditModeData={setEditModeData}
          title="Map"
          showHeader={true}
          closeModal={() => {
            setPolygonPreviewOpen(false);
            setDrawnPolygon(null);
            setEditModeData(null);
          }}
          postModalClose={() => {
            setPolygonPreviewOpen(false);
            setDrawnPolygon(null);
          }}
          {...modalStyles}
        />
        <ConfirmationModal
          isOpen={isConfimationModalOpen}
          title={STRINGS.DELETE_POLYGON}
          showHeader={true}
          closeModal={() => {
            setIsConfimationModalOpen(false);
            setEditModeData(null);
          }}
          searchFilterValue={searchFilterValue}
          postModalClose={() => {
            setEditModeData(null);
            setIsConfimationModalOpen(false);
          }}
          editModeData={editModeData}
          {...confirmPopupModalStyles}
          isBodyPaddingNone
        />
      </Card>
      {showAddEditForm && editModeType && (
        <div className="right">
          <AddEditShape
            showAddEditForm={showAddEditForm}
            editModeType={editModeType}
            setEditModeType={setEditModeType}
            editModeData={editModeData}
            setEditModeData={setEditModeData}
            drawnPolygon={drawnPolygon}
            setDrawnPolygon={setDrawnPolygon}
            searchFilterValue={searchFilterValue}
            title={
              editModeType === 'View'
                ? STRINGS.VIEW_SHAPE
                : editModeType === 'Edit'
                ? STRINGS.EDIT_SHAPE
                : STRINGS.ADD_SHAPE
            }
            onClose={() => {
              setEditModeType('');
              setShowAddEditForm(false);
              setDrawnPolygon(null);
              setEditModeData(null);
            }}
            onPostClose={() => {
              setEditModeType('');
              setShowAddEditForm(false);
              setDrawnPolygon(null);
              setEditModeData(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Shapes;
