/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/rules-of-hooks*/
import React, {
  useEffect,
  ReactElement,
  useState,
  FunctionComponent
} from 'react';
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
  HeaderProps,
  CellProps
} from 'react-table';
import { CheckBox, Icon } from '../../Universal/NovusDSImports';
import { GenericTableStyles, TableBody, TableHeader } from './styles';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useReduxDispatch } from '../../Store/reduxHooks';
import {
  setShowTableLoader,
  unSelectSelectedRows
} from '../../Store/reducers/Common';
import { checkBoxStyles } from '../../Universal/NovusDSImports/variants';
import InfiniteScroll from 'react-infinite-scroll-component';
import PALoader from '../../SharedComponets/PALoader';
import usePrevious from '../../Hooks/UsePrevious';
import { isEqual } from 'lodash';
import { DragAndDropIcon } from '../../Universal/Assets';
import {
  handleDragStart,
  handleDrop,
  formatTitleAttribute
} from '../../CommonUtilities/CommonUtilities';

interface TableTypes {
  columns: any[];
  data: any[];
  update: () => void;
  isSearching?: boolean;
  selectedRows?: any[];
  setSelectedRows?: (...props: any) => void;
  hasMore?: boolean;
  checkBoxColumnText?: string;
  noDataFoundScreen?: ReactElement;
  noFilterDataFoundScreen?: ReactElement;
  customSelectAllCheckBoxLogic?: () => void;
  customSingleCheckBoxLogic?: (id: number) => void;
  serialNumber?: boolean;
  showCheckBoxColumn?: boolean;
  presetFilters?: any;
  defaultAllColumnsSelected?: boolean;
  shouldNavigateOnArrowKey?: boolean;
  rowSelectWithoutCheckBox?: boolean;
  isMultiRowSelectAllowed?: boolean;
  rowMultiSelect?: boolean;
  handleRowClick?: (row: any) => void;
  shouldFocusColumnOfRow?: boolean;
  setSelectedSortOrder?: (sortOrder: any) => void;
  defaultSorted: Array<{ id: string; desc: boolean }>;
  disableSort?: boolean;
  isRegisteredUsers: boolean;
  activeUsersCount: number;
  isDraggable: boolean;
  updatedTableData?: any;
  setUpdatedTableData?: (data: any) => void;
}

// Our table component
const Table: FunctionComponent<TableTypes> = ({
  columns,
  data,
  update,
  isSearching,
  selectedRows,
  hasMore,
  checkBoxColumnText,
  noDataFoundScreen,
  noFilterDataFoundScreen,
  customSelectAllCheckBoxLogic,
  customSingleCheckBoxLogic,
  serialNumber,
  setSelectedSortOrder,
  isRegisteredUsers,
  activeUsersCount,
  isDraggable,
  updatedTableData,
  setUpdatedTableData,
  ...props
}) => {
  const defaultColumn: any = {
    Filter: false,
    width: isRegisteredUsers && 200
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // state,
    prepareRow,
    setAllFilters,
    toggleAllRowsSelected,
    state: {
      sortBy,
      // filters,
      selectedRowIds
    }
  } = useTable(
    {
      data,
      columns,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      defaultColumn, // Be sure to pass the defaultColumn option,
      manualSortBy: !!props.disableSort,
      initialState: {
        sortBy: props.defaultSorted
      }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useRowSelect,
    useBlockLayout,
    useResizeColumns,

    (hooks) => {
      let sNo = 0;
      hooks.visibleColumns.push((columns) => {
        const col: Array<any> = [];
        if (props.showCheckBoxColumn) {
          col.push({
            width: isRegisteredUsers && 120,
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: (props: HeaderProps<any>): ReactElement => {
              const { getToggleAllRowsSelectedProps } = props;
              const tableProps = { ...getToggleAllRowsSelectedProps() };
              //if all the rows are unselected using clear button in registered users, we are unchecking the header checkbox as well
              if (isRegisteredUsers) {
                if (
                  !selectedRows ||
                  Object.keys(selectedRows).length !== activeUsersCount
                )
                  delete tableProps.checked;
                else if (
                  selectedRows &&
                  Object.keys(selectedRows).length &&
                  Object.keys(selectedRows).length === activeUsersCount
                )
                  tableProps['checked'] = true;
              }

              return true ? ( // true is for future purpose, if any requirement like no need for checkbox for header then it will helpful in future.
                <div className="serial-no ps-0 d-flex">
                  <CheckBox
                    selected={tableProps.checked}
                    onClick={(): void => {
                      if (customSelectAllCheckBoxLogic) {
                        customSelectAllCheckBoxLogic();
                      } else if ((props as any).customSelectAllCheckboxLogic) {
                        (props as any).customSelectAllCheckboxLogic(props);
                      } else {
                        (props as any).toggleAllRowsSelected();
                      }
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === ' ') {
                        if (customSelectAllCheckBoxLogic) {
                          customSelectAllCheckBoxLogic();
                        } else if (
                          (props as any).customSelectAllCheckboxLogic
                        ) {
                          (props as any).customSelectAllCheckboxLogic(props);
                        } else {
                          (props as any).toggleAllRowsSelected();
                        }
                      }
                    }}
                    {...getToggleAllRowsSelectedProps()}
                    {...checkBoxStyles}
                    indeterminate={
                      selectedRows &&
                      Object.keys(selectedRows).length &&
                      (isRegisteredUsers
                        ? Object.keys(selectedRows).length !== activeUsersCount
                        : Object.keys(selectedRows).length !==
                        Object.keys(data).length)
                    }
                  />
                  {checkBoxColumnText && (
                    <span className="serial-no" title={checkBoxColumnText}>
                      {checkBoxColumnText}
                    </span>
                  )}
                </div>
              ) : (
                <></>
              );
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: (props: CellProps<any>): ReactElement => {
              const { row } = props;
              return (
                <div className="d-flex flex-row">
                  {false ? null : (
                    <CheckBox
                      selected={(selectedRows && selectedRows[row.id]) || false}
                      disabled={isRegisteredUsers && !row.original.is_active}
                      // selected={row.isSelected}
                      onClick={(): void => {
                        if (customSingleCheckBoxLogic) {
                          customSingleCheckBoxLogic(row.id);
                        } else if ((props as any).customSingleCheckboxLogic) {
                          (props as any).customSingleCheckboxLogic(row, props);
                        } else {
                          row.toggleRowSelected();
                        }
                      }}
                      onKeyDown={(event: any) => {
                        if (event.key === ' ') {
                          if ((props as any).customSingleCheckboxLogic) {
                            (props as any).customSingleCheckboxLogic(
                              row,
                              props
                            );
                          } else {
                            row.toggleRowSelected();
                          }
                        }
                      }}
                      {...checkBoxStyles}
                    />
                  )}
                  {checkBoxColumnText && checkBoxColumnText === 'S. No' ? (
                    <div className="serial-no">
                      {sNo < 9 ? 0 : ''}
                      {++sNo}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            }
          });
        } else if (serialNumber) {
          col.push({
            id: 'serialNumber',
            Header: 'S. No.',
            Cell: () => (
              <div className="serial-no">
                {sNo < 9 ? 0 : ''}
                {++sNo}
              </div>
            )
          });
        }
        col.push(...columns);
        return col;
      });
    }
  );

  const prevSortBy = usePrevious(sortBy);

  const [selectedRowsCount, setSelectedRowsCount] = useState<number>(
    Object.keys(selectedRowIds).length
  ),
    [draggingItem, setDraggingItem] = useState<any>(null),
    isUnselectSelectedRows = useSelector(
      (state: RootState) => state.Common.unSelectSelectedRows
    ),
    showTableLoader = useSelector(
      (state: RootState) => state.Common.showTableLoader
    ),
    dispatch = useReduxDispatch();

  React.useEffect(() => {
    if (props.presetFilters) {
      setAllFilters(props.presetFilters);
    }
  }, [data]);

  React.useEffect(() => {
    if (isDraggable && setUpdatedTableData && rows) setUpdatedTableData(rows);
  }, [data]);

  React.useEffect(() => {
    if (isUnselectSelectedRows) {
      // props.setSelectedRows([]);
      toggleAllRowsSelected(false);
      dispatch(unSelectSelectedRows({ shouldUnselect: false }));
    }
  }, [isUnselectSelectedRows]);

  useEffect(() => {
    if (props.defaultAllColumnsSelected) {
      toggleAllRowsSelected();
    }
  }, [props.defaultAllColumnsSelected]);

  React.useEffect(() => {
    if (!isRegisteredUsers) {
      if (props.setSelectedRows) {
        props.setSelectedRows(selectedRowIds);
      }
      setSelectedRowsCount(Object.keys(selectedRowIds).length);
    }
  }, [selectedRowIds]);

  React.useEffect(() => {
    if (setSelectedSortOrder && prevSortBy && !isEqual(sortBy, prevSortBy)) {
      setSelectedSortOrder(sortBy);
    }
  }, [sortBy]);

  const handleKeyDown = (event: any, row: any) => {
    const KEY = event.which || event.keyCode;
    const ENTER_KEY = 13,
      UP_ARROW_KEY = 38,
      DOWN_ARROW_KEY = 40;
    if (props.shouldNavigateOnArrowKey) {
      // should have arrow key navigation
      if (
        !(KEY === ENTER_KEY || KEY === UP_ARROW_KEY || KEY === DOWN_ARROW_KEY)
      )
        return;
    } else {
      // if the key pressed is not enter, then return
      if (KEY !== ENTER_KEY) {
        return;
      }
    }

    // check if the first column of row is a checkbox.
    const isFirstColumnCheckbox: boolean = row.original.is_editable;
    if (KEY === ENTER_KEY)
      if (
        props.rowSelectWithoutCheckBox ||
        isFirstColumnCheckbox ||
        props.isMultiRowSelectAllowed
      ) {
        // only if rowSelectWithoutCheckBox or first column of row is checkbox evaluates to true, then only toggle row selection
        if (!(props.isMultiRowSelectAllowed || selectedRowsCount === 0)) {
          toggleAllRowsSelected(false);
        }
        row.toggleRowSelected();
      } else {
        // to stop screen scrolling.
        event.preventDefault();
        if (
          KEY === DOWN_ARROW_KEY &&
          event.currentTarget &&
          event.currentTarget.nextSibling
        ) {
          event.currentTarget.nextSibling.focus();
        } else if (
          KEY === UP_ARROW_KEY &&
          event.currentTarget &&
          event.currentTarget.previousSibling
        ) {
          event.currentTarget.previousSibling.focus();
        }
      }
  };

  const getRowData = () => {
    return isDraggable ? updatedTableData : rows;
  };

  return showTableLoader ? (
    <PALoader />
  ) : (
    <InfiniteScroll
      dataLength={rows.length}
      next={update}
      hasMore={!!hasMore}
      loader={
        <div className="p-4">
          <PALoader />
        </div>
      }
      scrollableTarget="table-div"
    >
      <GenericTableStyles
        {...getTableProps()}
        role="table"
        isResizeable={isRegisteredUsers}
      >
        <TableHeader isResizeable={isRegisteredUsers}>
          {headerGroups.map((headerGroup: any, ind: number) => (
            <tr
              key={`${ind}${'headerGroups'}`}
              {...headerGroup.getHeaderGroupProps()}
              className="tr"
            >
              {headerGroup.headers.map((column: any, index: number) => (
                <th
                  key={`${index}${'headerGroup_headers'}`}
                  {...column.getHeaderProps()}
                  className={`th 
                      ${props.showCheckBoxColumn || props.rowMultiSelect
                      ? 'withcheckbox'
                      : ''
                    }
                    `}
                >
                  <div
                    className="d-flex justify-content-between"
                    onClick={(e) => {
                      if (!isDraggable) {
                        column
                          .getHeaderProps(column.getSortByToggleProps())
                          ?.onClick(e);
                        if (props.disableSort) {
                          dispatch(
                            setShowTableLoader({ showTableLoader: true })
                          );
                        }
                      }
                    }}
                  // {...column.getSortByToggleProps()}
                  >
                    <b
                      className={'pe-1'}
                      title={
                        props.showCheckBoxColumn && !index
                          ? 'S.No'
                          : column.render('Header')
                      }
                    >
                      {column.render('Header')}
                      {isRegisteredUsers && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${column.isResizing ? 'isResizing' : ''
                            }`}
                        />
                      )}
                    </b>
                    {!isDraggable ? (
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon icon={'assending'} />
                          ) : (
                            <Icon icon={'descending'} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    ) : null}
                  </div>
                  {/* <b
                    onClick={(e) => {
                      e.preventDefault();
                      props.setShowFilter(!props.showFilter);
                    }}
                    className={'px-2 cursor-pointer'}
                  >
                    {' '}
                    {column.canFilter && column.Filter && (
                      <i className={'fa fa-filter'}></i>
                    )}
                  </b> */}
                  {/* {props.showFilter && (
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  )} */}
                </th>
              ))}
            </tr>
          ))}
        </TableHeader>
        {getRowData()?.length ? (
          <TableBody
            {...getTableBodyProps()}
            id="t-body"
            isResizeable={isRegisteredUsers}
          >
            {getRowData()?.map((row: any, ind: number) => {
              prepareRow(row);
              return (
                <tr
                  key={`${ind}${'GenericTableBodyRow'}`}
                  {...row.getRowProps()}
                  onClick={() =>
                    props.handleRowClick ? props.handleRowClick(row) : null
                  }
                  isselected={row['isSelected'] ? row['isSelected'] : null}
                  tabIndex={0}
                  onKeyDown={(e) => handleKeyDown(e, row)}
                  draggable={isDraggable}
                  onDragStart={(e: any) => {
                    handleDragStart(e, row, setDraggingItem);
                  }}
                  onDragEnd={() => setDraggingItem(null)}
                  onDragOver={(e: any) => e.preventDefault()}
                  onDrop={() => {
                    setUpdatedTableData &&
                      setUpdatedTableData(
                        handleDrop(draggingItem, updatedTableData, row)
                      );
                  }}
                >
                  {row.cells.map((cell: any, index: number) => {
                    return (
                      <td
                        key={`${index}${'GenericTableDataCell'}`}
                        {...cell.getCellProps()}
                        className={
                          'break-word' +
                          (props.showCheckBoxColumn || props.rowMultiSelect
                            ? 'withcheckbox'
                            : '') +
                          `${isRegisteredUsers &&
                          !row.original.is_active &&
                          ' disabled-cell'
                          }`
                        }
                        draggable={index ? true : false}
                        onDragStart={(event: any) => {
                          if (index) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        // onClick={(e) => {
                        //   console.log(e);
                        //   handleTableColumnClick(e, props, cell, row);
                        // }}
                        onKeyDown={(e) => handleKeyDown(e, row)}
                        tabIndex={props.shouldFocusColumnOfRow ? 0 : -1}
                        aria-disabled={isRegisteredUsers && !row.original.is_active}
                      >
                        <div className="d-flex flex-row">
                          {isDraggable && !index && (
                            <div className="table-data-cell">
                              {' '}
                              <img src={DragAndDropIcon} draggable={false} />
                            </div>
                          )}
                          <div
                            className="d-flex flex-row col-12"
                            draggable={true}
                            onDragStart={(event: any) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {isDraggable && !index && (
                              <div className="table-data-cell number">
                                {isDraggable
                                  ? ind < 9
                                    ? '0' + (ind + 1)
                                    : ind + 1
                                  : ''}
                                .
                              </div>
                            )}

                            <div
                              className="table-data-cell"
                              title={formatTitleAttribute(
                                cell.render('Cell').props.cell.value
                              )}
                            >
                              {cell.render('Cell')}
                            </div>
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </TableBody>
        ) : isSearching ? (
          noFilterDataFoundScreen
        ) : (
          noDataFoundScreen
        )}
      </GenericTableStyles>
    </InfiniteScroll>
  );
};

interface GenericTableRT7PropType {
  tableData: Array<any>;
  columns: any[];
  isSearching?: boolean;
  setSelectedRows?: (...props: any) => void;
  showCheckBoxColumn?: boolean;
  checkBoxColumnText?: string;
  selectedRows?: any[];
  defaultAllColumnsSelected?: boolean;
  noDataFoundScreen?: React.ReactElement;
  noFilterDataFoundScreen?: React.ReactElement;
  customSelectAllCheckBoxLogic?: () => void;
  customSingleCheckBoxLogic?: (id: number) => void;
  serialNumber?: boolean;
  presetFilters?: any;
  setSelectedSortOrder?: (sortOrder: any) => void;
  defaultSorted?: Array<{ id: string; desc: boolean }>;
  infiniteScrollSettings?: {
    pageSize: number;
    totalCount: number;
    getNextSetOfData: (offset: number) => void;
  };
  isRegisteredUsers?: boolean;
  activeUsersCount?: number;
  isDraggable?: boolean;
  updatedTableData?: any;
  setUpdatedTableData?: (data: any) => void;
}

const GenericTableRT7 = (props: GenericTableRT7PropType) => {
  const {
    tableData,
    columns,
    infiniteScrollSettings,
    isSearching,
    setSelectedRows,
    showCheckBoxColumn,
    checkBoxColumnText,
    selectedRows,
    defaultAllColumnsSelected,
    noDataFoundScreen,
    noFilterDataFoundScreen,
    customSelectAllCheckBoxLogic,
    customSingleCheckBoxLogic,
    serialNumber,
    setSelectedSortOrder,
    defaultSorted,
    isRegisteredUsers = false,
    activeUsersCount = 0,
    isDraggable = false,
    updatedTableData,
    setUpdatedTableData
  } = props;

  const [data, setData] = React.useState<Array<any>>(tableData);
  const [hasMore, setHasMore] = React.useState(
    infiniteScrollSettings &&
    tableData?.length > (infiniteScrollSettings.pageSize || 10)
  );

  const ViewDetails = () => {
    const viewDetails = document.getElementsByClassName('view-details-section');
    Array.from(viewDetails).forEach((viewDetailDiv) => {
      const element = viewDetailDiv as HTMLElement;
      const closestTdElement = element.closest('td');
      closestTdElement?.classList.remove('disabled-cell');
    });
  };

  React.useEffect(() => {
    setData(tableData);
    ViewDetails();
  }, [tableData]);

  React.useEffect(() => {
    if (infiniteScrollSettings?.totalCount) {
      setHasMore(data.length < infiniteScrollSettings.totalCount);
    }
  }, [data]);

  const fetchMoreData = () => {
    if (infiniteScrollSettings?.getNextSetOfData) {
      infiniteScrollSettings.getNextSetOfData(data.length);
    }
  };

  return (
    <div
      className={
        'd-flex flex-column flex-grow-1 overflow-auto position-relative'
      }
      id="table-div"
    >
      <Table
        columns={columns}
        data={data}
        setSelectedRows={setSelectedRows}
        hasMore={hasMore}
        update={fetchMoreData}
        isSearching={isSearching}
        showCheckBoxColumn={showCheckBoxColumn}
        checkBoxColumnText={checkBoxColumnText}
        selectedRows={selectedRows}
        defaultAllColumnsSelected={defaultAllColumnsSelected}
        noDataFoundScreen={noDataFoundScreen}
        noFilterDataFoundScreen={noFilterDataFoundScreen}
        customSelectAllCheckBoxLogic={customSelectAllCheckBoxLogic}
        customSingleCheckBoxLogic={customSingleCheckBoxLogic}
        serialNumber={serialNumber}
        setSelectedSortOrder={setSelectedSortOrder}
        defaultSorted={defaultSorted || []}
        disableSort={!!infiniteScrollSettings}
        isRegisteredUsers={isRegisteredUsers}
        activeUsersCount={activeUsersCount}
        isDraggable={isDraggable}
        updatedTableData={updatedTableData}
        setUpdatedTableData={setUpdatedTableData}
      />
    </div>
  );
};

export default GenericTableRT7;
