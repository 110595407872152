import { Component, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class GoogleMapsErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false
  };

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return this.props.children;
      // return <h1>Something went wrong. Please reload.</h1>;
    }
    return this.props.children;
  }
}

export default GoogleMapsErrorBoundary;
