import styled, { css } from 'styled-components';
import { device } from '../../Universal/Foundation/breakpoints';
import { colorState, radius, typography } from '../../Universal/Foundation';

const loginWidth = '490';
const moveLeft = '12%';

export const fullVH = css`
  height: 100%;
  width: 100%;
`;

export const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  background: white;

  @media ${device.mobileL} {
    align-items: flex-start;
  }

  .left {
    border-right: solid 1px ${colorState.border.default.secondary};
    padding-right: 12px;

    @media ${device.mobileL} {
      border-right: none;
    }
  }

  .right {
    a {
      color: ${colorState.text.secondary};

      &:hover {
        color: ${colorState.text.brand.primary};
      }
    }
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  height: 100%;

  a {
    text-decoration: none;
  }

  /* To disable lock icon on safari browser */
  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  .outer-login-grid {
    flex: 1;
    align-items: center;
    display: flex;

    @media ${device.mobileL} {
      align-items: start;
    }

    .login-grid {
      width: ${loginWidth}px;
      zoom: 1;
      left: ${moveLeft};
      position: relative;
      margin: 12px 0;

      @media ${device.mobileXL} {
        width: 100%;
        left: auto;
      }

      p {
        margin: 0;
      }
    }
  }
  .footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-weight: ${typography.weight.medium};
    font-size: ${typography.size.ts};
    color: ${colorState.text.secondary};
    width: 100%;
    padding: 0 24px;
    padding-bottom: 12px;
    padding-top: 12px;
    z-index: 9;

    @media ${device.mobileL} {
      flex-direction: column;
      font-size: ${typography.size.tdxs};
    }

    .left {
      .divider {
        border-right: solid 1px ${colorState.border.default.secondary};
        padding-right: 12px;
      }
    }

    .right {
      .link-text {
        color: ${colorState.text.link};
        text-decoration: underline;
      }
      &:hover {
        color: ${colorState.text.brand.primary};
      }
    }
  }
`;

export const BacgroundImageView = styled.div`
  ${fullVH};
  position: absolute;

  img {
    ${fullVH};
    opacity: 1;
    object-fit: cover;
    object-position: center;

    @media ${device.laptop} {
      object-fit: contain;
      object-position: right bottom;
      opacity: 0.4;
    }
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessage = styled.p`
  color: ${colorState.text.danger.primary};
  font-size: ${typography.size.txs};
  font-weight: ${typography.weight.regular};
  margin: 0;
  padding-top: 8px;
`;

export const FormContainer = styled.div`
  h1 {
    font-weight: ${typography.weight.bold};
    font-size: ${typography.size.h4};
    line-height: 36px;
    color: ${colorState.text.primary};
    margin-bottom: 30px;

    @media ${device.laptop} {
      margin-bottom: 10px;
    }
  }

  h2,
  p {
    font-size: ${typography.size.tm};
    line-height: 24px;
  }

  .form-group {
    margin: 0;
  }

  label {
    color: ${colorState.text.secondary};
  }

  label,
  .text-forgotpassword {
    font-weight: ${typography.weight.medium};
    font-size: ${typography.size.ts};
  }

  .text-forgotpassword {
    cursor: pointer;
    color: ${colorState.surface.brand.primary};

    &:hover {
      color: ${colorState.surface.brand.primary};
    }
  }

  .text-description {
    color: ${colorState.text.tertiary};
    font-size: ${typography.size.ts};
    padding-bottom: 32px;

    span {
      padding-left: 8px;
      color: ${colorState.surface.default['primary-inverse']};
    }
  }

  .otp-timer {
    display: flex;
    align-self: center;
    padding: 3px 8px;
    background-color: ${colorState.surface.default.tertiary};
    width: fit-content;
    margin-bottom: 8px;
    border-radius: ${radius.radius_40}px;

    p {
      font-weight: ${typography.weight.semibold};
    }
  }

  .otp-input {
    display: flex;
    justify-content: center;
    align-items: center;

    .input-styles {
      input {
        &:hover,
        &:active,
        &:focus-visible,
        &:focus {
          border: 2px solid ${colorState.border.brand.primary} !important;
          outline: none;
        }

        &:active,
        &:focus {
          box-shadow: 0px 0px 0px 3px rgba(36, 100, 228, 0.6) !important;
        }
      }
    }
  }

  .text-note {
    padding-top: 8px;
    color: ${colorState.text.secondary};
    font-size: ${typography.size.txs};
    font-weight: ${typography.weight.regular};

    span {
      padding-right: 4px;
      color: ${colorState.text.tertiary};
    }
  }

  .otp-input,
  .text-note {
    padding-bottom: 24px;
  }

  .section-verification {
    display: flex;
    font-size: ${typography.size.ts};
    font-weight: ${typography.weight.medium};
    align-items: center;
    padding-bottom: 30px;

    span {
      padding-left: 4px;
      font-size: ${typography.size.ts};
      color: ${colorState.text.info.primary};
    }
  }
`;

export const PasswordValidation = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 10px;

  p {
    margin-bottom: 0;
  }
`;

export const OtpStyles = {
  width: '40px',
  height: '40px',
  marginRight: '12px',
  borderRadius: '8px',
  border: `2px solid ${colorState.border.default.secondary}`
};
