import { createSlice } from '@reduxjs/toolkit';
import {
  LoginStateType,
  LoginRequestType,
  RequestOtpReceivedType,
  RequestOtpType,
  LogoutRequest,
  VerifyOtpType,
  LoginRecievedType
} from '../actions/Login';
import Cookies from 'js-cookie';
import {
  clearCookiesAndLocalstorage,
  safeDecodeJWTToken
} from '../../CommonUtilities/CommonUtilities';
import { SECURE } from '../../settings';

const initialState: LoginStateType = {
  isFetchingLogin: false,
  loginData: null,
  isLoggedOut: false,
  isOtpRequested: false,
  isLoggedIn: false,
  message: '',
  otpExpiryTime: '',
  localVsServerTimeDifference: 0,
  isSubmitButtonLoading: false
};

export const LoginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    loginRequest: (state, _action: LoginRequestType) => {
      state.isFetchingLogin = true;
    },
    loginReceived: (state, action: LoginRecievedType) => {
      state.isSubmitButtonLoading = false;
      const token = action?.payload?.refresh;
      const decodedToken = safeDecodeJWTToken(token || '');
      const date = new Date(decodedToken?.exp * 1000); // Multiply by 1000 to convert seconds to milliseconds
      const formattedDate = new Date(date.toUTCString());
      Cookies.set('access', action.payload.access, {
        secure: SECURE,
        expires: formattedDate
      });
      Cookies.set('refresh', action.payload.refresh, {
        secure: SECURE,
        expires: formattedDate
      });
      const accessToken = Cookies.get('access');
      state.isFetchingLogin = false;
      state.isLoggedOut = false;
      state.loginData = action.payload;
      state.isOtpRequested = false;
      state.localVsServerTimeDifference =
        action.payload.localVsServerTimeDifference;
      const access = safeDecodeJWTToken(accessToken || '');
      if (access) {
        state.isLoggedIn = access;
      }
    },
    loginFailed: (state, action: any) => {
      state.message = action.payload;
      state.isFetchingLogin = false;
    },
    requestOtp: (state, _action: RequestOtpType) => {
      state.isSubmitButtonLoading = true;
    },
    requestOtpReceived: (state, action: RequestOtpReceivedType) => {
      state.isSubmitButtonLoading = false;
      state.isOtpRequested = action.payload.isOtpRequested;
      state.otpExpiryTime = action.payload.expires;
      state.message = '';
    },
    verifyOtp: (state, _action: VerifyOtpType) => {
      state.isSubmitButtonLoading = true;
    },
    logoutRequest: (_state, _action: LogoutRequest) => {},
    logoutReceived: (state) => {
      clearCookiesAndLocalstorage();
      state.isFetchingLogin = false;
      state.isLoggedOut = true;
      state.loginData = null;
      state.isLoggedIn = false;
    },
    isVerifyOtpOrRequestOtpFailed: (state) => {
      state.isSubmitButtonLoading = false;
    },
    resetLoginStoreValues: (state) => {
      state.isFetchingLogin = false;
      state.loginData = null;
      state.isLoggedOut = false;
      state.isOtpRequested = false;
      state.isLoggedIn = false;
      state.message = '';
    }
  }
});

export const {
  loginRequest,
  loginReceived,
  verifyOtp,
  requestOtp,
  requestOtpReceived,
  logoutReceived,
  logoutRequest,
  loginFailed,
  resetLoginStoreValues,
  isVerifyOtpOrRequestOtpFailed
} = LoginSlice.actions;

export default LoginSlice.reducer;
