import styled, { css } from 'styled-components';
import { colorState, typography } from '../../Universal/Foundation';
import { NoDataProps } from './types';

export const NoDataStyles = styled.div<NoDataProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  .text-description {
    margin: 0;
    font-size: ${typography.size.ts};
    font-weight: ${typography.weight.semibold};
    line-height: 20px;
    color: ${colorState.text.primary};
    text-align: center;
  }
  .sub-message-description {
    margin: 0;
    text-align: center;
    font-size: ${typography.size.tdxs};
    font-weight: ${typography.weight.regular};
    color: ${colorState.text.tertiary};
  }
`;
