import { InfoWindow } from '@react-google-maps/api';
import { PopupWindow } from '../../globalStyles';
import { Icon } from '../../../Universal/NovusDSImports';
import { STRINGS } from '../../../Constants/ConstantStrings';
import { useClickOutside } from '../../../Hooks/useClickOutside';
import React, { useEffect, useRef } from 'react';

interface RestFacilitiesPopUpWindowProps {
  restRoom: any;
  setSelectedRestRoom: Function;
  setMapCenter: Function;
  setZoomLevel: Function;
  markerOpenRef: React.RefObject<any>;
}

const RestFacilitiesPopUpWindow: React.FC<RestFacilitiesPopUpWindowProps> = (
  props
) => {
  const {
    restRoom,
    setSelectedRestRoom,
    markerOpenRef,
    setMapCenter,
    setZoomLevel
  } = props;

  const MarkerExcludedRef = useRef(null);

  useClickOutside(
    markerOpenRef,
    () => {
      setSelectedRestRoom(null);
    },
    [MarkerExcludedRef]
  );

  useEffect(() => {
    setZoomLevel(16);
    setMapCenter({
      lat: restRoom.Latitude,
      lng: restRoom.Longitude
    });
  }, [restRoom, setSelectedRestRoom]);

  return (
    <InfoWindow
      onCloseClick={() => setSelectedRestRoom(null)}
      position={{
        lat: restRoom.Latitude,
        lng: restRoom.Longitude
      }}
    >
      <PopupWindow ref={markerOpenRef}>
        <div className="header">
          <h5>Rest Room</h5>
          <Icon
            icon="close"
            width={'16px'}
            height={'16px'}
            onClick={() => {
              if (restRoom) setSelectedRestRoom(null);
            }}
            className="close-icon"
          >
            {STRINGS.CLOSE}
          </Icon>
        </div>
        <div className="body">
          <h6>{restRoom.Name}</h6>
          <div className="row latitude-longitude-section mx-0">
            <div className="col-sm-6 pe-1 ps-0">
              <label>Latitude</label>
              <p>{restRoom.Latitude}</p>
            </div>
            <div className="col-sm-6 pe-1 ps-0">
              <label>Longitude</label>
              <p>{restRoom.Longitude}</p>
            </div>
          </div>

          <div className="group mt-3">
            <label>Location</label>
            <p>{restRoom.Location}</p>
          </div>

          <div className="group mt-3">
            <label>Status</label>
            <p>{restRoom.Status}</p>
          </div>
        </div>
      </PopupWindow>
    </InfoWindow>
  );
};

export default RestFacilitiesPopUpWindow;
