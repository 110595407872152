import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Modal } from '../../Universal/NovusDSImports';
import { GoogleMap, Polygon, useLoadScript } from '@react-google-maps/api';
import { getCentroid } from '../../CommonUtilities/CommonUtilities';
import { PolygonPreviewModalType } from './shapes.types';
import GoogleMapsErrorBoundary from '../../GoogleMapsErrorBoundary';
import { GOOGLE_MAPS_API_KEY } from '../../settings';
import PALoader from '../../SharedComponets/PALoader';
// import { PA_BOUNDARY } from '../../Constants/constants';

const PolygonPreviewModal: React.FC<PolygonPreviewModalType> = ({
  editModeData
}) => {
  const { isLoaded } = useLoadScript({
    id: 'PolygonPreviewModal',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });
  // const [map, setMap] = useState<any>(null);
  const [center, setCenter] = useState<any>({ lat: 24.886, lng: -70.268 });

  const onLoad = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();

      editModeData.geometry.coordinates[0].forEach((point: any) => {
        bounds.extend({ lat: point[0], lng: point[1] });
      });

      map.fitBounds(bounds);
      // setMap(map);
    },
    [editModeData.geometry.coordinates]
  );

  const onUnmount = useCallback((map) => {
    // setMap(null);
  }, []);

  const getPolygonPath = (coords: any) => {
    const path: any = [];
    coords &&
      coords.forEach((point: any) => {
        path.push({ lat: point[0], lng: point[1] });
      });
    return path;
  };

  useEffect(() => {
    if (editModeData) {
      const value = getCentroid(editModeData.geometry.coordinates[0]);
      setCenter({ lat: value[1], lng: value[0] });
    }
  }, [editModeData]);

  const containerStyle = {
    width: '1000px',
    height: '500px'
  };

  return isLoaded ? (
    <Suspense fallback={<PALoader />}>
      <GoogleMapsErrorBoundary>
        <GoogleMap
          id="PolygonPreviewModal"
          mapContainerStyle={containerStyle}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          zoom={10}
          onClick={(event: google.maps.MapMouseEvent) => {
            event.stop();
          }}
          options={{
            maxZoom: 18,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false
            // restriction: {
            //   strictBounds: false,
            //   latLngBounds: PA_BOUNDARY
            // }
          }}
        >
          <Polygon
            onLoad={() => {}}
            paths={getPolygonPath(editModeData.geometry.coordinates[0])}
            options={{
              fillColor: 'red',
              fillOpacity: 0.2,
              strokeWeight: 3,
              strokeColor: 'red',
              clickable: false
            }}
          />
        </GoogleMap>
      </GoogleMapsErrorBoundary>
    </Suspense>
  ) : (
    <></>
  );
};

export default Modal(React.memo(PolygonPreviewModal));
