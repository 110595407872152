import { Controller, FormProvider, useForm } from 'react-hook-form';
import { validateYupSchema } from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import { Button, Modal, TextArea } from '../../Universal/NovusDSImports';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  btnStyles,
  inputStyles
} from '../../Universal/NovusDSImports/variants';
import { FormSegment } from '../globalStyles';
import { addOrEditLifeSafetyMessage } from '../../Store/reducers/RegisteredUsers';
import { useReduxDispatch } from '../../Store/reduxHooks';

const AddOrEditSafetyMessage = (props: any) => {
  const { isEdit, postModalClose, selectedMessage } = props;
  const dispatch = useReduxDispatch();

  const schema = yup.object().shape({
    message: validateYupSchema('text', STRINGS.YUP.LIFE_SAFETY_MESSAGE, true)
  });

  const getDefaultValues = () => {
    return {
      message: isEdit ? selectedMessage?.message_body : ''
    };
  };

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isDirty, errors }
  } = methods;

  const onSubmit = (data: any) => {
    const payload = {
      message: data.message
    };
    if (isEdit) {
      payload['id'] = selectedMessage?.id;
      payload['isEdit'] = true;
      dispatch(addOrEditLifeSafetyMessage({ params: payload }));
    } else {
      dispatch(addOrEditLifeSafetyMessage({ params: payload }));
    }
    postModalClose();
  };

  return (
    <FormProvider {...methods}>
      <FormSegment onSubmit={handleSubmit(onSubmit)}>
        <section className="formset-fields">
          <div className="form-group mb-0">
            <Controller
              control={control}
              name="message"
              render={({ field }) => {
                return (
                  <>
                    <TextArea
                      className="mb-0"
                      maxLength="120"
                      {...field}
                      label={STRINGS.MESSAGE}
                      placeholder={STRINGS.ENTER_TEXT}
                      {...inputStyles}
                      asterisk
                      error={errors?.message}
                      hintText={errors?.message?.message}
                    />

                    <p className="text-right">
                      {120 - getValues('message').length}{' '}
                      {STRINGS.CHARACTERS_REMAINING}
                    </p>
                  </>
                );
              }}
            />
          </div>
        </section>
        <section className="footer">
          <div className="d-flex gap-3 justify-content-end w-100">
            <Button
              {...btnStyles.secondary}
              onClick={postModalClose}
              type="button"
            >
              {isEdit ? STRINGS.CANCEL : STRINGS.CLOSE}
            </Button>
            <Button {...btnStyles.primary} type="submit" disabled={!isDirty}>
              {!isEdit ? STRINGS.SAVE : STRINGS.UPDATE}
            </Button>
          </div>
        </section>
      </FormSegment>
    </FormProvider>
  );
};

export default Modal(AddOrEditSafetyMessage);
