import { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CreateNewPassword from '../Components/CreateNewPassword';
import Login from '../Components/Login';
import PublicViewContainer from '../Components/PublicView';
import AdminGuide from '../Components/AdminGuide';
import { isPublicView } from '../settings';
import { setRowLimit } from '../CommonUtilities/CommonUtilities';

export function GlobalRouter() {
  const NotFound = lazy(() => import('./NotFound'));

  return (
    <Switch>
      {isPublicView && <Route path={''} component={PublicViewContainer} />}
      <Route path={'/AdminGuide'} component={AdminGuide} />
      {!isPublicView && (
        <Route path={'/ResetPassword'} component={CreateNewPassword} />
      )}
      {!isPublicView && <Route path={'/Login'} component={Login} />}
      {!isPublicView ? <Redirect to="/Login" /> : <Redirect to="/" />}
      <Suspense fallback={<></>}>
        <NotFound />
      </Suspense>
    </Switch>
  );
}

export default GlobalRouter;
