import { FunctionComponent } from 'react';
import { Button, Modal } from '../Universal/NovusDSImports';
import { btnStyles } from '../Universal/NovusDSImports/variants';
import { useReduxDispatch, useReduxSelector } from '../Store/reduxHooks';
import { closeEventRequest } from '../Store/reducers/Closures';
import { STRINGS } from '../Constants/ConstantStrings';
import { PopupConfirmStyles } from '../Components/globalStyles';
import { setSelectedTab } from '../Store/reducers/Common';
import { RootState } from '../store';

const ConfirmationPopupModal: FunctionComponent = (props: any) => {
  const dispatch = useReduxDispatch();
  const isSubmitting = useReduxSelector(
    (state: RootState) => state.Closures.isSubmitting
  );

  const onCloseEvent = () => {
    dispatch(
      closeEventRequest({
        closureId: props.closeEventPayload.closureId,
        closeText: props.closeEventPayload.closeText,
        closure_close_image: props.closeEventPayload.closure_close_image,
        onComplete: () => {
          props.handleCloseConfirm();
        }
      })
    );
    dispatch(setSelectedTab({ selectedTab: '' }));
  };

  return (
    <PopupConfirmStyles
      className={`${
        props.customFooter ? `mx-4 ${props.showHeader ? 'mt-2' : 'mt-4'}` : ''
      } mb-2`}
    >
      <h2>{props.description}</h2>
      {!props.customFooter ? (
        <div className="footer">
          <Button
            onClick={props.postModalClose}
            disabled={isSubmitting}
            {...btnStyles.default}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button
            onClick={onCloseEvent}
            disabled={isSubmitting}
            {...btnStyles.danger}
          >
            {isSubmitting ? STRINGS.IN_PROGRESS : STRINGS.YES_CLOSE}
          </Button>
        </div>
      ) : (
        props.customFooter
      )}
    </PopupConfirmStyles>
  );
};

export default Modal(ConfirmationPopupModal);
