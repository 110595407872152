import { TableTitleWrapper } from './styles';
import { TableTitleProps } from './helpers';

export default function TableTitle(props: TableTitleProps) {
  return (
    <TableTitleWrapper {...props} className={props.className}>
      <div className="header">
        <div className="left-wrapper">
          <h1 className="head-divider">{props.title}</h1>
          <h2>{props.message}</h2>
        </div>
        <div className="right-wrapper ps-3">{props.children}</div>
      </div>
    </TableTitleWrapper>
  );
}
