import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { STRINGS } from '../../Constants/ConstantStrings';
import { logoutRequest } from '../../Store/reducers/Login';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { ProfileImg } from '../../Universal/Assets';
import { colorState } from '../../Universal/Foundation';
import {
  Avatar,
  Badge,
  Icon,
  TopNavigation
} from '../../Universal/NovusDSImports';
import { RootState } from '../../store';
import {
  MenuWrapper,
  MobileMenuWrapper,
  ProfilePopupContainer,
  TopNavWrapper
} from './styles';
import { NavbarMenuItem } from '../../CommonTypes';
import {
  getPermissionForTab,
  getUserColor,
  getUserInitials
} from '../../CommonUtilities/CommonUtilities';
import {
  setSelectedClosure,
  setSelectedTab
} from '../../Store/reducers/Common';
import { isProdInstance } from '../../settings';

interface Props {
  items: NavbarMenuItem[];
  activeItem: NavbarMenuItem | null;
  setActiveItem: (item: NavbarMenuItem) => void;
  showOnMobile: boolean;
  handleMenuToggle: () => void;
  handleMenuClose: () => void;
  handleItemClick: (item: NavbarMenuItem) => void;
}

const TopNav: React.FC<Props> = ({
  items,
  activeItem,
  setActiveItem,
  showOnMobile,
  handleMenuToggle,
  handleMenuClose,
  handleItemClick
}) => {
  const dispatch = useReduxDispatch(),
    userDetails = useSelector(
      (state: RootState) => state.Common.currentUserDetails
    );

  const { client_name, title_logo } = useReduxSelector(
    (state: RootState) => state.ClientInfo
  );

  const adminGuide = useReduxSelector(
    (state: RootState) => state.ClientInfo.admin_guide
  );

  const logout = () => {
    dispatch(logoutRequest({}));
  };

  const getNavBarItemsLength = useCallback(() => {
    let count = 0;
    items.forEach((item: any) => {
      if (getPermissionForTab(item.link, userDetails)) count++;
    });
    return count;
  }, [userDetails]);

  const renderAvatar = () => {
    return userDetails ? (
      <>
        <div className="user-login">
          {userDetails?.fullname ? (
            <>
              <Avatar
                src={userDetails?.profile_image}
                username={getUserInitials(userDetails?.fullname)}
                userNameColor={getUserColor(userDetails?.fullname)}
                title={userDetails?.fullname || STRINGS.NO_NAME}
                isDefault={false}
              />
              {items && getNavBarItemsLength() < 7 && (
                <div
                  className="user-name"
                  title={userDetails?.fullname || STRINGS.NO_NAME}
                >
                  <span>{userDetails?.fullname || STRINGS.NO_NAME}</span>
                </div>
              )}
            </>
          ) : (
            <Avatar src={ProfileImg} alt="Avatar" isDefault={false} />
          )}
        </div>
      </>
    ) : (
      <></>
    );
  };

  const renderBadge = () => {
    return <Badge variant="negative">5</Badge>;
  };

  // const renderNotificationPopup = () => {
  //   return (
  //     <NotificationPopupContainer
  //       id={'notification-main'}
  //       className="animate__animated animate__fadeIn animate__faster"
  //     >
  //       <section className="header">
  //         <div className="d-flex justify-content-between align-items-center">
  //           <h3>{STRINGS.NOTIFICATIONS}</h3>
  //           <div className="d-flex align-items-center">
  //             <span className="pe-2">Today</span>
  //             <div className="notification-badge">300</div>
  //           </div>
  //         </div>
  //       </section>
  //       <section className="body">
  //         <Card className="p-3">Notifications here..</Card>
  //       </section>
  //     </NotificationPopupContainer>
  //   );
  // };

  const profileMenuOptions = [
    { icon: 'file_06', text: 'Admin Guide' },
    { icon: 'log_out_01', text: STRINGS.LOGOUT }
  ];

  const handleSupportClick = () => {
    // console.log('Help');
  };

  const renderProfilePopup = () => {
    const handleProfileMenuItemClick = (item: any) => {
      switch (item.text) {
        case STRINGS.LOGOUT:
          logout();
          break;
        case STRINGS.HELP:
          handleSupportClick();
          break;
        case 'Admin Guide':
          window.open(adminGuide ?? '/PAConnectGuidance.pdf', '_blank');
          break;
        default:
          break;
      }
    };

    const handleProfileMenuItemKeyDown = (
      event: React.KeyboardEvent<HTMLLIElement>,
      item: any
    ) => {
      switch (event.key) {
        case 'Enter':
          handleProfileMenuItemClick(item);
          break;
        case 'ArrowDown':
          focusNextMenuItem(event.currentTarget);
          break;
        case 'ArrowUp':
          focusPreviousMenuItem(event.currentTarget);
          break;
        default:
          break;
      }
    };

    const focusNextMenuItem = (currentItem: HTMLElement) => {
      const nextItem = currentItem.nextElementSibling as HTMLElement;
      if (nextItem) {
        nextItem.focus();
      }
    };

    const focusPreviousMenuItem = (currentItem: HTMLElement) => {
      const previousItem = currentItem.previousElementSibling as HTMLElement;
      if (previousItem) {
        previousItem.focus();
      }
    };

    return (
      <>
        {userDetails && (
          <ProfilePopupContainer className="animate__animated animate__fadeIn animate__faster">
            <div className="d-flex align-items-start">
              {renderAvatar()}
              <div className="user-details">
                <div className="user-name" title={userDetails.fullname}>
                  {userDetails.fullname || STRINGS.NO_NAME}
                </div>
                <div className="user-email" title={userDetails.email}>
                  {userDetails.email}
                </div>
              </div>
            </div>

            <div className="profile-menu-options">
              <ul role="menu">
                {profileMenuOptions.map((item, index) => (
                  <li
                    className="d-flex align-items-center"
                    title={item.text}
                    key={index}
                    id={
                      item.text === STRINGS.LOGOUT
                        ? 'logout-id'
                        : item.text === STRINGS.HELP
                        ? 'help-support-id'
                        : item.text === STRINGS.VIEW_PROFILE
                        ? 'view-profile-id'
                        : undefined
                    }
                    onClick={() => handleProfileMenuItemClick(item)}
                    onKeyDown={(event) =>
                      handleProfileMenuItemKeyDown(event, item)
                    }
                    role="menuitem"
                    aria-label={item.text || ''}
                    tabIndex={0}
                  >
                    <Icon
                      icon={item.icon}
                      width={20}
                      height={20}
                      stroke={
                        item.icon === 'log_out_01' &&
                        `${colorState.text.danger.primary}`
                      }
                    />
                    <p
                      style={{
                        color: `${
                          item.text === STRINGS.LOGOUT &&
                          `${colorState.text.danger.primary}`
                        } `
                      }}
                    >
                      {item.text}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </ProfilePopupContainer>
        )}
      </>
    );
  };
  const history = useHistory();

  return (
    <TopNavWrapper
      id="top-nav"
      role="navigation"
      aria-label="Top Navigation"
      tabIndex={0}
    >
      <TopNavigation
        showNotification={false}
        avatarContainerRole={'listbox'}
        avatarRole={'option'}
        showBadge={renderBadge()}
        showAvatar={renderAvatar()}
        // showNotificationPopup={false}
        showProfilePopup={renderProfilePopup()}
        // logoSrc={MainLogoImg}
        // logoWidth={'auto'}
        // logoHeight={'100%'}
        handleMenuToggle={handleMenuToggle}
        humburgerMenu={true}
        navigationBgColor={`${colorState.surface.default['topnav-bg-dark']}`}
      >
        <img
          src={title_logo}
          // Here the onclick is used to set the active item to the first item in the list. I tried this inside the Link tag but it didn't work.
          onClick={() => {
            dispatch(setSelectedTab({ selectedTab: '' }));
            dispatch(setSelectedClosure({ selectedClosure: null }));
            setActiveItem(items[0]);
            history.push('/');
          }}
          className="cursor-pointer"
          alt={`${client_name} Logo`}
          width={180}
          loading="lazy"
        />
        <div className="training-text">
          - {isProdInstance ? STRINGS.LIVE : STRINGS.TRAINING}
        </div>
        <MenuWrapper>
          <ul role="menu">
            {items.map((item, index: number) => {
              if (!getPermissionForTab(item.link, userDetails))
                return <div key={index}></div>;
              return (
                <li
                  key={index}
                  className={activeItem?.link === item.link ? 'active' : ''}
                  onClick={() => {
                    if (item.name === 'Admin Guide') {
                      window.open(adminGuide ?? item.link, '_blank');
                    } else {
                      handleItemClick(item);
                    }
                  }}
                  role="menuitem"
                  aria-current={
                    activeItem?.link === item.link ? 'page' : undefined
                  }
                  aria-label={item.name || ''}
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleItemClick(item);
                    }
                  }}
                >
                  <Link
                    to={
                      item.name === 'Admin Guide'
                        ? window.location.pathname
                        : item.link
                    }
                    tabIndex={-1}
                  >
                    <>
                      {activeItem?.link === item.link ? (
                        <img src={item.activeSrc} alt="" loading="lazy" />
                      ) : (
                        <img src={item.src} alt="" loading="lazy" />
                      )}
                      <b className="ms-1">{item.name}</b>
                    </>
                  </Link>
                </li>
              );
            })}
          </ul>
        </MenuWrapper>

        {showOnMobile && (
          <MobileMenuWrapper>
            <div className="menu-close">
              <Icon icon="close" stroke="black" onClick={handleMenuClose} />
            </div>
            <ul>
              {items.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className={activeItem?.link === item.link ? 'active' : ''}
                  onClick={() => handleItemClick(item)}
                >
                  <li>
                    {activeItem?.link === item.link ? (
                      <img src={item.activeSrc} alt="" loading="lazy" />
                    ) : (
                      <img src={item.src} alt="" loading="lazy" />
                    )}
                    <b className="ms-3">{item.name}</b>
                  </li>
                </Link>
              ))}
            </ul>
          </MobileMenuWrapper>
        )}
      </TopNavigation>
    </TopNavWrapper>
  );
};

export default TopNav;
