import React from 'react';
import { Modal, Button } from '../../Universal/NovusDSImports';
import { removeShapeData } from '../../Store/reducers/Shapes';
import { useReduxDispatch } from '../../Store/reduxHooks';
import { ConfirmationModalProps } from './shapes.types';
import { STRINGS } from '../../Constants/ConstantStrings';
import { PopupConfirmStyles } from '../globalStyles';
import { btnStyles } from '../../Universal/NovusDSImports/variants';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  title,
  showHeader,
  closeModal,
  postModalClose,
  editModeData,
  searchFilterValue
}) => {
  const dispatch = useReduxDispatch();

  const onSubmit = () => {
    const { id } = editModeData;

    dispatch(
      removeShapeData({
        polygonId: id,
        params: { ordering: '-modified, id', limit: 'all', search: searchFilterValue }
      })
    );
    postModalClose();
  };

  return (
    <PopupConfirmStyles>
      <h2>{STRINGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_POLYGON}?</h2>
      <div className="footer">
        <Button onClick={postModalClose} {...btnStyles.default}>
          {STRINGS.CANCEL}
        </Button>
        <Button onClick={onSubmit} {...btnStyles.danger}>
          {STRINGS.YES_DELETE}
        </Button>
      </div>
    </PopupConfirmStyles>
  );
};

export default Modal(ConfirmationModal);
