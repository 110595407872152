import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { emailValidation } from '../../CommonUtilities/CommonUtilities';
import {
  emailVerify,
  resetPasswordStoreValues
} from '../../Store/reducers/ForgotPassword';
import { useReduxDispatch } from '../../Store/reduxHooks';
import { colorState } from '../../Universal/Foundation';
import { Button, Icon, Input } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  inputStyles
} from '../../Universal/NovusDSImports/variants';
import { ErrorMessage, FlexCenter, FormContainer } from './styles';
import { STRINGS } from '../../Constants/ConstantStrings';
import { RootState } from '../../store';

interface ForgotPasswordPropType {
  goBack: () => void;
}

export default function ForgotPassword(props: ForgotPasswordPropType) {
  const dispatch = useReduxDispatch(),
    emailVerified = useSelector(
      (state: RootState) => state.ForgotPassword.emailVerified
    ),
    message = useSelector(
      (state: RootState) => state.ForgotPassword.onPasswordUpdateMessage
    ),
    [email, setEmail] = useState<string>(''),
    [inValidEmailFormat, setInvalidEmailFormat] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(resetPasswordStoreValues());
    };
  }, [dispatch]);

  const onSuccess = () => {
    setInvalidEmailFormat(false);

    if (emailValidation(email)) {
      const payload = {
        email
      };
      dispatch(emailVerify(payload));
    } else {
      setInvalidEmailFormat(true);
    }
  };

  return (
    <div className="d-flex flex-column flex-grow-1">
      {emailVerified ? (
        <FormContainer>
          <h1>{STRINGS.CHECK_YOUR_EMAIL}</h1>
          <p className="text-description">
            {STRINGS.SUCCESSFUL_RESET_PASSWORD_TEXT}
          </p>
          <div className="my-5 d-flex justify-content-center">
            <Icon
              icon="mail_01"
              stroke={`${colorState.icon.brand.primary}`}
              width={90}
              height={74}
            />
          </div>

          <Button
            className={'w-100'}
            onClick={props.goBack}
            {...btnStyles.primary}
          >
            <span className="me-1">{STRINGS.GO_TO_LOGIN}</span>
            <Icon icon="arrow_narrow_right" stroke="#ffffff" />
          </Button>
        </FormContainer>
      ) : (
        <FormContainer>
          <h1>{STRINGS.FORGOT_PASSWORD}</h1>
          <p className="text-description">{STRINGS.PASSWORD_RESET_TEXT}</p>
          <div className="mb-5 mt-3">
            <Input
              id="email"
              inputSize="lg"
              label="Email"
              autocomplete="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
              placeholder={STRINGS.ENTER_EMAIL}
              {...inputStyles}
            />
            {inValidEmailFormat ? (
              <ErrorMessage>{STRINGS.INVALID_EMAIL_FORMAT}.</ErrorMessage>
            ) : (
              !emailVerified &&
              message && <ErrorMessage>{`${message}.`}</ErrorMessage>
            )}
          </div>
          <Button
            className={'w-100'}
            onClick={onSuccess}
            {...btnStyles.primary}
            disabled={!email}
          >
            {STRINGS.SUBMIT}
          </Button>
          <FlexCenter className="flex-column mt-4">
            <Button
              onClick={props.goBack}
              {...btnStyles.tertiary}
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  props.goBack();
                }
              }}
              className="w-100"
            >
              <Icon
                icon="arrow_narrow_left"
                stroke={`${colorState.icon.brand.primary}`}
              />
              <span className="ms-1">{STRINGS.GO_BACK_TO_LOGIN_PAGE}</span>
            </Button>
          </FlexCenter>
        </FormContainer>
      )}
    </div>
  );
}
