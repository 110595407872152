import { createSlice } from '@reduxjs/toolkit';
import {
  CamerasReceivedAction,
  EventsReceivedAction,
  FetchCamerasAction,
  FetchEventsAction,
  FetchRestFacilitiesAction,
  MapFiltersInterface,
  RestFacilitiesReceivedAction
} from '../actions/MapFilters';

const initialState: MapFiltersInterface = {
  cameras: null,
  events: null,
  restFacilities: null,
  milePosts: null
};

const MapFiltersSlice = createSlice({
  name: 'MapFilters',
  initialState,
  reducers: {
    getCameras: (_state, _action: FetchCamerasAction) => {},
    camerasReceived: (state, action: CamerasReceivedAction) => {
      state.cameras = action.payload.cameras;
    },
    getRestFacilities: (_state, _action: FetchRestFacilitiesAction) => {},
    restFacilitiesReceived: (state, action: RestFacilitiesReceivedAction) => {
      state.restFacilities = action.payload.restFacilities;
    },
    getEvents: (_state, _action: FetchEventsAction) => {},
    eventsReceived: (state, action: EventsReceivedAction) => {
      state.events = action.payload.events;
    }
    // getMilePosts: (_state, _action: FetchMilePostsAction) => {},
    // milePostsReceived: (state, action: MilePostsReceivedAction) => {
    //   state.milePosts = action.payload.milePosts;
    // }
  }
});

export const {
  getCameras,
  camerasReceived,
  restFacilitiesReceived,
  getRestFacilities,
  eventsReceived,
  getEvents
  // milePostsReceived,
  // getMilePosts
} = MapFiltersSlice.actions;
export default MapFiltersSlice.reducer;
