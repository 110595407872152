import styled from 'styled-components';
import { colorState, spacing, typography } from '../../Universal/Foundation';
import { device } from '../../Universal/Foundation/breakpoints';

export const TableTitleWrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing.spacing_12}px;
    border-bottom: 1px solid ${colorState.border.default.secondary};

    @media ${device.mobileL} {
      flex-wrap: wrap;
    }

    .left-wrapper {
      display: flex;
      align-items: center;
      gap: 0.4em;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media ${device.mobileL} {
        padding-bottom: ${spacing.spacing_12}px;
      }

      h1 {
        font-weight: ${typography.weight.semibold};
        color: ${colorState.text.primary};
        font-size: ${typography.size.tl};
        margin-bottom: 0;
      }

      h2 {
        font-size: ${typography.size.tm};
        border-left: 2px solid ${colorState.text.primary};
        padding-left: 8px;
        color: ${colorState.text.secondary};
        margin-bottom: 0;
        overflow: hidden;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        white-space: nowrap;

        h1,
        h2 {
          font-weight: ${typography.weight.semibold};
        }
      }

      .right-wrapper {
        padding-top: 0px;

        @media ${device.mobileL} {
          padding-top: 20px;
        }
      }
    }
  }
`;
