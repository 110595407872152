import { useCallback, useEffect, useState } from 'react';

export const useTextTruncation = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [readMore, setReadMore] = useState('');
  const [messageElement, setMessageElement] = useState<HTMLDivElement>();

  // Proper way to handle ref changes in React. Refer https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const messageRef = useCallback((node) => {
    if (node !== null) {
      setMessageElement(node);
    }
  }, []);

  const handleResize = useCallback(() => {
    if (messageElement) {
      const isContentTruncated =
        messageElement.scrollHeight > messageElement.clientHeight;
      setIsTruncated(isContentTruncated);
      if (!isContentTruncated) {
        setIsExpanded(false);
      }
      setReadMore(isContentTruncated ? 'Read More' : '');
    }
  }, [messageElement]);

  useEffect(() => {
    handleResize();
    if (document.fonts?.onloadingdone) {
      document.fonts.onloadingdone = () => {
        handleResize();
      };
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, isTruncated, messageElement]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setReadMore(isExpanded ? 'Read More' : 'Read Less');
  };
  return { isExpanded, isTruncated, messageRef, readMore, toggleExpand };
};
