import { Suspense, lazy, useState } from 'react';
import Grid from '../../SharedComponets/Grid';
import { resetPasswordStoreValues } from '../../Store/reducers/ForgotPassword';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import {
  ArcadisFooterLogoImg,
  LoginBakgroundImg
} from '../../Universal/Assets';
import LoginForm from './LoginForm';
import { BacgroundImageView, GridContainer, PageContainer } from './styles';
import { STRINGS } from '../../Constants/ConstantStrings';
import { resetLoginStoreValues } from '../../Store/reducers/Login';
import { RootState } from '../../store';

const ForgotPassword = lazy(() => import('./ForgotPassword'));

export default function Login() {
  const dispatch = useReduxDispatch(),
    [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);

  const onForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const { client_name, login_logo } = useReduxSelector(
    (state: RootState) => state.ClientInfo
  );

  const goBack = () => {
    dispatch(resetPasswordStoreValues());
    dispatch(resetLoginStoreValues());
    setIsForgotPassword(false);
  };

  return (
    <PageContainer>
      <BacgroundImageView>
        <img src={LoginBakgroundImg} alt="login-img" loading="lazy" />
      </BacgroundImageView>
      <GridContainer className="d-flex flex-column justify-content-between">
        <div className="outer-login-grid">
          <Grid className="login-grid">
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <img
                  src={login_logo}
                  alt={`${client_name} Logo`}
                  id="logo-img"
                  loading="lazy"
                />
                <>
                  <Suspense fallback={<></>}>
                    {isForgotPassword ? (
                      <ForgotPassword goBack={goBack} />
                    ) : (
                      <LoginForm onClick={onForgotPassword} />
                    )}
                  </Suspense>
                </>
              </div>
            </div>
          </Grid>
        </div>
        <div className="footer" id="footer">
          <div className="left flex-wrap">
            <div className="d-flex flex-wrap">
              <div className="d-flex align-items-center divider">
                <span>{STRINGS.ALERT_NOW_IS_A_PRODUCT_OF} </span>
                <img
                  src={ArcadisFooterLogoImg}
                  alt="arcadis-logo"
                  className="ms-1"
                  loading="lazy"
                />
              </div>
              <span className="ms-3">&copy; 2024 All Rights Reserved</span>
            </div>
          </div>
          <div className="right">
            <span>{STRINGS.FOR_MORE_DETAILS_VISIT} :</span>
            <a href="https://traveliq.co/" target="_blank" rel="noreferrer">
              <span className="link-text">&nbsp;www.traveliq.co</span>
            </a>
          </div>
        </div>
      </GridContainer>
    </PageContainer>
  );
}
