import React from 'react';
import { StyledRightSidePanel } from './styles';
import { SidePanelProps } from './helpers';
import { Icon } from '../../Universal/NovusDSImports';

const RightSidePanel: React.FC<SidePanelProps> = ({
  header,
  onClose,
  body,
  fetchMessagesStyles
}) => {
  const closeRightSidePanel = (props: any) => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledRightSidePanel
      id="right-panel"
      fetchMessagesStyles={fetchMessagesStyles}
    >
      <div className="panel-header">
        <h1>{header}</h1>
        <Icon
          icon="close"
          stroke={'white'}
          className="close-icon"
          onClick={closeRightSidePanel}
          width={'20px'}
          height={'20px'}
        />
      </div>
      <div className="panel-body">{body}</div>
    </StyledRightSidePanel>
  );
};

export default RightSidePanel;
