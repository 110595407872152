import React from 'react';
import { Avatar, Modal, Button } from '../../Universal/NovusDSImports';
import { useDispatch } from 'react-redux';
import {
  editAdminUser,
  removeAdminUser
} from '../../Store/reducers/AdminUsers';
import {
  btnStyles,
  statusVariants
} from '../../Universal/NovusDSImports/variants';
import { ConfirmationModalProps } from './adminUsers.types';
import { STRINGS } from '../../Constants/ConstantStrings';
import { PopupConfirmStyles, UserGroupProfileSection } from '../globalStyles';
import { useReduxSelector } from '../../Store/reduxHooks';
import { RootState } from '../../store';
import {
  getUserColor,
  getUserInitials
} from '../../CommonUtilities/CommonUtilities';

const ConfirmationPopupModal: React.FC<ConfirmationModalProps> = ({
  postModalClose,
  modalType,
  editData,
  searchFilterValue
}) => {
  const dispatch = useDispatch();

  const isSubmitting = useReduxSelector(
    (state: RootState) => state.AdminUsers.isSubmittingAdmin
  );
  const onSubmit = () => {
    const { id, email, groups } = editData;
    const newData = {
      email,
      enabled: modalType === 'Enable',
      group_ids: groups.map((group: { id: number; name: string }) => group.id)
    };

    if (modalType === 'Remove') {
      dispatch(
        removeAdminUser({
          userId: id,
          params: {
            ordering: 'first_name, email, id',
            limit: 'all',
            search: searchFilterValue
          }
        })
      );
      postModalClose();
    } else {
      dispatch(
        editAdminUser({
          userId: id,
          data: newData,
          params: {
            ordering: 'first_name, email, id',
            limit: 'all',
            search: searchFilterValue
          }
        })
      );
      postModalClose();
    }
  };

  return (
    <>
      {modalType === 'Remove' ? (
        <PopupConfirmStyles>
          <h2>{STRINGS.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER}</h2>
          <div className="footer">
            <Button
              onClick={postModalClose}
              type="button"
              {...btnStyles.default}
              disabled={isSubmitting}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button onClick={onSubmit} {...btnStyles.danger}>
              {isSubmitting ? 'Removing...' : STRINGS.YES_REMOVE}
            </Button>
          </div>
        </PopupConfirmStyles>
      ) : (
        <PopupConfirmStyles>
          <UserGroupProfileSection className="d-flex align-items-center">
            <Avatar
              size="md"
              src={editData?.profile_image}
              username={getUserInitials(editData?.fullname)}
              userNameColor={getUserColor(editData?.fullname)}
              isDefault={false}
            />
            <div className="user-details">
              <p className="text-name">
                {editData?.first_name && editData?.first_name}{' '}
                {editData?.last_name && editData?.last_name}
              </p>
              <p className="text-email">{editData?.email && editData?.email}</p>
            </div>
          </UserGroupProfileSection>
          <div style={statusVariants.warning} className="status-message">
            {modalType === 'Disable'
              ? STRINGS.USER_DISABLED_AND_WILL_NOT_BE_ABLE_TO_LOGIN_TO_THE_APPLICATION_ANYMORE
              : STRINGS.AFTER_ENABLEING_THE_USER_WILL_BE_ABLE_TO_ACCESS_HIS_ACCOUNT}
          </div>
          <div className="footer">
            <Button
              onClick={postModalClose}
              disabled={isSubmitting}
              {...btnStyles.default}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button
              onClick={onSubmit}
              disabled={isSubmitting}
              {...btnStyles.warning}
            >
              {modalType === 'Enable'
                ? isSubmitting
                  ? STRINGS.ENABLING
                  : STRINGS.YES_ENABLE
                : isSubmitting
                ? STRINGS.DISABLING
                : STRINGS.YES_DISABLE}
            </Button>
          </div>
        </PopupConfirmStyles>
      )}
    </>
  );
};

export default Modal(ConfirmationPopupModal);
