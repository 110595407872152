export const spacing = {
  spacing_0: 0,
  spacing_2: 2,
  spacing_4: 4,
  spacing_6: 6,
  spacing_8: 8,
  spacing_10: 10,
  spacing_12: 12,
  spacing_16: 16,
  spacing_20: 20,
  spacing_22: 22,
  spacing_24: 24,
  spacing_28: 28,
  spacing_32: 32,
  spacing_36: 36,
  spacing_40: 40,
  spacing_48: 48,
  spacing_64: 64,
  spacing_80: 80,
  spacing_96: 96,
  spacing_112: 112,
  spacing_128: 128
};
