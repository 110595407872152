interface SvgIconProps {
  successSvg?: boolean;
  failedSvg?: boolean;
  className?: any;
}

const SvgComponent = (props: SvgIconProps) => {
  const { successSvg, failedSvg, className } = props;

  const SuccessSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        className={className}
      >
        <path
          d="M6 0C7.18669 0 8.34673 0.351894 9.33342 1.01118C10.3201 1.67047 11.0892 2.60754 11.5433 3.7039C11.9974 4.80026 12.1162 6.00666 11.8847 7.17054C11.6532 8.33443 11.0818 9.40353 10.2426 10.2426C9.40353 11.0818 8.33443 11.6532 7.17054 11.8847C6.00666 12.1162 4.80026 11.9974 3.7039 11.5433C2.60754 11.0892 1.67047 10.3201 1.01118 9.33342C0.351894 8.34673 0 7.18669 0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0V0Z"
          fill="#68BA97"
        />
        <path
          d="M3.54199 5.80713L5.65894 7.72412L9.22485 3.70312"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    );
  };

  const FailedSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 12 12"
        fill="none"
        className={className}
      >
        <path
          d="M6 0C7.18669 0 8.34673 0.351894 9.33342 1.01118C10.3201 1.67047 11.0892 2.60754 11.5433 3.7039C11.9974 4.80026 12.1162 6.00666 11.8847 7.17054C11.6532 8.33443 11.0818 9.40353 10.2426 10.2426C9.40353 11.0818 8.33443 11.6532 7.17054 11.8847C6.00666 12.1162 4.80026 11.9974 3.7039 11.5433C2.60754 11.0892 1.67047 10.3201 1.01118 9.33342C0.351894 8.34673 0 7.18669 0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0V0Z"
          fill="#E57373"
        />
        <path
          d="M8.89703 3.68703L8.31303 3.10303L6.00303 5.41603L3.68703 3.10303L3.10303 3.68703L5.41603 6.00303L3.10303 8.31303L3.68703 8.89703L6.00303 6.58403L8.31603 8.89703L8.90003 8.31303L6.58403 6.00303L8.89703 3.68703Z"
          fill="white"
        />
      </svg>
    );
  };

  const NormalSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 12 12"
        fill="none"
        className={className}
      >
        <path
          d="M6 0C7.18669 0 8.34673 0.351894 9.33342 1.01118C10.3201 1.67047 11.0892 2.60754 11.5433 3.7039C11.9974 4.80026 12.1162 6.00666 11.8847 7.17054C11.6532 8.33443 11.0818 9.40353 10.2426 10.2426C9.40353 11.0818 8.33443 11.6532 7.17054 11.8847C6.00666 12.1162 4.80026 11.9974 3.7039 11.5433C2.60754 11.0892 1.67047 10.3201 1.01118 9.33342C0.351894 8.34673 0 7.18669 0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0V0Z"
          fill="#212529"
        />
        <path
          d="M8.89703 3.68703L8.31303 3.10303L6.00303 5.41603L3.68703 3.10303L3.10303 3.68703L5.41603 6.00303L3.10303 8.31303L3.68703 8.89703L6.00303 6.58403L8.31603 8.89703L8.90003 8.31303L6.58403 6.00303L8.89703 3.68703Z"
          fill="white"
        />
      </svg>
    );
  };

  return (
    <>
      {successSvg && SuccessSvg()}
      {failedSvg && FailedSvg()}
      {!successSvg && !failedSvg && NormalSvg()}
    </>
  );
};

export default SvgComponent;
