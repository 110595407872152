import { Icon, Modal } from '../../Universal/NovusDSImports';
import dayjs from 'dayjs';
import { STRINGS } from '../../Constants/ConstantStrings';
import { PopupWindow } from '../globalStyles';
import { useReduxSelector } from '../../Store/reduxHooks';
import { RootState } from '../../store';

const UserDetailsPopUpModal = (props: any) => {
  const { selectedClosure, userDetails, setSelectedUser } = props;

  const questionsclosuresData = useReduxSelector(
    (state: RootState) => state.Questions.closuresQuestionsData
  );

  return (
    <>
      <PopupWindow>
        <div className="header">
          <h5>{STRINGS.USER_DETAILS}</h5>
          <Icon
            icon="close"
            width={'16px'}
            height={'16px'}
            onClick={() => {
              setSelectedUser(null);
            }}
            className="close-icon"
          >
            {STRINGS.CLOSE}
          </Icon>
        </div>
        <div className="body">
          <h6>{selectedClosure.wea_short_msg_english}</h6>
          <div className="row latitude-longitude-section mx-0">
            <div className="col-sm-6 pe-1 ps-0">
              <label>{STRINGS.LATITUDE}</label>
              <p>{userDetails?.location?.coordinates[0]}</p>
            </div>
            <div className="col-sm-6 ps-1 pe-0">
              <label>{STRINGS.LONGITUDE}</label>
              <p>{userDetails?.location?.coordinates[1]}</p>
            </div>
          </div>

          <div className="group mt-3">
            <label>{STRINGS.PHONE}</label>
            <p>{userDetails?.phone_number}</p>
          </div>

          <div className="group">
            <label>{STRINGS.LAST_CONTACTED_ON}</label>
            <p>
              {userDetails?.last_contacted_time
                ? dayjs(userDetails?.last_contacted_time)?.format(
                    'MM/DD/YYYY  hh:mm A'
                  )
                : 'N/A'}
            </p>
          </div>
          {questionsclosuresData &&
            questionsclosuresData.map((question: any) => {
              return (
                <div className="group">
                  <label>{question.display_name}</label>
                  <p>
                    {userDetails.answers && userDetails.answers[question.id]
                      ? userDetails.answers && userDetails.answers[question.id]
                      : 'N/A'}
                  </p>
                </div>
              );
            })}
        </div>
      </PopupWindow>
    </>
  );
};

export default Modal(UserDetailsPopUpModal);
