import styled from 'styled-components';
import { colorState, radius, typography } from '../../Universal/Foundation';

interface MessagesProps {
  fetchMessagesStyles?: any;
}

export const StyledRightSidePanel = styled.div<MessagesProps>`
  display: flex;
  flex-direction: column;
  height: 100%;

  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) =>
      props.fetchMessagesStyles
        ? colorState.surface.default.primary
        : colorState.surface.brand['primary-dark']};

    border-top-left-radius: ${radius.radius_12}px;
    border-top-right-radius: ${radius.radius_12}px;
    padding: 12px 20px;
    border-bottom: ${(props) =>
      props.fetchMessagesStyles &&
      `1px solid ${colorState.border.default.primary}`};

    h1 {
      margin: 0;
      font-size: ${typography.size.tm};
      font-weight: ${typography.weight.semibold};
      text-overflow: ellipsis;
      color: ${(props) =>
        props.fetchMessagesStyles
          ? colorState.text.primary
          : colorState.text['primary-inverse']};
    }

    .close-icon {
      cursor: pointer;
      path {
        stroke: ${(props) =>
          props.fetchMessagesStyles && colorState.icon.default.secondary};
      }
    }
  }

  .panel-body {
    background-color: white;
    flex: 1;
    border-bottom-left-radius: ${radius.radius_12}px;
    border-bottom-right-radius: ${radius.radius_12}px;
    overflow-y: auto;
  }
`;
