import React from 'react';
import { StyledCard } from './styles';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function Card(props: Props) {
  return (
    <StyledCard className={props?.className} {...props}>
      {props?.children}
    </StyledCard>
  );
}
