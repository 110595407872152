import React, { useEffect, useRef, useState } from 'react';
import { InfoWindow } from '@react-google-maps/api';
import { PopupWindow } from '../../globalStyles';
import { Button, Icon } from '../../../Universal/NovusDSImports';
import { STRINGS } from '../../../Constants/ConstantStrings';
import { useClickOutside } from '../../../Hooks/useClickOutside';
import ReactPlayer from 'react-player';
import {
  btnStyles,
  confirmPopupModalStyles
} from '../../../Universal/NovusDSImports/variants';
import { showError } from '../../../CommonUtilities/CommonUtilities';
import { CAMERA_SECURE_TOKEN_API } from '../../../settings';
import { colorState } from '../../../Universal/Foundation';
import SnapshotModal from '../SnapshotModal';

interface CameraTooltipProps {
  camera: any;
  setSelectedCamera: Function;
  postModalClose: Function;
  setMapCenter: Function;
  setZoomLevel: Function;
  markerOpenRef: React.RefObject<any>;
}

const CameraTooltip: React.FC<CameraTooltipProps> = (props) => {
  const {
    camera,
    setSelectedCamera,
    // markerOpenRef,
    setMapCenter,
    postModalClose,
    setZoomLevel
  } = props;

  const MarkerExcludedRef = useRef(null);
  const markerOpenRef = useRef(null);
  const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(true);
  const [secureToken, setSecureToken] = useState<string | null>(null);
  const [showImageInFullScreen, setShowImageInFullScreen] =
    useState<boolean>(false);

  useClickOutside(
    markerOpenRef,
    () => {
      if (!showImageInFullScreen) {
        setSelectedCamera(null);
        postModalClose(false);
      }
    },
    [MarkerExcludedRef]
  );

  useEffect(() => {
    setZoomLevel(16);
    setMapCenter({
      lat: camera.latitude,
      lng: camera.longitude
    });
  }, [camera, setSelectedCamera]);

  useEffect(() => {
    if (showVideoPlayer && camera?.isVideoAuthenticationRequired) {
      getCameraToken();
    } else if (showVideoPlayer) {
      setSecureToken('');
    }
  }, [showVideoPlayer]);

  const getCameraToken = async () => {
    const { token, cameraId } = camera;

    await fetch(CAMERA_SECURE_TOKEN_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        cameraId
      })
    })
      .then((response) => response.json())
      .then((data) => setSecureToken(data))
      .catch(() => {
        showError(STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
      });
  };
  const snapShotCameraStyles = {
    content: {
      minWidth: '80%',
      height: '100%'
    }
  };

  return (
    <>
      <InfoWindow
        onCloseClick={() => setSelectedCamera(null)}
        position={{
          lat: camera.latitude,
          lng: camera.longitude
        }}
      >
        <PopupWindow ref={markerOpenRef}>
          <div className="header">
            <h5>Camera</h5>
            <Icon
              icon="close"
              width={'16px'}
              height={'16px'}
              onClick={() => {
                if (camera) setSelectedCamera(null);
              }}
              className="close-icon"
            >
              {STRINGS.CLOSE}
            </Icon>
          </div>
          <div className="body">
            <h6>{camera.locationName}</h6>
            {camera?.videoHlsUri && (
              <div className="d-flex justify-content-center">
                <Button
                  disabled={secureToken === null}
                  onClick={() => {
                    if (!showVideoPlayer) {
                      setSecureToken(null);
                    }
                    setShowVideoPlayer((prev) => !prev);
                  }}
                  {...btnStyles.secondary}
                >
                  {showVideoPlayer ? 'Show Video' : 'Hide Video'}
                </Button>
              </div>
            )}

            {showVideoPlayer ? (
              camera?.snapshotStatus !== 'Unavailable' ? (
                <div className="group mt-3">
                  <img
                    onClick={() => {
                      setShowImageInFullScreen(true);
                    }}
                    src={camera.snapshotAddress}
                    alt={camera.locationName}
                    width="320px"
                    height="240px"
                  />
                </div>
              ) : (
                <div className="group mt-3 no-camera-feed">
                  <Icon
                    icon="alert_circle"
                    width="80px"
                    height="80px"
                    stroke={`${colorState.icon.default.disabled}`}
                  />
                  <p>No live camera feed at this time</p>
                </div>
              )
            ) : (
              ''
            )}

            {!showVideoPlayer && (
              <div className="group mt-3">
                <ReactPlayer
                  url={`${camera.videoHlsUri}${secureToken}`}
                  className="react-player"
                  playing={true}
                  controls
                  width="320px"
                  height="240px"
                />
              </div>
            )}
          </div>
        </PopupWindow>
      </InfoWindow>

      <SnapshotModal
        isOpen={showImageInFullScreen}
        onClose={() => setShowImageInFullScreen(false)}
        postModalClose={() => setShowImageInFullScreen(false)}
        selectedCamera={camera}
        markerOpenRef={markerOpenRef}
        showHeader={true}
        title={camera.locationName}
        {...confirmPopupModalStyles}
        showBorderTop={false}
        isBodyPaddingNone={true}
        style={snapShotCameraStyles}
      />
    </>
  );
};

export default CameraTooltip;
