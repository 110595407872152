import { NoDataProps } from './types';
import { NoDataStyles } from './styles';

const NoDataFound: React.FC<NoDataProps> = ({
  src,
  description,
  alt,
  subMsgDescription,
  btn,
  imgWidth,
  imgHeight,
  isAbsolute
}) => {
  return (
    <NoDataStyles isAbsolute={isAbsolute}>
      <img
        src={src}
        alt={alt}
        width={imgWidth}
        height={imgHeight}
        loading="lazy"
      />
      {description && <h2 className="text-description">{description}</h2>}
      {subMsgDescription && (
        <p className="sub-message-description">{subMsgDescription}</p>
      )}
      {btn}
    </NoDataStyles>
  );
};

export default NoDataFound;
