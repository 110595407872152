import { createSlice } from '@reduxjs/toolkit';

import {
  GetClientInfoType,
  ClientInfoInterface,
  FetchClientInfoType
} from '../actions/ClientInfo';

const initialState: ClientInfoInterface = {
  client_name: 'Alert Now',
  title_logo: null,
  login_logo: null,
  favicon: null,
  clientInfoReceived: false,
  admin_guide: null,
  state_boundary: null
};

export const ClientInfoSlice = createSlice({
  name: 'ClientInfo',
  initialState,
  reducers: {
    getClientInfoFetch: (_state, _action: FetchClientInfoType) => {},
    getClientInfoSuccess: (state, action: GetClientInfoType) => {
      const { client_name, title_logo, login_logo, favicon, admin_guide, state_boundary } =
        action.payload.clientInfo;

      state.client_name = client_name;
      state.title_logo = title_logo;
      state.login_logo = login_logo;
      state.favicon = favicon;
      state.admin_guide = admin_guide;
      state.state_boundary = state_boundary;
      state.clientInfoReceived = true;
    }
  }
});

export const { getClientInfoFetch, getClientInfoSuccess } =
  ClientInfoSlice.actions;

export default ClientInfoSlice.reducer;
