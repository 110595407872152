import Cookies from 'js-cookie';
import {
  AdminGuideIcon,
  AdminUsersIcon,
  ClosuresIcon,
  QuestionsIcon,
  ReportsIcon,
  ShapesIcon,
  AdminGuideActiveIcon,
  AdminUsersActiveIcon,
  ClosuresActiveIcon,
  QuestionsActiveIcon,
  ReportsActiveIcon,
  ShapesActiveIcon,
  ManageCertificate,
  ManageCertificateActiveIcon
} from '../Universal/Assets';
import { NavbarMenuItem } from '../CommonTypes';

export { default as PAStateBoundary } from './Pennsylvania_State_Boundary.json';

export const clearJwtCookie = () => {
  Cookies.remove('access');
  Cookies.remove('refresh');
  Cookies.remove('isValidated');
};

export const clearJwtLocalStorage = () => {
  localStorage.removeItem('isLoggedIn');
  if (localStorage.getItem('selectedClosure'))
    localStorage.removeItem('selectedClosure');
  if (localStorage.getItem('selectedTab'))
    localStorage.removeItem('selectedTab');
};

export const api_prefix = '/api/vl/';

export const maxVerticesInClosure = 100;
export const minVerticesInClosure = 3;

export const PA_BOUNDARY = {
  north: 43.911776256500175,
  south: 37.42037948979245,
  west: -81.22109335488982,
  east: -73.82729452676482
};

export const navbarMenuItems: NavbarMenuItem[] = [
  {
    name: 'Closures',
    src: ClosuresIcon,
    activeSrc: ClosuresActiveIcon,
    link: '/ClosureEvent',
    page: 'Closures'
  },
  {
    name: 'Question Library',
    src: QuestionsIcon,
    activeSrc: QuestionsActiveIcon,
    link: '/QuestionLibrary',
    page: 'Questions'
  },
  {
    name: 'Shapes Library',
    src: ShapesIcon,
    activeSrc: ShapesActiveIcon,
    link: '/ShapesLibrary',
    page: 'Shapes'
  },
  {
    name: 'Manage Users',
    src: AdminUsersIcon,
    activeSrc: AdminUsersActiveIcon,
    link: '/ManageUsers',
    page: 'AdminUsers'
  },
  {
    name: 'After Action Reports',
    src: ReportsIcon,
    activeSrc: ReportsActiveIcon,
    link: '/AfterActionReports',
    page: 'Reports'
  },
  {
    name: 'Resources',
    src: ReportsIcon,
    activeSrc: ReportsActiveIcon,
    link: '/Resources',
    page: 'Resources'
  },
  {
    name: 'Admin Guide',
    src: AdminGuideIcon,
    activeSrc: AdminGuideActiveIcon,
    link: '/AdminGuide',
    page: 'AdminGuide',
    isHidden: true
  },
  {
    name: 'Manage Certificate',
    src: ManageCertificate,
    activeSrc: ManageCertificateActiveIcon,
    link: '/ManageCertificate',
    page: 'Certificate'
  }
];

export const MapFilterIntervalTime = 10000;

export const monthsListDropdownOptions = [
  { value: '0', label: 'January' },
  { value: '1', label: 'February' },
  { value: '2', label: 'March' },
  { value: '3', label: 'April' },
  { value: '4', label: 'May' },
  { value: '5', label: 'June' },
  { value: '6', label: 'July' },
  { value: '7', label: 'August' },
  { value: '8', label: 'September' },
  { value: '9', label: 'October' },
  { value: '10', label: 'November' },
  { value: '11', label: 'December' }
];

export const getYearDropdownOptions = (
  startYear: number = 2020,
  endYear: number = new Date().getFullYear()
) => {
  const yearListDropdownOptions: Array<{ value: number; label: string }> = [];
  for (let year = startYear; year <= endYear; year++) {
    yearListDropdownOptions.push({ value: year, label: year.toString() });
  }
  return yearListDropdownOptions;
};
