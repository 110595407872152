import { createSlice } from '@reduxjs/toolkit';
import {
  FetchMapData,
  MapDataReceived,
  ResourcesStateInterface
} from '../actions/Resources';

const initialState: ResourcesStateInterface = {
  mapData: null,
  isFetchingMapData: false
};

export const ResourcesSlice = createSlice({
  name: 'Resources',
  initialState,
  reducers: {
    fetchMapData: (state, _action: FetchMapData) => {
      state.isFetchingMapData = true;
    },
    mapDataReceived: (state, action: MapDataReceived) => {
      state.mapData = action.payload.data;
      state.isFetchingMapData = false;
    },
    exportExcel: (_state, _action) => {}
  }
});

export const { fetchMapData, mapDataReceived, exportExcel } =
  ResourcesSlice.actions;

export default ResourcesSlice.reducer;
