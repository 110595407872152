import { useCallback, useEffect, useState } from 'react';
import { STRINGS } from '../../Constants/ConstantStrings';
import Card from '../../SharedComponets/Card';
import TableTitle from '../../SharedComponets/TableTitle';
import { colorState } from '../../Universal/Foundation';
import {
  Button,
  Icon,
  Input,
  SingleDatePicker,
  TextArea
} from '../../Universal/NovusDSImports';
import {
  btnStyles,
  inputStyles,
  singleDatePickerStyles
} from '../../Universal/NovusDSImports/variants';
import { FormSegment } from '../globalStyles';
import * as yup from 'yup';
import { validateYupSchema } from '../../CommonUtilities/CommonUtilities';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCOGDetails, updateCOGDetails } from '../../Store/reducers/IPAWS';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { RootState } from '../../store';
import PALoader from '../../SharedComponets/PALoader';
import dayjs from 'dayjs';
import { ManageCertificate } from './styles';

const Certificate = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    cogDetails = useReduxSelector((state: RootState) => state.IPAWS.cogDetails),
    isFetchingCOGDetails = useReduxSelector(
      (state: RootState) => state.IPAWS.isFetchingCOGDetails
    ),
    isSubmitting = useReduxSelector(
      (state: RootState) => state.IPAWS.isSubmittingIPAWS
    ),
    dispatch = useReduxDispatch();

  useEffect(() => {
    if (currentUser?.user_permission_set?.role_names?.includes('IPAWS Admin')) {
      dispatch(getCOGDetails({}));
    }
  }, [currentUser]);

  const schema = yup.object().shape({
    logon_cog_id: validateYupSchema('text', '', true),
    logon_user: validateYupSchema('text', STRINGS.YUP.COG_NAME, true),
    url: validateYupSchema('text', STRINGS.YUP.URL, true),
    logon_certificate_expiry: validateYupSchema(
      'text',
      STRINGS.YUP.COG_CERTIFICATE_EXPIRY,
      true
    ),
    cap_cert: validateYupSchema('text', STRINGS.YUP.IPAWS_CERTIFICATE, true),
    cap_cert_1: validateYupSchema(
      'text',
      STRINGS.YUP.IPAWS_SUB_CERTIFICATE,
      true
    ),
    cap_key: validateYupSchema('text', STRINGS.YUP.IPAWS_KEY, true)
  });

  const getDefaultValues = useCallback(() => {
    return {
      logon_cog_id: cogDetails?.logon_cog_id,
      logon_user: cogDetails?.logon_user,
      url: cogDetails?.url,
      logon_certificate_expiry: cogDetails?.logon_certificate_expiry,
      cap_cert: '',
      cap_cert_1: '',
      cap_key: ''
    };
  }, [cogDetails]);

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    getValues,
    setValue
  } = methods;

  useEffect(() => {
    if (isEdit) {
      reset(getDefaultValues());
    }
  }, [isEdit]);

  const onSubmit = (data: any) => {
    const cap_key_encoded = window.btoa(data.cap_key),
      cap_cert_encoded = window.btoa(data.cap_cert),
      cap_cert_1_encoded = window.btoa(data.cap_cert_1);
    const payload = {
      cap_constants: {
        logon_cog_id: data.logon_cog_id,
        logon_user: data.logon_user,
        logon_certificate_expiry: data.logon_certificate_expiry,
        url: data.url
      },
      cap_key: cap_key_encoded,
      cap_cert: cap_cert_encoded,
      cap_cert_1: cap_cert_1_encoded
    };
    dispatch(
      updateCOGDetails({
        params: payload,
        onCompleteAction: () => setIsEdit(false)
      })
    );
  };

  return (
    <Card className="w-100 d-flex flex-column overflow-auto">
      <TableTitle title={'Manage Certificate'}></TableTitle>
      {isFetchingCOGDetails ? (
        <PALoader />
      ) : (
        <ManageCertificate>
          <div className="d-flex align-items-center gap-3 mb-3">
            <h2 className="py-10">Certificate Details</h2>
            {!isEdit && (
              <Button
                {...btnStyles.tertiary}
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                <Icon
                  icon="edit_02"
                  height={20}
                  width={20}
                  stroke={colorState.icon.brand.primary}
                />
                {STRINGS.EDIT}
              </Button>
            )}
          </div>
          {!isEdit ? (
            <div className="event-details-wrapper">
              <div className="event-details-data">
                <h3>{STRINGS.COG_ID}: </h3>
                <p>{cogDetails?.logon_cog_id}</p>
              </div>
              <div className="d-flex col-3 gap-3 justify-content-between">
                <div className="event-details-data">
                  <h3>{STRINGS.COG_NAME}:</h3>
                  <p>{cogDetails?.logon_user}</p>
                </div>
                <div className="event-details-data">
                  <h3>{STRINGS.COG_CERTIFICATE_EXPIRY}:</h3>{' '}
                  <p>{cogDetails?.logon_certificate_expiry}</p>
                </div>
              </div>
              <div className="event-details-data">
                <h3>{STRINGS.URL}:</h3> <p>{cogDetails?.url}</p>
              </div>
            </div>
          ) : (
            <FormProvider {...methods}>
              <FormSegment onSubmit={handleSubmit(onSubmit)} className="col-5">
                <section className="formset-fields">
                  <div className="form-group">
                    <Controller
                      name="logon_cog_id"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="logon_cog_id"
                          label={STRINGS.COG_ID}
                          type="text"
                          isMargin={false}
                          {...inputStyles}
                          asterisk
                          disabled
                          error={errors?.logon_cog_id}
                          hintText={errors?.logon_cog_id?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex gap-3">
                    <div className="form-group w-100">
                      <Controller
                        name="logon_user"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            id="logon_user"
                            label={STRINGS.COG_NAME}
                            type="text"
                            placeholder={STRINGS.ENTER_COG_NAME}
                            isMargin={false}
                            {...inputStyles}
                            asterisk
                            error={errors?.logon_user}
                            hintText={errors?.logon_user?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="form-group w-100">
                      <Controller
                        name="logon_certificate_expiry"
                        control={control}
                        render={() => (
                          <SingleDatePicker
                            selected={
                              new Date(
                                getValues('logon_certificate_expiry') || null
                              )
                            }
                            onChange={(date: any) => {
                              if (date)
                                setValue(
                                  'logon_certificate_expiry',
                                  dayjs(date).format('MM/DD/YYYY')
                                );
                            }}
                            onKeyDown={(event) => {
                              if (
                                event.key === 'Backspace' ||
                                event.key === 'Delete'
                              ) {
                                event.preventDefault();
                              }
                            }}
                            label={STRINGS.COG_CERTIFICATE_EXPIRY}
                            placeholder={STRINGS.ENTER_COG_CERTIFICATE_EXPIRY}
                            dateFormat={'MM/dd/yyyy'}
                            asterisk
                            {...inputStyles}
                            error={errors?.logon_certificate_expiry}
                            hintText={errors?.logon_certificate_expiry?.message}
                            {...singleDatePickerStyles}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <Controller
                      name="url"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="url"
                          label={STRINGS.URL}
                          type="text"
                          placeholder={STRINGS.ENTER_URL}
                          isMargin={false}
                          {...inputStyles}
                          asterisk
                          error={errors?.url}
                          hintText={errors?.url?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <Controller
                      control={control}
                      name="cap_key"
                      render={({ field }) => {
                        return (
                          <TextArea
                            {...field}
                            label={STRINGS.IPAWS_KEY}
                            placeholder={STRINGS.ENTER_IPAWS_KEY}
                            {...inputStyles}
                            asterisk
                            error={errors?.cap_key}
                            hintText={errors?.cap_key?.message}
                            className="mb-0"
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <Controller
                      control={control}
                      name="cap_cert"
                      render={({ field }) => {
                        return (
                          <TextArea
                            {...field}
                            label={STRINGS.IPAWS_CERTIFICATE}
                            placeholder={STRINGS.ENTER_IPAWS_CERTIFICATE}
                            {...inputStyles}
                            asterisk
                            error={errors?.cap_cert}
                            hintText={errors?.cap_cert?.message}
                            className="mb-0"
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <Controller
                      control={control}
                      name="cap_cert_1"
                      render={({ field }) => {
                        return (
                          <TextArea
                            {...field}
                            label={STRINGS.IPAWS_SUB_CERTIFICATE}
                            placeholder={STRINGS.ENTER_IPAWS_SUB_CERTIFICATE}
                            {...inputStyles}
                            asterisk
                            error={errors?.cap_cert_1}
                            hintText={errors?.cap_cert_1?.message}
                            className="mb-0"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <Button
                      type="submit"
                      {...btnStyles.primary}
                      disabled={!isDirty || isSubmitting}
                    >
                      {isSubmitting ? STRINGS.SUBMITTING : STRINGS.UPDATE}
                    </Button>
                    <Button
                      onClick={(e: any) => {
                        e.preventDefault();
                        setIsEdit(false);
                      }}
                      {...btnStyles.secondary}
                    >
                      {STRINGS.CANCEL}
                    </Button>
                  </div>
                </section>
              </FormSegment>
            </FormProvider>
          )}
        </ManageCertificate>
      )}
    </Card>
  );
};

export default Certificate;
