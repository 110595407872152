import styled from "styled-components";
// import { device } from "../../Universal/Foundation/breakpoints";
import { colorState, radius } from "../../Universal/Foundation";

export const StyledCard = styled.div`
  background-color: ${colorState.surface.default.primary};
  height: auto;
  border: 1px solid ${colorState.border.default.secondary};
  box-shadow: 0px 1px 2px 0px rgba(23, 23, 28, 0.06),
    0px 1px 3px 0px rgba(23, 23, 28, 0.1);
  border-radius: ${radius.radius_12}px;
  height: 100%;
`;
