const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '480px',
  mobileXL: '600px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1366px',
  laptopXL:'1600px',
  laptopXXL:'1900px',
  desktop: '2560px'
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  laptopXL: `(max-width: ${size.laptopXL})`,
  laptopXXL: `(max-width: ${size.laptopXXL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`
};
