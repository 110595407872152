import { all, fork } from 'redux-saga/effects';
import watchAdminUsers from './AdminUsers';
import watchClosureQuestions from './ClosureQuestions';
import watchClosures from './Closures';
import watchCommon from './Common';
import watchForgotPassword from './ForgotPassword';
import watchIPAWS from './IPAWS';
import watchLogin from './Login';
import watchMapFilters from './MapFilters';
import watchMessages from './Messages';
import watchPublicView from './PublicView';
import watchRegisteredUsers from './RegisteredUsers';
import watchResources from './Resources';
import watchShapes from './Shapes';
import watchClientInfo from './ClientInfo';

export default function* rootSaga() {
  yield all([
    fork(watchAdminUsers),
    fork(watchClosureQuestions),
    fork(watchClosures),
    fork(watchCommon),
    fork(watchForgotPassword),
    fork(watchIPAWS),
    fork(watchLogin),
    fork(watchMapFilters),
    fork(watchMessages),
    fork(watchPublicView),
    fork(watchRegisteredUsers),
    fork(watchResources),
    fork(watchShapes),
    fork(watchClientInfo)
  ]);
}
