import * as Sentry from '@sentry/react';
import { ENVIRONMENT, SENTRY_DSN } from './settings';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured'
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracePropagationTargets: [/^\//, '*'],
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0
});
