export const typography = {
  type: {
    primary: 'Poppins, sans-serif'
  },
  weight: {
    thin: '100',
    extralight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900'
  },
  size: {
    h1: '3rem', // 48px
    h2: '2.25rem', // 36px
    h3: '1.875rem', // 30px
    h4: '1.75rem', // 28px
    h5: '1.5rem', // 24px
    h6: '1.25rem', // 20px
    tl: '1.125rem', // 18px
    tm: '1rem', // 16px
    ts: '0.875rem', // 14px
    txs: '0.75rem', // 12px
    tdxs: '0.625rem' // 10px
  }
};
