import axios from 'axios';
import Cookies from 'js-cookie';
import {
  api_prefix,
  clearJwtCookie,
  clearJwtLocalStorage
} from '../Constants/constants';
import {
  safeDecodeJWTToken,
  showPopUp
} from '../CommonUtilities/CommonUtilities';
import { STRINGS } from '../Constants/ConstantStrings';
import { SECURE, isPublicView } from '../settings';

//==================== Axios Request interceptor =======================

axios.interceptors.request.use(
  (config: any) => {
    axios.defaults.headers.patch['Content-Type'] =
      'application/json; charset=utf-8';
    axios.defaults.headers.put['Content-Type'] =
      'application/json; charset=utf-8';
    axios.defaults.headers.post['Content-Type'] =
      'application/json; charset=utf-8';
    axios.defaults.headers['Accept'] = 'application/json';

    const token = Cookies.get('access');

    if (token && !isPublicView) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    if (!window.navigator.onLine) {
      throw new axios.Cancel(
        'Looks like you are offline, make sure you are online'
      );
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export function logoutOnRefreshTokenFailed() {
  clearJwtCookie();
  clearJwtLocalStorage();
  if (!isPublicView && !window.location.pathname.includes('AdminGuide')) {
    window.location.href = '/Login';
  }
}
//==================== Axios Response interceptor =======================

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest.url.includes('approve_alert') &&
      error.response.data.detail !== 'User not found'
    ) {
      if (originalRequest.url.includes('refresh')) {
        // showPopUp(STRINGS.YOU_ARE_LOGGED_OUT_LOGIN_BACK, 'warning', {
        //   action: () => {
        logoutOnRefreshTokenFailed();
        //   }
        // });
      } else if (
        originalRequest.url.includes('get-token') ||
        window.location.pathname.includes('AdminGuide')
      ) {
        // No need to do anything because we have to show a message to user
      } else if (localStorage.getItem('isLoggedIn')) {
        await axios
          .post(`${api_prefix}refresh/`, {
            refresh: Cookies.get('refresh')
          })
          .then((response) => {
            const tokenData = response.data;
            const CookiesData: any = Cookies.get('refresh');
            const expiresData = safeDecodeJWTToken(CookiesData).exp;
            const date = new Date(expiresData.exp * 1000); // Multiply by 1000 to convert seconds to milliseconds
            const formattedDate = new Date(date.toUTCString());
            Cookies.set('access', tokenData.access, {
              expires: formattedDate,
              secure: SECURE
            });
            Cookies.set('refresh', tokenData.refresh, {
              secure: SECURE,
              expires: formattedDate
            });
            window.location.reload();
          })
          .catch(() => {
            // showPopUp(STRINGS.YOU_ARE_LOGGED_OUT_LOGIN_BACK, 'warning', {
            //   action: () => {
            logoutOnRefreshTokenFailed();
            //   }
            // });
          });
      }
    } else if (error.response.data.detail === 'User not found') {
      logoutOnRefreshTokenFailed();
    } else if (
      (error.request && !error.request.status) ||
      (error.response && !error.response.status)
    ) {
      showPopUp(STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN, 'error');
    } else if (
      (error.response && error.response.status >= 500) ||
      (error.request && error.request.status >= 500)
    ) {
      const errorText = STRINGS.FAILED_TO_CONNECT;
      showPopUp(errorText, 'info');
    }
    return Promise.reject(error);
  }
);
