import { FunctionComponent, useEffect } from 'react';
import { Button, Input, Modal, TextArea } from '../../Universal/NovusDSImports';
import * as yup from 'yup';
import {
  btnStyles,
  inputStyles
} from '../../Universal/NovusDSImports/variants';
import {
  showError,
  validateYupSchema
} from '../../CommonUtilities/CommonUtilities';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSegment } from '../globalStyles';
import { STRINGS } from '../../Constants/ConstantStrings';
import { useReduxDispatch } from '../../Store/reduxHooks';
import {
  setSelectedClosure,
  setSelectedTab
} from '../../Store/reducers/Common';
import html2canvas from 'html2canvas';
import {
  clearUIforScreenshot,
  restoreUIforScreenshot
} from '../../CommonUtilities/screenshotUtlity';

import axios from 'axios';
import { api_prefix } from '../../Constants/constants';
const CloseEventModal: FunctionComponent = (props: any) => {
  const {
    handleCloseEvent,
    postModalClose,
    selectedClosure,
    setIsCloseEvent,
    setRegisteredUsersLocation,
    setMapCenter
  } = props;

  const schema = yup.object().shape({
    text: validateYupSchema('string', STRINGS.TEXT, true)
  });

  const getDefaultValues = () => ({
    text: STRINGS.CLOSE_EVENT_DEFAULT_MESSAGE
  });
  const dispatch = useReduxDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(schema)
  });

  const getUserLocation = async () => {
    try {
      await axios
        .get(
          `${api_prefix}closures/${selectedClosure.id}/registered_user_location/`
        )
        .then((response) => response.data)
        .then((data) => setRegisteredUsersLocation(data.result));
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    if (selectedClosure) {
      getUserLocation();
      setIsCloseEvent(true);
      setMapCenter({
        lat: selectedClosure.centre_latitude,
        lng: selectedClosure.centre_longitude
      });
    }
  }, [selectedClosure, setIsCloseEvent, setMapCenter]);

  const onSubmit = async (data: any) => {
    const componentRef = document.getElementById('map-wrapper');
    clearUIforScreenshot();
    if (componentRef) {
      await html2canvas(componentRef, { useCORS: true })
        .then((canvas) => {
          const screenshot = canvas.toDataURL('image/webp');
          return screenshot.split(',')[1];
        })
        .then((screenshot) => {
          restoreUIforScreenshot();
          return screenshot;
        })
        .then((screenshot) => {
          props.setCloseEventPayload({
            closureId: props.selectedClosure.id,
            closeText: data.text,
            closure_close_image: screenshot,
            shouldCancelWEA: props.selectedClosure.alert_status === 'approved'
          });
          handleCloseEvent();
          dispatch(setSelectedTab({ selectedTab: '' }));
          dispatch(setSelectedClosure({ selectedClosure: null }));
        });
    }
  };

  return (
    <FormSegment onSubmit={handleSubmit(onSubmit)}>
      <section className="formset-fields">
        <div className="form-group mb-0">
          <Input
            name="closureStatus"
            label={STRINGS.STATUS}
            value="closed"
            disabled
          />
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                label={STRINGS.DEFAULT_MESSAGE}
                disabled={true}
                hintText={
                  <span className="hint-text">
                    {`${STRINGS.MESSAGE_WILL_BE_SENT_TO_ALL_THE_USERS}
                      ${errors?.['text']?.message || ''}`}
                  </span>
                }
                placeholder={STRINGS.DEFAULT_MESSAGE}
                {...inputStyles}
                error={errors?.['text']}
                isMargin={false}
              />
            )}
          />
        </div>
      </section>
      <section className="footer">
        <div className="d-flex gap-3 justify-content-end w-100">
          <Button
            type="button"
            onClick={() => postModalClose()}
            {...btnStyles.tertiary}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button type="submit" {...btnStyles.primary}>
            {STRINGS.CLOSE_EVENT}
          </Button>
        </div>
      </section>
    </FormSegment>
  );
};

export default Modal(CloseEventModal);
