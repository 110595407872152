import { createSlice } from '@reduxjs/toolkit';
import {
  AddNewShapeSuccessType,
  AddNewShapeType,
  EditShapeSuccessType,
  EditShapeType,
  FetchShapesType,
  GetShapesType,
  RemoveShapeSuccessType,
  RemoveShapeType,
  ShapesType
} from '../actions/Shapes';

const initialState: ShapesType = {
  shapes: null,
  isFetchingShapes: false,
  isSubmittingShape: false
};

export const ShapeSlice = createSlice({
  name: 'Shapes',
  initialState,
  reducers: {
    getShapesFetch: (state, _action: FetchShapesType) => {
      state.isFetchingShapes = true;
    },
    getShapesSuccess: (state, action: GetShapesType) => {
      if (action.payload.shouldAppend && state.shapes?.results) {
        action.payload.shapes.results = [
          ...state.shapes.results,
          ...action.payload.shapes.results
        ];
      }
      action.payload.shapes.results = action.payload.shapes.results.map(
        (shape) => ({
          ...shape,
          geometry: {
            type: 'Polygon',
            coordinates: [
              shape.geometry.coordinates[0].map((coords) => [
                coords[1],
                coords[0]
              ])
            ]
          }
        })
      );
      state.shapes = action.payload.shapes;
      state.isFetchingShapes = false;
      state.isSubmittingShape = false;
    },
    addNewShapeData: (state, _action: AddNewShapeType) => {
      state.isSubmittingShape = true;
    },
    addNewShapeDataSuccess: (state, _action: AddNewShapeSuccessType) => {
      state.isSubmittingShape = false;
    },
    editShapeData: (state, _action: EditShapeType) => {
      state.isSubmittingShape = true;
    },
    editShapeDataSuccess: (state, _action: EditShapeSuccessType) => {
      state.isSubmittingShape = false;
    },
    removeShapeData: (state, _action: RemoveShapeType) => {
      state.isSubmittingShape = true;
    },
    removeShapeDataSuccess: (state, _action: RemoveShapeSuccessType) => {
      state.isSubmittingShape = false;
    }
  }
});

export const {
  getShapesFetch,
  getShapesSuccess,
  addNewShapeData,
  addNewShapeDataSuccess,
  editShapeData,
  editShapeDataSuccess,
  removeShapeData,
  removeShapeDataSuccess
} = ShapeSlice.actions;

export default ShapeSlice.reducer;
