import styled from 'styled-components';
import { colorState, device, typography } from '../../Universal/Foundation';

export const Footer = styled.div`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .link-text {
    color: ${colorState.text.link};
  }

  p {
    font-size: ${typography.size.ts};
    font-weight: ${typography.weight.regular};
    margin: 0;
    text-wrap: nowrap;
  }

  .product-ad {
    border-right: 1px solid ${colorState.border.default.strong};
    margin-right: 10px;
  }

  @media ${device.mobileL} {
    display: none;
  }
`;
