import axios from 'axios';
import { api_prefix } from '../../Constants/constants';
import {
  addClosureSuccess,
  closeEventSuccess,
  closuresDataReceived,
  eventNamesReceived,
  getClosuresData,
  savedPolygonsReceived,
  ticketStatusesReceived,
  submittingClosure,
  submitted,
  getEventNames,
  getTicketStatus,
  getSavedPolygons,
  addClosure,
  closeEventRequest,
  vehicleStatsReceived,
  getVehicleStats,
  getClosuresUnreadMessagesCount,
  closuresUnreadMessagesCountReceived
} from '../reducers/Closures';
import { put, takeLatest } from 'redux-saga/effects';
import {
  AddClosureAction,
  CloseEventRequestAction,
  FetchClosuresDataAction,
  FetchSavedPolygonsAction,
  FetchTicketStatusAction,
  GetClosuresUnreadMessagesCountAction,
  GetVehicleStatsAction
} from '../actions/Closures';
import { showError, showPopUp } from '../../CommonUtilities/CommonUtilities';
import { defaultGetResponse } from '../../GetResponse';
import { STRINGS } from '../../Constants/ConstantStrings';

function* fetchEventNames() {
  try {
    const json = yield axios
      .get(`${api_prefix}event_names/`)
      .then((response) => response);
    yield put(eventNamesReceived({ eventNames: json?.data?.results }));
  } catch (error: any) {
    yield put(eventNamesReceived({ eventNames: defaultGetResponse }));
    showError(error);
  }
}

function* fetchTicketStatus({ payload }: FetchTicketStatusAction) {
  try {
    let json: any = null;
    if (payload.statusFilter) {
      json = yield axios
        .get(`${api_prefix}ticket_status/?name__in=${payload.statusFilter}`)
        .then((response) => response);
    } else {
      json = yield axios
        .get(`${api_prefix}ticket_status/`)
        .then((response) => response);
    }

    yield put(
      ticketStatusesReceived({
        ticketStatuses: json?.data?.results
      })
    );
  } catch (error: any) {
    ticketStatusesReceived({
      ticketStatuses: defaultGetResponse
    });
    showError(error);
  }
}

function* fetchSavedPolygons({ payload }: FetchSavedPolygonsAction) {
  try {
    const json = yield axios
      .get(`${api_prefix}polygons/?limit=${payload.limit}`, {
        params: { ordering: '-modified, id' }
      })
      .then((response) => response);
    yield put(
      savedPolygonsReceived({
        savedPolygons: json?.data?.results
      })
    );
  } catch (error: any) {
    yield put(savedPolygonsReceived({ savedPolygons: defaultGetResponse }));
    showError(error);
  }
}

function* addClosureSaga({ payload }: AddClosureAction) {
  try {
    yield put(submittingClosure());
    if (payload.isEdit) {
      yield axios
        .patch(`${api_prefix}closures/${payload.params.id}/`, payload.params)
        .then((response) => response);
    } else {
      yield axios
        .post(`${api_prefix}closures/`, payload.params)
        .then((response) => response);
    }
    yield put(addClosureSuccess({ isAddClosureSuccess: true }));
    yield put(submitted());
    showPopUp(
      `Incident has been ${payload.isEdit ? 'edited' : 'created'} successfully`,
      'success'
    );
    yield put(getClosuresData({ limit: 'all' }));
  } catch (error) {
    yield put(submitted());
    yield put(addClosureSuccess({ isAddClosureSuccess: false }));
    showError(error);
  }
}

function* fetchClosuresData({ payload }: FetchClosuresDataAction) {
  try {
    let ordering = payload.ordering;

    if (!ordering) {
      ordering = '-modified';
    }
    const json = yield axios
      .get(
        `${api_prefix}closures/?ordering=${ordering}&status__in=${
          payload.status_in || '1,2,3'
        }${payload.limit ? `&limit=${payload.limit}` : ''}${
          payload.offset ? `&offset=${payload.offset}` : ''
        }`
      )
      .then((response) => response);
    yield put(
      closuresDataReceived({
        closuresData: json?.data,
        shouldAppend: !!payload.shouldAppend
      })
    );
    yield put(getClosuresUnreadMessagesCount({ limit: 'all' }));
  } catch (error: any) {
    yield put(
      closuresDataReceived({
        closuresData: defaultGetResponse,
        shouldAppend: false
      })
    );
    showError(error);
  }
}

function* closeEventRequestSaga({ payload }: CloseEventRequestAction) {
  try {
    yield put(submittingClosure());
    yield axios
      .post(`${api_prefix}closures/${payload.closureId}/close_event/`, {
        closed_reason: payload.closeText,
        closure_close_image: payload.closure_close_image
      })
      .then((response) => response);
    yield put(closeEventSuccess({ closingEventSuccessful: true }));
    yield put(submitted());
    showPopUp(STRINGS.EVENT_CLOSED_SUCCESSFULLY, 'success');
    payload.onComplete();
    yield put(getClosuresData({ limit: 'all' }));
  } catch (error) {
    yield put(submitted());
    yield put(closeEventSuccess({ closingEventSuccessful: false }));
    showError(error);
  }
}

function* getVehicleStatsSaga({ payload }: GetVehicleStatsAction) {
  try {
    const json = yield axios
      .get(`${api_prefix}closures/${payload.closureId}/vehicle_stats/`)
      .then((response) => response.data);
    yield put(vehicleStatsReceived({ vehicleStats: json }));
  } catch (e) {
    yield put(vehicleStatsReceived({ vehicleStats: {} }));
  }
}

function* getClosuresUnreadMessagesCountSaga({
  payload
}: GetClosuresUnreadMessagesCountAction) {
  try {
    const json = yield axios
      .get(
        `${api_prefix}closures/fetch_closure_unread_messages/?status__in=1,2,3${
          payload.limit ? `&limit=${payload.limit}` : ''
        }`
      )
      .then((response) => response);
    yield put(
      closuresUnreadMessagesCountReceived({
        closuresUnreadMessagesCount: json && json.data && json.data.data
      })
    );
  } catch (error) {
    showError(error);
  }
}

export default function* watchClosures() {
  yield takeLatest(getEventNames, fetchEventNames);
  yield takeLatest(getTicketStatus, fetchTicketStatus);
  yield takeLatest(getSavedPolygons, fetchSavedPolygons);
  yield takeLatest(addClosure, addClosureSaga);
  yield takeLatest(getClosuresData, fetchClosuresData);
  yield takeLatest(closeEventRequest, closeEventRequestSaga);
  yield takeLatest(getVehicleStats, getVehicleStatsSaga);
  yield takeLatest(
    getClosuresUnreadMessagesCount,
    getClosuresUnreadMessagesCountSaga
  );
}
