import styled from 'styled-components';
import {
  device,
  colorState,
  radius,
  spacing,
  typography
} from '../../Universal/Foundation';

interface PublicFormProps {
  messageType?: string;
  isExpanded?: boolean;
}

export const PublicViewContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 93vh;
  overflow: auto;

  .navigation-link {
    padding: ${spacing.spacing_12}px;
    gap: ${spacing.spacing_4}px;
    text-align: center;
    color: ${colorState.text.secondary};
    cursor: pointer;
    text-decoration: underline;
    font-size: ${typography.size.ts};
    align-self: center;
    font-weight: ${typography.weight.medium};

    &:focus-visible {
      padding: 5px;
      outline: none;
      border-radius: 8px;
      border: 1px solid ${colorState.border.brand.primary};
    }
  }

  .container {
    flex: 1;
    padding: 40px 100px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: ${spacing.spacing_24}px;

    @media ${device.laptop} {
      padding: ${spacing.spacing_20}px;
      gap: ${spacing.spacing_20}px;
    }

    @media ${device.mobileXL} {
      padding: ${spacing.spacing_16}px;
      gap: ${spacing.spacing_16}px;
    }

    .message-history-tabs {
      display: flex;
      gap: ${spacing.spacing_4}px;
      border: 1px solid ${colorState.border.default.secondary};
      border-radius: 100px;
      padding: ${spacing.spacing_2}px;
      max-width: 100%;
      width: fit-content;

      @media ${device.mobileXL} {
        overflow: auto;
      }
    }
  }
`;
export const PublicMainContainer = styled.div`
  flex: 1;
  padding: 1.7vh 320px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 2vh;

  @media ${device.mobileL} {
    padding: ${spacing.spacing_16}px;
  }

  @media ${device.laptopL} {
    padding: ${spacing.spacing_20}px;
  }

  .closure-cards,
  .middle-section,
  .description-section {
    display: flex;
    flex-direction: column;
  }

  .closure-cards {
    padding: ${spacing.spacing_16}px;
    overflow: auto;
    gap: ${spacing.spacing_24}px;
    justify-content: space-between;
    border: 1px solid ${colorState.border.default.primary};
    border-radius: ${radius.radius_16}px;

    .map-section {
      img {
        height: 240px;
        width: 100%;
        border: 1px solid ${colorState.border.default.primary};
        border-radius: ${radius.radius_8}px;

        @media ${device.mobileL} {
          height: 150px;
        }
      }
    }

    .middle-section {
      gap: ${spacing.spacing_10}px;
      justify-content: space-between;
      flex: 1;

      .incident-name {
        font-size: ${typography.size.tm};
        font-weight: ${typography.weight.semibold};
        color: ${colorState.text.primary};
        margin-top: ${spacing.spacing_12}px;
        cursor: default;
      }

      .description-section {
        background-color: ${colorState.surface.default.tertiary};
        padding: ${spacing.spacing_12}px;
        border-radius: ${radius.radius_8}px;
        font-size: ${typography.size.ts};
        gap: ${spacing.spacing_12}px;

        .heading {
          color: ${colorState.text.tertiary};
          font-weight: ${typography.weight.medium};
          margin-bottom: ${spacing.spacing_4}px;
          cursor: default;
        }
        .sub-text {
          color: ${colorState.text.primary};
          font-weight: ${typography.weight.regular};
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          position: relative;
          text-overflow: ellipsis;
          cursor: default;

          @media ${device.laptop} {
            -webkit-line-clamp: unset;
          }
        }
      }
    }
  }
`;

export const HighlightMessage = styled.div<PublicFormProps>`
  background-color: ${(props) =>
    props.messageType === 'error'
      ? colorState.surface.danger.tertiary
      : props.messageType === 'success'
      ? colorState.surface.success.tertiary
      : colorState.surface.warning.secondary};
  padding: ${spacing.spacing_24}px;
  font-weight: ${typography.weight.medium};
  font-size: ${typography.size.tm};
  line-height: 24px;
  color: ${(props) =>
    props.messageType === 'error'
      ? colorState.text.danger.primary
      : props.messageType === 'success'
      ? colorState.text.success.primary
      : colorState.text.primary};
  border-radius: ${radius.radius_12}px;
  border: ${(props) =>
    props.messageType === 'error'
      ? `1px solid ${colorState.border.danger.tertiary}`
      : props.messageType === 'success'
      ? `1px solid ${colorState.border.success.tertiary}`
      : 'none'};

  .truncated-text {
    overflow: ${(props) => (props.isExpanded ? 'visible' : 'hidden')};
    -webkit-line-clamp: ${(props) => (props.isExpanded ? 'unset' : '3')};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    text-overflow: ellipsis;
    cursor: default;

    &.expanded-text {
      display: block;
      -webkit-line-clamp: unset;
      text-overflow: unset;
    }

    a {
      color: ${colorState.text.primary};
    }
  }
  .toggle-expand {
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${device.mobileXL} {
    padding: ${spacing.spacing_12}px ${spacing.spacing_16}px;
    line-height: 20px;
    font-size: ${typography.size.ts};
  }
`;

export const FormPrimaryHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${spacing.spacing_20}px;
  cursor: default;

  @media ${device.mobileXL} {
    gap: ${spacing.spacing_12}px;
  }

  .form-main-heading {
    color: ${colorState.text.primary};
    font-weight: ${typography.weight.bold};
    font-size: 32px;
    line-height: 40px;
    text-align: center;

    @media ${device.mobileXL} {
      font-size: ${typography.size.tl};
      font-weight: ${typography.weight.semibold};
      line-height: 28px;
    }
  }

  .submain-heading {
    font-size: ${typography.size.tm};
    font-weight: ${typography.weight.regular};
    color: ${colorState.text.secondary};
    line-height: 24px;
    text-align: center;

    @media ${device.mobileXL} {
      font-size: ${typography.size.ts};
      line-height: 20px;
    }
  }
`;

export const UserRegistrationForm = styled.form`
  border: 1px solid ${colorState.border.default.secondary};
  border-radius: ${radius.radius_16}px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: ${colorState.surface.default.tertiary};
  padding-top: ${spacing.spacing_20}px;

  @media ${device.mobileXL} {
    padding-top: ${spacing.spacing_12}px;
  }

  .form-body {
    gap: ${spacing.spacing_24}px;
    display: flex;
    flex-direction: column;
    padding: 0 ${spacing.spacing_20}px ${spacing.spacing_20}px
      ${spacing.spacing_20}px;
    overflow: auto;

    @media ${device.mobileXL} {
      padding: 0 ${spacing.spacing_12}px ${spacing.spacing_12}px
        ${spacing.spacing_12}px;
    }

    .toggle-switch-text {
      font-size: ${typography.size.ts};
      line-height: 20px;
      font-weight: ${typography.weight.medium};
      color: ${colorState.text.secondary};
      padding-right: 3px;
    }
    .checkbox-heading-wrapper {
      b {
        font-weight: ${typography.weight.regular};
      }
    }

    h1 {
      font-size: ${typography.size.tl};
      line-height: 28px;
      margin-bottom: 0;
      @media ${device.mobileXL} {
        font-size: ${typography.size.tm};
      }
    }
    h1,
    h2 {
      font-weight: ${typography.weight.medium};
      cursor: default;
    }
    p,
    h2 {
      font-size: ${typography.size.tm};
      line-height: 24px;
      margin-bottom: 0;
      @media ${device.mobileXL} {
        font-size: ${typography.size.ts};
      }
    }
    p {
      font-weight: ${typography.weight.regular};
      cursor: default;
    }
    .active-message-card {
      background-color: ${colorState.surface.default['selected-brand-bg']};
      border: 1px solid ${colorState.border.default.secondary};
    }
  }

  .form-footer {
    bottom: 0;
    position: sticky;
    padding: ${spacing.spacing_20}px;
    display: flex;
    justify-content: end;
    gap: ${spacing.spacing_12}px;
    border-top: 1px solid ${colorState.border.default.secondary};
    background-color: white;

    @media ${device.mobileXL} {
      bottom: unset;
      position: relative;
      padding: ${spacing.spacing_16}px;
    }
  }
`;
export const MessageCards = styled.div`
  background-color: ${colorState.surface.default.tertiary};
  border-radius: ${radius.radius_8}px;
  padding: ${spacing.spacing_12}px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;

  p[aria-label='status'] {
    text-wrap: nowrap;

    @media ${device.mobileL} {
      font-size: ${typography.size.txs};
    }
  }

  .message-history-text {
    font-size: ${typography.size.ts};
    font-weight: ${typography.weight.medium};
    line-height: 20px;
    color: ${colorState.text.secondary};
    flex: 1;
    cursor: pointer;
  }

  .message-formatted-time {
    font-size: ${typography.size.txs};
    font-weight: ${typography.weight.medium};
    line-height: 16px;
    color: ${colorState.text.tertiary};
    text-wrap: nowrap;
    cursor: default;
  }

  &:hover {
    background-color: ${colorState.surface.default['selected-brand-bg']};
    border: 1px solid ${colorState.border.default.secondary};
  }

  @media ${device.mobileL} {
    padding: ${spacing.spacing_6}px;
  }
`;
