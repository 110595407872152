import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { STRINGS } from '../../Constants/ConstantStrings';
import NoDataFound from '../../SharedComponets/NoDataFound';
import {
  deleteQuestion,
  getclosureCategories,
  requestQuestionsLibraryData
} from '../../Store/reducers/ClosureQuestions';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { NoQuestionsIcon } from '../../Universal/Assets';
import { colorState } from '../../Universal/Foundation';
import { Button, Icon } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  confirmPopupModalStyles
} from '../../Universal/NovusDSImports/variants';
import GenericTableRT7 from '../GenericTableComponent/GenericTable';
import { TooltipComponent } from '../../SharedComponets/Tooltip';
import { RootState } from '../../store';
import ConfirmationPopupModal from '../../SharedComponets/ConfirmationPopupModal';
import { closureQuestionsReceived } from '../../Store/reducers/PublicView';
import { defaultGetResponse } from '../../GetResponse';
import usePrevious from '../../Hooks/UsePrevious';
import PALoader from '../../SharedComponets/PALoader';
import { getPermission } from '../../CommonUtilities/CommonUtilities';
import { unSelectSelectedRows } from '../../Store/reducers/Common';

export const EventQuestionLibraryTable = (props: any) => {
  const {
    selectedCategory,
    activeTab,
    setIsEdit,
    setEditData,
    setIsOpenModal,
    setSelectedRows,
    selectedRows,
    addQuestion,
    isQuestionLibrary
  } = props;
  const dispatch = useReduxDispatch(),
    [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false),
    [deleteData, setDeleteData] = useState<any>(),
    [isLoadingInitialData, setIsLoadingInitialData] = useState<boolean>(true),
    questionslibraryData = useSelector(
      (state: RootState) => state.Questions.questionslibraryData
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    isFetchingQuestionsLibraryData = useSelector(
      (state: RootState) => state.Questions.isFetchingQuestionsLibraryData
    ),
    shouldUnselect = useReduxSelector(
      (state: RootState) => state.Common.isMobileScreen
    ),
    previousIsFetchingQuestionsLibraryData = usePrevious(
      isFetchingQuestionsLibraryData
    );
  const isAdmin = useReduxSelector(
    (state: RootState) =>
      state?.Common?.currentUserDetails?.user_permission_set?.role_names
  )?.includes('Admin');

  useEffect(() => {
    if (
      previousIsFetchingQuestionsLibraryData &&
      !isFetchingQuestionsLibraryData &&
      isLoadingInitialData
    ) {
      setIsLoadingInitialData(false);
    }
  }, [
    isFetchingQuestionsLibraryData,
    isLoadingInitialData,
    previousIsFetchingQuestionsLibraryData
  ]);

  useEffect(() => {
    if (shouldUnselect) {
      setSelectedRows({});
    }
  }, [dispatch, shouldUnselect, setSelectedRows]);

  const editQuestion = (data: any) => {
    setIsOpenModal(true);
    setIsEdit(true);
    setEditData(data);
  };

  const deleteLibraryQuestion = (data: any) => {
    setIsDeleteModalOpen(true);
    setDeleteData(data);
  };

  useEffect(() => {
    return () => {
      dispatch(closureQuestionsReceived({ data: defaultGetResponse }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isQuestionLibrary) {
      dispatch(getclosureCategories({ limit: 'all' }));
    }
  }, [dispatch, isQuestionLibrary]);

  const columns = [
    {
      Header: 'Questions',
      accessor: 'display_name',
      Cell: ({ row }) => (
        <div className="event-questions-wrapper">
          {row.original.display_name}
          {row.original.default && ' *'}
          {row.original.multiple_choices?.length ? (
            <ul>
              {row.original.multiple_choices.map((item: any, index: number) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          ) : (
            <div>{row.original.numeric}</div>
          )}
        </div>
      )
    },
    {
      Header: STRINGS.TYPE,
      accessor: 'field_type'
    },
    {
      Header: STRINGS.ABBREVIATED,
      accessor: 'abbr_name'
    },
    {
      Header: STRINGS.ACTIONS,
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="d-flex">
          {getPermission(currentUser, 'change_closureticket') && (
            <div
              role="button"
              aria-label="delete-action"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (isAdmin || !row.original.default) {
                    deleteLibraryQuestion(row.original);
                  }
                }
              }}
              onClick={() => {
                if (isAdmin || !row.original.default) {
                  deleteLibraryQuestion(row.original);
                }
              }}
              className={` ${
                isAdmin
                  ? 'me-2 me-md-5 action-icons'
                  : row.original.default
                  ? 'disabled me-2 me-md-5'
                  : 'me-2 me-md-5 action-icons'
              }`}
              data-tooltip-id={`delete-${row.original.id}`}
            >
              <Icon
                icon={'trash_01'}
                className={`${
                  isAdmin ? '' : row.original.default && 'disabled'
                } trash`}
                height={20}
                width={20}
                stroke={
                  isAdmin
                    ? colorState.icon.default.primary
                    : row.original.default && colorState.icon.default.disabled
                }
              >
                {STRINGS.DELETE}
              </Icon>
              {!row.original.default && (
                <TooltipComponent id={`delete-${row.original.id}`}>
                  {STRINGS.DELETE}
                </TooltipComponent>
              )}
            </div>
          )}
          {getPermission(currentUser, 'change_field') && (
            <div
              role="button"
              aria-label="edit-action"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (isAdmin || !row.original.default) {
                    editQuestion(row.original);
                  }
                }
              }}
              onClick={() => {
                if (isAdmin || !row.original.default) {
                  editQuestion(row.original);
                }
              }}
              className={`${
                isAdmin
                  ? 'action-icons'
                  : row.original.default
                  ? 'disabled'
                  : 'action-icons'
              }`}
              data-tooltip-id={`edit-${row.original.id}`}
            >
              <Icon
                icon={'edit_02'}
                height={20}
                width={20}
                stroke={
                  isAdmin
                    ? colorState.icon.default.primary
                    : row.original.default
                    ? colorState.icon.default.disabled
                    : colorState.icon.default.primary
                }
                className={isAdmin ? '' : row.original.default && 'disabled'}
              >
                {STRINGS.EDIT}
              </Icon>
              {!row.original.default && (
                <TooltipComponent id={`edit-${row.original.id}`}>
                  {STRINGS.EDIT}
                </TooltipComponent>
              )}
            </div>
          )}
        </div>
      )
    }
  ];

  useEffect(() => {
    if (selectedCategory && getPermission(currentUser, 'view_field')) {
      dispatch(
        requestQuestionsLibraryData({
          categoryId: selectedCategory.id,
          limit: 'all'
        })
      );
      if (selectedRows && Object.keys(selectedRows).length) {
        setSelectedRows({});
        dispatch(unSelectSelectedRows({ shouldUnselect: true }));
      }
    }
  }, [dispatch, selectedCategory, currentUser]);

  const submit = () => {
    const params = {
      categoryId: deleteData.category,
      questionId: deleteData.id
    };
    dispatch(deleteQuestion({ params, activeTab }));
    setIsDeleteModalOpen(false);
  };

  const customConfirmationStyles = {
    content: {
      minWidth: '20%',
      minHeight: '10vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const postModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const renderConfirmationFooter = () => {
    return (
      <div className="gap-3 d-flex">
        <Button
          onClick={() => setIsDeleteModalOpen(false)}
          {...btnStyles.default}
        >
          {STRINGS.CANCEL}
        </Button>
        <Button onClick={submit} {...btnStyles.danger}>
          {STRINGS.YES}
        </Button>
      </div>
    );
  };

  const NoQuestionsData = () => {
    return (
      <NoDataFound
        src={NoQuestionsIcon}
        description={STRINGS.YOUR_LIST_OF_QUESTIONS_ARE_EMPTY}
        alt={'no-questions-img'}
        isAbsolute
        subMsgDescription={
          STRINGS.YOUR_LIST_OF_QUESTIONS_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_QUESTIONS_YET
        }
        btn={
          getPermission(currentUser, 'add_field') && (
            <Button {...btnStyles.primary} onClick={() => addQuestion(true)}>
              <Icon
                icon={'add'}
                stroke={colorState.icon.default['primary-inverse']}
              />
              {STRINGS.ADD_NEW_QUESTOIN}
            </Button>
          )
        }
      />
    );
  };
  return (
    <>
      {isLoadingInitialData || !questionslibraryData ? (
        <PALoader />
      ) : (
        <GenericTableRT7
          columns={columns}
          tableData={questionslibraryData}
          setSelectedRows={setSelectedRows}
          showCheckBoxColumn={
            !isQuestionLibrary &&
            getPermission(currentUser, 'add_new_closure_question')
          }
          selectedRows={selectedRows}
          noDataFoundScreen={NoQuestionsData()}
        />
      )}
      <ConfirmationPopupModal
        isOpen={isDeleteModalOpen}
        showHeader={true}
        title={STRINGS.DELETE}
        description={STRINGS.ARE_YOU_SURE_WANT_TO_DELETE_QUESTION}
        showFooter={renderConfirmationFooter()}
        style={customConfirmationStyles}
        postModalClose={postModalClose}
        closeModal={() => postModalClose()}
        showBorderTop={false}
        isBodyPaddingNone={true}
        customFooter={true}
        {...confirmPopupModalStyles}
      />
    </>
  );
};
