import { useRef, useEffect } from 'react';

export default function usePrevious<StateType>(
  value: StateType
): StateType | undefined {
  const ref = useRef<StateType>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
