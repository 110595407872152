let baseURL = window.location.origin;
let travelIQKey = import.meta.env.REACT_APP_TRAVEL_IQ_KEY || '';
let REACPTCHA_SITE_KEY = import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
let GOOGLE_MAPS_API_KEY = import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
let GOOGLE_ANALYTICS_ID = import.meta.env.REACT_APP_GOOGLE_ANALYTICS_ID;
let ENVIRONMENT = import.meta.env.REACT_APP_ENVIRONMENT || ''; // DEVELOPMENT/UAT/PRODUCTION
const SENTRY_DSN = import.meta.env.REACT_APP_SENTRY_DSN || '';

const checkIfAdmin = /https?:\/\/admin/.test(window.location.origin);

// local settings overrides
if (import.meta.env.DEV) {
  const settings = await import('./local_settings.js');
  baseURL = checkIfAdmin
    ? 'https://admin-paconnect.dev.ibidigital.com'
    : settings.default.BASE_URL;
  travelIQKey = settings.default.travelIQKey;
  REACPTCHA_SITE_KEY = settings.default.REACPTCHA_SITE_KEY;
  GOOGLE_MAPS_API_KEY = settings.default.GOOGLE_MAPS_API_KEY;
  GOOGLE_ANALYTICS_ID = settings.default.GOOGLE_ANALYTICS_ID;
  ENVIRONMENT = settings.default.ENVIRONMENT;
}

export const BASE_URL = baseURL;
export const WebSocketHostName = BASE_URL.replace('https://', '');
export const WebSocketURL = `wss://${WebSocketHostName}/websocket/?token=Bearer `;

export const SECURE = !window.location.host.includes('localdevfe');

export const isPublicView = !checkIfAdmin;

export const isProdInstance = ENVIRONMENT === 'PRODUCTION';
export const isUatInstance = ENVIRONMENT === 'UAT';
export const isDevInstance = ENVIRONMENT === 'DEVELOPMENT';
export const isTesting = process.env.NODE_ENV === 'test';

// external api's
export const MILEPOSTS_API = 'https://pa.ibi511.com/map/mapWMS/MileMarkers/';
export const TRAFFIC_API = 'https://tiles.ibi511.com/Geoservice/GetTrafficTile';

// external keys
export const CAMERA_SECURE_TOKEN_API =
  'https://pa.arcadis-ivds.com/api/SecureTokenUri/GetSecureTokenUriByCameraId';

// keys
export {
  travelIQKey,
  REACPTCHA_SITE_KEY,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_ANALYTICS_ID,
  SENTRY_DSN,
  ENVIRONMENT
};
