import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  Input,
  Button,
  Avatar,
  Lozenge
} from '../../Universal/NovusDSImports';
import Card from '../../SharedComponets/Card';
import GenericTableRT7 from '../GenericTableComponent/GenericTable';
import { TableWrapper, UserGroupProfileSection } from '../globalStyles';
import TableTitle from '../../SharedComponets/TableTitle';
import { TooltipComponent } from '../../SharedComponets/Tooltip';
import {
  btnStyles,
  confirmPopupModalStyles,
  inputStyles,
  modalStyles
} from '../../Universal/NovusDSImports/variants';

import {
  getAdminUsersFetch,
  getAdminAgenciesFetch,
  getAdminGroupsFetch
} from '../../Store/reducers/AdminUsers';

import AddEditAdminUserModal from './AddEditAdminUserModal';
import ConfirmationPopupModal from './ConfirmationPopupModal';
import { useReduxSelector } from '../../Store/reduxHooks';
import { FormDataType } from './adminUsers.types';
import { STRINGS } from '../../Constants/ConstantStrings';
import { colorState } from '../../Universal/Foundation';
import { TablePopupStyles } from '../GenericTableComponent/styles';
import { RootState } from '../../store';
import {
  debounceFunction,
  getOrderingParam,
  getPermission,
  getUserColor,
  getUserInitials
} from '../../CommonUtilities/CommonUtilities';
import NoDataFound from '../../SharedComponets/NoDataFound';
import { NoManageUsersIcon } from '../../Universal/Assets';
import PALoader from '../../SharedComponets/PALoader';
import { setShowTableLoader } from '../../Store/reducers/Common';

const AdminUsers: React.FC = () => {
  const dispatch = useDispatch();
  const timerToSaveDetails = useRef<NodeJS.Timeout | null>(null),
    [searchFilterValue, setSearchFilterValue] = useState<string>(''),
    // [isSearching, setIsSearching] = useState<boolean>(false),
    [isModalOpen, setIsModalOpen] = useState<boolean>(false),
    [isConfirmationModalOpen, setIsConfirmationModalOpen] =
      useState<boolean>(false),
    [editFormData, setEditFormData] = useState<FormDataType>(),
    [editMode, setEditMode] = useState<string>(),
    [userGroup, setUserGroup] = useState<boolean>(false),
    [confirmationModalType, setConfirmationModalType] = useState<string>(),
    [tableSortOrder, setTableSortOrder] = useState<
      Array<{ id: string; desc: boolean }>
    >([]),
    [agency, setAgency] = useState<Array<{ value: number; label: string }>>([]);

  const { adminUsers, isFetchingAdminUsers } = useSelector(
      (state: RootState) => state.AdminUsers
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    isMobileScreen = useReduxSelector(
      (state: RootState) => state.Common.isMobileScreen
    ),
    tableRowLimit = useReduxSelector(
      (state: RootState) => state.Common.tableRowLimit
    ),
    agencies = useSelector(
      (state: RootState) => state.AdminUsers.adminAgencies.results
    );

  useEffect(() => {
    if (agencies)
      setAgency(
        agencies.map((agency) => {
          return { value: agency.id, label: agency.name };
        })
      );
  }, [agencies]);

  const columns = [
    {
      Header: STRINGS.NAME,
      accessor: 'email',
      Cell: ({ row }) => (
        <UserGroupProfileSection className="d-flex align-items-center">
          <div className="me-3">
            <Avatar
              size="s"
              src={row.original.profile_image}
              username={getUserInitials(row.original.fullname)}
              userNameColor={getUserColor(row.original.fullname)}
              isDefault={false}
            />
          </div>
          <div className="d-flex flex-column">
            <p className="text-name">
              {row.original.first_name} {row.original.last_name}
            </p>
            <p className="text-email">{row.original.email}</p>
          </div>
        </UserGroupProfileSection>
      )
    },
    {
      Header: STRINGS.USER_GROUPS,
      accessor: 'groups',
      Cell: ({ row }) => {
        return row.original.groups.map((group, index: number) => (
          <div key={index}>{group.name}</div>
        ));
      }
    },
    {
      Header: STRINGS.STATUS,
      accessor: 'enabled',
      Cell: ({ row }) => (
        <Lozenge
          type={row.original.enabled ? 'success' : 'warning'}
          text={row.original.enabled ? 'Active' : 'Disabled'}
        />
      )
    },
    {
      Header: STRINGS.AGENCY,
      accessor: 'agency',
      Cell: ({ row }) => (
        <div
          title={
            row.original.agency ? agency[row.original.agency - 1]?.label : ''
          }
        >
          {row.original.agency ? agency[row.original.agency - 1]?.label : ''}
        </div>
      )
    },
    {
      Header: STRINGS.IPAWS_NOTIFICATIONS,
      Cell: ({ row }) => (
        <>
          {row.original.send_ipaws_email_notifications && <div>Email</div>}
          {row.original.send_ipaws_sms_notifications && <div>SMS</div>}
        </>
      )
    },
    {
      Header: STRINGS.CONTACT_NUMBER,
      Cell: ({ row }) => (
        <>
          {}
          {row.original.phone_number.country_code &&
            `+${row.original.phone_number.country_code}`}{' '}
          {row.original.phone_number.number}
        </>
      )
    },
    {
      Header: STRINGS.ACTIONS,
      Cell: ({ row }) => {
        return (
          <div className="view-details-section gap-3">
            {getPermission(currentUser, 'view_applicationuser') && (
              <div
                className="action-icons"
                role="button"
                tabIndex={0}
                aria-label="view-details"
                data-tooltip-id={`view-${row.original.id}`}
                onClick={() => {
                  setEditMode('View');
                  setEditFormData({ ...row.original });
                  setUserGroup(false);
                  setIsModalOpen(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setEditMode('View');
                    setEditFormData({ ...row.original });
                    setUserGroup(false);
                    setIsModalOpen(true);
                  }
                }}
              >
                <Icon icon="view_details" height="20px" width="20px" />
                <TooltipComponent id={`view-${row.original.id}`}>
                  {STRINGS.VIEW}
                </TooltipComponent>
              </div>
            )}
            {getPermission(currentUser, 'add_applicationuser') && (
              <div
                className="action-icons"
                role="button"
                tabIndex={0}
                aria-label="edit-details"
                onClick={() => {
                  setEditMode('Edit');
                  setEditFormData({ ...row.original });
                  setUserGroup(false);
                  setIsModalOpen(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setEditMode('Edit');
                    setEditFormData({ ...row.original });
                    setUserGroup(false);
                    setIsModalOpen(true);
                  }
                }}
                data-tooltip-id={`edit-${row.original.id}`}
              >
                <Icon icon="edit_02" height="20px" width="20px" />
                <TooltipComponent id={`edit-${row.original.id}`}>
                  {STRINGS.EDIT}
                </TooltipComponent>
              </div>
            )}
            <div
              data-tooltip-id={row.original.id}
              className="option-icon"
              aria-label="options"
              tabIndex={0}
              role="button"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  event.currentTarget.click();
                }
              }}
            >
              <Icon icon="dots_vertical" width={'20px'} height={'20px'} />
            </div>
            <TooltipComponent
              openOnClick
              id={row.original.id}
              tooltipBgColor={colorState.surface.default.secondary}
              noPadding
              noArrow
              closeOnScroll
              place="bottom"
            >
              <TablePopupStyles>
                <ul>
                  {getPermission(currentUser, 'change_applicationuser') && (
                    <li
                      aria-label="change-user-group"
                      tabIndex={0}
                      role="button"
                      onClick={() => {
                        setUserGroup(true);
                        setEditFormData({ ...row.original });
                        setEditMode(undefined);
                        setIsModalOpen(true);
                      }}
                      onKeyDown={(event: any) => {
                        if (event.key === 'Enter') {
                          setUserGroup(true);
                          setEditFormData({ ...row.original });
                          setEditMode(undefined);
                          setIsModalOpen(true);
                        }
                      }}
                    >
                      {STRINGS.CHANGE_USER_GROUP}
                    </li>
                  )}
                  {getPermission(currentUser, 'change_user') && (
                    <li
                      aria-label={
                        row.original.enabled ? STRINGS.DISABLE : STRINGS.ENABLE
                      }
                      tabIndex={0}
                      role="button"
                      onClick={() => {
                        setIsConfirmationModalOpen(true);
                        setConfirmationModalType(
                          row.original.enabled
                            ? STRINGS.DISABLE
                            : STRINGS.ENABLE
                        );
                        setEditFormData({ ...row.original });
                      }}
                      onKeyDown={(event: any) => {
                        if (event.key === 'Enter') {
                          setIsConfirmationModalOpen(true);
                          setConfirmationModalType(
                            row.original.enabled
                              ? STRINGS.DISABLE
                              : STRINGS.ENABLE
                          );
                          setEditFormData({ ...row.original });
                        }
                      }}
                    >
                      {row.original.enabled
                        ? STRINGS.DISABLE_USER
                        : STRINGS.ENABLE_USER}
                    </li>
                  )}
                  {getPermission(currentUser, 'delete_applicationuser') && (
                    <li
                      aria-label="remove-user"
                      tabIndex={0}
                      role="button"
                      className="text-danger"
                      onClick={() => {
                        setIsConfirmationModalOpen(true);
                        setConfirmationModalType('Remove');
                        setEditFormData({ ...row.original });
                      }}
                      onKeyDown={(event: any) => {
                        if (event.key === 'Enter') {
                          setIsConfirmationModalOpen(true);
                          setConfirmationModalType('Remove');
                          setEditFormData({ ...row.original });
                        }
                      }}
                    >
                      {STRINGS.REMOVE_USER}
                    </li>
                  )}
                </ul>
              </TablePopupStyles>
            </TooltipComponent>
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    if (getPermission(currentUser, 'view_agency')) {
      dispatch(getAdminAgenciesFetch({}));
    }

    dispatch(getAdminGroupsFetch({}));
  }, [dispatch, currentUser]);

  const handleComplexTableSort = () => {
    const newSortOrder: Array<{ id: string; desc: boolean }> = [];

    for (let i = 0; i < tableSortOrder.length; i++) {
      if (tableSortOrder[i].id === 'email') {
        newSortOrder.push(
          { id: 'first_name', desc: tableSortOrder[i].desc },
          { id: 'last_name', desc: tableSortOrder[i].desc },
          tableSortOrder[i]
        );
      } else if (tableSortOrder[i].id === 'groups') {
        newSortOrder.push({ id: 'group', desc: tableSortOrder[i].desc });
      } else {
        newSortOrder.push(tableSortOrder[i]);
      }
    }
    return getOrderingParam(newSortOrder);
  };

  const getNextSetOfData = (offset: number) => {
    dispatch(
      getAdminUsersFetch({
        params: {
          offset,
          limit: tableRowLimit,
          search: searchFilterValue,
          ordering: handleComplexTableSort()
        },
        shouldAppend: true
      })
    );
  };

  useEffect(() => {
    debounceFunction(timerToSaveDetails, 100, () => {
      dispatch(
        getAdminUsersFetch({
          params: {
            limit: tableRowLimit,
            search: searchFilterValue,
            ordering: handleComplexTableSort()
          }
        })
      );
    });
  }, [searchFilterValue, tableSortOrder]);

  useEffect(() => {
    if (!isFetchingAdminUsers) {
      dispatch(setShowTableLoader({ showTableLoader: false }));
    }
  }, [isFetchingAdminUsers]);

  const AddEditUserStyles = {
    content: {
      position: 'absolute',
      minWidth: isMobileScreen ? '95%' : '436px',
      maxWidth: isMobileScreen ? '95%' : '436px',
      boxShadow: '0px 10px 22px rgba(45,77,108,0.25)',
      margin: '10px',
      right: isMobileScreen ? 'unset' : '4px',
      borderRadius: '16px',
      height: '100%',
      maxHeight: '98%'
    }
  };

  const customStyles = {
    content: {
      maxWidth: '40%',
      minHeight: '10vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const NoManageUsersData = () => {
    return (
      <NoDataFound
        src={NoManageUsersIcon}
        description={STRINGS.YOUR_LIST_OF_USERS_ARE_EMPTY}
        alt={'no-manage-users-img'}
        isAbsolute
        subMsgDescription={
          STRINGS.YOUR_LIST_OF_USERS_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_USERS_YET
        }
        btn={
          <Button {...btnStyles.primary}>
            <Icon
              icon={'add'}
              stroke={colorState.icon.default['primary-inverse']}
            />
            {STRINGS.ADD_NEW_USERS}
          </Button>
        }
      />
    );
  };

  const NoManageUsersFilterData = () => {
    return (
      <NoDataFound
        src={NoManageUsersIcon}
        description={STRINGS.USER_NOT_FOUND}
        alt={'no-manage-users-img'}
        isAbsolute
        subMsgDescription={STRINGS.NO_USER_FOUND_WITH_GIVEN_VALUE}
      />
    );
  };

  return (
    <Card className="w-100">
      <TableWrapper id="table-group">
        <TableTitle title={STRINGS.USER_MANAGEMENT}>
          {getPermission(currentUser, 'add_applicationuser') && (
            <Button
              title="Add New User"
              onClick={() => {
                setUserGroup(false);
                setIsModalOpen(true);
                setEditMode('Add');
              }}
              {...btnStyles.primary}
            >
              <Icon icon={'add'} stroke={'white'} />
              {STRINGS.ADD_USER}
            </Button>
          )}
        </TableTitle>
        <div className="search-input col-12 col-sm-6 col-md-4 col-lg-3">
          <Input
            name="adminSearch"
            placeholder={STRINGS.SEARCH}
            isMargin={false}
            hideLabel={true}
            value={searchFilterValue}
            iconLeft={
              <Icon
                icon={'search'}
                stroke={colorState.icon.default.secondary}
              />
            }
            onChange={(e) => {
              setSearchFilterValue(e.target.value);
            }}
            {...inputStyles}
          />
        </div>

        <div id="table">
          <div className="w-100 h-100 d-flex overflow-auto flex-grow-1 flex-column">
            {!adminUsers ? (
              <PALoader />
            ) : (
              <GenericTableRT7
                columns={columns}
                tableData={adminUsers?.results || []}
                noDataFoundScreen={NoManageUsersData()}
                isSearching={searchFilterValue.length > 0}
                noFilterDataFoundScreen={NoManageUsersFilterData()}
                infiniteScrollSettings={{
                  getNextSetOfData,
                  pageSize: tableRowLimit,
                  totalCount: adminUsers?.meta?.count
                }}
                setSelectedSortOrder={setTableSortOrder}
                defaultSorted={tableSortOrder}
              />
            )}
          </div>
        </div>

        <AddEditAdminUserModal
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          editMode={editMode}
          setEditMode={setEditMode}
          editFormData={editFormData}
          setEditFormData={setEditFormData}
          userGroup={userGroup}
          setUserGroup={setUserGroup}
          searchFilterValue={searchFilterValue}
          title={
            userGroup
              ? STRINGS.USER_GROUP
              : editMode === 'View'
              ? STRINGS.VIEW_USER_PROFILE
              : editMode === 'Edit'
              ? STRINGS.EDIT_USER_PROFILE
              : STRINGS.ADD_USER
          }
          isBodyPaddingNone={true}
          showHeader={true}
          closeModal={() => setIsModalOpen(false)}
          postModalClose={() => setIsModalOpen(false)}
          style={AddEditUserStyles}
          {...modalStyles}
        />

        <ConfirmationPopupModal
          isOpen={isConfirmationModalOpen}
          closeModal={() => setIsConfirmationModalOpen(false)}
          editData={editFormData}
          showHeader={true}
          postModalClose={() => setIsConfirmationModalOpen(false)}
          modalType={confirmationModalType}
          title={`${confirmationModalType} User`}
          showBorderTop={false}
          searchFilterValue={searchFilterValue}
          style={customStyles}
          {...confirmPopupModalStyles}
          isBodyPaddingNone={true}
        />
      </TableWrapper>
    </Card>
  );
};

export default AdminUsers;
