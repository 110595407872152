import { LegendDropdownMenuWrapper } from './styles';
import { Button, CheckBox, Icon } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  checkBoxStyles
} from '../../Universal/NovusDSImports/variants';
import {
  // activeRoadwork,
  camera,
  construction,
  incidents,
  mileposts,
  restFacilities,
  specialEvents
} from '../../Universal/Assets';
import { STRINGS } from '../../Constants/ConstantStrings';

interface FilterDropdownProps {
  checkedItems: any;
  setCheckedItems: any;
  setIsLegendDropDown: any;
  isDropdownHeight?: any;
  handleLayerToggle: (boolean, string) => void;
  handleClear: any;
}

export default function LegendDropdownMenu(props: FilterDropdownProps) {
  const {
    checkedItems,
    setCheckedItems,
    setIsLegendDropDown,
    handleLayerToggle,
    handleClear
  } = props;

  const handleItemClick = (key) => {
    setCheckedItems((prevState) => {
      if (key === 'mileposts') {
        handleLayerToggle(!prevState[key], 'mileposts');
      }
      return {
        ...prevState,
        [key]: !prevState[key]
      };
    });
  };

  const ptcListItems = [
    { key: 'cameras', icon: camera, label: 'Cameras' },
    { key: 'mileposts', icon: mileposts, label: 'Mileposts' }
  ];

  const penndotListItems = [
    { key: 'incidents', icon: incidents, label: 'Incidents' },
    { key: 'construction', icon: construction, label: 'Active Roadwork' },
    { key: 'specialEvents', icon: specialEvents, label: 'Special Events' },
    // { key: 'activeRoadwork', icon: activeRoadwork, label: 'Active Roadwork' },
    { key: 'restFacilities', icon: restFacilities, label: 'Rest Facilities' }
  ];

  return (
    <LegendDropdownMenuWrapper isDropdownHeight={props.isDropdownHeight}>
      <div className="dropdown-menu-header">
        {STRINGS.LEGENDS}
        <div className="d-flex align-items-center justify-content-between gap-1">
          <Button
            {...btnStyles.dangerTertiary}
            onClick={handleClear}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleClear();
              }
            }}
            disabled={!Object.values(checkedItems).includes(true)}
          >
            {STRINGS.CLEAR_ALL}
          </Button>
          <Icon
            icon="close"
            width={18}
            height={18}
            className="cursor-pointer"
            onClick={() => setIsLegendDropDown(false)}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setIsLegendDropDown(false);
              }
            }}
          />
        </div>
      </div>
      <div className="dropdown-body-section">
        <div className="gap-1 d-flex flex-column">
          <h3>{STRINGS.PA_TURNPIKE}</h3>
          <ul>
            {ptcListItems.map((item) => (
              <li
                key={item.key}
                onClick={() => handleItemClick(item.key)}
                tabIndex={0}
                aria-label={item.label}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleItemClick(item.key);
                  }
                }}
                className={checkedItems[item.key] && 'selected-list'}
              >
                <CheckBox
                  {...checkBoxStyles}
                  selected={checkedItems[item.key]}
                  onKeyDown={(event) => {
                    if (event.key === ' ') {
                      handleItemClick(item.key);
                    }
                  }}
                />
                <img src={item.icon} alt={item.icon} loading="lazy" />
                <label>{item.label}</label>
              </li>
            ))}
          </ul>
        </div>
        <div className="gap-1 d-flex flex-column">
          <h3>{STRINGS.PENNDOT}</h3>
          <ul>
            {penndotListItems.map((item) => (
              <li
                key={item.key}
                onClick={() => handleItemClick(item.key)}
                tabIndex={0}
                aria-label={item.label}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleItemClick(item.key);
                  }
                }}
                className={checkedItems[item.key] && 'selected-list'}
              >
                <CheckBox
                  {...checkBoxStyles}
                  selected={checkedItems[item.key]}
                />
                <img src={item.icon} alt={item.icon} loading="lazy" />
                <label>{item.label}</label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </LegendDropdownMenuWrapper>
  );
}
