import { put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import {
  clearCookiesAndLocalstorage,
  safeDecodeJWTToken,
  showError,
  showPopUp
} from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import {
  api_prefix,
  clearJwtCookie,
  clearJwtLocalStorage
} from '../../Constants/constants';
import { LoginRequestType, RequestOtpType } from '../actions/Login';
import {
  loginFailed,
  loginReceived,
  loginRequest,
  logoutReceived,
  logoutRequest,
  requestOtp,
  requestOtpReceived,
  verifyOtp,
  isVerifyOtpOrRequestOtpFailed
} from '../reducers/Login';
import Cookies from 'js-cookie';

function* loginRequestSaga({ payload }: LoginRequestType) {
  clearJwtCookie();
  clearJwtLocalStorage();
  try {
    const json = yield axios
      .post(`${api_prefix}get-token/`, payload)
      .then((response) => {
        if (response?.data?.access) {
          const decoded = safeDecodeJWTToken(response.data.access);
          if (decoded?.mfa_verified) {
            localStorage.setItem('isLoggedIn', 'true');
          }
        }
        response.data.localVsServerTimeDifference =
          new Date().getTime() - new Date(response.data.current_time).getTime();
        return response.data;
      });
    yield put(loginReceived(json));
  } catch (error: any) {
    showError(error);
    yield put(loginFailed(error?.response?.data?.detail));
  }
}

function* requestOtpSaga({ payload }: RequestOtpType) {
  try {
    const url = `${api_prefix}mfa/send_otp/`;
    const json = yield axios
      .post(url, payload.params)
      .then((response) => response.data);
    yield put(
      requestOtpReceived({ isOtpRequested: true, expires: json.expires })
    );
  } catch (error: any) {
    yield put(isVerifyOtpOrRequestOtpFailed());
    showPopUp(error.response.data[0] || error.response.data.message, 'error');
  }
}
function* verifyOtpSaga({ payload }: any) {
  try {
    const url = `${api_prefix}mfa/otp_verify/`;
    const json = yield axios
      .post(url, payload.params)
      .then((response) => response.data);
    localStorage.setItem('isLoggedIn', 'true');
    yield put(loginReceived(json));
  } catch (error: any) {
    yield put(isVerifyOtpOrRequestOtpFailed());
    showPopUp(error.response.data.message, 'error');
  }
}

function* logoutRequestSaga() {
  try {
    const url = `${api_prefix}logout/`;
    const refreshToken = Cookies.get('refresh');
    let params = {};
    if (refreshToken) {
      params = {
        refresh_token: refreshToken
      };
    }
    yield axios.post(url, params);
    yield put(logoutReceived());
  } catch (error: any) {
    if (!window.location.pathname.includes('AdminGuide')) {
      if (error?.response?.status > 400 && error?.response?.status < 500) {
        showPopUp(STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN, 'warning');
      }
      clearCookiesAndLocalstorage();
    }
  }
}

export default function* watchLogin() {
  yield takeLatest(loginRequest, loginRequestSaga);
  yield takeLatest(requestOtp, requestOtpSaga);
  yield takeLatest(verifyOtp, verifyOtpSaga);
  yield takeLatest(logoutRequest, logoutRequestSaga);
}
