export interface GetResponseInterface<T> {
  meta: {
    count: number;
    next: null | string;
    previous: null | string;
  };
  results: Array<T>;
  count?: number;
}

export const defaultGetResponse: GetResponseInterface<any> = {
  meta: {
    count: 0,
    next: null,
    previous: null
  },
  results: []
};
