import { lazy, Suspense } from 'react';
import { Modal } from '../../Universal/NovusDSImports';
import PALoader from '../../SharedComponets/PALoader';

const XMLViewer = lazy(() => import('react-xml-viewer'));

const RequestAndResponseContent = (props: any) => {
  const { selectedRow, isRequest } = props;
  return (
    <Suspense fallback={<PALoader />}>
      <XMLViewer
        xml={isRequest ? selectedRow?.request_xml : selectedRow?.response_xml}
      />
    </Suspense>
  );
};

export default Modal(RequestAndResponseContent);
