import { createSlice } from '@reduxjs/toolkit';
import {
  CommonStateType,
  SetIsMobileScreenAction,
  SetTableLoader,
  SetTableRowLimitAction,
  currentUserDetailsReceivedAction,
  getCurrentUserAction,
  setSelectedClosureAction,
  setSelectedTabAction,
  unSelectRows
} from '../actions/Common';

const initialState: CommonStateType = {
  isMobileScreen: false,
  unSelectSelectedRows: false,
  selectedTab: '',
  currentUserDetails: null,
  selectedClosure: null,
  tableRowLimit: 50,
  showTableLoader: false
};

const CommonSlice = createSlice({
  name: 'Common',
  initialState,
  reducers: {
    setIsMobileScreen: (state, action: SetIsMobileScreenAction) => {
      state.isMobileScreen = action.payload.isMobileScreen;
    },
    unSelectSelectedRows: (state, action: unSelectRows) => {
      state.unSelectSelectedRows = action.payload.shouldUnselect;
    },
    setSelectedTab: (state, action: setSelectedTabAction) => {
      if (action.payload.selectedTab?.length) {
        localStorage.setItem('selectedTab', action.payload.selectedTab);
      } else {
        localStorage.removeItem('selectedTab');
      }
      state.selectedTab = action.payload.selectedTab;
    },
    getCurrentUser: (_state, _action: getCurrentUserAction) => {},
    userDetailsReceived: (state, action: currentUserDetailsReceivedAction) => {
      state.currentUserDetails = action.payload.data;
    },
    setSelectedClosure: (state, action: setSelectedClosureAction) => {
      if (action.payload.selectedClosure) {
        localStorage.setItem(
          'selectedClosure',
          action.payload.selectedClosure.id
        );
      } else {
        localStorage.removeItem('selectedClosure');
      }
      state.selectedClosure = action.payload.selectedClosure;
    },
    setTableRowLimit: (state, action: SetTableRowLimitAction) => {
      state.tableRowLimit = action.payload.tableRowLimit;
    },
    setShowTableLoader: (state, action: SetTableLoader) => {
      state.showTableLoader = action.payload.showTableLoader;
    }
  }
});

export const {
  setIsMobileScreen,
  unSelectSelectedRows,
  setSelectedTab,
  getCurrentUser,
  userDetailsReceived,
  setSelectedClosure,
  setTableRowLimit,
  setShowTableLoader
} = CommonSlice.actions;
export default CommonSlice.reducer;
