export const radius = {
  radius_2: 2,
  radius_4: 4,
  radius_6: 6,
  radius_8: 8,
  radius_10: 10,
  radius_12: 12,
  radius_16: 16,
  radius_20: 20,
  radius_24: 24,
  radius_28: 28,
  radius_32: 32,
  radius_40: 40,
  radius_46: 46,
  radius_rounded: 100
};
