import { PasswordValidation } from '../../Components/Login/styles';
import SvgComponent from '../SvgComponents';
import { STRINGS } from '../../Constants/ConstantStrings';

interface PasswordPropType {
  password: string;
  isDirty: boolean;
}

const PasswordStrengthValidation = (passwordParam: PasswordPropType) => {
  const { password, isDirty } = passwordParam;
  const getValidationWithRegex = (value: string, password: string) => {
    if (password.length) {
      switch (value) {
        case 'totalLength':
          return password.length >= 16;

        case 'upperCase':
          return /[A-Z]/.test(password);

        case 'lowerCase':
          return /[a-z]/.test(password);

        case 'number':
          return /\d/.test(password);

        case 'specialCharacter':
          return /[#$%@.,!^]/.test(password);

        default:
          return false;
      }
    }
    return false;
  };

  return (
    <div className="py-2">
      <h6>{STRINGS.PASSWORD_MUST}:</h6>
      <PasswordValidation>
        <div className="pe-2">
          {isDirty ? (
            getValidationWithRegex('totalLength', password) ? (
              <SvgComponent successSvg={true} />
            ) : (
              <SvgComponent failedSvg={true} />
            )
          ) : (
            <SvgComponent />
          )}
        </div>
        <p
          className={
            isDirty
              ? getValidationWithRegex('totalLength', password)
                ? 'text-success'
                : 'text-danger'
              : ''
          }
        >
          {STRINGS.PASSWORD_LENGTH_VALIDAITON}
        </p>
      </PasswordValidation>
      <PasswordValidation>
        <div className="pe-2">
          {isDirty ? (
            getValidationWithRegex('upperCase', password) ? (
              <SvgComponent successSvg={true} />
            ) : (
              <SvgComponent failedSvg={true} />
            )
          ) : (
            <SvgComponent />
          )}
        </div>
        <p
          className={
            isDirty
              ? getValidationWithRegex('upperCase', password)
                ? 'text-success'
                : 'text-danger'
              : ''
          }
        >
          {STRINGS.PASSWORD_UPPERCASE_VALIDATION}
        </p>
      </PasswordValidation>
      <PasswordValidation>
        <div className="pe-2">
          {isDirty ? (
            getValidationWithRegex('lowerCase', password) ? (
              <SvgComponent successSvg={true} />
            ) : (
              <SvgComponent failedSvg={true} />
            )
          ) : (
            <SvgComponent />
          )}
        </div>
        <p
          className={
            isDirty
              ? getValidationWithRegex('lowerCase', password)
                ? 'text-success'
                : 'text-danger'
              : ''
          }
        >
          {STRINGS.PASSWORD_LOWERCASE_VALIDATION}
        </p>
      </PasswordValidation>
      <PasswordValidation>
        <div className="pe-2">
          {isDirty ? (
            getValidationWithRegex('number', password) ? (
              <SvgComponent successSvg={true} />
            ) : (
              <SvgComponent failedSvg={true} />
            )
          ) : (
            <SvgComponent />
          )}
        </div>

        <p
          className={
            isDirty
              ? getValidationWithRegex('number', password)
                ? 'text-success'
                : 'text-danger'
              : ''
          }
        >
          {STRINGS.PASSWORD_NUMBER_VALIDATION}
        </p>
      </PasswordValidation>
      <PasswordValidation>
        <div className="pe-2">
          {isDirty ? (
            getValidationWithRegex('specialCharacter', password) ? (
              <SvgComponent successSvg={true} />
            ) : (
              <SvgComponent failedSvg={true} />
            )
          ) : (
            <SvgComponent />
          )}
        </div>
        <p
          className={
            isDirty
              ? getValidationWithRegex('specialCharacter', password)
                ? 'text-success'
                : 'text-danger'
              : ''
          }
        >
          {STRINGS.PASSWORD_SPECIAL_CHARACTER_VALIDATION}
        </p>
      </PasswordValidation>
    </div>
  );
};

export default PasswordStrengthValidation;
