import { weaPrefillTexts } from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import { Button, Modal } from '../../Universal/NovusDSImports';
import { btnStyles } from '../../Universal/NovusDSImports/variants';
import { AdminHistoryStyles } from '../globalStyles';

const AdminRequestHistory = (props: any) => {
  const { selectedClosure, getEventType, alertStatusOptions } = props;
  const adminHistoryData = [
    {
      heading: STRINGS.CMAM_WEA_TEXT,
      description: selectedClosure?.wea_short_msg_english
    },
    {
      heading: STRINGS.CMAM_WEA_LONG_TEXT,
      description: selectedClosure?.wea_long_msg_english
    },
    {
      heading: STRINGS.CMAM_SPANISH_WEA_TEXT,
      description:
        selectedClosure?.wea_short_msg_spanish?.length + 1 <
        weaPrefillTexts[selectedClosure?.maintained_by].length
          ? selectedClosure?.wea_short_msg_spanish
          : 'N/A'
    },
    {
      heading: STRINGS.CMAM_SPANISH_WEA_LONG_TEXT,
      description:
        selectedClosure?.wea_short_msg_spanish?.length + 1 <
        weaPrefillTexts[selectedClosure?.maintained_by].length
          ? selectedClosure?.wea_long_msg_spanish
          : 'N/A'
    },
    {
      heading: STRINGS.JURISDICTIONS_AFFECTED,
      description: selectedClosure?.jurisdictions
    },
    {
      heading: STRINGS.ALERT_DURATION_HOUR_S,
      description: selectedClosure?.alert_duration
    },
    {
      heading: STRINGS.CATEGORY,
      description: getEventType(selectedClosure?.event_type)
    },
    {
      heading: STRINGS.EVENT,
      description: selectedClosure?.event
    },
    {
      heading: STRINGS.URGENCY,
      description: selectedClosure?.urgency
    },
    {
      heading: STRINGS.SEVERITY,
      description: selectedClosure?.severity
    },
    {
      heading: STRINGS.CERTAINITY,
      description: selectedClosure?.certainity
    },
    {
      heading: STRINGS.WEA_HANDLING,
      description: selectedClosure?.wea_heandling
    },
    {
      heading: STRINGS.REQUESTED_ALERT_TYPE,
      description: selectedClosure?.requested_alert_type
    },
    {
      heading: STRINGS.ALERT_STATUS,
      description: alertStatusOptions[selectedClosure?.alert_status]
    }
  ];

  return (
    <AdminHistoryStyles>
      <section className="admin-history-content">
        <p className="form-description-text">
          {selectedClosure?.wea_short_msg_english}
        </p>
        {adminHistoryData.map((item) => {
          return (
            <div className="admin-history-data">
              <h1>{item.heading} :</h1>
              <p>{item.description ? item.description : 'N/A'}</p>
            </div>
          );
        })}
      </section>
      <div className="footer">
        <Button
          {...btnStyles.secondary}
          onClick={() => {
            props.setShowAdminRequestsHistory(false);
          }}
        >
          Close
        </Button>
      </div>
    </AdminHistoryStyles>
  );
};

export default Modal(AdminRequestHistory);
