import React from "react";
import { StyledGrid } from "./styles";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function Grid(props: Props) {
  return (
    <StyledGrid className={props.className} {...props}>
      {props.children}
    </StyledGrid>
  );
}
