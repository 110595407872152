import { put, takeLatest } from 'redux-saga/effects';
import { api_prefix } from '../../Constants/constants';
import axios from 'axios';
import { getCurrentUser, userDetailsReceived } from '../reducers/Common';
import { showError } from '../../CommonUtilities/CommonUtilities';

function* getCurrentUserDetailsSaga({ payload }: any) {
  try {
    let url = `${api_prefix}user/currentuser/?fields=${payload.fields}`;
    const json = yield axios.get(url).then((response) => response.data);
    yield put(userDetailsReceived(json));
  } catch (error: any) {
    showError(error?.response?.data?.message, 'error');
    yield put(userDetailsReceived({ data: null }));
  }
}

export default function* watchCommon() {
  yield takeLatest(getCurrentUser, getCurrentUserDetailsSaga);
}
