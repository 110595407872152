import { isPublicView } from '../../settings';
import { PublicViewContainerStyles } from '../PublicView/styles';

export default function AdminGuide() {
  return (
    <>
      {isPublicView ? (
        <PublicViewContainerStyles>
          <iframe
            title="PDF Viewer"
            src="/PAConnectGuidance.pdf"
            width="100%"
            height="100%"
          />
        </PublicViewContainerStyles>
      ) : (
        <iframe
          title="PDF Viewer"
          src="/PAConnectGuidance.pdf"
          width="100%"
          height="100%"
        />
      )}
    </>
  );
}
