import { combineReducers } from 'redux';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import Questions from './ClosureQuestions';
import Closures from './Closures';
import AdminUsers from './AdminUsers';
import PublicView from './PublicView';
import Common from './Common';
import RegisteredUsers from './RegisteredUsers';
import Shapes from './Shapes';
import MapFilters from './MapFilters';
import Messages from './Messages';
import AfterActionReports from './AfterActionReports';
import Resources from './Resources';
import IPAWS from './IPAWS';
import ClientInfo from './ClientInfo';

const appReducers = combineReducers({
  Login,
  ClientInfo,
  ForgotPassword,
  Questions,
  Closures,
  AdminUsers,
  Common,
  PublicView,
  RegisteredUsers,
  Shapes,
  MapFilters,
  Messages,
  AfterActionReports,
  Resources,
  IPAWS
});
const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT_RECEIVED') {
    state = {};
  }
  return appReducers(state, action);
};
export default rootReducer;
