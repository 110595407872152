import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import {
  getAdminUsersFetch,
  getAdminUsersSuccess,
  addNewAdminUser,
  addNewAdminUserSuccess,
  editAdminUser,
  editAdminUserSuccess,
  removeAdminUser,
  removeAdminUserSuccess,
  getAdminGroupsFetch,
  getAdminGroupsSuccess,
  getAdminAgenciesFetch,
  getAdminAgenciesSuccess
} from '../reducers/AdminUsers';

import {
  AddNewAdminType,
  EditAdminType,
  FetchAdminUsersType,
  RemoveAdminType
} from '../actions/AdminUsers';
import { showError, showPopUp } from '../../CommonUtilities/CommonUtilities';
import { api_prefix } from '../../Constants/constants';
import { defaultGetResponse } from '../../GetResponse';
import { STRINGS } from '../../Constants/ConstantStrings';

function* fetchAdminUsers({ payload }: FetchAdminUsersType) {
  try {
    const { params } = payload;
    const adminUserUrl = `${api_prefix}user/`;

    if (!params.ordering) {
      params.ordering = 'first_name,email,id';
    }

    const AdminUsers = yield axios
      .get(adminUserUrl, { params })
      .then((response) => response.data);

    yield put(
      getAdminUsersSuccess({ AdminUsers, shouldAppend: payload.shouldAppend })
    );
  } catch (error) {
    showError(error);
    yield put(getAdminUsersSuccess({ AdminUsers: defaultGetResponse }));
  }
}

function* fetchAdminGroups() {
  try {
    const adminGroupsUrl = `${api_prefix}group/`;

    const AdminGroups = yield axios
      .get(adminGroupsUrl)
      .then((response) => response.data);

    yield put(getAdminGroupsSuccess({ AdminGroups }));
  } catch (err) {
    showError(err);
    yield put(getAdminGroupsSuccess({ AdminGroups: defaultGetResponse }));
  }
}

function* fetchAdminAgencies() {
  try {
    const agencyUrl = `${api_prefix}agency/`;

    const AdminAgencies = yield axios
      .get(agencyUrl)
      .then((response) => response.data);

    yield put(getAdminAgenciesSuccess({ AdminAgencies }));
  } catch (err) {
    showError(err);
    yield put(getAdminAgenciesSuccess({ AdminAgencies: defaultGetResponse }));
  }
}

function* addNewAdmin({ payload }: AddNewAdminType) {
  try {
    const { data, params } = payload;
    const addNewAdminUserUrl = `${api_prefix}user/`;

    yield axios
      .post(addNewAdminUserUrl, data)
      .then((response) => response.data);

    showPopUp(STRINGS.ADMIN_CREATED_SUCCESSFULLY, 'success');

    const adminUserUrl = `${api_prefix}user/`;

    const AdminUsers = yield axios
      .get(adminUserUrl, { params })
      .then((response) => response.data);

    yield put(getAdminUsersSuccess({ AdminUsers }));
  } catch (err) {
    showError(err);
    yield put(addNewAdminUserSuccess({}));
  }
}

function* editAdmin({ payload }: EditAdminType) {
  try {
    const { userId, data, params } = payload;

    const editAdminUrl = `${api_prefix}user/${userId}/`;

    yield axios.patch(editAdminUrl, data).then((response) => response.data);

    showPopUp(STRINGS.ADMIN_UPDATED_SUCCESSFULLY, 'success');
    const adminUserUrl = `${api_prefix}user/`;

    const AdminUsers = yield axios
      .get(adminUserUrl, { params })
      .then((response) => response.data);

    yield put(getAdminUsersSuccess({ AdminUsers }));
  } catch (err) {
    showError(err);
    yield put(editAdminUserSuccess({}));
  }
}

function* removeAdmin({ payload }: RemoveAdminType) {
  try {
    const { userId, params } = payload;
    const removeAdminUrl = `${api_prefix}user/${userId}/`;

    yield axios.delete(removeAdminUrl).then((response) => response.data);
    showPopUp(STRINGS.ADMIN_REMOVED_SUCCESSFULLY, 'success');

    const adminUserUrl = `${api_prefix}user/`;

    const AdminUsers = yield axios
      .get(adminUserUrl, { params })
      .then((response) => response.data);

    yield put(getAdminUsersSuccess({ AdminUsers }));
  } catch (err) {
    showError(err);
    yield put(removeAdminUserSuccess({}));
  }
}

export default function* watchAdminUsers() {
  yield takeLatest(getAdminAgenciesFetch, fetchAdminAgencies);
  yield takeLatest(getAdminGroupsFetch, fetchAdminGroups);
  yield takeLatest(getAdminUsersFetch, fetchAdminUsers);
  yield takeLatest(addNewAdminUser, addNewAdmin);
  yield takeLatest(editAdminUser, editAdmin);
  yield takeLatest(removeAdminUser, removeAdmin);
}
