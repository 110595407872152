// Hooks
import { useEffect, useState, FunctionComponent, lazy, Suspense } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';

// Components
import TableTitle from '../../SharedComponets/TableTitle';
import { TableWrapper } from '../globalStyles';
import { Icon } from '../../Universal/NovusDSImports';

// Styles
import { modalStyles } from '../../Universal/NovusDSImports/variants';

// Utilities
import { RootState } from '../../store';
import { NoManageUsersIcon } from '../../Universal/Assets';
import { format } from 'date-fns';
import { colorState } from '../../Universal/Foundation';
import { getClosuresData } from '../../Store/reducers/Closures';
import axios from 'axios';
import { api_prefix } from '../../Constants/constants';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import {
  getOrderingParam,
  getPermission,
  showPopUp
} from '../../CommonUtilities/CommonUtilities';
import IPAWSRequestsHistory from '../IPAWS/IPAWSRequestsHistory';
import { STRINGS } from '../../Constants/ConstantStrings';
import PALoader from '../../SharedComponets/PALoader';
import { setShowTableLoader } from '../../Store/reducers/Common';
import { useDispatch } from 'react-redux';

const Card = lazy(() => import('../../SharedComponets/Card'));
const GenericTableRT7 = lazy(
  () => import('../GenericTableComponent/GenericTable')
);
const NoDataFound = lazy(() => import('../../SharedComponets/NoDataFound'));

const NoAfterActionReports: FunctionComponent = () => {
  return (
    <Suspense fallback={<PALoader />}>
      <NoDataFound
        src={NoManageUsersIcon}
        description={'No After Action Reports to show'}
        alt={'no-registered-users-img'}
        isAbsolute
        subMsgDescription={'No After Action Reports to show'}
        imgWidth={60}
        imgHeight={60}
      />
    </Suspense>
  );
};

export default function Reports() {
  const [isAdminRequestHistoryOpen, setIsAdminRequestHistoryOpen] =
      useState<boolean>(false),
    [selectedClosure, setSelectedClosure] = useState<any>(null),
    [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false),
    [sortOrder, setSelectedSortOrder] = useState<Array<any>>([
      { id: 'modified', desc: true }
    ]),
    // [showTableLoader, setShowTableLoader] = useState<boolean>(false),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    closuresData = useReduxSelector(
      (state: RootState) => state.Closures.closuresData
    ),
    isFetchingClosuresData = useReduxSelector(
      (state: RootState) => state.Closures.isFetchingClosureData
    ),
    tableLimit = useReduxSelector(
      (state: RootState) => state.Common.tableRowLimit
    );

  const dispatch = useReduxDispatch();

  useEffect(() => {
    // setShowTableLoader(true);
    dispatch(setShowTableLoader({ showTableLoader: true }));
    dispatch(
      getClosuresData({
        status_in: '4',
        limit: tableLimit,
        ordering: getOrderingParam(sortOrder)
      })
    );
  }, [tableLimit, sortOrder]);

  useEffect(() => {
    if (!isFetchingClosuresData) {
      dispatch(setShowTableLoader({ showTableLoader: false }));
    }
  }, [isFetchingClosuresData]);

  const getNextSetOfData = (offset: number) => {
    dispatch(
      getClosuresData({
        status_in: '4',
        limit: tableLimit,
        offset,
        shouldAppend: true,
        ordering: getOrderingParam(sortOrder)
      })
    );
  };

  const genarateReport = (id: number, event_id: number) => {
    if (!isGeneratingReport) {
      setIsGeneratingReport(true);
      axios
        .get(`${api_prefix}closures/${id}/get_after_action_reports/`, {
          responseType: 'blob'
        })
        .then((response) => {
          fileDownload(
            response.data,
            `ActionReport_${event_id}_${dayjs().format('YYYYMMDDHHmmss')}.pdf`
          );
          setIsGeneratingReport(false);
        })
        .catch(() => {
          showPopUp('Something went wrong', 'error');
          setIsGeneratingReport(false);
        });
    }
  };

  const customStyles = {
    content: {
      maxWidth: '60%',
      maxHeight: '60%'
    }
  };

  const getColumns = () => {
    const columns = [
      {
        Header: 'Date',
        accessor: 'created',
        Cell: ({ row: { original } }) => (
          <div>{format(new Date(original.created), 'MM/dd/yyyy hh:mm aa')}</div>
        )
      },
      {
        Header: 'Event Number',
        accessor: 'event_id'
      },
      {
        Header: 'WEA Short Message',
        accessor: 'wea_short_msg_english'
      },
      {
        Header: 'Description',
        accessor: 'description'
      }
    ];
    if (getPermission(currentUser, 'download_after_action_report')) {
      columns.push({
        Header: 'Report',
        Cell: ({ row: { original } }) => (
          <div
            className={`${isGeneratingReport ? colorState.text.disabled : ''}  d-flex align-items-center gap-2 link`}
            tabIndex={0}
            aria-label="generate-pdf"
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                genarateReport(original.id, original.event_id);
              }
            } }
            onClick={() => genarateReport(original.id, original.event_id)}
          >
            <div
              className={`${isGeneratingReport && 'disabled-generated-pdf-icon'} generate-pdf-icon`}
            >
              <Icon
                icon="sticker_square"
                stroke={isGeneratingReport
                  ? colorState.icon.brand['primary-disabled']
                  : colorState.icon.brand.primary}
                width={18}
                height={18} />
            </div>
            <div
              className={`${isGeneratingReport ? 'disabled-link' : 'link'} text-decoration-none`}
            >
              Generate
            </div>
          </div>
        ),
        accessor: ''
      });
    }
    if (getPermission(currentUser, 'view_capmessage')) {
      columns.push({
        Header: 'IPAWS Requests History',
        Cell: ({ row: { original } }) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              setSelectedClosure(original);
              setIsAdminRequestHistoryOpen(true);
            } }
            tabIndex={0}
            aria-label="generate-pdf"
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setSelectedClosure(original);
                setIsAdminRequestHistoryOpen(true);
              }
            } }
            className="link"
          >
            View
          </div>
        ),
        accessor: ''
      });
    }
    return columns;
  };

  return (
    <>
      <Card className="w-100">
        <TableWrapper id="table-group">
          <TableTitle title={'After Action Reports'} />
          <div id="table">
            <div className="w-100 h-100 d-flex overflow-auto flex-grow-1 flex-column">
              {!closuresData ? (
                <PALoader />
              ) : (
                <Suspense fallback={<PALoader />}>
                  <GenericTableRT7
                    tableData={closuresData.results}
                    columns={getColumns()}
                    noDataFoundScreen={<NoAfterActionReports />}
                    serialNumber
                    infiniteScrollSettings={{
                      getNextSetOfData,
                      pageSize: tableLimit,
                      totalCount: closuresData.meta.count
                    }}
                    setSelectedSortOrder={setSelectedSortOrder}
                    defaultSorted={sortOrder}
                  />
                </Suspense>
              )}
            </div>
          </div>
        </TableWrapper>
      </Card>
      {isAdminRequestHistoryOpen && (
        <IPAWSRequestsHistory
          isBodyPaddingNone={true}
          isOpen={isAdminRequestHistoryOpen}
          postModalClose={() => setIsAdminRequestHistoryOpen(false)}
          selectedClosure={selectedClosure}
          showHeader={true}
          title={`${STRINGS.IPAWS_REQUESTS_HISTORY_FOR} "${selectedClosure?.wea_short_msg_english}"`}
          {...modalStyles}
          style={customStyles}
        />
      )}
    </>
  );
}
