import { createSlice } from '@reduxjs/toolkit';
import { AfterActionReportsInterface } from '../actions/AfterActionReports';

const initialState: AfterActionReportsInterface = {
  afterActionReports: null,
  isFetchingAfterActionReports: false
};

export const AfterActionReportsSlice = createSlice({
  name: 'AfterActionReports',
  initialState,
  reducers: {
    getAfterActionReports: (state, _action) => {
      state.isFetchingAfterActionReports = true;
    },
    afterActionReportsReceived: (state, action) => {
      state.isFetchingAfterActionReports = false;
      state.afterActionReports = action.payload.afterActionReports;
    }
  }
});

export const { getAfterActionReports, afterActionReportsReceived } =
  AfterActionReportsSlice.actions;

export default AfterActionReportsSlice.reducer;
