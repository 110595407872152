import './Sentry';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import './Middlewares/axiosMiddleWare';
import './imports';
import { store } from './store';
import { theme } from './styles/theme';
import ReactGA from 'react-ga4';
import { BASE_URL, GOOGLE_ANALYTICS_ID } from './settings';
import axios from 'axios';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.patch['Content-Type'] =
  'application/json; charset=utf-8';
axios.defaults.headers.put['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

try {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
} catch (e) {
  console.error('Error initializing Google Analytics', e);
}

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
