import { FunctionComponent, useEffect } from 'react';
import { inputStyles } from '../../Universal/NovusDSImports/variants';
import { useFormContext } from 'react-hook-form';
import { TextArea } from '../../Universal/NovusDSImports';
import { weaTextMessageMap } from '../../CommonUtilities/CommonUtilities';

interface WEATextMessageTextAreaProps {
  name: string;
  maintainedBy: 'penndot' | 'ptc' | 'both' | '';
  maxLength: number;
  [prop: string]: any;
}

const WEAMessageTextArea: FunctionComponent<WEATextMessageTextAreaProps> = (
  props
) => {
  const { name, maintainedBy, maxLength } = props;

  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    let value: string = getValues(name);
    Object.values(weaTextMessageMap).forEach((message) => {
      if (value?.startsWith(message)) {
        value = value.replaceAll(message, '');
      }
    });

    if ((weaTextMessageMap[maintainedBy] + value).length > maxLength) {
      setValue(
        name,
        (weaTextMessageMap[maintainedBy] + value).slice(0, maxLength)
      );
    } else {
      setValue(name, weaTextMessageMap[maintainedBy] + value);
    }
  }, [maintainedBy]);

  const isInputAllowed = (event) => {
    return (
      [
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'End',
        'Home'
      ].includes(event.key) ||
      (event.ctrlKey && event.code === 'KeyC')
    );
  };

  const onKeyDown = (event) => {
    const invalidSelectionEnd = weaTextMessageMap[maintainedBy].length;
    if (
      (maintainedBy && event.key === 'Backspace' && event.ctrlKey) ||
      ((event.target.selectionStart < invalidSelectionEnd ||
        (event.target.selectionEnd === invalidSelectionEnd &&
          event.key === 'Backspace') ||
        event.target.selectionEnd < invalidSelectionEnd) &&
        !isInputAllowed(event))
    ) {
      event.preventDefault();
    }
  };

  const onPaste = (event) => {
    const invalidSelectionEnd = weaTextMessageMap[maintainedBy].length;
    if (event.target.selectionEnd < invalidSelectionEnd) {
      event.preventDefault();
    }
  };

  return (
    <TextArea
      {...inputStyles}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      {...props}
    />
  );
};

export default WEAMessageTextArea;
