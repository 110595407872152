import styled from 'styled-components';
import {
  colorState,
  device,
  radius,
  spacing,
  typography
} from '../Universal/Foundation';

interface Props {
  isOpen?: boolean;
  isCollapsableMap?: boolean;
}

interface tabItemProps {
  selectedtab?: boolean;
  disabled?: any;
  defaultTextColor?: boolean;
  defaultRadius?: boolean;
}

export const primaryNavWidth = 340;
export const rightNavWidth = 430;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  #layout-container {
    background-color: ${colorState.border.default.secondary};
  }

  #top-nav {
    z-index: 9;
  }
`;

export const MainContainer = styled.div<Props>`
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  box-shadow: inset 0 0 10px #eee;
  position: relative;
  padding: 12px;
  padding-bottom: 0;

  .grid-view {
    display: flex;
    gap: 6px;

    .header {
      h1 {
        font-size: ${typography.size.tl};
        font-weight: ${typography.weight.semibold};
      }
    }

    .transition {
      transition: width 0.3s ease;
    }

    .transition-active {
      width: 0;
    }
  }

  .left,
  .middle,
  .right {
    &:focus-visible {
      border: solid 1px ${colorState.border.brand.primary};
      border-radius: 12px;
      outline: none;
    }
  }

  .left {
    width: ${primaryNavWidth}px;
    position: relative;
    z-index: 1;

    @media ${device.laptop} {
      width: ${primaryNavWidth - 100}px;
    }

    @media ${device.laptopL} {
      width: ${rightNavWidth - 160}px;
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    position: relative;

    .filter-btn {
      position: absolute;
      right: 60px;
      top: 12px;
    }

    .map-collapsable-wrapper {
      display: flex;
      justify-content: space-between;
      padding: ${spacing.spacing_4}px ${spacing.spacing_10}px;
      background-color: ${colorState.surface.default.tertiary};
      border-top: 1px solid ${colorState.border.default.primary};
      margin: 0 12px 0 12px;

      .header {
        color: ${colorState.text.primary};
        font-weight: ${typography.weight.medium};
        font-size: ${typography.size.ts};
        line-height: 24px;
      }

      .collapse-icon {
        cursor: pointer;
        background-color: ${colorState.surface.default.primary};
        border-radius: ${radius.radius_4}px;
      }
      .collapse-down-icon:hover,
      .collapse-up-icon:hover {
        background-color: ${colorState.surface.brand.primary};
        border-radius: ${radius.radius_6}px;
        svg path {
          stroke: ${colorState.icon.default['primary-inverse']};
        }
      }
    }
  }

  .right {
    width: ${rightNavWidth}px;

    @media ${device.mobileL} {
      width: 100% !important;
      position: fixed !important;
    }

    @media ${device.tablet} {
      width: 50%;
      height: 100%;
      right: 0;
      top: 0;
      position: absolute;
      z-index: 999;
      padding: 10px;
    }

    @media ${device.laptop} {
      width: ${rightNavWidth - 100}px;
      height: 100%;
      right: 0;
      top: 0;
      position: absolute;
      z-index: 10;
      padding: 10px;
    }

    @media ${device.laptopL} {
      width: ${rightNavWidth - 120}px;
    }
  }

  @media ${device.mobileL} {
    .grid-view {
      flex-direction: column;

      .middle {
        margin-left: 0 !important;
      }

      .middle {
        overflow: inherit;
      }

      .left,
      .right,
      .middle {
        width: 100%;
      }
    }
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .ipwas-status {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: ${spacing.spacing_16}px;
    padding-bottom: ${spacing.spacing_16}px;
    padding-left: ${spacing.spacing_20}px;
    padding-right: ${spacing.spacing_20}px;
    border-bottom: 1px solid ${colorState.border.default.primary};

    h1 {
      font-size: ${typography.size.txs};
      color: ${colorState.text.primary};
      font-weight: ${typography.weight.regular};
      cursor: default;
      padding-right: 4px;
      margin-bottom: 0;
    }
  }

  .register-users-table {
    height: 75%;
  }
  .register-users-map {
    padding: 0;
    margin: 0 12px 12px 12px;
    border: 2px solid ${colorState.border.default.primary};
    height: 40%;
  }
`;

// Table styles
export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 0px 12px;
  height: 100%;

  #table-tabs {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    background-color: ${colorState.surface.default.tertiary};
    border-bottom: 1px solid ${colorState.border.default.secondary};
    flex-wrap: wrap;

    &:focus-visible {
      outline: none;
      border: solid 1px ${colorState.border.brand.primary};
      border-radius: ${spacing.spacing_8}px;
    }

    .left-wrapper {
      display: flex;
      align-items: center;

      &:focus-visible {
        outline: none;
        border: solid 1px ${colorState.border.brand.primary};
        border-radius: ${radius.radius_28}px;
      }
    }

    .right-wrapper {
      display: flex;
      align-items: center;

      .custom-select-width {
        width: 200px;
      }

      h3 {
        margin-bottom: 0;
        text-wrap: nowrap;
        font-size: ${typography.size.ts};
        font-weight: ${typography.weight.medium};
        color: ${colorState.text['text-cursor-black']};
      }

      &:focus-visible {
        outline: none;
        border: solid 1px ${colorState.border.brand.primary};
        border-radius: ${radius.radius_28}px;
      }
      .participant-variant-tab {
        color: ${colorState.text.brand.primary};
        background-color: ${colorState.actions.secondary[
          'secondary-bg-pressed'
        ]};
      }
    }
  }

  .search-input {
    margin: ${spacing.spacing_12}px ${spacing.spacing_24}px;
    background-color: ${colorState.background.muted};
    border-radius: ${radius.radius_46}px;
    input {
      border-radius: ${radius.radius_46}px;
    }
  }

  @media ${device.mobileL} {
    #table-tabs {
      .right-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  #table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    position: relative;
  }
`;

export const TabItems = styled.div<tabItemProps>`
  display: flex !important;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: ${(props) =>
    props.defaultRadius ? radius.radius_8 : radius.radius_28}px;
  font-size: ${typography.size.ts};
  font-weight: ${typography.weight.medium};
  background: ${(props) =>
    props.selectedtab
      ? colorState.actions.primary['primary-bg-selected']
      : colorState.actions['default-bg-secondary']};
  color: ${(props) =>
    props.selectedtab
      ? colorState.text['primary-inverse']
      : props.defaultTextColor
      ? colorState.text.tertiary
      : colorState.text.secondary};
  box-shadow: ${(props) =>
    props.selectedtab ? '' : '0px 1px 2px 0px rgba(23, 23, 28, 0.06)'};
  opacity: ${(props) => (props.disabled ? '70%' : '')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  max-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;

  &:hover {
    color: ${(props) =>
      props.disabled
        ? ''
        : props.selectedtab
        ? colorState.text['primary-inverse']
        : colorState.text.brand.primary};

    svg {
      path {
        stroke: ${(props) => !props.disabled && colorState.icon.brand.primary};
      }
    }
  }

  svg {
    path {
      stroke: ${(props) => props.selectedtab && 'white'};
    }
  }

  &:focus-visible {
    outline: none;
    border: solid 1px ${colorState.border.brand.primary};
    border-radius: ${radius.radius_28}px;
  }

  .eraser-icon {
    &:hover {
      path {
        stroke: ${(props) => !props.disabled && colorState.icon.brand.primary};
      }
    }
  }
`;

// Form styles
export const FormSegment = styled.form`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .edit-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colorState.border.default.secondary};
    background-color: ${colorState.surface.default.tertiary};
    padding: ${spacing.spacing_12}px ${spacing.spacing_20}px;

    p {
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.medium};
      line-height: ${spacing.spacing_20}px;
    }
  }

  .formset-fields {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;

    .form-group {
      margin-bottom: 20px;

      .border-bottom {
        border: 1px solid ${colorState.border.default.primary};
      }

      .hint-text {
        display: flex;
        justify-content: space-between;

        span {
          padding-right: 14px;

          &:last-child {
            padding-right: 0;
          }
        }
      }

      p {
        color: ${colorState.text.tertiary};
        font-size: ${typography.size.txs};
        font-weight: ${typography.weight.regular};
        margin: 0;
        &:last-child {
          padding: 0;
          text-align: right;
        }
      }

      h6 {
        padding-bottom: 8px;
        font-weight: ${typography.weight.medium};
        font-size: ${typography.size.ts};
        color: ${colorState.text.secondary};
        margin: 0;
      }
    }

    .affected-area {
      margin-top: 0;
      margin-left: 25px;
    }

    .checkbox-label {
      cursor: pointer;
      color: ${colorState.text.secondary} !important;
      font-size: ${typography.size.ts}px !important;
      font-weight: ${typography.weight.medium} !important;
      text-align: left !important;

      span {
        color: ${colorState.text.danger.primary};
      }
    }

    // custom radio
    .custom-radio-group {
      .form-group {
        display: flex;
        gap: 16px;
      }

      .asterik {
        color: ${colorState.text.danger.primary};
      }

      label {
        padding-bottom: 12px;
        font-weight: ${typography.weight.medium};
        font-size: ${typography.size.tm};
        color: ${colorState.text.secondary};
      }

      .checkbox-heading-wrapper {
        b {
          color: ${colorState.text.secondary};
          font-weight: ${typography.weight.medium};
        }
      }

      h1 {
        padding-bottom: 20px;
        font-weight: ${typography.weight.semibold};
        font-size: ${typography.size.tl};
        color: ${colorState.text.primary};
        margin: 0;
      }
    }

    // custom checkbox
    .custom-checkbox-group {
      display: flex;
      align-items: center;
      margin-bottom: ${spacing.spacing_24}px;

      label {
        font-size: ${typography.size.ts};
        font-weight: ${typography.weight.medium};
        margin-bottom: 0;
        margin-left: 12px;
      }
    }
  }

  .error-text {
    color: ${colorState.text.danger.primary} !important;
    text-align: start !important;
    font-size: ${typography.size.txs} !important;
  }

  .footer {
    border-top: 1px solid ${colorState.border.default.tertiary};
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background: white;
  }

  .formset-fields,
  .footer {
    padding: 16px;
  }
`;

export const PopupWindow = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 12px;
  min-width: 300px;
  max-width: 400px;
  box-shadow: 0px 24px 48px -12px rgba(23, 23, 28, 0.18);
  background-color: ${colorState.surface.default.tertiary};
  border-radius: ${spacing.spacing_12}px;
  border: 1px solid white;
  font-family: 'Poppins', sans-serif;
  font-display: fallback;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;

    h5 {
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.medium};
      color: ${colorState.text.secondary};
      margin: 0;
    }

    .close-icon {
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    background-color: white;
    border-radius: 10px;
    padding: 12px;

    h6 {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.semibold};
      color: ${colorState.text.primary};
      margin-bottom: 0;
    }

    .no-camera-feed {
      border: 1px solid ${colorState.border.default.primary};
      padding: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        padding-left: 12px;
        text-align: center;
        color: ${colorState.text.tertiary};
      }
    }

    button {
      display: block !important;
    }

    .latitude-longitude-section {
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: solid 1px ${colorState.border.default.secondary};
      padding-bottom: 12px;
    }

    .group {
      margin-bottom: 12px;
      word-break: break-word;
    }

    label,
    p {
      font-size: ${typography.size.txs};
    }

    label {
      color: ${colorState.text.tertiary};
      font-size: ${typography.size.txs};
      font-weight: ${typography.weight.regular};
      padding-bottom: 6px;
    }

    p {
      margin: 0;
      color: ${colorState.text.primary};
      font-weight: ${typography.weight.semibold};
    }
  }
`;

export const UserGroupProfileSection = styled.section`
  p {
    margin: 0;
  }

  .change-user-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }

  .user-details {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
  }

  .text-name,
  .text-email {
    font-size: ${typography.size.ts};
    margin: 0;
    line-height: 20px;
  }

  .text-name {
    color: ${colorState.text.primary};
    font-weight: ${typography.weight.medium};
  }

  .text-email {
    color: ${colorState.text.tertiary};
    font-weight: ${typography.weight.regular};
    word-break: break-all;
  }
`;

export const TextNote = styled.p`
  color: ${colorState.surface.default['primary-inverse']};
  font-size: ${typography.size.txs};
  font-weight: ${typography.weight.regular};
  margin-bottom: ${spacing.spacing_24}px;

  span {
    font-size: ${typography.size.ts};
    font-weight: ${typography.weight.medium};
  }
`;

export const PopupConfirmStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: ${spacing.spacing_24}px;

  h2 {
    font-size: ${typography.size.ts};
    font-weight: ${typography.weight.medium};
    color: ${colorState.text.primary};
  }

  .status-message {
    font-size: ${typography.size.txs};
    font-weight: ${typography.weight.regular};
    line-height: 16px;
    padding: 12px 8px;
    border-radius: 8px;
    margin-bottom: 0;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
  }
`;

export const MapComponentWrapper = styled.div`
  height: 100%;
  padding: 12px;

  @media ${device.laptop} {
    height: 100vh;
  }

  @media ${device.mobileL} {
    height: 500px;
  }

  .gm-fullscreen-control {
    bottom: 14px !important;
  }
  .gm-style-cc {
    display: none;
  }

  .gm-style div:nth-child(4) .gmnoprint[role='menubar'] {
    border-radius: 44px !important;
    background: #fff;
    display: flex;
    left: 280px !important;
    gap: ${spacing.spacing_4}px;
    padding: ${spacing.spacing_4}px;
    top: 12px !important;
  }

  .gm-style div:nth-child(4) .gmnoprint[role='menubar'] button {
    box-shadow: none !important;
    padding: ${spacing.spacing_8}px!important;
    border-radius: 36px !important;

    &:hover,
    &:focus-within {
      background-color: #edf3fd !important;
    }
  }

  .polygon-clear-button {
    border-radius: 44px;
    position: absolute;
    background-color: white;
    color: ${colorState.text.secondary};
    font-size: ${typography.size.ts};
    line-height: 20px;
    font-weight: ${typography.weight.medium};
    padding: ${spacing.spacing_10}px ${spacing.spacing_20}px;
    left: 276px;
    top: 16px;
    cursor: pointer;

    @media ${device.laptopL} {
      top: 60px;
      left: 20px;
    }
  }

  .custom-legend-wrapper {
    margin-bottom: 0;
    position: absolute;
    bottom: 10px;
    background-color: white;
    list-style: none;
    padding: ${spacing.spacing_8}px ${spacing.spacing_12}px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: ${radius.radius_12}px;
    display: flex;
    right: 5%;
    gap: 14px;
    display: flex;
    flex-direction: row;

    li {
      gap: ${spacing.spacing_8}px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: ${radius.radius_10}px;
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.medium};
      cursor: default;

      .dashed {
        border: 4px dashed black;
        width: 30px;
        opacity: unset;
        margin: 0;
      }
    }

    @media ${device.laptopXL} {
      gap: ${spacing.spacing_12}px;
      left: auto;
      right: 60px;
      display: flex;
      flex-direction: column;
      transform: scale(0.85);
      margin: 0;
      align-items: flex-start;
      bottom: 0;
    }
  }
`;

export const DrawControlButtonsWrapper = styled.div<any>`
  border-radius: 44px;
  display: flex;
  position: absolute;
  background-color: white;
  color: ${colorState.text.secondary};
  font-size: ${typography.size.ts};
  line-height: 20px;
  font-weight: ${typography.weight.medium};
  padding: 4px ${spacing.spacing_6}px;
  left: 276px;
  top: 15px;
  cursor: pointer;
  gap: 4px;

  .draw-polygon-btn {
    background-color: ${colorState.surface.brand.tertiary};
    border-radius: 36px;
  }

  @media ${device.laptopL} {
    top: 60px;
    left: 20px;
  }
`;

export const AdminHistoryStyles = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  .admin-history-content {
    overflow: auto;
    flex: 1;
    padding: 16px;
    gap: 12px;
    display: flex;
    flex-direction: column;

    .admin-history-data {
      display: flex;
      align-items: start;
      gap: 4px;
      line-height: 16px;
      cursor: default;

      h1 {
        font-weight: ${typography.weight.semibold};
        color: ${colorState.text.primary};
        text-wrap: nowrap;
      }
      p {
        font-weight: ${typography.weight.regular};
        color: ${colorState.text.tertiary};
      }

      h1,
      p {
        font-size: ${typography.size.txs};
        margin-bottom: 0;
      }
    }

    .form-description-text {
      color: ${colorState.text.warning.primary};
      font-size: ${typography.size.txs};
      font-weight: ${typography.weight.regular};
      margin-bottom: 0;
    }
  }
  .footer {
    border-top: 1px solid ${colorState.border.default.tertiary};
    display: flex;
    justify-content: flex-end;
    background: white;
    padding: ${spacing.spacing_12}px ${spacing.spacing_24}px;
  }
`;
