import { useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import {
  composeMessageRequest,
  getChatHistory
} from '../../Store/reducers/RegisteredUsers';
import { RootState } from '../../store';
import {
  Avatar,
  Button,
  CheckBox,
  Icon,
  TextArea
} from '../../Universal/NovusDSImports';
import dayjs from 'dayjs';
import { ChatBoxStyles, MessageInput } from './styles';
import {
  btnStyles,
  checkBoxStyles,
  inputStyles
} from '../../Universal/NovusDSImports/variants';
import { getPermission } from '../../CommonUtilities/CommonUtilities';
import PALoader from '../../SharedComponets/PALoader';
import NoDataFound from '../../SharedComponets/NoDataFound';
import { NoManageUsersIcon } from '../../Universal/Assets';
import { STRINGS } from '../../Constants/ConstantStrings';
import { colorState } from '../../Universal/Foundation';

const ChatBox = (props: any) => {
  const {
    selectedChat,
    selectedMessages,
    setSelectedMessages,
    setSelectedUsers,
    setIsSelectAll,
    isSelectAll,
    selectedUsers
  } = props;
  const chatHistory = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.chatHistory
    ),
    isFetchingChatHistory = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.isFetchingChatHistory
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    isMessageRequestInProgress = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.isMessageRequestInProgress
    ),
    [message, setMessage] = useState<any>(''),
    dispatch = useReduxDispatch();

  useEffect(() => {
    if (selectedChat && getPermission(currentUser, 'view_chatmessage')) {
      dispatch(getChatHistory({ userId: selectedChat.id }));
    }
  }, [selectedChat, dispatch, currentUser]);

  const handleMessageSelect = (messageID: number) => {
    if (selectedUsers && Object.keys(selectedUsers)?.length)
      setSelectedUsers(null);
    if (isSelectAll) setIsSelectAll(false);
    const data = [...selectedMessages];
    if (selectedMessages.includes(messageID))
      data.splice(data.indexOf(messageID), 1);
    else data.push(messageID);
    setSelectedMessages(data);
  };

  useEffect(() => {
    const element =
      document.getElementsByClassName('chat-box-wrapper') &&
      document.getElementsByClassName('chat-box-wrapper')[0];
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [chatHistory]);

  const handleSendClick = () => {
    const composeMessagePayload = {
      add_to_website: false,
      saftey_tip: false,
      text_message_body: message,
      voice_message_body: message,
      registered_users: [selectedChat.id],
      fetchMessages: true
    };
    composeMessagePayload['closure'] = selectedClosure?.id;
    dispatch(composeMessageRequest({ composeMessagePayload }));
  };

  return isFetchingChatHistory ? (
    <PALoader />
  ) : (
    <div className="d-flex flex-column justify-content-between flex-grow-1 overflow-auto">
      <ChatBoxStyles className="chat-box-wrapper">
        {selectedChat ? (
          chatHistory?.length ? (
            chatHistory?.map((message: any) => {
              return (
                <div
                  className={`${
                    message.sent_by
                      ? 'chat-outgoing-messages-wrapper'
                      : 'chat-incoming-messages-wrapper'
                  } messages-wrapper`}
                >
                  <div
                    className={`d-flex gap-4 ${
                      message.sent_by && 'align-items-start'
                    }`}
                  >
                    {!message.sent_by && (
                      <CheckBox
                        selected={selectedMessages.includes(message.id)}
                        onClick={() => handleMessageSelect(message.id)}
                        onKeyDown={(e: any) => {
                          if (e.key === ' ') {
                            handleMessageSelect(message.id);
                          }
                        }}
                        {...checkBoxStyles}
                      />
                    )}
                    <div
                      className={`d-flex flex-column ${
                        message.sent_by
                          ? 'align-items-end'
                          : 'align-items-start'
                      }`}
                    >
                      <div
                        className={`${
                          message.sent_by
                            ? 'outgoing-messages'
                            : 'incoming-messages'
                        } messages`}
                      >
                        <div className="d-flex flex-column gap-1">
                          {!message.sent_by && message.read_by && (
                            <p className="read-by-user">
                              Read by: {message.read_by.email}
                            </p>
                          )}
                          {message.sent_by && (
                            <p className="sent-user">
                              {message.sent_by?.email}
                            </p>
                          )}
                          <p
                            className={`${
                              !message.sent_by && !message.is_read
                                ? 'unread-msg'
                                : 'read-msg'
                            } `}
                          >
                            {message.message_body}
                          </p>
                        </div>
                      </div>
                      <p
                        className={`${
                          message.sent_by
                            ? 'sent-date-time-wrapper'
                            : 'recieved-date-time-wrapper'
                        } date-time-wrapper`}
                      >
                        {dayjs(message.created).format('MM/DD/YYYY hh:mm A')}
                      </p>
                    </div>
                    {message.sent_by && <Avatar size="s" />}
                  </div>
                </div>
              );
            })
          ) : (
            <NoDataFound
              src={NoManageUsersIcon}
              description={STRINGS.NO_MESSAGES}
              alt={'no-registered-users-img'}
              isAbsolute
              // subMsgDescription={
              //   STRINGS.YOUR_LIST_OF_USERS_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_USERS_YET
              // }
              imgWidth={60}
              imgHeight={60}
            />
          )
        ) : null}
      </ChatBoxStyles>
      {selectedChat?.is_active && (
        <MessageInput>
          <TextArea
            name="messageBox"
            className="w-100"
            hideLabel
            rows={1}
            onChange={(e: any) => setMessage(e.target.value)}
            value={message}
            type="text"
            placeholder={'Send a Message'}
            isMargin={false}
            {...inputStyles}
          ></TextArea>
          <Button
            containsIcon
            className="send-btn"
            onClick={handleSendClick}
            onkeyDown={(event: any) => {
              if (event.key === 'Enter') {
                handleSendClick;
              }
            }}
            disabled={!message?.length || isMessageRequestInProgress}
            {...btnStyles.primary}
          >
            <Icon
              icon="send_03"
              stroke={
                !message?.length || isMessageRequestInProgress
                  ? colorState.icon.default.disabled
                  : colorState.icon.default['primary-inverse']
              }
            />
          </Button>
        </MessageInput>
      )}
    </div>
  );
};

export default ChatBox;
