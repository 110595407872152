import OtpInput from 'react18-input-otp';
import { STRINGS } from '../../Constants/ConstantStrings';
import { btnStyles } from '../../Universal/NovusDSImports/variants';
import { OtpStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useCallback, useEffect, useState } from 'react';

const OtpVerifyPage = ({ selectedType, setOtp, otp, requestOTPfn }: any) => {
  const expires = useSelector((state: RootState) => state.Login.otpExpiryTime),
    localVsServerTimeDifference = useSelector(
      (state: RootState) => state.Login.localVsServerTimeDifference
    );

  const getCountdownText = useCallback(() => {
    const expiresTimestamp = new Date(expires).getTime(); // gets converted to browser timezone and we get the unix timestamp
    let currentTimestamp = new Date().getTime(); // gets the current timezone in unix timestamp
    // kept the error threshold as 10ms
    if (Math.abs(localVsServerTimeDifference) > 10) {
      currentTimestamp -= localVsServerTimeDifference;
    }
    if (expiresTimestamp > currentTimestamp) {
      const remainingTime = expiresTimestamp - currentTimestamp;
      const minutes = Math.floor(remainingTime / 60000);
      const seconds = Math.floor((remainingTime % 60000) / 1000);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} sec`;
    } else {
      return `00:00 sec`;
    }
  }, [expires, localVsServerTimeDifference]);

  const [timeText, setTimeText] = useState<string>(getCountdownText());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeText(getCountdownText());
    }, 900);
    return () => clearInterval(interval);
  }, [getCountdownText, expires]);

  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  return (
    <div className="d-flex flex-column">
      <p className="text-description">
        {STRINGS.ENTER_6_DIGIT_VERIFICATION_CODE}{' '}
        {selectedType?.id === 'email' ? 'email' : 'mobile number'}:
        <span>{selectedType?.label}</span>
      </p>
      <div className="otp-input">
        <OtpInput
          className="input-styles"
          value={otp}
          onChange={handleChange}
          numInputs={6}
          isInputNum={true}
          separateAfter={1}
          inputStyle={OtpStyles}
        />
      </div>
      <div className="otp-timer">
        <p>{timeText}</p>
      </div>
      <p className="text-note">
        <span>Note:</span>
        {`${STRINGS.CODE_VALIDATION_FOR_5_MINUTES}.`}
      </p>
      <div className="section-verification">
        {STRINGS.DIDNT_RECEIVE_CODE}
        <a
          onClick={requestOTPfn}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              requestOTPfn();
            }
          }}
          {...btnStyles.tertiary}
          role="link"
          tabIndex={0}
        >
          <span className="cursor-pointer">
            {STRINGS.RESEND_VERIFICATION_CODE}
          </span>
        </a>
      </div>
    </div>
  );
};

export default OtpVerifyPage;
