import { createSlice } from '@reduxjs/toolkit';
import { defaultGetResponse } from '../../GetResponse';

import {
  GetAdminUsersType,
  GetAdminGroupsType,
  GetAdminAgenciesType,
  AdminUsersType,
  FetchAdminUsersType,
  AddNewAdminType,
  AddNewAdminSuccessType,
  EditAdminType,
  EditAdminSuccessType,
  RemoveAdminType,
  RemoveAdminSuccessType,
  AdminGroupsType,
  AdminAgenciesType
} from '../actions/AdminUsers';

const initialState: AdminUsersType = {
  adminUsers: null,
  adminGroups: defaultGetResponse,
  adminAgencies: defaultGetResponse,
  isFetchingAdminUsers: false,
  isSubmittingAdmin: false
};

export const AdminUsersSlice = createSlice({
  name: 'AdminUsers',
  initialState,
  reducers: {
    getAdminUsersFetch: (state, _action: FetchAdminUsersType) => {
      state.isFetchingAdminUsers = true;
    },
    getAdminUsersSuccess: (state, action: GetAdminUsersType) => {
      if (action.payload.shouldAppend && state.adminUsers?.results) {
        action.payload.AdminUsers.results = [
          ...state.adminUsers.results,
          ...action.payload.AdminUsers.results
        ];
      }
      state.adminUsers = action.payload.AdminUsers;
      state.isFetchingAdminUsers = false;
      state.isSubmittingAdmin = false;
    },
    addNewAdminUser: (state, _action: AddNewAdminType) => {
      state.isSubmittingAdmin = true;
    },
    addNewAdminUserSuccess: (state, _action: AddNewAdminSuccessType) => {
      state.isSubmittingAdmin = false;
    },
    editAdminUser: (state, _action: EditAdminType) => {
      state.isSubmittingAdmin = true;
    },
    editAdminUserSuccess: (state, _action: EditAdminSuccessType) => {
      state.isSubmittingAdmin = false;
    },
    removeAdminUser: (state, _action: RemoveAdminType) => {
      state.isSubmittingAdmin = true;
    },
    removeAdminUserSuccess: (state, _action: RemoveAdminSuccessType) => {
      state.isSubmittingAdmin = false;
    },
    getAdminGroupsFetch: (_state, _action: AdminGroupsType) => {},
    getAdminGroupsSuccess: (state, action: GetAdminGroupsType) => {
      state.adminGroups = action.payload.AdminGroups;
    },
    getAdminAgenciesFetch: (_state, _action: AdminAgenciesType) => {},
    getAdminAgenciesSuccess: (state, action: GetAdminAgenciesType) => {
      state.adminAgencies = action.payload.AdminAgencies;
    }
  }
});

export const {
  getAdminUsersFetch,
  getAdminUsersSuccess,
  addNewAdminUser,
  addNewAdminUserSuccess,
  editAdminUser,
  editAdminUserSuccess,
  removeAdminUser,
  removeAdminUserSuccess,
  getAdminGroupsFetch,
  getAdminGroupsSuccess,
  getAdminAgenciesFetch,
  getAdminAgenciesSuccess
} = AdminUsersSlice.actions;

export default AdminUsersSlice.reducer;
