import styled from 'styled-components';
import {
  colorState,
  device,
  radius,
  spacing,
  typography
} from '../../Universal/Foundation';

export const StyledLeftSidePanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  border-radius: ${radius.radius_12}px;
  box-shadow: 0px 1px 2px 0px rgba(23, 23, 28, 0.06),
    0px 1px 3px 0px rgba(23, 23, 28, 0.1);
  background-color: white;

  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    margin-bottom: 0;

    .custom-btn {
      width: 84px;
      height: 28px;
      padding: 8px 6px;
    }

    h5 {
      font-size: ${typography.size.tl};
      font-weight: ${typography.weight.semibold};
      color: ${colorState.text.primary};
      margin-bottom: 0;
    }
  }

  .panel-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    border-top: 1px solid ${colorState.border.default.primary};

    @media ${device.mobileL} {
      max-height: 360px;
      overflow: auto;
    }
  }

  .card-data {
    border: none;
    border-bottom: 1px solid ${colorState.border.default.primary};
    display: flex;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      .non-selected-closure {
        border-right: 5px solid ${colorState.border.brand.primary};
        margin: 10px 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .non-selected-closure {
      border-right: 5px solid ${colorState.border.default['focusring-bg']};
    }

    .selected-closure {
      border-right: 5px solid ${colorState.border.brand.primary};
      margin: 10px 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    section {
      width: 100%;
      padding: 16px;

      h2 {
        font-size: ${typography.size.ts};
        font-weight: ${typography.weight.semibold};
        padding: 8px 8px 16px;
        margin: 0;
        cursor: pointer;

        &:hover {
          color: ${colorState.text.link};
        }
      }

      .selected-closure-item {
        color: ${colorState.text.link};
        word-break: break-word;

        &:hover {
          text-decoration: underline;
        }

        &:focus-visible {
          outline: none;
          border: solid 2px ${colorState.border.brand.primary};
          border-radius: 8px;
        }
      }

      h4,
      p {
        font-size: ${typography.size.txs};
        cursor: default;
      }

      h4 {
        font-weight: ${typography.weight.regular};
        color: ${colorState.text.tertiary};
      }
      p {
        font-weight: ${typography.weight.semibold};
      }

      .card-status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: ${typography.size.ts};
        padding: 4px 10px 4px 12px;
        border-radius: ${radius.radius_6}px;
        font-weight: ${typography.weight.medium};
        margin-bottom: 16px;
        cursor: default;
      }

      .status-wrapper,
      .updates-wrapper {
        word-wrap: wrap;

        h3 {
          font-size: ${typography.size.txs};
          color: ${colorState.text.tertiary};
          font-weight: ${typography.weight.regular};
          cursor: default;
        }
      }

      .read-by-text-wrapper {
        display: flex;
        justify-content: start;
        align-items: center;
        word-break: break-word;

        h3,
        p {
          font-size: ${typography.size.txs};
          color: ${colorState.text.tertiary};
          font-weight: ${typography.weight.regular};
          margin-bottom: 0;
          cursor: default;
        }

        p {
          color: ${colorState.text.primary};
          padding-left: 4px;
        }
      }

      .action-dropdown {
        background-color: ${colorState.background['default-page-bg']};
        color: ${colorState.text.primary};
        border-radius: 10px;
        padding: 6px 12px;
        cursor: pointer;
        margin-bottom: 16px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px;

          &:focus-visible {
            outline: none;
            border: solid 2px ${colorState.border.brand.primary};
            border-radius: 10px;
            padding: 8px 6px;
          }
        }

        h3 {
          font-size: ${typography.size.ts};
          font-weight: ${typography.weight.semibold};
          color: ${colorState.surface.default['primary-inverse']};
          margin: 0;
        }

        .action-list {
          display: flex;
          flex-direction: column;
          gap: 5px;
          list-style: none;
          cursor: pointer;
          padding-left: 0;
          margin-bottom: 0;
        }

        ul :nth-child(2) svg path {
          fill: ${colorState.icon.default.primary};
          stroke: none;
        }

        ul .selected-item:nth-child(2) svg path {
          fill: ${colorState.icon.brand.primary};
          stroke: none;
        }

        ul :hover:nth-child(2) svg path {
          fill: ${colorState.icon.brand.primary};
          stroke: none;
        }

        .notification-badge {
          display: flex;
          align-items: center;
          gap: ${spacing.spacing_6}px;
          background-color: ${colorState.surface.default.tertiary};
          border: 1px solid ${colorState.border.brand.primary};
          border-radius: ${spacing.spacing_8}px;
          padding: ${spacing.spacing_6}px ${spacing.spacing_8}px;
          margin: 0 ${spacing.spacing_2}px 0 ${spacing.spacing_8}px;

          &:hover {
            background-color: ${colorState.surface.default.primary};
          }

          @media ${device.laptop} {
            padding: ${spacing.spacing_2}px ${spacing.spacing_4}px;
          }
        }
      }

      li {
        font-size: ${typography.size.ts};
        display: flex;
        padding: 8px;
        align-items: center;

        &:hover {
          background-color: ${colorState.surface.brand.tertiary};

          svg path {
            stroke: ${colorState.icon.brand.primary};
          }

          h4 {
            color: ${colorState.text.brand.secondary};
          }
        }

        &:focus-visible {
          outline: none;
          border: solid 1px ${colorState.border.brand.primary};
          border-radius: ${radius.radius_8}px;
        }

        h4 {
          font-size: ${typography.size.ts};
          font-weight: ${typography.weight.semibold};
          color: ${colorState.surface.default['primary-inverse']};
          margin: 0;
          padding-left: 12px;
          font-weight: ${typography.weight.medium};
        }
      }

      .selected-item {
        background-color: ${colorState.surface.brand.tertiary};
        border-radius: ${radius.radius_8}px;
        align-items: center;
        border: 1px solid ${colorState.border.brand.tertiary};

        h4 {
          color: ${colorState.text.brand.secondary};
        }
      }

      .mark-as-read-text {
        text-wrap: nowrap;
        text-align: center;
        color: ${colorState.text.secondary};
        cursor: pointer;
        text-decoration: underline;
        font-size: ${typography.size.ts};
        align-self: center;
        font-weight: ${typography.weight.medium};

        &:focus-visible {
          padding: 5px;
          outline: none;
          border-radius: 8px;
          border: 1px solid ${colorState.border.brand.primary};
        }
      }
      .disabled {
        opacity: 70%;
        cursor: not-allowed;
      }
    }
  }
`;
