import { useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { Modal } from '../../Universal/NovusDSImports';
import {
  IPAWSRequestsReceived,
  getIPAWSRequests
} from '../../Store/reducers/IPAWS';
import { RootState } from '../../store';
import GenericTableRT7 from '../GenericTableComponent/GenericTable';
import { STRINGS } from '../../Constants/ConstantStrings';
import dayjs from 'dayjs';
import RequestAndResponseContent from './RequestAndResponseContent';
import { modalStyles } from '../../Universal/NovusDSImports/variants';
import PALoader from '../../SharedComponets/PALoader';

const IPAWSRequestsHistory = (props: any) => {
  const { selectedClosure } = props;

  const requestHistory = useReduxSelector(
      (state: RootState) => state.IPAWS.IPAWSRequestsHistoryData
    ),
    isFetchingIPAWSRequestsHistory = useReduxSelector(
      (state: RootState) => state.IPAWS.isFetchingIPAWSRequestsHistoryData
    ),
    [isContentOpen, setIsContentOpen] = useState<boolean>(false),
    [isRequest, setIsRequest] = useState<boolean>(false),
    [selectedRow, setSelectedRow] = useState<any>(null),
    dispatch = useReduxDispatch();

  useEffect(() => {
    dispatch(
      getIPAWSRequests({ closureId: selectedClosure?.id, limit: 'all' })
    );
  }, [dispatch, selectedClosure?.id]);

  useEffect(() => {
    return () => {
      dispatch(IPAWSRequestsReceived({ IPAWSRequestsHistoryData: [] }));
    };
  }, [dispatch]);

  const getColumns = () => {
    return [
      {
        Header: STRINGS.REQUESTED_DATE_TIME,
        accessor: 'requested_on',
        Cell: function (data: any) {
          return (
            <div>
              {dayjs(data.row.original.requested_on).format(
                'MM/DD/YYYY hh:mm A'
              )}
            </div>
          );
        }
      },
      {
        Header: STRINGS.POSTED_DATE_TIME,
        accessor: 'posted_on',
        Cell: function (data: any) {
          return (
            <div>
              {dayjs(data.row.original.posted_on).format('MM/DD/YYYY hh:mm A')}
            </div>
          );
        }
      },
      {
        Header: STRINGS.ALERT_IDENTIFIER,
        accessor: 'identifier'
      },
      {
        Header: STRINGS.REQUESTED_BY,
        Cell: function (data: any) {
          return <div>{data.row.original.requested_by?.fullname}</div>;
        }
      },
      {
        Header: STRINGS.POSTED_BY,
        Cell: function (data: any) {
          return <div>{data.row.original.posted_by?.fullname}</div>;
        }
      },
      {
        Header: STRINGS.SUCCEEDED,
        accessor: 'succeeded',
        Cell: function (data: any) {
          return <div>{data.row.original.succeeded ? 'Yes' : 'No'}</div>;
        }
      },
      {
        Header: STRINGS.REQUEST_CONTENT,
        accessor: 'request_xml',
        Cell: function (data: any) {
          return (
            <div
              tabIndex={0}
              aria-label="generate-pdf"
              onClick={() => {
                setIsRequest(true);
                setIsContentOpen(true);
                setSelectedRow(data.row.original);
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  setIsRequest(true);
                  setIsContentOpen(true);
                  setSelectedRow(data.row.original);
                }
              }}
              className="link"
            >
              {STRINGS.VIEW}
            </div>
          );
        }
      },
      {
        Header: STRINGS.RESPONSE_CONTENT,
        accessor: 'response_xml',
        Cell: function (data: any) {
          return (
            <div
              tabIndex={0}
              aria-label="generate-pdf"
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  setIsContentOpen(true);
                  setSelectedRow(data.row.original);
                }
              }}
              onClick={() => {
                setIsContentOpen(true);
                setSelectedRow(data.row.original);
              }}
              className="link"
            >
              {STRINGS.VIEW}
            </div>
          );
        }
      }
    ];
  };

  return (
    <>
      {isFetchingIPAWSRequestsHistory ? (
        <PALoader />
      ) : requestHistory?.length ? (
        <GenericTableRT7
          tableData={requestHistory}
          columns={getColumns()}
          noDataFoundScreen={
            !requestHistory?.length && !isFetchingIPAWSRequestsHistory
          }
        />
      ) : (
        <div className="p-4">No Requests Approved</div>
      )}
      <RequestAndResponseContent
        isOpen={isContentOpen}
        showHeader={true}
        title={isRequest ? STRINGS.REQUEST_CONTENT : STRINGS.RESPONSE_CONTENT}
        postModalClose={() => {
          setIsRequest(false);
          setIsContentOpen(false);
          setSelectedRow(null);
        }}
        selectedRow={selectedRow}
        isRequest={isRequest}
        {...modalStyles}
      />
    </>
  );
};

export default Modal(IPAWSRequestsHistory);
