import styled from 'styled-components';
import { device } from '../../Universal/Foundation/breakpoints';
import { colorState, radius } from '../../Universal/Foundation';

const cardHeight = 660;

export const StyledGrid = styled.div`
  background-color: white;
  min-height: ${cardHeight}px;
  max-height: max-content;
  border: 1px solid ${colorState.border.default.secondary};
  box-shadow: 0px 16px 32px 0px rgba(36, 100, 228, 0.2);
  border-radius: ${radius.radius_28}px;
  padding: 30px;
  overflow: auto;

  #logo-img {
    width: 100%;
    padding-bottom: 30px;

    @media ${device.laptop} {
      padding-bottom: 20px;
    }
  }

  @media ${device.mobileL} {
    overflow: initial;
    height: auto;
    max-height: max-content;
    padding: 30px;
    box-shadow: none;
    border: none;
  }
`;
