import React, {
  FunctionComponent,
  ReactNode,
  CSSProperties,
  useRef,
  useEffect,
  useState
} from 'react';
import { Tooltip } from 'react-tooltip';
import { TooltipView, TooltipWrapper } from './styles';
import { TooltipProps } from './helpers';

export const TooltipComponent: FunctionComponent<TooltipProps> = ({
  children,
  place,
  id,
  noArrow,
  style,
  containerStyle,
  tooltipBgColor,
  tooltipTextColor,
  tooltipMaxWidth,
  arrowColor,
  isOpen, // New prop to control tooltip visibility,
  borderColor,
  openOnClick,
  closeOnScroll,
  position,
  className,
  customHeight,
  preventCloseOnContentClick,
  noPadding,
  ...props
}) => {
  const [tooltipStyle, setTooltipStyle] = useState<CSSProperties>({});
  const containerRef = useRef<HTMLDivElement>(null);

  //   useEffect(() => {
  //     if (containerRef.current) {
  //       const containerRect = containerRef.current.getBoundingClientRect();
  //       const tooltipRect =
  //         containerRef.current.firstElementChild?.getBoundingClientRect();

  //       if (tooltipRect) {
  //         let updatedStyle: CSSProperties = { ...style };

  //         // Adjust the tooltip's position based on boundaries
  //         if (place === 'top' && tooltipRect.top < containerRect.top) {
  //           updatedStyle.top = containerRect.top - tooltipRect.top + 'px';
  //         }
  //         // Add similar checks for other placement options

  //         setTooltipStyle(updatedStyle);
  //       }
  //     }
  //   }, [place, style]);

  const handleContentClick = (event: React.MouseEvent) => {
    if (preventCloseOnContentClick) {
      event.stopPropagation();
    }
  };



  return (
    <TooltipWrapper
      ref={containerRef}
      style={containerStyle}
      tooltipBgColor={tooltipBgColor}
      tooltipTextColor={tooltipTextColor}
      tooltipMaxWidth={tooltipMaxWidth}
      borderColor={borderColor}
      customHeight={customHeight}
      noPadding={noPadding}
    >
      <Tooltip
        place={place}
        className={className || 'tooltip'}
        id={id}
        noArrow={noArrow}
        style={tooltipStyle}
        openOnClick={openOnClick}
        closeOnEsc={true}
        closeOnScroll={closeOnScroll}
        position={position}
        isOpen={isOpen}
        arrowColor={arrowColor}
        clickable={true}
      >
        <TooltipView onClick={handleContentClick}>{children}</TooltipView>
      </Tooltip>
    </TooltipWrapper>
  );
};

TooltipComponent.defaultProps = {
  place: 'bottom',
  id: 'arcadis-tooltip',
  children: 'Tooltip content',
  noArrow: false,
  tooltipMaxWidth: '200px',
  tooltipBgColor: 'black',
  tooltipTextColor: 'white',
  borderColor: '#ccc',
  openOnClick: false,
  closeOnScroll: false,
  arrowColor: 'black',
  // isOpen: undefined,
  customHeight: '180px'
};
