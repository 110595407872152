import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { STRINGS } from '../../../Constants/ConstantStrings';
import {
  getClosureQuestions,
  getPublicClosuresData,
  getUserPhoneNumber,
  registerSubmit,
  submitted,
  userPhoneNumberReceived
} from '../../../Store/reducers/PublicView';
import {
  Button,
  Icon,
  Input,
  Select,
  Switch
} from '../../../Universal/NovusDSImports';
import {
  btnStyles,
  inputStyles,
  selectStyles,
  switchStyles
} from '../../../Universal/NovusDSImports/variants';
import TermsAndConditions from '../TermsConditions';
import {
  FormPrimaryHeader,
  HighlightMessage,
  PublicViewContainerStyles,
  UserRegistrationForm
} from '../styles';
import { useReduxDispatch, useReduxSelector } from '../../../Store/reduxHooks';
import { RootState } from '../../../store';
import { ProcessTypes } from '../types';
import axios from 'axios';
import { BASE_URL } from '../../../settings';
import { colorState } from '../../../Universal/Foundation';
import PALoader from '../../../SharedComponets/PALoader';
import { useTextTruncation } from '../../../Hooks/useTextTruncation';
import { showPopUp } from '../../../CommonUtilities/CommonUtilities';

export default function ClosureRegistrationForm() {
  const history = useHistory(),
    dispatch = useReduxDispatch(),
    [showTermsAndConditions, setShowTermsAndConditions] =
      useState<boolean>(false),
    [isNewQuestionsSubmit, setisNewQuestionsSubmit] = useState<boolean>(false),
    [formQuestions, setFormQuestions] = useState<any>([]),
    [submitValues, setSubmitValues] = useState<any>({}),
    [isOptLocation, setIsOptLocation] = useState<boolean>(true),
    [menuIsOpenState, setMenuIsOpenState] = useState<{
      [key: string]: boolean;
    }>({}),
    countryCode = '+1',
    { isExpanded, isTruncated, messageRef, readMore, toggleExpand } =
      useTextTruncation(),
    [isSubmit, setIsSubmit] = useState(false),
    [showLocationError, setShowLocationError] = useState<any>({
      isError: false,
      message: ''
    }),
    selectedClosureQuestions = useReduxSelector(
      (state: RootState) => state.PublicView.selectedClosureQuestions
    ),
    isFetchingClosures = useReduxSelector(
      (state: RootState) => state.PublicView.isfetchingPublicClosuresData
    ),
    publicClosures = useReduxSelector(
      (state: RootState) => state.PublicView.publicClosuresData
    ),
    isRegisterSuccesful = useReduxSelector(
      (state: RootState) => state.PublicView.isRegisterSuccesful
    ),
    isFetchingClosureQuestions = useReduxSelector(
      (state: RootState) => state.PublicView.isFetchingClosureQuestions
    ),
    isSubmitting = useReduxSelector(
      (state: RootState) => state.PublicView.isSubmitting
    ),
    isFetchingPhoneNumber = useReduxSelector(
      (state: RootState) => state.PublicView.isFetchingUserPhoneNumber
    ),
    userPhoneNumber = useReduxSelector(
      (state: RootState) => state.PublicView.registeredUserPhoneNumber
    ),
    isRegistrationUnsucessful = useReduxSelector(
      (state: RootState) => state.PublicView.isRegisterUnsuccessful
    );

  useEffect(() => {
    return () => {
      dispatch(userPhoneNumberReceived({ phoneNumber: null }));
    };
  }, []);

  const setAxiosDefaults = () => {
    axios.defaults.baseURL = BASE_URL;
    axios.defaults.headers.patch['Content-Type'] =
      'application/json; charset=utf-8';
    axios.defaults.headers.put['Content-Type'] =
      'application/json; charset=utf-8';
    axios.defaults.headers.post['Content-Type'] =
      'application/json; charset=utf-8';
  };

  const urlParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return Object.fromEntries(params.entries());
  }, [window.location.search]);

  useEffect(() => {
    if (urlParams?.user_uuid) {
      dispatch(getUserPhoneNumber({ phoneNumberUUID: urlParams?.user_uuid }));
    }
  }, [urlParams?.user_uuid]);

  useEffect(() => {
    setAxiosDefaults();
    if (urlParams?.closure_id) {
      if (!publicClosures?.length) {
        dispatch(getPublicClosuresData({}));
      } else if (
        !publicClosures?.find(
          (closure) => String(closure.id) === urlParams?.closure_id
        )
      ) {
        showPopUp('Closure not found or has been closed', 'error');
        history.push('/');
      } else if (urlParams?.questions) {
        setisNewQuestionsSubmit(true);
        dispatch(
          getClosureQuestions({
            closureId: urlParams?.closure_id,
            questions: urlParams?.questions
          })
        );
      } else {
        dispatch(getClosureQuestions({ closureId: urlParams?.closure_id }));
      }
    } else {
      history.push('/');
    }
  }, [dispatch, history, publicClosures, urlParams]);

  const processSubmitValues = (value: any, id: any, key: ProcessTypes) => {
    const data = { ...submitValues };
    data[id] = key === 'numeric' && value !== '' ? Number(value) : value;
    setSubmitValues(data);
  };

  useEffect(() => {
    if (selectedClosureQuestions) {
      const data: Array<any> = [];
      data.push({
        id: 'phone_number',
        value: '',
        display_name: STRINGS.MOBILE_NUMBER,
        field_type: 'numeric'
      });
      if (!isNewQuestionsSubmit) {
        data.push({
          id: 'contact_by',
          value: '',
          display_name: STRINGS.CONTACT_BY,
          field_type: 'choice',
          multiple_choices: [
            { label: STRINGS.TEXT_MESSAGE, value: 'text' },
            { label: STRINGS.AUTOMATED_PHONE_CALL, value: 'call' }
          ]
        });
      }
      const finalData = [...data, ...selectedClosureQuestions];
      setFormQuestions(finalData);
      const temp = {};
      finalData.forEach((item: any) => {
        temp[item.id] = '';
      });
      if (userPhoneNumber) {
        temp['phone_number'] = userPhoneNumber.slice(2);
      }
      setSubmitValues(temp);
    }
  }, [selectedClosureQuestions, isNewQuestionsSubmit, userPhoneNumber]);

  useEffect(() => {
    if (isRegisterSuccesful) {
      history.push('/');
    }
  }, [isRegisterSuccesful, history]);

  const validationCheck = () => {
    for (const key in submitValues) {
      if (Object.hasOwnProperty.call(submitValues, key)) {
        if (
          submitValues[key] === null ||
          submitValues[key] === undefined ||
          submitValues[key] === '' ||
          submitValues['phone_number'].toString().length < 10
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = (positions: any) => {
    if (submitValues && Object.keys(submitValues).length) {
      const data = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        answers: (({ contact_by, closure, phone_number, ...rest }) => rest)(
          submitValues
        ),
        closure: urlParams?.closure_id,
        location: isOptLocation
          ? {
              type: 'Point',
              coordinates: [
                positions.coords.longitude,
                positions.coords.latitude
              ]
            }
          : null
      };

      data['phone_number'] = countryCode + submitValues['phone_number'];

      if (!isNewQuestionsSubmit) {
        data['contact_by'] = submitValues['contact_by'];
      }
      dispatch(
        registerSubmit({ data, isNewQuestionsSubmit: isNewQuestionsSubmit })
      );
    }
  };

  const onRegisterSubmit = (e: Event) => {
    e.preventDefault();
    setShowLocationError({
      isError: false,
      message: ''
    });
    setIsSubmit(true);
    if (
      submitValues &&
      Object.keys(submitValues).length &&
      validationCheck() &&
      validateNumericRange('validate', selectedClosureQuestions)
    ) {
      if (isOptLocation) {
        navigator.geolocation?.getCurrentPosition(
          (position) => {
            handleSubmit(position);
          },
          (error) => {
            setIsSubmit(false);
            if (error?.code === 1) {
              setShowLocationError({
                isError: true,
                message: STRINGS.LOCATION_PERMISSION_ERROR
              });
            } else {
              setIsSubmit(false);
              setShowLocationError({
                isError: true,
                message: STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN
              });
            }
          }
        );
      } else if (isRegistrationUnsucessful) {
        dispatch(submitted({ isRegisterUnsuccessful: false }));
        setIsSubmit(false);
      } else {
        handleSubmit([]);
      }
    }
  };

  const validateNumericRange = (type, item) => {
    if (type === 'isError') {
      if (
        (!submitValues[item.id] && submitValues[item.id] !== 0) ||
        Number(submitValues[item.id]) < item.min_value ||
        Number(submitValues[item.id]) > item.max_value
      ) {
        return true;
      }
      return false;
    } else if (type === 'isErrorMessage') {
      if (!submitValues[item.id] && submitValues[item.id] !== 0) {
        return `${item.display_name} is required`;
      } else if (
        Number(submitValues[item.id]) < item.min_value ||
        Number(submitValues[item.id]) > item.max_value
      ) {
        return `Value range Should be ${item.min_value} to ${item.max_value}`;
      }
      return '';
    } else if (type === 'validate') {
      let isValid = true;
      selectedClosureQuestions.forEach((question) => {
        if (
          question.field_type === 'numeric' &&
          (submitValues[question.id] < question.min_value ||
            submitValues[question.id] > question.max_value)
        ) {
          isValid = false;
        }
      });
      return isValid;
    }
    return false;
  };

  const onCancel = (e) => {
    e.preventDefault();
    history.push('/');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const onBack = () => {
    setShowTermsAndConditions(false);
  };

  const handleClose = (id: string) => {
    setMenuIsOpenState(() => ({
      [id]: false // Close only the select-dropdown with the given item.id
    }));
  };

  const handleOpen = (id: string) => {
    setMenuIsOpenState(() => ({
      [id]: true // Open only the select-dropdown with the given item.id
    }));
  };

  return urlParams?.user_uuid && !userPhoneNumber && isFetchingPhoneNumber ? (
    <PALoader />
  ) : (
    <PublicViewContainerStyles>
      {!isFetchingClosures ? (
        <>
          {!showTermsAndConditions ? (
            <div
              className="container d-flex overflow-auto flex-column flex-grow-1 h-100 w-100"
              onKeyDown={handleKeyDown}
            >
              <FormPrimaryHeader className="text-lg-nowrap">
                <div className="form-main-heading">
                  {
                    publicClosures?.find(
                      (closure) => String(closure.id) === urlParams?.closure_id
                    )?.wea_short_msg_english
                  }
                </div>
                <div className="submain-heading">
                  {
                    publicClosures?.find(
                      (closure) => String(closure.id) === urlParams?.closure_id
                    )?.description
                  }
                </div>
              </FormPrimaryHeader>
              <HighlightMessage
                isExpanded={isExpanded}
                aria-label="content"
                tabIndex={0}
              >
                <div
                  className={`truncated-text ${
                    isExpanded && isTruncated ? 'expanded-text' : ''
                  }`}
                  ref={messageRef}
                >
                  {STRINGS.REGISTER_FOR_UPDATE}
                  <a
                    href="https://www.511pa.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    {STRINGS.PA_CONNECT_URL}
                  </a>{' '}
                  or{' '}
                  <a
                    href="https://www.paturnpike.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {STRINGS.PATURNPIKE_URL}
                  </a>{' '}
                  {STRINGS.FOR_INFORMATION}
                </div>
                {readMore && (
                  <span
                    className="toggle-expand"
                    onClick={toggleExpand}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        toggleExpand();
                      }
                    }}
                    role="textbox"
                    aria-label={readMore}
                    tabIndex={0}
                  >
                    {readMore}
                  </span>
                )}
              </HighlightMessage>
              {showLocationError?.isError && (
                <HighlightMessage messageType="error">
                  <Icon
                    icon={'alert_circle'}
                    stroke={colorState.icon.danger.secondary}
                    className="me-3"
                  />
                  {showLocationError?.message}
                </HighlightMessage>
              )}
              <UserRegistrationForm>
                <div className="form-body">
                  {formQuestions ? (
                    formQuestions.map((item: any) => (
                      <>
                        {' '}
                        {item.field_type === 'choice' ? (
                          <Select
                            key={item.id}
                            displayName={item.display_name}
                            placeholder={`Select option`}
                            onChange={(e) =>
                              processSubmitValues(e.value, item.id, 'choice')
                            }
                            menuIsOpen={menuIsOpenState[item.id] || false} // Control the open/close state for this specific select
                            onMenuOpen={() => handleOpen(item.id)}
                            onMenuClose={() => handleClose(item.id)}
                            menuPortalTarget={document.getElementById('root')}
                            closeMenuOnScroll={(e) => {
                              if (
                                e.target.className === 'form-body' &&
                                e.target.className !==
                                  'react-select-dropdown__menu-list'
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                            options={
                              item.multiple_choices
                                ? item.id === 'contact_by'
                                  ? item.multiple_choices
                                  : item.multiple_choices.map(
                                      (question: any) => ({
                                        label: question,
                                        value: question
                                      })
                                    )
                                : []
                            }
                            asterisk={true}
                            {...selectStyles}
                            error={
                              isSubmit &&
                              (!submitValues[item.id] || !submitValues)
                            }
                            hintText={
                              isSubmit &&
                              (!submitValues[item.id] || !submitValues) &&
                              `${item.display_name} is required`
                            }
                          />
                        ) : (
                          <>
                            {item.id === 'phone_number' ? (
                              <div className="row" key={item.id}>
                                <div className="text-nowrap col-4 col-sm-3 col-lg-2 pe-0">
                                  <Input
                                    name="Country Code"
                                    label="Country Code"
                                    placeholder="+1 (USA)"
                                    isMargin={false}
                                    {...inputStyles}
                                    disabled={true}
                                  />
                                </div>
                                <div className="col-8 col-sm-9 col-lg-10 ps-2">
                                  <Input
                                    key={item.id}
                                    label={item.display_name}
                                    type="text"
                                    placeholder={`Enter ${item.display_name}`}
                                    isMargin={false}
                                    value={submitValues?.[item.id]}
                                    disabled={!!userPhoneNumber}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value &&
                                          e.target.value.length < 11) ||
                                        !e.target.value
                                      ) {
                                        const input = e.target.value;
                                        // Validate input to allow only numbers
                                        const sanitizedInput = input.replace(
                                          /[^0-9]/g,
                                          ''
                                        );
                                        processSubmitValues(
                                          sanitizedInput,
                                          item.id,
                                          'numeric'
                                        );
                                      }
                                    }}
                                    {...inputStyles}
                                    bgColor="white"
                                    error={
                                      isSubmit &&
                                      (!submitValues ||
                                        !submitValues[item.id] ||
                                        submitValues[item.id].toString()
                                          .length < 10)
                                    }
                                    hintText={
                                      isSubmit &&
                                      (!submitValues ||
                                        !submitValues[item.id] ||
                                        submitValues[item.id].toString()
                                          .length < 10) &&
                                      `${item.display_name} should be 10 digits`
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <Input
                                key={item.id}
                                label={item.display_name}
                                placeholder={`Enter ${item.display_name}`}
                                type={'number'}
                                isMargin={false}
                                {...inputStyles}
                                value={submitValues && submitValues[item.id]}
                                onChange={(e) => {
                                  processSubmitValues(
                                    e.target.value,
                                    item.id,
                                    'numeric'
                                  );
                                }}
                                onKeyDown={(e: any) => {
                                  if (['e', 'E', '+', '-', '.'].includes(e.key))
                                    e.preventDefault();
                                }}
                                bgColor="white"
                                asterisk={true}
                                error={
                                  isSubmit &&
                                  validateNumericRange('isError', item)
                                }
                                hintText={
                                  isSubmit &&
                                  validateNumericRange('isErrorMessage', item)
                                }
                              />
                            )}
                          </>
                        )}
                      </>
                    ))
                  ) : (
                    <>{isFetchingClosureQuestions ? <PALoader /> : null}</>
                  )}
                  <div className="form-group d-flex align-items-center">
                    <h6 className="me-3 mb-0 pb-0">
                      {STRINGS.DO_YOU_WANT_OPT_IN_LOCATION_SERVICE}
                    </h6>
                    <Switch
                      id="enabled"
                      selected={isOptLocation}
                      onClick={() => setIsOptLocation(!isOptLocation)}
                      {...switchStyles}
                    />
                  </div>
                  <div>
                    {STRINGS.TERMS_AND_CONDITION_DESCRIPTION}
                    <span
                      tabIndex={0}
                      className="navigation-link"
                      onClick={() => setShowTermsAndConditions(true)}
                    >
                      {STRINGS.TERMS_AND_CONDITIONS_PATH}
                    </span>
                  </div>
                </div>
                <div className="form-footer">
                  <Button
                    onClick={onCancel}
                    {...btnStyles.secondary}
                    className="flex-grow-2 flex-md-grow-0"
                    disabled={isSubmitting}
                    type="button"
                  >
                    {STRINGS.CANCEL}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    onClick={(e) => onRegisterSubmit(e)}
                    {...btnStyles.primary}
                    className="flex-grow-1 flex-md-grow-0"
                  >
                    {isSubmitting ? STRINGS.SUBMITTING : STRINGS.SUBMIT}
                  </Button>
                </div>
              </UserRegistrationForm>
            </div>
          ) : null}
          {showTermsAndConditions && <TermsAndConditions onBack={onBack} />}
        </>
      ) : (
        <PALoader />
      )}
    </PublicViewContainerStyles>
  );
}
