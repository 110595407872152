import { useState } from 'react';
import { useSelector } from 'react-redux';
import { STRINGS } from '../../Constants/ConstantStrings';
import {
  requestOtp,
  requestOtpReceived,
  resetLoginStoreValues,
  verifyOtp
} from '../../Store/reducers/Login';
import { useReduxDispatch } from '../../Store/reduxHooks';
import { colorState } from '../../Universal/Foundation';
import { Button, Icon, Select } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  selectStyles
} from '../../Universal/NovusDSImports/variants';
import OtpVerifyPage from './OtpVerifyPage';
import { FlexCenter } from './styles';
import { RootState } from '../../store';
import { useFormContext } from 'react-hook-form';
import { isProdInstance } from '../../settings';

const AuthenticationPage = () => {
  const dispatch = useReduxDispatch(),
    [otp, setOtp] = useState(''),
    [selectedType, setSelectedType] = useState<any>(null),
    loginData = useSelector((state: RootState) => state.Login.loginData),
    formContext = useFormContext(),
    isOtpRequested = useSelector(
      (state: RootState) => state.Login.isOtpRequested
    ),
    isSubmitButtonLoading = useSelector(
      (state: RootState) => state.Login.isSubmitButtonLoading
    );

  const getAuthOptions = () => {
    const options: any = [];
    if (loginData?.email) {
      options.push({
        label: loginData.email,
        value: loginData.email,
        id: 'email'
      });
      if (!selectedType) {
        setSelectedType({
          label: loginData.email,
          value: loginData.email,
          id: 'email'
        });
      }
    }
    if (loginData?.phone) {
      options.push({
        label: loginData.phone,
        value: loginData.phone,
        id: 'sms'
      });
    }
    return options;
  };

  const onChangeAuthType = (type) => {
    setSelectedType(type);
  };

  const requestOTPfn = () => {
    setOtp('');
    const params = {
      type: selectedType?.id
    };
    dispatch(requestOtp({ params }));
  };

  const submit = () => {
    if (isOtpRequested) {
      const params = {
        code: otp
      };
      dispatch(verifyOtp({ params }));
    } else {
      requestOTPfn();
    }
  };

  const prevPage = () => {
    formContext.reset();
    if (isOtpRequested) {
      dispatch(requestOtpReceived({ isOtpRequested: false, expires: '' }));
    } else {
      dispatch(resetLoginStoreValues());
      // dispatch(logoutReceived());
    }
  };

  const isSubmitButtonDisabled = () => {
    if (isOtpRequested) {
      return !(otp.length === 6);
    }
    return !selectedType;
  };

  return (
    <>
      {isOtpRequested || localStorage.getItem('isLoggedIn') ? (
        <>
          <h1>
            {isProdInstance
              ? STRINGS.LOG_IN_TO_YOUR_LIVE_ACCOUNT
              : STRINGS.LOG_IN_TO_YOUR_TRAINING_ACCOUNT}
          </h1>
          <OtpVerifyPage
            selectedType={selectedType}
            setOtp={setOtp}
            otp={otp}
            requestOTPfn={requestOTPfn}
          />
        </>
      ) : (
        <>
          <h1>
            {isProdInstance
              ? STRINGS.LOG_IN_TO_YOUR_LIVE_ACCOUNT
              : STRINGS.LOG_IN_TO_YOUR_TRAINING_ACCOUNT}
          </h1>
          <Select
            {...selectStyles}
            displayName={STRINGS.TWO_FACTOR_AUTHENTICATION}
            placeHolder={STRINGS.PLEASE_SELECT_OPTION}
            asterisk={false}
            options={getAuthOptions()}
            value={selectedType}
            onChange={onChangeAuthType}
          />
          {selectedType && (
            <p className="text-description pt-2">
              {STRINGS.CLICK_SUBMIT_BUTTON}
              {selectedType.id === 'email' ? 'email' : 'mobile number'}
            </p>
          )}
        </>
      )}
      <Button
        className={'w-100'}
        {...btnStyles.primary}
        disabled={isSubmitButtonDisabled()}
        isLoading={isSubmitButtonLoading}
        onClick={submit}
      >
        {STRINGS.SUBMIT}
      </Button>
      <FlexCenter className="flex-column mt-4">
        <Button
          onClick={() => prevPage()}
          {...btnStyles.tertiary}
          className="w-100"
        >
          <Icon
            icon="arrow_narrow_left"
            stroke={`${colorState.icon.brand.primary}`}
          />
          <span className="ms-1">
            {isOtpRequested || localStorage.getItem('isLoggedIn')
              ? STRINGS.GO_BACK
              : STRINGS.GO_BACK_TO_LOGIN_PAGE}{' '}
          </span>
        </Button>
      </FlexCenter>
    </>
  );
};

export default AuthenticationPage;
