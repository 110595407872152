import React, { useState, useEffect } from 'react';
import { Button, Icon } from '../../Universal/NovusDSImports';
import { btnStyles } from '../../Universal/NovusDSImports/variants';
import { SidePanelProps } from './helpers';
import { StyledLeftSidePanel } from './styles';
import { useReduxSelector } from '../../Store/reduxHooks';
import { RootState } from '../../store';

const LeftSidePanel: React.FC<SidePanelProps> = ({
  header,
  icon,
  body,
  isFilterButton,
  isMobileCollapsible
}) => {
  const [isCollapse, setIsCollapse] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapse(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobileScreen = useReduxSelector(
    (state: RootState) => state.Common.isMobileScreen
  );

  const handleCollapse = () => {
    if (isMobileScreen) {
      setIsCollapse(!isCollapse);
    }
  };
  return (
    <StyledLeftSidePanel>
      <div className="panel-header" onClick={handleCollapse}>
        <h5>{header}</h5>
        {!isMobileCollapsible && (
          <Icon
            icon={`${isCollapse ? 'chevron_down' : 'chevron_up'}`}
            onClick={handleCollapse}
          />
        )}
        {isFilterButton && (
          <Button {...btnStyles.default} className={'custom-btn'}>
            <Icon icon={icon} fill="none" width={16} height={16} />
            Filters
          </Button>
        )}
      </div>
      {(isMobileCollapsible || !isCollapse) && (
        <div className="panel-content">{body}</div>
      )}
    </StyledLeftSidePanel>
  );
};

export default LeftSidePanel;
