import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  Suspense
} from 'react';
import {
  GoogleMap,
  DrawingManager,
  Polygon,
  useLoadScript,
  Autocomplete as MapSearchBox,
  Marker
} from '@react-google-maps/api';
import { GoogleMapsComponentType } from './shapes.types';
import {
  clearPolygon,
  getBoundaryBox,
  getCentroid,
  isPolygonInvalid,
  showError,
  showPopUp
} from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import { MapComponentWrapper } from '../globalStyles';
import GoogleMapsErrorBoundary from '../../GoogleMapsErrorBoundary';
import { GOOGLE_MAPS_API_KEY } from '../../settings';
import { Icon, Input } from '../../Universal/NovusDSImports';
import { SearchBoxWrapper } from '../Closures/styles';
import { inputStyles } from '../../Universal/NovusDSImports/variants';
import { colorState } from '../../Universal/Foundation';
import { locationIcon } from '../../Universal/Assets';
import PALoader from '../../SharedComponets/PALoader';
import { useReduxSelector } from '../../Store/reduxHooks';
import { RootState } from '../../store';

const libraries: any = ['drawing', 'places'];

const GoogleMapsComponent: React.FC<GoogleMapsComponentType> = ({
  editModeData,
  editModeType,
  drawnPolygon,
  isDrawing,
  setIsDrawing,
  setDrawnPolygon
}) => {
  const { isLoaded } = useLoadScript({
    id: 'PolygonMap',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries
  });
  const mapRef = useRef<google.maps.Map | null>(null);
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef<Array<google.maps.MapsEventListener>>([]);

  const searchBoxRef = useRef<any>(null);
  const [searchBox, setSearchBox] = useState<string>('');

  const onPlaceChanged = useCallback(() => {
    if (searchBoxRef.current) {
      const place = searchBoxRef.current.getPlace();
      if (place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setSearchBox(place.formatted_address);
        setCenter({ lat: latitude, lng: longitude });
        setZoomLevel(11);

        setSearchMarker({ lat: latitude, lng: longitude });
      }
    }
  }, []);

  const [center, setCenter] = useState<{ lat: any; lng: any }>({
    lat: 0,
    lng: 0
  });


  const stateBoundary = useReduxSelector((state:RootState)=> state.ClientInfo.state_boundary);

  const [zoomLevel, setZoomLevel] = useState<number>(2);
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const [searchMarker, setSearchMarker] = useState<any>(null);

  const onLoad = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      if (editModeData && editModeData.geometry && editModeType !== 'Add') {
        editModeData.geometry.coordinates[0].forEach((point: any) => {
          bounds.extend({ lat: point[0], lng: point[1] });
        });
        const value = getCentroid(editModeData.geometry.coordinates[0]);
        map.fitBounds(bounds);
        setCenter({ lat: value[1], lng: value[0] });
      } else if(stateBoundary){
        const boundary = getBoundaryBox(stateBoundary.coordinates)
        bounds.extend({lat: boundary[1], lng: boundary[0]});
        bounds.extend({lat: boundary[3], lng: boundary[0]});
        bounds.extend({lat: boundary[3], lng: boundary[2]});
        bounds.extend({lat: boundary[1], lng: boundary[2]});
        map.fitBounds(bounds);
      }
      mapRef.current = map;
    },
    [editModeData, editModeType, stateBoundary]
  );

  useEffect(()=>{
    if(stateBoundary){
      const centerPoint = getCentroid(stateBoundary.coordinates[0]);

      setCenter({
        lat: centerPoint[0],
        lng: centerPoint[1]
      })

      setZoomLevel(8);
    }
  }, [stateBoundary, setCenter]);

  useEffect(() => {
    if (editModeData && editModeData.geometry && editModeType !== 'Add') {
      setDrawnPolygon(editModeData.geometry.coordinates[0]);
      setShowClearButton(true);
    }
  }, [editModeData, setDrawnPolygon, editModeType]);

  const onDrawComplete = (polygon: any) => {
    const polygonCoordsArray: any = [];
    const coords = polygon?.getPath()?.getArray();
    clearPolygon(polygon);
    for (let i = 0; i < coords.length; i++) {
      polygonCoordsArray.push([coords[i].lat(), coords[i].lng()]);
    }

    if(stateBoundary?.coordinates){
      const polygonInvalidError = isPolygonInvalid(polygonCoordsArray, false, stateBoundary?.coordinates);
      if (polygonInvalidError) {
        handleClear();
        showPopUp(polygonInvalidError, 'error');
        return;
      }
    }else{
      showError(STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    }


    polygonCoordsArray.push(polygonCoordsArray[0]);

    setDrawnPolygon(polygonCoordsArray);
    polygonRef.current?.setMap(mapRef.current);
    setShowClearButton(true);
    setIsDrawing(false);
  };

  const onUnmount = useCallback((map) => {
    mapRef.current = null;
  }, []);

  const getPolygonPath = (coords: any) => {
    const path: any = [];
    coords &&
      coords.forEach((point: any) => {
        path.push({ lat: point[0], lng: point[1] });
      });
    return path;
  };

  const handleClear = () => {
    clearPolygon(polygonRef.current);
    setDrawnPolygon(null);
    setShowClearButton(false);
    setIsDrawing(true);
  };

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath: any = [];
      polygonRef.current
        .getPath()
        .getArray()
        .forEach((latLng) => {
          nextPath.push([latLng.lat(), latLng.lng()]);
        });
      setDrawnPolygon(nextPath);
    }
  }, [setDrawnPolygon]);

  const onPolygonLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      if (editModeType !== 'View')
        listenersRef.current.push(
          path?.addListener('set_at', onEdit),
          path?.addListener('insert_at', onEdit),
          path?.addListener('remove_at', onEdit)
        );
    },
    [onEdit, editModeType]
  );

  return isLoaded ? (
    <MapComponentWrapper id="map-wrapper">
      <Suspense fallback={<PALoader />}>
        <GoogleMapsErrorBoundary>
          <GoogleMap
            id="PolygonPreviewModal"
            mapContainerStyle={{
              width: '100%',
              height: '100%',
              borderRadius: 8
            }}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={zoomLevel}
            onClick={(event: google.maps.MapMouseEvent) => {
              event.stop();
            }}
            options={{
              maxZoom: 18,
              streetViewControl: false,
              mapTypeControl: true,
              mapTypeControlOptions: {
                position: window.google.maps.ControlPosition.BOTTOM_LEFT,
                mapTypeIds: ['roadmap', 'satellite', 'terrain', 'hybrid']
              },
              fullscreenControl: true
              // restriction: {
              //   strictBounds: false,
              //   latLngBounds: PA_BOUNDARY
              // }
            }}
          >
            <SearchBoxWrapper>
              <MapSearchBox
                onLoad={(searchBox) => (searchBoxRef.current = searchBox)}
                onPlaceChanged={onPlaceChanged}
              >
                <Input
                  type="text"
                  placeholder="Search for a location"
                  hideLabel
                  value={searchBox}
                  onRightIconActionClick={() => {
                    setSearchBox('');
                    setSearchMarker(null);
                  }}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setSearchMarker(null);
                      setSearchBox('');
                    } else {
                      setSearchBox(e.target.value);
                    }
                  }}
                  iconLeft={
                    <Icon
                      icon={'search'}
                      stroke={colorState.icon.default.secondary}
                    />
                  }
                  iconRight={
                    searchBox && (
                      <Icon
                        icon={'close'}
                        stroke={colorState.icon.default.secondary}
                      />
                    )
                  }
                  {...inputStyles}
                />
              </MapSearchBox>
            </SearchBoxWrapper>

            {searchMarker && !showClearButton && (
              <Marker position={searchMarker} icon={locationIcon} />
            )}
            {isDrawing && (
              <>
                <DrawingManager
                  drawingMode={google.maps.drawing.OverlayType.POLYGON}
                  onPolygonComplete={(e: any) => onDrawComplete(e)}
                  options={{
                    drawingControl: true,
                    drawingControlOptions: {
                      position: window.google.maps.ControlPosition.TOP_LEFT,
                      drawingModes: [
                        window.google.maps.drawing.OverlayType.POLYGON
                      ]
                    },
                    polygonOptions: {
                      fillColor: 'red',
                      fillOpacity: 0.2,
                      strokeWeight: 3,
                      strokeColor: 'red',
                      clickable: false,
                      zIndex: 300
                    }
                  }}
                />
              </>
            )}
            {showClearButton && (
              <div
                onClick={handleClear}
                className="polygon-clear-button"
                tabIndex={0}
              >
                {STRINGS.CLEAR}
              </div>
            )}
            {(isDrawing || drawnPolygon) && (
              <Polygon
                onLoad={onPolygonLoad}
                paths={getPolygonPath(drawnPolygon)}
                options={{
                  fillColor: 'red',
                  fillOpacity: 0.2,
                  strokeWeight: 3,
                  strokeColor: 'red',
                  clickable: false,
                  zIndex: 300,
                  editable: editModeType === 'Edit'
                }}
                onMouseUp={onEdit}
              />
            )}
          </GoogleMap>
        </GoogleMapsErrorBoundary>
      </Suspense>
    </MapComponentWrapper>
  ) : (
    <></>
  );
};

export default GoogleMapsComponent;
