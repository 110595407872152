import { polygon } from '@turf/helpers';
import kinks from '@turf/kinks';
/**
 *
 * @param {Array<[number, number]>} polygonArray Array of coordinates for the polygon
 * @returns {boolean} If polygon is intersecting with itself or not
 */
const isPolygonIntersecting = (
  polygonArray: Array<[number, number]>,
  isPolygonComplete: boolean = false
): boolean => {
  if (!isPolygonComplete) {
    polygonArray = [...polygonArray, polygonArray[0]];
  }
  const turfPolygon = polygon([polygonArray], {
    name: 'polygon'
  });
  return kinks(turfPolygon)?.features.length === 0;
};

export default isPolygonIntersecting;
