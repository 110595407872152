import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPermission } from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import ConfirmationPopupModal from '../../SharedComponets/ConfirmationPopupModal';
import NoDataFound from '../../SharedComponets/NoDataFound';
import { TooltipComponent } from '../../SharedComponets/Tooltip';
import { deleteCategory } from '../../Store/reducers/ClosureQuestions';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { NoQuestionsIcon } from '../../Universal/Assets';
import { colorState } from '../../Universal/Foundation';
import { Button, Icon } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  confirmPopupModalStyles
} from '../../Universal/NovusDSImports/variants';
import { RootState } from '../../store';
import GenericTableRT7 from '../GenericTableComponent/GenericTable';

const CategoryListTable = (props: any) => {
  const {
    setIsModalOpen,
    setCategoryEditData,
    setIsCategoryEdit,
    setCategoryData,
    addCategory,
    setAddNewCategorySubmit
  } = props;

  const currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false),
    [deletedCategory, setDeletedCategory] = useState<any>(),
    categories = useSelector((state: any) => state.Questions.categories),
    dispatch = useReduxDispatch();

  const customConfirmationStyles = {
    content: {
      minWidth: '20%',
      minHeight: '10vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const onDeleteCategory = (data) => {
    setIsDeleteModalOpen(true);
    setDeletedCategory(data);
  };

  const onDelete = () => {
    setIsDeleteModalOpen(false);
    setDeletedCategory(null);
    const payload = {
      id: deletedCategory.id
    };
    dispatch(deleteCategory(payload));
  };

  const renderConfirmationFooter = () => {
    return (
      <div className="gap-3 d-flex">
        <Button
          onClick={() => setIsDeleteModalOpen(false)}
          {...btnStyles.default}
        >
          {STRINGS.CANCEL}
        </Button>
        <Button onClick={onDelete} {...btnStyles.danger}>
          {STRINGS.YES_DELETE}
        </Button>
      </div>
    );
  };

  const editQuestion = (data) => {
    setAddNewCategorySubmit(false);
    setIsCategoryEdit(true);
    setIsModalOpen(true);
    setCategoryEditData(data);
    setCategoryData(data.name);
  };

  const columns = [
    {
      Header: STRINGS.CATEGORY_NAME,
      accessor: 'name'
    },
    {
      Header: STRINGS.ACTIONS,
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="d-flex">
          {getPermission(currentUser, 'change_closureticket') && (
            <div
              role="button"
              aria-label="delete-action"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (!(row.original.label === 'Default Questions'))
                    onDeleteCategory(row.original);
                }
              }}
              onClick={() =>
                !(row.original.label === 'Default Questions') &&
                onDeleteCategory(row.original)
              }
              className={` ${
                row.original.label === 'Default Questions'
                  ? 'disabled me-2 me-md-5'
                  : 'me-2 me-md-5 action-icons'
              }`}
              data-tooltip-id={`delete-${row.original.id}`}
            >
              <Icon
                icon={'trash_01'}
                className={`${row.original.default && 'disabled'} trash`}
                height={20}
                width={20}
                stroke={
                  row.original.label === 'Default Questions' &&
                  colorState.icon.default.disabled
                }
              >
                {STRINGS.DELETE}
              </Icon>
              {!(row.original.label === 'Default Questions') && (
                <TooltipComponent id={`delete-${row.original.id}`}>
                  {STRINGS.DELETE}
                </TooltipComponent>
              )}
            </div>
          )}
          {getPermission(currentUser, 'change_field') && (
            <div
              role="button"
              aria-label="edit-action"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (!(row.original.label === 'Default Questions'))
                    editQuestion(row.original);
                }
              }}
              onClick={() =>
                !(row.original.label === 'Default Questions') &&
                editQuestion(row.original)
              }
              className={` ${
                row.original.label === 'Default Questions' ? 'disabled' : 'action-icons'
              }`}
              data-tooltip-id={`edit-${row.original.id}`}
            >
              <Icon
                icon={'edit_02'}
                height={20}
                width={20}
                stroke={
                  row.original.label === 'Default Questions' &&
                  colorState.icon.default.disabled
                }
              >
                {STRINGS.EDIT}
              </Icon>
              {!(row.original.label === 'Default Questions') && (
                <TooltipComponent id={`edit-${row.original.id}`}>
                  {STRINGS.EDIT}
                </TooltipComponent>
              )}
            </div>
          )}
        </div>
      )
    }
  ];

  const NoCategoryDataScreen = () => {
    return (
      <NoDataFound
        src={NoQuestionsIcon}
        description={STRINGS.YOUR_CATEGORY_LIST_ARE_EMPTY}
        alt={'no-questions-img'}
        isAbsolute
        subMsgDescription={
          STRINGS.YOUR_CATEGORY_LIST_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_CATEGORY_YET
        }
        btn={
          getPermission(currentUser, 'add_field') && (
            <Button {...btnStyles.primary} onClick={() => addCategory(true)}>
              <Icon
                icon={'help'}
                fill={colorState.icon.default['primary-inverse']}
                stroke={'none'}
              />
              {STRINGS.ADD_NEW_CATEGORY}
            </Button>
          )
        }
      />
    );
  };

  return (
    <>
      <GenericTableRT7
        columns={columns}
        tableData={categories}
        serialNumber
        showCheckBoxColumn={false}
        noDataFoundScreen={NoCategoryDataScreen()}
      />
      <ConfirmationPopupModal
        isOpen={isDeleteModalOpen}
        showHeader={true}
        title={STRINGS.DELETE}
        description={STRINGS.ARE_YOU_SURE_WANT_TO_DELETE_CATEGORY}
        showFooter={renderConfirmationFooter()}
        style={customConfirmationStyles}
        postModalClose={() => setIsDeleteModalOpen(false)}
        closeModal={() => setIsDeleteModalOpen(false)}
        showBorderTop={false}
        isBodyPaddingNone={true}
        customFooter={true}
        {...confirmPopupModalStyles}
      />
    </>
  );
};

export default CategoryListTable;
