import {
  lazy,
  MutableRefObject,
  Suspense,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
// import RightSidePanel from '../../SharedComponets/RightSidePanel';
import {
  Button,
  Input,
  TextArea,
  RadioBox,
  Select,
  CheckBox
} from '../../Universal/NovusDSImports';
import {
  btnStyles,
  checkBoxStyles,
  inputStyles,
  radioBoxStyles,
  selectStyles
} from '../../Universal/NovusDSImports/variants';
import { FormSegment } from '../globalStyles';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { addClosure } from '../../Store/reducers/Closures';
import {
  getCentroid,
  isPolygonInvalid,
  removeEventManagedByLabel,
  safeDecodeJWTToken,
  showPopUp,
  validateYupSchema,
  weaTextMessageMap
} from '../../CommonUtilities/CommonUtilities';
import Cookies from 'js-cookie';
import { STRINGS } from '../../Constants/ConstantStrings';
import html2canvas from 'html2canvas';
import { RootState } from '../../store';
import {
  clearUIforScreenshot,
  restoreUIforScreenshot
} from '../../CommonUtilities/screenshotUtlity';
import { isProdInstance, isUatInstance } from '../../settings';
import WEAMessageTextArea from './WEAMessageTextArea';
import PALoader from '../../SharedComponets/PALoader';

const RightSidePanel = lazy(
  () => import('../../SharedComponets/RightSidePanel')
);

interface AddEditClosureProps {
  drawnPolygon: any;
  isEdit: any;
  setDrawnPolygon: any;
  setIsDrawing: any;
  onClose: any;
  mapRef: MutableRefObject<google.maps.Map | null>;
  polygonRef: MutableRefObject<google.maps.Polygon | null>;
  setIsDrawMode: Function;
  affectedAreaType: any;
  setAffectedAreaType: Function;
}

const weaMessageAppendingUrl =
  'Go to ' +
  (isProdInstance
    ? 'https://paconnect.511pa.com/'
    : isUatInstance
    ? 'https://paconnect-t.511pa.com/'
    : 'https://paconnect.dev.ibidigital.com/');

export default function AddEditClosure(props: AddEditClosureProps) {
  const {
    drawnPolygon,
    isEdit,
    setDrawnPolygon,
    setIsDrawing,
    onClose,
    mapRef,
    setIsDrawMode,
    affectedAreaType,
    setAffectedAreaType
  } = props;
  const selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    [maintainedBy, setMaintainedBy] = useState<'penndot' | 'ptc' | 'both' | ''>(
      isEdit ? selectedClosure?.maintained_by : ''
    ),
    [isSaveForFuture, setIsSaveForFuture] = useState<boolean>(
      isEdit
        ? selectedClosure?.save_for_future
          ? selectedClosure?.save_for_future
          : false
        : false
    ),
    [isSendAlert, setIsSendAlert] = useState<boolean>(
      isEdit
        ? selectedClosure?.alert_status !== 'not_submitted'
          ? true
          : false
        : false
    ),
    [isMaintaniedBySelected, setIsMaintainedBySelected] =
      useState<boolean>(true),
    [isAffectedAreaTypeSelected, setIsAffectedAreaTypeSelected] =
      useState<boolean>(true),
    [showIPAWSAlert, setShowIPAWSAlert] = useState<boolean>(false),
    [isEditingState, setIsEditingState] = useState<boolean>(isEdit),
    [weaMessageAppendingUrlLength, setWeaMessageAppendingUrlLength] =
      useState<number>(0),
    dispatch = useReduxDispatch(),
    ticketStatuses = useReduxSelector(
      (state: RootState) => state.Closures.ticketStatuses
    ),
    eventNames = useReduxSelector(
      (state: RootState) => state.Closures.eventNames
    ),
    savedPolygons = useReduxSelector(
      (state: RootState) => state.Closures.savedPolygons
    ),
    isAddClosureSuccess = useReduxSelector(
      (state: RootState) => state.Closures.isAddClosureSuccess
    ),
    isSubmitting = useReduxSelector(
      (state: RootState) => state.Closures.isSubmitting
    );

  const savedPolygonOptions = () => {
    const savedPolygonsOption: any = [];
    savedPolygons?.forEach((polygon: any) => {
      if (
        !(
          selectedClosure?.new_polygon &&
          selectedClosure?.saved_polygon?.id === polygon.id
        )
      ) {
        savedPolygonsOption.push({
          label: polygon.polygon_name,
          value: polygon.id
        });
      }
    });
    return savedPolygonsOption;
  };

  const statusOptions = useCallback(() => {
    const statusOptions: any = [];
    ticketStatuses?.forEach((status: any) => {
      if (status.name !== 'Closed') {
        statusOptions.push({ label: status.name, value: status.id });
      }
    });
    return statusOptions;
  }, [ticketStatuses, isEdit, selectedClosure]);

  const eventTypeOptions = useCallback(() => {
    const eventOptions: any = [];
    eventNames?.forEach((status: any) => {
      eventOptions.push({ label: status.name, value: status.id });
    });
    return eventOptions;
  }, [eventNames]);

  const hourOptions = () => {
    const hourOptions: any = [];
    for (let i = 1; i <= 24; i++) {
      hourOptions.push({ label: i, value: i });
    }
    return hourOptions;
  };

  const getStatus = useCallback(
    (id: number) => {
      let option: any = null;
      statusOptions()?.forEach((status: any) => {
        if (status.value === id) {
          option = status;
        }
      });
      return option;
    },
    [statusOptions]
  );

  const getEventType = useCallback(
    (id: number) => {
      let option: any = null;
      eventTypeOptions()?.forEach((event: any) => {
        if (event.value === id) {
          option = event;
        }
      });
      return option;
    },
    [eventTypeOptions]
  );

  // const getSavedPlygon = (id: number) => {
  //   let option: any = null;
  //   savedPolygonOptions() &&
  //     savedPolygonOptions().forEach((polygon: any) => {
  //       if (polygon.value === id) {
  //         option = polygon;
  //       }
  //     });
  //   return option;
  // };

  const getHour = useCallback((id: number) => {
    let option: any = null;
    hourOptions()?.forEach((hour: any) => {
      if (hour.value === id) {
        option = hour;
      }
    });
    return option;
  }, []);

  const getDefaultValues = useCallback(() => {
    const dict: any = {
      eventId: isEdit
        ? selectedClosure?.event_id
        : `${dayjs().format('YYYYMMDDHHmm')}`,
      shortMessage: isEdit ? selectedClosure?.wea_short_msg_english : '',
      longMessage: isEdit ? selectedClosure?.wea_long_msg_english : '',
      spanishShortMessage: isEdit
        ? selectedClosure?.wea_short_msg_spanish ||
          weaTextMessageMap[maintainedBy]
        : '',
      spanishLongMessage: isEdit
        ? selectedClosure?.wea_long_msg_spanish ||
          weaTextMessageMap[maintainedBy]
        : '',
      description: isEdit ? selectedClosure?.description : '',
      centerLat: isEdit
        ? `${selectedClosure?.centre_latitude}`
        : drawnPolygon
        ? getCentroid(drawnPolygon)[1]
        : '',
      centerLong: isEdit
        ? selectedClosure?.centre_longitude
        : drawnPolygon
        ? getCentroid(drawnPolygon)[0]
        : '',
      affectedArea:
        isEdit &&
        !selectedClosure?.new_polygon &&
        selectedClosure?.saved_polygon
          ? {
              label: selectedClosure?.saved_polygon?.polygon_name,
              value: selectedClosure?.saved_polygon?.id
            }
          : null,
      status: isEdit ? getStatus(selectedClosure?.status.id) : null,
      eventType: isEdit ? getEventType(selectedClosure?.event_type) : null,
      alertDuration: isEdit ? getHour(selectedClosure?.alert_duration) : null,
      polygonName: ''
    };
    return dict;
  }, [
    drawnPolygon,
    getEventType,
    getHour,
    getStatus,
    isEdit,
    selectedClosure,
    ticketStatuses
  ]);

  const schema = yup.object().shape({
    eventId: validateYupSchema('eventId', STRINGS.YUP.EVENT_ID, true),
    shortMessage: validateYupSchema(
      'weaMessage',
      STRINGS.YUP.SHORT_MESSAGE,
      true,
      { maxNumber: 90 - weaMessageAppendingUrlLength }
    ),
    longMessage: validateYupSchema(
      'weaMessage',
      STRINGS.YUP.LONG_MESSAGE,
      true,
      { maxNumber: 360 - weaMessageAppendingUrlLength }
    ),
    spanishShortMessage: validateYupSchema(
      'weaMessage',
      STRINGS.YUP.SPANISH_SHORT_MESSAGE,
      false,
      { maxNumber: 90 - weaMessageAppendingUrlLength }
    ),
    spanishLongMessage: validateYupSchema(
      'weaMessage',
      STRINGS.YUP.SPANISH_LONG_MESSAGE,
      false,
      { maxNumber: 360 - weaMessageAppendingUrlLength }
    ),
    description: validateYupSchema('string', 'Description', true),
    affectedArea: validateYupSchema(
      'select',
      STRINGS.YUP.AFFECTED_AREA,
      affectedAreaType === 'select_polygon'
    ),
    centerLat: validateYupSchema('string', STRINGS.YUP.CENTER_LATITUDE, true),
    centerLong: validateYupSchema('string', STRINGS.YUP.CENTER_LONGITUDE, true),
    status: validateYupSchema('select', STRINGS.YUP.STATUS, true),
    polygonName: validateYupSchema(
      'string',
      STRINGS.YUP.POLYGON_NAME,
      isSaveForFuture
    ),
    eventType: validateYupSchema(
      'select',
      STRINGS.YUP.EVENT_CATEGORY,
      isSendAlert
    ),
    alertDuration: validateYupSchema(
      'select',
      STRINGS.YUP.ALERT_DURATION,
      isSendAlert
    )
  });

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors, isSubmitted }
  } = methods;

  useEffect(() => {
    if (selectedClosure) {
      reset(getDefaultValues());
      setDrawnPolygon(selectedClosure?.geometry?.coordinates[0]);
    }
    // getDefaultValues needs to be memoized or similar to fix this eslint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, selectedClosure, setDrawnPolygon]);

  useEffect(() => {
    if (isEdit && selectedClosure?.alert_status !== 'not_submitted') {
      setIsSendAlert(true);
    } else {
      setIsSendAlert(false);
    }
    if (isEdit && selectedClosure) {
      setAffectedAreaType(
        !selectedClosure?.new_polygon && selectedClosure?.saved_polygon
          ? 'select_polygon'
          : 'draw_polygon'
      );
    } else {
      setAffectedAreaType('');
    }
  }, [isEdit, selectedClosure]);

  const watchWEAShortMessage = watch('shortMessage'),
    watchWEALongMessage = watch('longMessage'),
    watchWEAShortMessageSpanish = watch('spanishShortMessage'),
    watchWEALongMessageSpanish = watch('spanishLongMessage'),
    watchAffectedArea = watch('affectedArea'),
    watchStatus = watch('status'),
    watchShortMessage = watch('shortMessage');

  useEffect(() => {
    const val = getValues('shortMessage');
    //using iseditingstate to stop the shortmessage getting assigned to long message when default value is assigned in edit mode
    if (val.length >= 0 && !isEditingState) {
      setValue('longMessage', val);
      if (isSubmitted) {
        trigger('longMessage');
      }
    }
    //if spanish long message doesnot exists, then set the iseditingstate false here itself
    //if spanish long message exists, then iseditstate will be made false in succeeding useeffect (watchweashortmessagespanish)
    if (isEdit && !selectedClosure?.wea_long_msg_spanish) {
      setIsEditingState(false);
    }
  }, [getValues, setValue, watchShortMessage, selectedClosure]);

  useEffect(() => {
    const val = getValues('spanishShortMessage');

    if (val?.length >= 0 && !isEditingState) {
      setValue('spanishLongMessage', val);
      if (isSubmitted) {
        trigger('spanishLongMessage');
      }
    }
    setIsEditingState(false);
  }, [getValues, setValue, watchWEAShortMessageSpanish]);

  useEffect(() => {
    setShowIPAWSAlert(
      watchStatus && (watchStatus.value === 1 || watchStatus.value === 2)
    );
    setWeaMessageAppendingUrlLength(
      watchStatus?.label === 'Two-Way Communications (Open)'
        ? weaMessageAppendingUrl?.length + 1
        : 0
    );
  }, [watchStatus]);

  useEffect(() => {
    if (isSaveForFuture) setIsSaveForFuture(false);
  }, [affectedAreaType]);

  const stateBoundary = useReduxSelector(
    (state: RootState) => state.ClientInfo.state_boundary
  );

  const onSubmit = async (data: any) => {
    const componentRef = document.getElementById('map-wrapper');
    clearUIforScreenshot();
    if (componentRef) {
      await html2canvas(componentRef, { useCORS: true })
        .then((canvas) => {
          const screenshot = canvas.toDataURL('image/webp');
          return screenshot.split(',')[1];
        })
        .then((screenShot) => {
          if (stateBoundary?.coordinates) {
            const polygonInvalidError = isPolygonInvalid(
              drawnPolygon,
              true,
              stateBoundary?.coordinates
            );

            if (polygonInvalidError) {
              throw new Error(polygonInvalidError);
            }
            return screenShot;
          } else {
            throw new Error(STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
          }
        })
        .then((screenShot) => {
          restoreUIforScreenshot();
          setIsAffectedAreaTypeSelected(
            affectedAreaType?.length ? true : false
          );
          setIsMaintainedBySelected(maintainedBy?.length ? true : false);

          if (!drawnPolygon && affectedAreaType === 'draw_polygon') {
            showPopUp(STRINGS.PLEASE_DRAW_A_POLYGON, 'warning');
          } else if (isAffectedAreaTypeSelected && maintainedBy?.length) {
            const json = JSON.parse(
              JSON.stringify(safeDecodeJWTToken(Cookies.get('access') || ''))
            );
            const payload = {
              centre_latitude: data.centerLat,
              wea_short_msg_english: data.shortMessage,
              wea_long_msg_english: data.longMessage,
              wea_short_msg_spanish: removeEventManagedByLabel(
                data.spanishShortMessage
              )?.length
                ? data.spanishShortMessage
                : null,
              wea_long_msg_spanish: removeEventManagedByLabel(
                data.spanishLongMessage
              )?.length
                ? data.spanishLongMessage
                : null,
              status_id: data.status.value,
              event_id: data.eventId,
              centre_longitude: data.centerLong,
              alert_status: isSendAlert ? 'pending_review' : 'not_submitted',
              save_for_future: isSaveForFuture,
              description: data.description,
              created_user: json.user_id,
              maintained_by: maintainedBy,
              closure_image: screenShot,
              saved_polygon_id: data.affectedArea?.value || null,
              new_polygon: affectedAreaType === 'draw_polygon',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  drawnPolygon.map((coords) => [coords[1], coords[0]])
                ]
              }
            };
            if (isSaveForFuture) {
              payload['polygon_name'] = data.polygonName;
            }
            if (isSendAlert) {
              payload['event_type'] = data.eventType.value;
              payload['alert_duration'] = data.alertDuration.value;
            } else {
              payload['event_type'] = null;
              payload['alert_duration'] = null;
            }
            if (isEdit) {
              payload['id'] = selectedClosure?.id;
              dispatch(addClosure({ isEdit: true, params: payload }));
            } else {
              dispatch(addClosure({ params: payload }));
            }
          }
        })
        .catch((error) => {
          showPopUp(error, 'error');
        });
    }
  };

  useEffect(() => {
    if (isAddClosureSuccess) {
      setDrawnPolygon(null);
      onClose();
    }
  }, [isAddClosureSuccess, onClose]);

  useEffect(() => {
    if (drawnPolygon) {
      setValue('centerLat', getCentroid(drawnPolygon)[1]);
      setValue('centerLong', getCentroid(drawnPolygon)[0]);
    } else if (drawnPolygon === null) {
      setValue('affectedArea', null);
      setValue('centerLat', '');
      setValue('centerLong', '');
    }
  }, [drawnPolygon, setValue]);

  const options = [
    { value: 'penndot', label: 'PennDot' },
    { value: 'ptc', label: 'PTC' },
    { value: 'both', label: 'Both' }
  ];

  const affectedAreaTypeOptions = [
    { label: STRINGS.DRAW_POLYGON_ON_MAP, value: 'draw_polygon' },
    { label: STRINGS.CHOOSE_FROM_SHAPES_LIBRARY, value: 'select_polygon' }
  ];

  const onAffectedAreaChange = (e: any) => {
    setIsDrawing(false);
    setValue('affectedArea', e);
    savedPolygons?.forEach((polygon: any) => {
      if (polygon.id === e.value) {
        setDrawnPolygon(polygon.geometry.coordinates[0]);
        setValue('centerLat', getCentroid(polygon.geometry.coordinates[0])[1]);
        setValue('centerLong', getCentroid(polygon.geometry.coordinates[0])[0]);
        const bounds = new google.maps.LatLngBounds(null);
        polygon.geometry.coordinates[0].forEach((point) => {
          bounds.extend({ lat: point[0], lng: point[1] });
        });
        mapRef.current?.fitBounds(bounds);
      }
    });
  };

  const onInValid = () => {
    setIsAffectedAreaTypeSelected(affectedAreaType?.length ? true : false);
    setIsMaintainedBySelected(maintainedBy ? true : false);
    if (!drawnPolygon && affectedAreaType === 'draw_polygon') {
      showPopUp(STRINGS.PLEASE_DRAW_A_POLYGON, 'warning');
    }
  };

  useEffect(() => {
    if (watchAffectedArea) {
      setIsSaveForFuture(false);
    }
  }, [watchAffectedArea]);

  const SectionBody = () => {
    return (
      <FormProvider {...methods}>
        <FormSegment onSubmit={handleSubmit(onSubmit, onInValid)}>
          <section className="formset-fields">
            <div className="form-group">
              <Controller
                name="eventId"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="eventId"
                    label={STRINGS.EVENT_ID}
                    type="text"
                    placeholder={STRINGS.ENTER_EVENT_ID}
                    isMargin={false}
                    {...inputStyles}
                    asterisk
                    error={errors?.eventId}
                    hintText={errors?.eventId?.message}
                  />
                )}
              />
            </div>
            <div className="custom-radio-group">
              <label>
                {STRINGS.EVENT_MANAGED_BY} <span className="asterik">*</span>
              </label>
              <div className="form-group">
                {options?.map((option: any, index: number) => (
                  <RadioBox
                    id="rb-unchecked"
                    key={index}
                    disabled={false}
                    text={option.label}
                    checked={maintainedBy === option.value}
                    onChange={() => {
                      setIsMaintainedBySelected(true);
                      setMaintainedBy(option.value);
                    }}
                    {...radioBoxStyles}
                  />
                ))}
              </div>
              {!isMaintaniedBySelected && (
                <p className="error-text">{STRINGS.THIS_FIELD_IS_REQUIRED}</p>
              )}
            </div>

            <div className="form-group">
              <Controller
                name="status"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <>
                      <Select
                        value={value}
                        onChange={onChange}
                        displayName={STRINGS.STATUS}
                        placeHolder={STRINGS.SELECT_OPTION}
                        options={statusOptions()}
                        asterisk
                        {...selectStyles}
                        error={errors?.status}
                        hintText={errors?.status?.message}
                      />
                      {watchStatus?.label ===
                        'Two-Way Communications (Open)' && (
                        <p className="text-start mt-2">
                          {`
                      Note: '${weaMessageAppendingUrl}' will be appended to your WEA message title automatically.`}
                        </p>
                      )}
                    </>
                  );
                }}
              />
            </div>

            {showIPAWSAlert && (
              <div className="form-group">
                <CheckBox
                  onClick={() => {
                    if (
                      !isEdit ||
                      (isEdit &&
                        selectedClosure?.alert_status === 'not_submitted')
                    ) {
                      if (isSendAlert && isEdit) {
                        setValue('eventType', null);
                        setValue('alertDuration', null);
                      }
                      setIsSendAlert(!isSendAlert);
                    }
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === ' ') {
                      if (
                        !isEdit ||
                        (isEdit &&
                          selectedClosure?.alert_status === 'not_submitted')
                      ) {
                        if (isSendAlert && isEdit) {
                          setValue('eventType', null);
                          setValue('alertDuration', null);
                        }
                        setIsSendAlert(!isSendAlert);
                      }
                    }
                  }}
                  selected={isSendAlert}
                  disabled={
                    isEdit && selectedClosure?.alert_status !== 'not_submitted'
                  }
                  size={'md'}
                  text={
                    <p
                      onClick={() => {
                        if (
                          !isEdit ||
                          (isEdit &&
                            selectedClosure?.alert_status === 'not_submitted')
                        ) {
                          if (isSendAlert && isEdit) {
                            setValue('eventType', null);
                            setValue('alertDuration', null);
                          }
                          setIsSendAlert(!isSendAlert);
                        }
                      }}
                      className="checkbox-label"
                    >
                      {STRINGS.SEND_IPAWS_ALERT}{' '}
                      <span>({STRINGS.REMEMBER_TO_CALL_PEMA})</span>
                    </p>
                  }
                  {...checkBoxStyles}
                />
              </div>
            )}

            <div className="form-group">
              <Controller
                name="shortMessage"
                control={control}
                render={({ field }) => {
                  return (
                    <>
                      <WEAMessageTextArea
                        {...field}
                        maxLength={90 - weaMessageAppendingUrlLength}
                        id="shortMessage"
                        label="511PA WEA Short Message"
                        placeholder="e.g. I-80 WB Closed from Exit xx (Town) to Exit xx (Town)"
                        hintText={errors?.shortMessage?.message || ''}
                        {...inputStyles}
                        asterisk
                        error={errors?.shortMessage}
                        maintainedBy={maintainedBy}
                      />

                      <p className="text-right">
                        {90 -
                          weaMessageAppendingUrlLength -
                          (watchWEAShortMessage?.length || 0)}{' '}
                        {STRINGS.CHARACTERS_REMAINING}
                      </p>
                    </>
                  );
                }}
              />
            </div>

            <div className="form-group">
              <Controller
                name="longMessage"
                control={control}
                render={({ field }) => {
                  return (
                    <>
                      <WEAMessageTextArea
                        {...field}
                        maxLength={360 - weaMessageAppendingUrlLength}
                        id="longMessage"
                        label="511PA WEA Long Message"
                        placeholder="Write a Long Message"
                        hintText={errors?.longMessage?.message || ''}
                        error={errors?.longMessage}
                        {...inputStyles}
                        asterisk
                        maintainedBy={maintainedBy}
                      />
                      <p className="text-right">
                        {360 -
                          weaMessageAppendingUrlLength -
                          (watchWEALongMessage?.length || 0)}{' '}
                        {STRINGS.CHARACTERS_REMAINING}
                      </p>
                    </>
                  );
                }}
              />
            </div>

            <div className="form-group">
              <Controller
                name="spanishShortMessage"
                control={control}
                render={({ field }) => {
                  return (
                    <>
                      <WEAMessageTextArea
                        {...field}
                        maxLength={90 - weaMessageAppendingUrlLength}
                        id="spanishShortMessage"
                        label="511PA WEA Short Message in Spanish (Not Required)"
                        placeholder="Write a Short Message"
                        hintText={errors?.spanishShortMessage?.message || ''}
                        error={errors?.spanishShortMessage}
                        maintainedBy={maintainedBy}
                        {...inputStyles}
                      />
                      {/* <p>
                      {`Note: ‘Ir a ${BASE_URL}/’
                      will be appended to your WEA message title automatically.`}
                    </p> */}
                      <p className="text-right">
                        {90 -
                          weaMessageAppendingUrlLength -
                          (watchWEAShortMessageSpanish?.length || 0)}{' '}
                        {STRINGS.CHARACTERS_REMAINING}
                      </p>
                    </>
                  );
                }}
              />
            </div>
            <div className="form-group">
              <Controller
                name="spanishLongMessage"
                control={control}
                render={({ field }) => {
                  return (
                    <>
                      <WEAMessageTextArea
                        {...field}
                        maxLength={360 - weaMessageAppendingUrlLength}
                        id="spanishLongMessage"
                        label="511PA WEA Long Message in Spanish (Not Required)"
                        placeholder="Write a Long Message"
                        hintText={errors?.spanishLongMessage?.message || ''}
                        error={errors?.spanishLongMessage}
                        maintainedBy={maintainedBy}
                        {...inputStyles}
                      />
                      {/* <p>
                      Note: ‘Ir a ${BASE_URL}/ will be appended to your WEA
                      message title automatically.
                    </p> */}
                      <p className="text-right">
                        {360 -
                          weaMessageAppendingUrlLength -
                          (watchWEALongMessageSpanish?.length || 0)}{' '}
                        {STRINGS.CHARACTERS_REMAINING}
                      </p>
                    </>
                  );
                }}
              />
            </div>
            <div className="form-group">
              <Controller
                control={control}
                name="description"
                render={({ field }) => {
                  return (
                    <TextArea
                      {...field}
                      label={STRINGS.DESCRIPTION}
                      placeholder={STRINGS.ENTER_TEXT}
                      {...inputStyles}
                      asterisk
                      error={errors?.description}
                      hintText={errors?.description?.message}
                    />
                  );
                }}
              />
            </div>

            <div className="custom-radio-group">
              <label>
                {STRINGS.SELECT_AFFECTED_AREA}{' '}
                <span className="asterik">*</span>
              </label>
              {affectedAreaTypeOptions?.map((option: any, index: number) => (
                <RadioBox
                  className={'form-group'}
                  id="rb-unchecked"
                  key={index}
                  disabled={false}
                  text={option.label}
                  checked={affectedAreaType === option.value}
                  onChange={() => {
                    setAffectedAreaType(option.value);
                    setIsAffectedAreaTypeSelected(true);
                    setIsDrawMode(
                      option.value === 'draw_polygon' ? true : false
                    );
                    setIsDrawing(
                      option.value === 'draw_polygon' ? true : false
                    );
                    setDrawnPolygon(null);
                    setValue('affectedArea', null);
                  }}
                  {...radioBoxStyles}
                />
              ))}
              {!isAffectedAreaTypeSelected && (
                <p className="error-text">{STRINGS.THIS_FIELD_IS_REQUIRED}</p>
              )}
            </div>
            {affectedAreaType === 'select_polygon' && (
              <div className="form-group affected-area">
                <Controller
                  name="affectedArea"
                  control={control}
                  render={({ field: { value } }) => {
                    return (
                      <>
                        <Select
                          value={value}
                          onChange={(e: any) => {
                            onAffectedAreaChange(e);
                          }}
                          placeHolder={STRINGS.SELECT_OPTION}
                          isSearchable
                          options={savedPolygonOptions()}
                          asterisk={true}
                          error={!watch('affectedArea') && errors?.affectedArea}
                          hintText={
                            !watch('affectedArea') &&
                            errors?.affectedArea?.message
                          }
                          {...selectStyles}
                        />
                      </>
                    );
                  }}
                />
              </div>
            )}

            <div className="form-group">
              <Controller
                name="centerLat"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      id="latitude"
                      label={STRINGS.CENTER_POINT_LATITUDE}
                      placeholder={STRINGS.ENTER_CENTER_POINT_LATITUDE}
                      isMargin={false}
                      {...inputStyles}
                      disabled
                      asterisk
                      error={!watch('centerLat') && errors?.centerLat}
                      hintText={
                        !watch('centerLat') && errors?.centerLat?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <div className="form-group">
              <Controller
                name="centerLong"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      id="longitude"
                      label={STRINGS.CENTER_POINT_LONGITUDE}
                      placeholder={STRINGS.ENTER_CENTER_POINT_LONGITUDE}
                      isMargin={false}
                      {...inputStyles}
                      disabled
                      asterisk
                      error={!watch('centerLong') && errors?.centerLong}
                      hintText={
                        !watch('centerLong') && errors?.centerLong?.message
                      }
                    />
                  );
                }}
              />
            </div>

            {affectedAreaType === 'draw_polygon' && !watchAffectedArea && (
              <div className="form-group">
                <CheckBox
                  selected={isSaveForFuture}
                  onClick={() => {
                    setIsSaveForFuture(!isSaveForFuture);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === ' ') {
                      setIsSaveForFuture(!isSaveForFuture);
                    }
                  }}
                  size={'md'}
                  text={STRINGS.SAVE_ALERT_POLYGON_FOR_FUTURE_USE}
                  {...checkBoxStyles}
                />
              </div>
            )}

            {isSaveForFuture && (
              <div className="form-group">
                <Controller
                  name="polygonName"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="polygonName"
                        label={STRINGS.POLYGON_NAME}
                        placeholder={STRINGS.ENTER_POLYGON_NAME}
                        isMargin={false}
                        {...inputStyles}
                        asterisk
                        error={errors?.polygonName}
                        hintText={errors?.polygonName?.message}
                      />
                    );
                  }}
                />
              </div>
            )}

            {isSendAlert && (
              <div>
                <div className="form-group">
                  <Controller
                    name="eventType"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          displayName={STRINGS.EVENT_CATEGORY}
                          value={value}
                          onChange={onChange}
                          placeHolder={STRINGS.SELECT_OPTION}
                          options={eventTypeOptions()}
                          asterisk
                          {...selectStyles}
                          error={errors?.eventType}
                          hintText={errors?.eventType?.message}
                        />
                      );
                    }}
                  />
                </div>

                <div className="form-group">
                  <Controller
                    name="alertDuration"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          value={value}
                          onChange={onChange}
                          displayName={STRINGS.ALERT_DURATION_HOUR_S}
                          placeHolder={STRINGS.SELECT_OPTION}
                          options={hourOptions()}
                          asterisk
                          {...selectStyles}
                          error={errors && errors.alertDuration}
                          hintText={errors?.alertDuration?.message}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </section>

          <section className="footer">
            <div className="d-flex gap-3 justify-content-end w-100">
              <Button
                {...btnStyles.secondary}
                onClick={onClose}
                disabled={isSubmitting}
                type="button"
              >
                Cancel
              </Button>
              <Button
                {...btnStyles.primary}
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting ? STRINGS.SAVING : STRINGS.SAVE}
              </Button>
            </div>
          </section>
        </FormSegment>
      </FormProvider>
    );
  };

  return (
    <Suspense fallback={<PALoader />}>
      <RightSidePanel
        header={`${isEdit ? 'Edit' : 'Add'} Closure`}
        body={SectionBody()}
        onClose={props.onClose}
      />
    </Suspense>
  );
}
